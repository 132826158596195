import { useEffect, useState, useRef, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useAdminRole, useTier } from '../../../../auth/hooks'
import { isMobile, isFirefox } from 'react-device-detect'
import {
    Flex,
    Center,
    Text,
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    Spacer,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react'

import SidebarSubfolderItem from '../components/SidebarSubfolderItem'
import MenuIcon from '../../../../icons/menu-icons/MenuIcon'
import PremiumBadge from '../../PremiumBadge'
import { ReactComponent as FolderTab } from '../assets/folder-tab.svg'
import { MdOutlineHighlightOff } from 'react-icons/md'

import {
    dataModeState,
    layerSelectionState,
    layerSelectionHandler,
    fullscreenModalVisibleState,
    wipMenuItemsState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../../globalState'

export default function SidebarFolderItem({ item, folderIsActive, isPremium }) {
    const {
        id,
        items,
        label,
        isDisabled,
        externalURL,
        type,
        wip,
        tier,
        canUse,
        unavailable,
    } = item

    const dataMode = useRecoilValue(dataModeState)
    const advsMode = dataMode === 'advs'
    const layerSelection = useRecoilValue(layerSelectionState)
    const layerHandler = useSetRecoilState(layerSelectionHandler)
    const [active, setActive] = useState(false)
    const [labelsVisible, setLabelsVisible] = useState(false)
    const [initialLabelsVisible, setInitialLabelsVisible] = useState(false)
    const [activeLabelsVisible, setActiveLabelsVisible] = useState(false)
    const [mounted, setMounted] = useState(false)
    const fullscreenModalOpen = useRecoilValue(fullscreenModalVisibleState)
    const [wipMenuItems, setWipMenuItems] = useRecoilState(wipMenuItemsState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isAdmin = useAdminRole()
    const userTier = useTier()
    const isHidden = (!wipMenuItems && wip) || (!wipMenuItems && id === 'wip')
    const mfaExpired = userTier > 1 && !mfaVerified

    useEffect(() => {
        if (label && !items) {
            if (layerSelection[dataMode][id]) {
                setActive(true)
            } else {
                setActive(false)
            }
        } else if (id && items) {
            const layers = items.map((i) => i.id)
            const filtered = layers.map((i) => layerSelection[dataMode][i])
            filtered && setActive(filtered.includes(true))
        }
    }, [layerSelection, dataMode, id, label, items])

    useEffect(() => {
        // precaution for when downgrading from admin role in testing
        if (wipMenuItems && !isAdmin) {
            setWipMenuItems(false)
        }
    }, [isAdmin, wipMenuItems, setWipMenuItems])

    const startTimer = useRef(null)
    const endTimer = useRef(null)
    const startTimerTwo = useRef(null)
    const endTimerTwo = useRef(null)

    //TODO check if this even is still relevant
    // Initial Tooltips on page load. Times out after 6 seconds
    useEffect(() => {
        if (folderIsActive) {
            startTimer.current = setTimeout(() => {
                setInitialLabelsVisible(folderIsActive)
            }, 300)
            setMounted(false)
            endTimer.current = setTimeout(() => {
                setInitialLabelsVisible(false)
                setMounted(true)
            }, 6000)
        } else {
            clearTimeout(startTimer.current)
            clearTimeout(endTimer.current)
            setInitialLabelsVisible(folderIsActive)
        }
        return () => clearTimeout(startTimer.current)
    }, [folderIsActive])

    useEffect(() => {
        if (initialLabelsVisible) {
            endTimer.current = setTimeout(() => {
                setInitialLabelsVisible(false)
                setMounted(true)
            }, 6000)
        }
        return () => clearTimeout(endTimer.current)
    }, [initialLabelsVisible])

    // Labels for non-subfolder items. Times out after 4 seconds
    useEffect(() => {
        if (active) {
            startTimerTwo.current = setTimeout(() => {
                setActiveLabelsVisible(active)
            }, 300)
        } else {
            clearTimeout(startTimerTwo.current)
            clearTimeout(endTimerTwo.current)
            setActiveLabelsVisible(active)
        }
        return () => clearTimeout(startTimerTwo.current)
    }, [active])

    useEffect(() => {
        if (activeLabelsVisible) {
            endTimerTwo.current = setTimeout(() => {
                setActiveLabelsVisible(false)
            }, 4000)
        }
        return () => clearTimeout(endTimerTwo.current)
    }, [activeLabelsVisible])

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )
    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const folderItemHover = useColorModeValue('light.40', 'dark.40') // Open and Hover
    const textHover = useColorModeValue('light.80', 'dark.80')
    const subfolderTitleColor = useColorModeValue('brand.strong', 'dark.80')
    const subfolderTitleBg = useColorModeValue('light.10', 'dark.10')
    const subfolderItemColor = useColorModeValue('brand.strong', 'dark.100')
    const subfolderItemBg = useColorModeValue('light.50', 'dark.50')
    const subfolderItemBgFF = useColorModeValue('light.51', 'dark.51')

    const hover = {
        _hover: {
            width: { base: '40px', md: '100%' },
            minWidth: { base: '40px', md: '100%' },
            backgroundColor: folderItemHover,
            color: textHover,
            borderTopRadius: {
                base: id === 'airspace' || id === 'notam' ? '10px' : '0',
                md: '0',
            },
        },
    }

    function showLabelHandler(value) {
        if (mounted && !initialLabelsVisible) {
            setLabelsVisible(value)
        }
    }

    if (items && !isHidden) {
        return (
            <Flex
                // display={isHidden ? 'none' : undefined}
                position={'relative'}
                flexDir="column"
                w="100%"
                alignItems={'center'}
                _hover={{
                    textDecor: 'none',
                    backgroundColor: folderItemHover,
                    color: textHover,
                    borderTopRadius: {
                        base:
                            id === 'advisories' || id === 'wip' ? '10px' : '0',
                        md: '0',
                    },
                }}
            >
                {initialLabelsVisible && (
                    <Flex // INITIAL MENU LABELS
                        h="100%"
                        w="250px"
                        pointerEvents="none"
                        alignItems="center"
                        position={'absolute'}
                        right={'-8px'}
                        transform={'translate(100%, 0)'}
                    >
                        <Text
                            pointerEvents="none"
                            textShadow="1px 1px 2px rgba(0, 0, 0, 0.7)"
                            py="0"
                            fontSize="13px"
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            fontWeight="500"
                            letterSpacing="0.5px"
                            paddingStart="1"
                            color="white"
                        >
                            {label}
                        </Text>
                    </Flex>
                )}
                <Menu position={'relative'} placement="right-start" gutter={0}>
                    {({ isOpen, onClose }) => (
                        <>
                            <Tooltip
                                display={(isOpen || !folderIsActive) && 'none'}
                                isOpen={labelsVisible}
                                placement="right"
                                label={label}
                                bg="gray.300"
                                color="white"
                                textShadow="1px 1px 2px rgba(0, 0, 0, 0.7)"
                                py="0"
                                fontSize="13px"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                fontWeight="500"
                                lineHeight="0.9"
                                letterSpacing="0.5px"
                                paddingStart="1"
                                boxShadow="none"
                                background="transparent"
                                openDelay={400}
                                borderRadius="lg"
                                pointerEvents="none"
                            >
                                {/* *** Sidebar Folder Menu *** */}
                                <MenuButton
                                    position={'relative'}
                                    onMouseOver={() =>
                                        showLabelHandler(
                                            !isOpen && true && !isMobile
                                        )
                                    }
                                    onMouseEnter={() =>
                                        showLabelHandler(
                                            !isOpen && true && !isMobile
                                        )
                                    }
                                    onMouseLeave={() => showLabelHandler(false)}
                                    w="100%"
                                    m={0}
                                    p={0}
                                    borderTopRadius={{
                                        base:
                                            !isOpen &&
                                            (id === 'advisories' ||
                                                id === 'wip' ||
                                                id === 'airspace')
                                                ? '10px'
                                                : '0',
                                        md: '0',
                                    }}
                                    borderTopLeftRadius={{
                                        base:
                                            id === 'advisories' ||
                                            id === 'wip' ||
                                            id === 'airspace'
                                                ? '10px'
                                                : '0',
                                        md: '0',
                                    }}
                                    _hover={{
                                        backgroundColor:
                                            !isOpen && folderItemHover,
                                        borderRadius: !isOpen && '0',
                                        borderTopRadius: {
                                            base:
                                                !isOpen &&
                                                (id === 'advisories' ||
                                                    id === 'wip' ||
                                                    id === 'airspace')
                                                    ? '10px'
                                                    : '0',
                                            md: '0',
                                        },
                                        borderTopLeftRadius: {
                                            base:
                                                id === 'advisories' ||
                                                id === 'wip' ||
                                                id === 'airspace'
                                                    ? '10px'
                                                    : '0',
                                            md: '0',
                                        },
                                    }}
                                >
                                    <Center
                                        w={{ base: '47px', md: '51px' }}
                                        paddingRight="1px"
                                        backgroundColor={
                                            isOpen
                                                ? subfolderTitleBg
                                                : 'transparent'
                                        }
                                        h={{ base: '44px', md: '42px' }}
                                        position="relative"
                                        borderTopLeftRadius={{
                                            base:
                                                id === 'advisories' ||
                                                id === 'wip' ||
                                                id === 'airspace'
                                                    ? '10px'
                                                    : '0',
                                            md: '0',
                                        }}
                                        boxShadow={
                                            isOpen &&
                                            '7px 3px 30px -7px rgba(0, 0, 0, 0.4)'
                                        }
                                    >
                                        <MenuIcon
                                            id={id}
                                            active={isOpen || active}
                                            color={
                                                !active &&
                                                isOpen &&
                                                subfolderTitleColor
                                            }
                                        />
                                        <Text fontSize="7px" display={'none'}>
                                            {label}
                                        </Text>
                                        {!isOpen && (
                                            <Icon
                                                as={FolderTab}
                                                boxSize="5px"
                                                opacity="0.5"
                                                position="absolute"
                                                bottom={'3px'}
                                                right={'3px'}
                                            />
                                        )}
                                    </Center>
                                </MenuButton>
                            </Tooltip>

                            {/* *** Sidebar Sub Folder Pop Out Menu *** */}
                            <MenuList
                                zIndex={1401}
                                borderLeftRadius="0"
                                p={0}
                                border="none"
                                w={275}
                                ml="1px"
                                color={subfolderItemColor}
                                background={
                                    isOpen && (isFirefox || fullscreenModalOpen)
                                        ? subfolderItemBgFF
                                        : isOpen && subfolderItemBg
                                }
                                borderRadius="20px"
                                boxShadow={
                                    fullscreenModalOpen
                                        ? '0px 5px 25px -5px rgba(0, 0, 0, 0.4)'
                                        : '0px 5px 25px -5px rgba(0, 0, 0, 0.1)'
                                }
                                sx={{
                                    clipPath: 'inset(-35px -35px -40px -1px)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <Flex
                                    h={{ base: '44px', md: '42px' }}
                                    p={'10px'}
                                    mb={2}
                                    ml="-2px"
                                    alignItems="center"
                                    borderTopRightRadius="20px"
                                    color={subfolderTitleColor}
                                    backgroundColor={subfolderTitleBg}
                                    boxShadow="-8px 3px 30px -10px rgba(0, 0, 0, 0.4)"
                                    clipPath="inset(0px -35px -40px 0px)"
                                >
                                    <Text
                                        fontSize={'15px'}
                                        pl={2}
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight={'500'}
                                    >
                                        {label}
                                    </Text>
                                </Flex>
                                {items.map((i, idx) => (
                                    <SidebarSubfolderItem
                                        key={idx}
                                        item={i}
                                        tier={tier}
                                        userTier={userTier}
                                        closeFolder={onClose}
                                        isPremium={isPremium}
                                        unavailable={unavailable}
                                    />
                                ))}
                                <Spacer h="12px" />
                            </MenuList>
                        </>
                    )}
                </Menu>
            </Flex>
        )
    } else if (!isHidden) {
        return (
            <Flex
                // display={isHidden ? 'none' : undefined}
                position={'relative'}
                flexDir="column"
                w="100%"
                alignItems={'center'}
                _hover={{
                    textDecor: 'none',
                    backgroundColor: folderItemHover,
                    color: textHover,
                    borderTopRadius: {
                        base:
                            id === 'airspace' || id === 'notam' ? '10px' : '0',
                        md: '0',
                    },
                }}
            >
                {(initialLabelsVisible ||
                    activeLabelsVisible ||
                    labelsVisible) && (
                    <Flex
                        h="100%"
                        w="250px"
                        pointerEvents="none"
                        alignItems="center"
                        position={'absolute'}
                        right={'-8px'}
                        transform={'translate(100%, 0)'}
                    >
                        <Text
                            // pointerEvents="none"
                            textShadow="1px 1px 2px rgba(0, 0, 0, 0.7)"
                            py="0"
                            fontSize="13px"
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            fontWeight="500"
                            letterSpacing="0.5px"
                            paddingStart="1"
                            color="white"
                            opacity={isHidden ? '0.4' : '1'}
                        >
                            {label}
                        </Text>
                    </Flex>
                )}
                <Button
                    position={'relative'}
                    p={0}
                    onMouseOver={() => {
                        showLabelHandler(true)
                    }}
                    onMouseEnter={() => showLabelHandler(true)}
                    onMouseLeave={() => showLabelHandler(false)}
                    color={subfolderItemColor}
                    border="none"
                    borderRadius={0}
                    h={{ base: '44px', md: '42px' }}
                    w={{ base: '40px', md: '100%' }}
                    minWidth={{ base: '40px', md: '100%' }}
                    onClick={() => {
                        if (!isDisabled && !unavailable) {
                            if (type === 'external') {
                                window.open(externalURL, '_blank')
                            } else if (label && canUse) {
                                return layerHandler({
                                    mode: dataMode,
                                    layer: id,
                                })
                            } else if (mfaExpired && !canUse) {
                                setShowResetMfaModal(true)
                            } else if (!canUse) {
                                setShowPricingTiersModal(true)
                            }
                        }
                    }}
                    cursor={
                        isDisabled || unavailable ? 'not-allowed' : 'pointer'
                    }
                    _focus={{ border: 'none' }}
                    _active={{
                        backgroundColor: 'none',
                    }}
                    {...hover}
                    variant="outline"
                >
                    <Flex
                        w="100%"
                        direction="column"
                        alignItems="center"
                        opacity={
                            (isPremium && tier > 1) || unavailable
                                ? '0.3'
                                : isDisabled || !canUse
                                ? '30%'
                                : '100%'
                        }
                    >
                        <MenuIcon id={id} active={active} />
                        <Text fontSize="7px" display={'none'}>
                            {label}
                        </Text>
                    </Flex>
                    {unavailable && (
                        <Icon
                            as={MdOutlineHighlightOff}
                            position="absolute"
                            top="5px"
                            zIndex="1"
                            right="7px"
                            color="red.600"
                            borderRadius={'100%'}
                            fontSize="16px"
                            bg="white"
                        />
                    )}
                </Button>
                {isPremium &&
                    !advsMode &&
                    tier > 1 && ( // PREMIUM BADGE
                        <Flex position="absolute" right="8px" top="5px">
                            <PremiumBadge />
                        </Flex>
                    )}
            </Flex>
        )
    } else {
        return null
    }
}
