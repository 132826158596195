import { formatTimeAndTimezone } from '../../../util/dateFormatter'

export default function formatAAWData(
    geomData,
    aawData,
    selectedTime,
    timeZone
) {
    if (geomData && aawData) {
        const selectedEpochTime = selectedTime / 1000

        const areaFeatures = geomData.features.filter(
            (feat) => feat.properties.area !== 'LINES'
        )

        const lineFeature = geomData.features.filter(
            (feat) => feat.properties.area === 'LINES'
        )

        const dataForSelectedTime = aawData.filter(
            (aaw) =>
                aaw.start_epoch <= selectedEpochTime &&
                aaw.end_epoch + 1 >= selectedEpochTime
        )

        const areaFeaturesWithData = areaFeatures.map((feat) => {
            const newFeat = { ...feat }
            newFeat.properties = {
                ...dataForSelectedTime.filter(
                    (aaw) => aaw.area === feat.properties.area
                )[0],
                ...feat.properties,
            }

            if (newFeat.properties.between_start) {
                const formattedBetweenStart = formatTimeAndTimezone(
                    newFeat.properties.between_start,
                    timeZone
                )

                newFeat.properties.between_start &&
                newFeat.properties.between_end
                    ? (newFeat.properties.between_start_formatted =
                          formattedBetweenStart.match(/\d{2}:\d{2}/)[0])
                    : (newFeat.properties.between_start_formatted =
                          formattedBetweenStart)
            }

            if (newFeat.properties.between_end) {
                newFeat.properties.between_end_formatted =
                    formatTimeAndTimezone(
                        newFeat.properties.between_end,
                        timeZone
                    )
            }

            return newFeat
        })

        return {
            type: 'FeatureCollection',
            features: [...areaFeaturesWithData, ...lineFeature],
        }
    }
}
