import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Divider,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../globalState'

export default function SpecialNoticeModal() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'specialNotice', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.specialNotice ? onOpen() : onClose()
    }, [modalVisibility.specialNotice, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'faqs'}
                    size="4xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            Important Notice for Apple iOS Users
                        </ModalHeader>
                        <ModalBody
                            background="light.600"
                            style={{ padding: 0 }}
                            fontSize="0.9rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={8}
                                pb={6}
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                <Text
                                    fontSize="1rem"
                                    fontWeight="600"
                                    textAlign="center"
                                    width="100%"
                                    px="10px"
                                    color="gray.500"
                                >
                                    Apple iOS 17 Bug Advisory (Versions 17.0.0 –
                                    17.0.3)
                                </Text>
                                <Divider mt="20px" mb="20px" />
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    pb="10px"
                                    px="20px"
                                    gap="10px"
                                    letterSpacing="0.5px"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.9rem',
                                    }}
                                    textAlign="center"
                                    color="light.100"
                                >
                                    <Text>
                                        We've identified an intermittent issue
                                        affecting map functionality on iPhones
                                        and iPads running Apple's recent iOS 17
                                        updates, specifically with iOS versions
                                        17.0.0 to 17.0.3. On rare occasions, the
                                        map may fail to load. This is a known
                                        issue attributed to an Apple iOS bug
                                        specifically impacting MapBox, our
                                        mapping software we use.
                                    </Text>
                                    <Text>
                                        <span style={{ fontWeight: '600' }}>
                                            For affected users, we recommend
                                            updating your iOS device to version{' '}
                                            <span style={{ color: '#00D599' }}>
                                                17.1
                                            </span>{' '}
                                            or later
                                        </span>
                                        .
                                    </Text>
                                    <Text>
                                        Our investigations indicate that this
                                        update resolves the loading issue. No
                                        similar problems have been reported on
                                        non-iOS devices.
                                    </Text>
                                    <Text>
                                        Additionally, as a helpful tip, we
                                        suggest optimising device performance by
                                        limiting the number of apps running in
                                        the background and periodically clearing
                                        your browser cache. Certain background
                                        apps on mobiles and tablets can
                                        occasionally impact device web browser
                                        performance, and adopting this proactive
                                        approach can assist users in identifying
                                        and mitigating any potential factors
                                        that may affect the efficiency of their
                                        device.
                                    </Text>
                                    <Text>
                                        We appreciate your understanding as we
                                        work to ensure a seamless experience for
                                        our users.
                                    </Text>
                                </Flex>
                                <Divider mt="20px" mb="20px" />
                                <Text
                                    color="gray.400"
                                    fontWeight="300"
                                    width="100%"
                                    textAlign="center"
                                    fontSize="0.75rem"
                                    pb="5px"
                                >
                                    Published: 20 November 2023
                                </Text>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
