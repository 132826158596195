import React, { useCallback, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTier } from '../../../../auth/hooks'
import LegendIcon from '../../../../icons/menu-icons/LegendIcon'
import {
    Modal,
    Flex,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
    LightMode,
    Icon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useBreakpointValue,
} from '@chakra-ui/react'
import { isDesktop, isIOS, isMobile } from 'react-device-detect'

import {
    setModalVisibilityState,
    dataModeState,
    briefingOptionsState,
    fullscreenModalVisibleState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../globalState'
import {
    aeroModeLegend,
    metModeLegend,
    advsModeLegend,
    briefModeLegend,
    defaultLegend,
} from './components/LegendDetails'

export default function LegendModal() {
    const { onClose } = useDisclosure()

    const dataMode = useRecoilValue(dataModeState)
    const briefingOptionsActive = useRecoilValue(briefingOptionsState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const fullscreen = useRecoilValue(fullscreenModalVisibleState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'legend', value })
        },
        [setModalVisibility]
    )
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const aeroMode = dataMode === 'aero'
    const metMode = dataMode === 'met'
    const advsMode = dataMode === 'advs'
    const briefMode = dataMode === 'brief'

    function onCloseHandler() {
        setShow(false)
        onClose()
    }

    // close Legend modal when BRIEF mode options appear, or fullscreen modal
    useEffect(() => {
        if (briefingOptionsActive || fullscreen) {
            setShow(false)
            onClose()
        }
    }, [briefingOptionsActive, fullscreen, setShow, onClose])

    const transitionEffect = useBreakpointValue({
        base: 'slideInBottom',
        md: 'slideInRight',
    })

    return (
        <>
            <LightMode>
                <Modal
                    onClose={onCloseHandler}
                    isOpen={true}
                    trapFocus={false}
                    className="legend-modal"
                    backdrop="static"
                    size="xs"
                    variant="legend"
                    motionPreset={transitionEffect}
                    closeOnOverlayClick={false}
                    blockScrollOnMount={false}
                    isCentered
                >
                    <ModalContent
                        pointerEvents="initial"
                        marginTop={{ base: '70px', smd: '70px', md: '15px' }}
                        maxWidth="275px"
                        marginInlineStart={{
                            base: '15px',
                            xs: 'auto',
                        }}
                        marginInlineEnd={{
                            base: '70px',
                            sm: '70px',
                            smd: '70px',
                            md: '68px',
                        }}
                    >
                        <ModalCloseButton color="white" mt="-3px" />
                        <ModalHeader
                            textAlign="left"
                            color="white"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            fontWeight="600"
                            fontFamily="'Open Sans', sans-serif"
                            fontSize={{
                                base: '1rem',
                                md: '1.2rem',
                            }}
                            pl="1.3rem"
                            minHeight="50px"
                        >
                            <Flex ml="-3px">
                                <Icon as={LegendIcon} boxSize={6} />
                            </Flex>
                            <Text
                                ml="5px"
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.9rem',
                                }}
                                pb="1px"
                            >
                                Legend
                            </Text>
                            <Text
                                ml="5px"
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.9rem',
                                }}
                                pb="1px"
                                fontWeight="300"
                            >
                                {'– '}
                                {aeroMode
                                    ? ' Aero Mode'
                                    : metMode
                                    ? ' Met Mode'
                                    : advsMode
                                    ? ' Advisory Mode'
                                    : briefMode
                                    ? ' Briefing Mode'
                                    : ''}
                            </Text>
                        </ModalHeader>
                        <ModalBody
                            padding="0"
                            justifyContent="center"
                            alignItems="flex-start"
                            fontFamily="'Open Sans', sans-serif"
                        >
                            <Accordion
                                // defaultIndex={0}
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.8rem',
                                }}
                                color="light.100"
                                className="terms"
                                overflowY="auto"
                                overflowX="hidden"
                                maxHeight={{
                                    base:
                                        !isDesktop || (isIOS && isMobile)
                                            ? 'calc(100vh - 300px)'
                                            : 'calc(100vh - 285px)',
                                    md: aeroMode
                                        ? 'calc(100vh - 88px)' > '730px'
                                            ? '730px'
                                            : 'calc(100vh - 88px)'
                                        : 'calc(100vh - 165px)',
                                    xl: aeroMode
                                        ? 'calc(100vh - 88px)' > '730px'
                                            ? '730px'
                                            : 'calc(100vh - 88px)'
                                        : 'calc(100vh - 165px)' > '593px'
                                        ? '593px'
                                        : 'calc(100vh - 165px)',
                                }}
                                px={0}
                                pt="0px"
                                pb="0px"
                                allowToggle
                                width="100%"
                            >
                                {(aeroMode
                                    ? aeroModeLegend
                                    : metMode
                                    ? metModeLegend
                                    : advsMode
                                    ? advsModeLegend
                                    : briefMode
                                    ? briefModeLegend
                                    : defaultLegend
                                ).map((legendData, i) => {
                                    const lastButton =
                                        i ===
                                        (aeroMode
                                            ? aeroModeLegend
                                            : metMode
                                            ? metModeLegend
                                            : advsMode
                                            ? advsModeLegend
                                            : briefMode
                                            ? briefModeLegend
                                            : defaultLegend
                                        ).length -
                                            1
                                    return (
                                        <AccordionItem
                                            border={'none'}
                                            key={`legendData${i}`}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <AccordionButton
                                                        pt="0px"
                                                        pb={{
                                                            base:
                                                                lastButton &&
                                                                !isExpanded
                                                                    ? '2px'
                                                                    : '0px',
                                                            md:
                                                                lastButton &&
                                                                !isExpanded
                                                                    ? '2px'
                                                                    : '0px',
                                                        }}
                                                        height={{
                                                            base:
                                                                lastButton &&
                                                                !isExpanded
                                                                    ? '40px'
                                                                    : '38px',
                                                            md:
                                                                lastButton &&
                                                                !isExpanded
                                                                    ? '38px'
                                                                    : '36px',
                                                        }}
                                                        transition="all ease 300ms"
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="left"
                                                        flexDirection="column"
                                                        borderTop={'none'}
                                                        borderBottom={
                                                            '1px solid'
                                                        }
                                                        borderColor="gray.200"
                                                        fontSize="0.8rem"
                                                        clipPath="inset(0px 0px -50px)"
                                                        boxShadow={
                                                            isExpanded
                                                                ? 'rgba(0, 0, 0, 0.07) 0px 5px 10px 0px'
                                                                : 'none'
                                                        }
                                                    >
                                                        <Text
                                                            color="gray.500"
                                                            fontWeight="600"
                                                            width="100%"
                                                        >
                                                            {legendData.label}
                                                        </Text>
                                                    </AccordionButton>
                                                    <AccordionPanel
                                                        pl="2"
                                                        pr="3"
                                                        pb="5"
                                                        borderBottom={
                                                            '1px solid'
                                                        }
                                                        borderColor="gray.200"
                                                        boxShadow={
                                                            'inset rgba(0, 0, 0, 0.05) 0px -14px 11px -10px'
                                                        }
                                                    >
                                                        <Flex
                                                            justifyContent="space-between"
                                                            mt="5px"
                                                            mb="2px"
                                                            alignItems="basline"
                                                        ></Flex>

                                                        <Flex
                                                            width="100%"
                                                            flexDirection="column"
                                                            gap="8px"
                                                        >
                                                            {legendData.content.map(
                                                                (filter, i) => (
                                                                    <Flex
                                                                        display={
                                                                            isPremium &&
                                                                            filter.role &&
                                                                            filter.role ===
                                                                                'premium'
                                                                                ? 'none'
                                                                                : 'flex'
                                                                        }
                                                                        key={`legendDataDetails${i}`}
                                                                        width="100%"
                                                                        justifyContent="left"
                                                                        alignItems="center"
                                                                        pt={
                                                                            filter.border &&
                                                                            filter.border ===
                                                                                'top'
                                                                                ? '15px'
                                                                                : '0'
                                                                        }
                                                                        mt={
                                                                            filter.border &&
                                                                            filter.border ===
                                                                                'top'
                                                                                ? '10px'
                                                                                : '0'
                                                                        }
                                                                        borderTop={
                                                                            filter.border &&
                                                                            filter.border ===
                                                                                'top'
                                                                                ? '1px solid #edf2f7'
                                                                                : 'none'
                                                                        }
                                                                        borderColor="gray.100"
                                                                        gap="5px"
                                                                    >
                                                                        <Flex
                                                                            ml="-3px"
                                                                            justifyContent="left"
                                                                            alignItems="center"
                                                                            flexDirection="column"
                                                                            minWidth="50px"
                                                                            mr="0px"
                                                                        >
                                                                            {filter.icon && (
                                                                                <Icon
                                                                                    style={{
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                    as={
                                                                                        filter.icon
                                                                                    }
                                                                                    color="light.200"
                                                                                    transition="all ease 200ms"
                                                                                    boxSize={
                                                                                        filter.size
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Flex>
                                                                        <Flex
                                                                            mt={
                                                                                filter.label ===
                                                                                    null ||
                                                                                filter.label ===
                                                                                    'Transit Lane' ||
                                                                                filter.label ===
                                                                                    'Military Operating Area'
                                                                                    ? '-12px'
                                                                                    : !filter.icon
                                                                                    ? '-6px'
                                                                                    : '0px'
                                                                            }
                                                                            justifyContent="space-between"
                                                                            alignItems="baseline"
                                                                            width="100%"
                                                                            flexDirection={
                                                                                (filter.alignFlex &&
                                                                                    filter.alignFlex ===
                                                                                        'row') ||
                                                                                legendData.label ===
                                                                                    'AIRSPACE' ||
                                                                                filter.comment ===
                                                                                    '>400 ft AGL' ||
                                                                                filter.comment ===
                                                                                    '<400 ft AGL'
                                                                                    ? 'row'
                                                                                    : 'column'
                                                                            }
                                                                        >
                                                                            <Text
                                                                                width="100%"
                                                                                fontWeight="400"
                                                                                fontSize="0.75rem"
                                                                                lineHeight="1.2"
                                                                            >
                                                                                {
                                                                                    filter.label
                                                                                }
                                                                            </Text>

                                                                            <Text
                                                                                fontWeight="300"
                                                                                color="gray.500"
                                                                                fontSize="0.7rem"
                                                                                pl={
                                                                                    !filter.label ||
                                                                                    (filter.alignComment &&
                                                                                        filter.alignComment ===
                                                                                            'left')
                                                                                        ? '0'
                                                                                        : '5px'
                                                                                }
                                                                                width={
                                                                                    legendData.label ===
                                                                                        'AIRSPACE' ||
                                                                                    filter.comment ===
                                                                                        '>400 ft AGL' ||
                                                                                    filter.comment ===
                                                                                        '<400 ft AGL'
                                                                                        ? 'auto'
                                                                                        : '100%'
                                                                                }
                                                                                textAlign={
                                                                                    !filter.label ||
                                                                                    (filter.alignComment &&
                                                                                        filter.alignComment ===
                                                                                            'left')
                                                                                        ? 'left'
                                                                                        : 'right'
                                                                                }
                                                                                lineHeight="1.2"
                                                                                whiteSpace={
                                                                                    filter.comment ===
                                                                                        '>400 ft AGL' ||
                                                                                    filter.comment ===
                                                                                        '<400 ft AGL'
                                                                                        ? 'pre'
                                                                                        : 'unset'
                                                                                }
                                                                                pt={
                                                                                    filter.alignComment &&
                                                                                    filter.alignComment ===
                                                                                        'left'
                                                                                        ? '2px'
                                                                                        : '0px'
                                                                                }
                                                                            >
                                                                                {
                                                                                    filter.comment
                                                                                }
                                                                            </Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                )
                                                            )}
                                                        </Flex>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
