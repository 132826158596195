import React, { useEffect } from 'react'
import {
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Text,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Image,
    Badge,
    Divider,
    useDisclosure,
    LightMode,
    Icon,
} from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { parseISO, formatDistanceToNow } from 'date-fns'

import {
    clickedFeaturesState,
    timeZoneState,
    globalTimeState,
} from '../../../globalState'

import { ReactComponent as NotamIcon } from '../../../icons/assets/notam.svg'
import notam from '../assets/notam-header.svg'
import notamDrone from '../assets/notam-header-drone.svg'
import notamObstacle from '../assets/notam-header-obstacle.svg'
import notamAerodrome from '../assets/notam-header-aerodrome.svg'
import notamWarning from '../assets/notam-header-warning.svg'
import notamComms from '../assets/notam-header-comms.svg'
import { ReactComponent as ErrorIcon } from '../../../icons/assets/error.svg'

import formatDateTime, { timeRangeInWords } from '../../../util/dateFormatter'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../util/notamDecode'

export default function NotamModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const timeZone = useRecoilValue(timeZoneState)
    const now = useRecoilValue(globalTimeState)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const feat = clickedFeatures[0]

    const code23 = code23Decode(feat.properties.code23)
    const code45 = code45Decode(feat.properties.code45)
    const traffic = trafficDecode(feat.properties.traffic)

    function notamIconFormatter() {
        return ['WU', 'WZ'].includes(feat.properties.code23)
            ? notamDrone
            : ['OB', 'OL'].includes(feat.properties.code23)
            ? notamObstacle
            : feat.properties.code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(feat.properties.code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(feat.properties.code23.charAt(0))
            ? notamComms
            : notam
    }

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant="notam"
                    size="xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    scrollBehavior="inside"
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton
                            color="white"
                            mr={{ base: '0px', md: '10px' }}
                            mt={{ base: '0px', md: '8px' }}
                        />
                        <ModalHeader
                            backgroundImage="linear-gradient(345deg, #FF9A36 10%, #EA6300 90%)"
                            variant="notam"
                        >
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.4) translateX(-4px)"
                                        opacity="0.1"
                                        boxSize="36px"
                                        as={NotamIcon}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-36px"
                                    color="white"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Image
                                        boxSize="36px"
                                        src={notamIconFormatter()}
                                    />
                                    <Box pl={4}>
                                        <Heading variant="mainHeading">
                                            {code23}
                                        </Heading>
                                        <Heading variant="subHeading">
                                            {code45}
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>
                        <ModalBody>
                            {feat.properties.outofdate && (
                                <Flex
                                    ml="-2px"
                                    flexDirection="row"
                                    alignItems="flex-start"
                                >
                                    <ErrorIcon
                                        color="#ff0000"
                                        width="40px"
                                        height="40px"
                                    />
                                    <Box
                                        color="#ff0000"
                                        width="100%"
                                        paddingTop="2px"
                                        paddingBottom="2px"
                                        mt="-1px"
                                        mb="10px"
                                        ml="12px"
                                        textAlign="left"
                                        fontWeight="400"
                                        fontSize="12px"
                                    >
                                        NOTAM information may be out of date.
                                        Last successful update was{' '}
                                        <strong>
                                            {formatDistanceToNow(
                                                parseISO(
                                                    feat.properties.updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                        </strong>
                                        .<br />
                                        Please contact your local briefing
                                        office or flight information service for
                                        a more up to date briefing if required.
                                    </Box>
                                </Flex>
                            )}
                            <Table size="sm" variant="notam" marginTop="2">
                                <Tbody>
                                    <Tr>
                                        <Td
                                            // variant="columnLeft"
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            NOTAM #:
                                        </Td>
                                        <Td
                                            width="100%"
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            {`${feat.properties.series}${feat.properties.number}/${feat.properties.year}`}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            Affects:
                                        </Td>
                                        <Td width="100%">
                                            <Flex
                                                alignItems="baseline"
                                                height="18px"
                                            >
                                                {/* TODO - Need to move Badge styling across to theme as well */}
                                                {traffic.ifr && (
                                                    <Badge
                                                        variant="solid"
                                                        background="ifr.100"
                                                        color="white"
                                                        paddingStart="2"
                                                        paddingEnd="2"
                                                        paddingTop="2px"
                                                        paddingBottom="2px"
                                                        borderRadius="xl"
                                                        marginRight="5px"
                                                        minWidth="40px"
                                                        textAlign="center"
                                                        fontSize="0.75rem"
                                                        height="18px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        display="flex"
                                                    >
                                                        IFR
                                                    </Badge>
                                                )}
                                                {traffic.ifr && traffic.vfr
                                                    ? ' '
                                                    : ''}
                                                {traffic.vfr && (
                                                    <Badge
                                                        variant="solid"
                                                        background="vfr.100"
                                                        color="white"
                                                        paddingStart="2"
                                                        paddingEnd="2"
                                                        paddingTop="2px"
                                                        paddingBottom="2px"
                                                        borderRadius="xl"
                                                        marginRight="5px"
                                                        minWidth="40px"
                                                        textAlign="center"
                                                        fontSize="0.75rem"
                                                        height="18px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        display="flex"
                                                    >
                                                        VFR
                                                    </Badge>
                                                )}
                                                <Text paddingLeft="1px">
                                                    TRAFFIC
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            Status:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.startvalidity &&
                                            feat?.properties?.endvalidity
                                                ? timeRangeInWords(
                                                      now,
                                                      feat.properties
                                                          .startvalidity,
                                                      feat.properties
                                                          .endvalidity
                                                  )
                                                : feat?.properties
                                                      ?.startvalidity &&
                                                  !feat?.properties?.endvalidity
                                                ? timeRangeInWords(
                                                      now,
                                                      feat.properties
                                                          .startvalidity
                                                  )
                                                : ''}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            From:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.startvalidity &&
                                                formatDateTime(
                                                    feat.properties
                                                        .startvalidity,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>
                                    {!feat.properties.permanent && (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="90px"
                                            >
                                                To:
                                            </Td>
                                            <Td width="100%">
                                                {feat?.properties
                                                    ?.endvalidity &&
                                                    formatDateTime(
                                                        feat.properties
                                                            .endvalidity,
                                                        timeZone
                                                    )}
                                                {feat?.properties?.estimation &&
                                                    ' (Estimated)'}
                                            </Td>
                                        </Tr>
                                    )}
                                    {feat.properties.itemd &&
                                        feat.properties.itemd !== 'null' && (
                                            <Tr>
                                                <Td
                                                    paddingStart="0"
                                                    fontWeight="bold"
                                                    width="auto"
                                                    minWidth="90px"
                                                >
                                                    Period:
                                                </Td>
                                                <Td width="100%">
                                                    {feat.properties.itemd +
                                                        ' (UTC if time shown)'}
                                                </Td>
                                            </Tr>
                                        )}
                                    {feat.properties.itemf && (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="90px"
                                            >
                                                Altitude:
                                            </Td>
                                            <Td width="100%">
                                                {feat.properties.lowerlimit +
                                                    ' – ' +
                                                    feat.properties.upperlimit}
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                            <Divider variant="primary" />
                            <Text
                                marginTop={3}
                                marginBottom={3}
                                whiteSpace="pre-line"
                            >
                                {feat.properties.iteme}
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
