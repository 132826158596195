import React, { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import useUserRoles from '../../../auth/hooks'
import {
    HStack,
    VStack,
    Text,
    Box,
    useColorModeValue,
    Badge,
    Flex,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { ReactComponent as ProfileIcon } from '../../../icons/assets/profile.svg'

import {
    settingsMenuOpenState,
    setModalVisibilityState,
    userProfileState,
} from '../../../globalState'

export default function Profile(props) {
    const { mfaTimeToExpire, showMfaBadge } = props

    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const profileData = useRecoilValue(userProfileState)

    const setShowProfileModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'profile', value })
        },
        [setModalVisibility]
    )

    const userRoles = useUserRoles()

    const roleLengthLong = userRoles.some((role) =>
        ['MetService Aviation', 'MetService Service Desk'].includes(role)
    )

    return (
        <HStack
            pl={5}
            pr={4}
            mt="1rem"
            mb={2}
            pt="0.25rem"
            pb="0.35rem"
            color={useColorModeValue('light.100', 'dark.100')}
            cursor="pointer"
            onClick={() => {
                setSettingsMenuOpen(false)
                setShowProfileModal(true)
            }}
            _hover={{
                color: useColorModeValue('light.900', 'dark.80'),
                background: useColorModeValue('light.20', 'dark.20'),
            }}
        >
            <HStack alignItems={roleLengthLong ? 'flex-start' : 'center'}>
                <Box mr="2px" mt="2px" p="2px">
                    {showMfaBadge && ( // MFA badge shows when MFA is due to expire soon or has expired
                        <Badge
                            zIndex="1"
                            position="absolute"
                            marginTop="-5px"
                            marginLeft="-12px"
                            variant="outline"
                            border="1px solid"
                            bg={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? '#FFE0E0'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? '#FFEDDB'
                                    : 'gray.100'
                            }
                            boxShadow="none"
                            borderColor={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? 'red.500'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? 'warning.200'
                                    : 'gray.300'
                            }
                            color={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? 'red.500'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? 'warning.200'
                                    : 'gray.400'
                            }
                            borderRadius="10"
                            px="4px"
                            pb="0px"
                            pt="0px"
                            fontSize="0.6rem"
                            fontWeight="700"
                            height="14px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            cursor="pointer"
                        >
                            MFA
                        </Badge>
                    )}
                    <ProfileIcon opacity="0.7" height="26px" width="26px" />
                </Box>
                <VStack marginInlineStart="10px !important">
                    <Flex
                        justifyContent="flex-start"
                        flexDirection={roleLengthLong ? 'row' : 'row'}
                        width="100%"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        alignItems={roleLengthLong ? 'flex-start' : 'center'}
                        flexWrap="wrap"
                    >
                        {(!profileData.firstName || !profileData.lastName) && (
                            <Flex
                                transition="all ease-in-out 300ms"
                                justifyContent="flex-start"
                                color="light.200"
                                fontSize="0.8rem"
                                fontWeight="600"
                                lineHeight={{
                                    base: '1.3',
                                    md: '2.5',
                                }}
                                width="100%"
                                textAlign={{
                                    base: 'center',
                                    md: 'left',
                                }}
                                whiteSpace="pre"
                            >
                                PLEASE UPDATE YOUR PROFILE
                            </Flex>
                        )}
                        {(profileData.firstName || profileData.lastName) && (
                            <Flex order="1">
                                <Text
                                    whiteSpace="pre"
                                    fontSize={{ base: '14px', md: '0.85rem' }}
                                    fontWeight="500"
                                    mr="10px"
                                >
                                    {profileData.firstName}{' '}
                                    {profileData.lastName}
                                </Text>
                            </Flex>
                        )}

                        <Flex order={roleLengthLong ? '2' : '3'} width="100%">
                            <Text
                                opacity={
                                    profileData.firstName ||
                                    profileData.lastName
                                        ? '0.6'
                                        : '1'
                                }
                                fontSize="0.65rem"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                fontWeight="300"
                                width="100%"
                                mt="-1px !important"
                            >
                                {profileData.email}
                            </Text>
                        </Flex>
                    </Flex>
                </VStack>
            </HStack>
            <ChevronRightIcon
                boxSize={5}
                color={
                    (!profileData.firstName || !profileData.lastName) &&
                    'light.200'
                }
            />
        </HStack>
    )
}
