import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Divider,
    IconButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    ButtonGroup,
    useBreakpointValue,
    Tooltip,
    Portal,
} from '@chakra-ui/react'
import Image from '../../../../components/Image'
import { isMobileOnly } from 'react-device-detect'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'
import { useInView } from 'react-intersection-observer'
import { useSwipeable } from 'react-swipeable'

import ZoomSlider from '../../../components/ZoomSlider'
import PinchDrag, { useScale } from '../../../components/PinchDrag'
import formatDateTime, {
    formatTimeAndTimezone,
    formatDateToTimezone,
} from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { parseISO, formatISO, addHours, formatDistanceToNow } from 'date-fns'
import ErrorText from '../../../components/ErrorText'
import PremiumBadge from '../../../../controls/PremiumBadge'

import { useTier } from '../../../../../auth/hooks'
import {
    timeZoneState,
    showOutdatedErrorsState,
    inViewPositionState,
    briefingParamsState,
    collapseDropdownsState,
    simulateFreeUserState,
    selectedBriefingAltitudeState,
    setModalVisibilityState,
    briefingSigwxLevelState,
    selectedBriefingTimeState,
    fullscreenHandlerState,
    mfaVerifiedState,
} from '../../../../../globalState'
import SigwxIcon from '../../../../../icons/menu-icons/SigwxIcon'

export default function SigwxBriefing(props) {
    const { briefingData, fullscreenRef, handleReset, setHandleReset } = props
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const params = useRecoilValue(briefingParamsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const maxAltitude = useRecoilValue(selectedBriefingAltitudeState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const [fullscreenHandler, setFullscreenHandler] = useRecoilState(
        fullscreenHandlerState
    )
    const [showImageLoadErrors, setShowImageLoadErrors] = useState(false)
    const { scale, setScale, xy, setXy } = useScale()
    const fullscreen = Boolean(fullscreenHandler === 'sigwx')
    const scrollRef = useRef()

    const sigwx = briefingData && briefingData?.res?.areamet?.charts?.sigwx

    // const hideOnMobile = window.innerWidth < 860 && !fullscreen
    const hideOnMobile = !fullscreen

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    const timeZone = useRecoilValue(timeZoneState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const [sigwxTime, setSigwxTime] = useState(null)
    const [sigwxTimePosition, setSigwxTimePosition] = useState(0)
    const [sigwxLevelPosition, setSigwxLevelPosition] = useState(0)
    const [buttonExpand, setButtonExpand] = useState('time')
    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    const time = Date.now()
    const timeFormatted = formatDateToTimezone(formatISO(time), 'utc')
    const fourTimeSelections = sigwx && sigwx.length > 9

    const selectedSigwxTime =
        sigwx && sigwx.filter((time) => time.validfrom === sigwxTime)

    const availableFlightLevels =
        selectedSigwxTime &&
        selectedSigwxTime.map((fl, i) => {
            const preceedingAlt = // determine what is preceeding converted FL to altitude
                i === 0
                    ? 0
                    : selectedSigwxTime[i > 0 ? i - 1 : i].level.slice(-3) * 100
            if (maxAltitude >= preceedingAlt) {
                return fl
            } else {
                return null
            }
        })
    const filteredFlightLevels =
        availableFlightLevels &&
        availableFlightLevels.filter((filter) => filter !== null)

    const setBriefingSigwxLevel = useSetRecoilState(briefingSigwxLevelState)
    const [sigwxLevel, setSigwxLevel] = useState(
        isPremium || maxAltitude <= 10000
            ? 'fl100'
            : maxAltitude <= 25000
            ? 'fl250'
            : 'fl410'
    )

    const pageScale = useBreakpointValue({
        base: fullscreen ? 19 : 20,
        md: 18,
        lg: 16,
        xl: 13,
    })
    const pageScaleWidth = pageScale * 5 + '%'

    useEffect(() => {
        inView && setInViewPosition('sigwxContainer')
    }, [inView, setInViewPosition])

    useEffect(() => {
        if (handleReset) {
            setScale(1)
            setXy({
                x: 0,
                y: 0,
            })
            setHandleReset(false)
            setFullscreenHandler(null)
        }
    }, [
        fullscreen,
        handleReset,
        setFullscreenHandler,
        setScale,
        setXy,
        setHandleReset,
    ])

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            if (fullscreen) {
                setHandleReset(true)
            }
        },
    })

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateDateFontSize, calculateLabelSize } =
        useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })
    const adjustDateFontSize = useBreakpointValue({
        base: calculateDateFontSize(0.8, fullscreen),
        sm: calculateDateFontSize(0.8),
    })

    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        if (sigwx) {
            sigwxLevel === 'fl410'
                ? setSigwxLevelPosition(2)
                : sigwxLevel === 'fl250'
                ? setSigwxLevelPosition(1)
                : setSigwxLevelPosition(0)
        }
    }, [sigwx, setSigwxLevel, sigwxLevel, setSigwxLevelPosition])

    useEffect(() => {
        // if maxAltitude slider is reduced and decreases number of buttons, then change button to highest after change
        if (
            maxAltitude <= 25000 &&
            maxAltitude > 10000 &&
            sigwxLevel === 'fl410'
        ) {
            setSigwxLevel('fl250')
            setSigwxLevelPosition(1)
        }
        if (
            maxAltitude <= 10000 &&
            (sigwxLevel === 'fl250' || sigwxLevel === 'fl410')
        ) {
            setSigwxLevel('fl100')
            setSigwxLevelPosition(0)
            setButtonExpand('time')
        }

        if (maxAltitude <= 10000) {
            setButtonExpand('time')
        }
    }, [maxAltitude, sigwxLevel, setSigwxLevel])

    useEffect(() => {
        // set briefingSigwxLevel for export to PDF (based on maxAltitude position)
        if (maxAltitude > 25000) {
            setBriefingSigwxLevel('fl410')
        } else if (maxAltitude <= 25000 && maxAltitude > 10000) {
            setBriefingSigwxLevel('fl250')
        } else if (maxAltitude < 10000) {
            setBriefingSigwxLevel('fl100')
        }
    }, [maxAltitude, setBriefingSigwxLevel])

    useEffect(() => {
        if (sigwx) {
            setSigwxTime(sigwx[0].validfrom, 0)
        }
    }, [sigwx])

    const filteredCharts =
        sigwx && sigwx.filter((chart) => parseISO(chart.validfrom) <= periodTo)

    useEffect(() => {
        if (parseISO(sigwxTime) > periodTo) {
            setSigwxTime(
                filteredCharts &&
                    filteredCharts[filteredCharts.length - 3] &&
                    filteredCharts[filteredCharts.length - 3].validfrom
            )
            setSigwxTimePosition(filteredCharts.length - 3)
        }
    }, [periodTo, filteredCharts, sigwxTime, sigwx])

    // Set time position if first image is out dated (can occur)
    useEffect(() => {
        if (sigwx && sigwx.length > 0) {
            const currentTime = Date.now()
            const currentTimeFormatted = formatDateToTimezone(
                formatISO(currentTime),
                'utc'
            )
            let indexToSet = 0
            // filter through the sigwx array to find the first current time selection
            for (let i = 0; i < sigwx.length; i++) {
                const validToTime = sigwx[i]?.validto
                const isValid =
                    validToTime && validToTime > currentTimeFormatted
                if (isValid) {
                    indexToSet = i
                    break
                }
            }
            if (indexToSet && sigwx[indexToSet]?.validfrom) {
                setSigwxTime(sigwx[indexToSet].validfrom)
                setSigwxTimePosition(indexToSet)
                // console.log('Updating SIGWX Chart to current time')
            }
        }
    }, [sigwx])

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    function onImageError(e) {
        // console.error('Image loading error:', e.target.src)
        if (e.target.src) {
            setShowImageLoadErrors(true)
        } else {
            setShowImageLoadErrors(false)
        }
    }

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()

    const portalLevels = useBreakpointValue({
        base: { containerRef: mobile },
        xl: {
            containerRef: fullscreen && !fourTimeSelections ? desktop : mobile,
        },
        '2xl': { containerRef: fullscreen ? desktop : mobile },
        '3xl': { containerRef: desktop },
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <div
                id="sigwxContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            {params.sigwx && (
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    zIndex="1"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginTop="25px"
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#35ADD3"
                        backgroundImage="linear-gradient(345deg, #5CCFF1 0%, #018AB0 80%)"
                        filter={{
                            base: isMobileOnly && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="30px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Box>
                                <SigwxIcon
                                    width="20px"
                                    height="20px"
                                    color="white"
                                />
                            </Box>

                            <Box my="auto" paddingLeft="12px">
                                <Text
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    SIGWX
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box>
                        <Accordion
                            border="none"
                            allowToggle
                            allowMultiple
                            index={expandedDropdowns}
                            onChange={(expandedIndex) => {
                                setExpandedDropdowns(expandedIndex)
                            }}
                        >
                            {sigwx && sigwx.length > 0 ? (
                                <AccordionItem
                                    borderStyle="none"
                                    index="1"
                                    boxShadow="0px -5px 15px 0px rgba(0,0,0,0.04)"
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                sx={{
                                                    clipPath:
                                                        'inset(0px 0px -50px 0px)',
                                                }}
                                                pl="1.3rem"
                                                height="50px"
                                                pr={5}
                                                py={3}
                                                _hover={{
                                                    background: 'light.30',

                                                    cursor: 'pointer',
                                                }}
                                                color={
                                                    !isExpanded
                                                        ? '#26aed7'
                                                        : '#26aed7'
                                                }
                                                background={
                                                    isExpanded
                                                        ? 'light.25'
                                                        : 'white'
                                                }
                                                boxShadow={
                                                    isExpanded &&
                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                }
                                                _focus={{
                                                    boxShadow: isExpanded
                                                        ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                        : 'none',
                                                }}
                                                opacity="1"
                                                style={{
                                                    borderBottomRadius:
                                                        isExpanded
                                                            ? '0px'
                                                            : '20px',
                                                    transition:
                                                        'boxShadow all 800ms',
                                                }}
                                            >
                                                <Box
                                                    fontSize={
                                                        adjustLabelFontSize
                                                    }
                                                    lineHeight="1.2"
                                                    fontWeight={
                                                        isExpanded
                                                            ? '600'
                                                            : '500'
                                                    }
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    flex="1"
                                                    textAlign="left"
                                                    paddingLeft="1"
                                                >
                                                    SIGWX Charts
                                                </Box>

                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            </AccordionButton>

                                            {sigwx && (
                                                <AccordionPanel
                                                    pt={0}
                                                    pb={6}
                                                    pl="1.5rem"
                                                    pr="1.5rem"
                                                >
                                                    <Portal
                                                        containerRef={
                                                            fullscreen
                                                                ? fullscreenRef
                                                                : scrollRef
                                                        }
                                                    >
                                                        <Flex
                                                            flexDirection="column"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            height="100%"
                                                        >
                                                            <Flex
                                                                {...headerSwipe}
                                                                flexDirection="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                width="100%"
                                                                pt={
                                                                    fullscreen
                                                                        ? '10px'
                                                                        : '25px'
                                                                }
                                                                px={
                                                                    fullscreen
                                                                        ? '20px'
                                                                        : '0px'
                                                                }
                                                                pb="10px"
                                                                zIndex="2"
                                                                boxShadow={
                                                                    fullscreen &&
                                                                    'rgba(0, 0, 0, 0.15) 0px 0px 15px -1px'
                                                                }
                                                                borderBottom={
                                                                    fullscreen &&
                                                                    '1px solid #E2E8F0'
                                                                }
                                                                background={
                                                                    fullscreen &&
                                                                    'white'
                                                                }
                                                            >
                                                                <Flex
                                                                    flexDirection="row"
                                                                    alignItems="center"
                                                                    width="100%"
                                                                >
                                                                    <Flex
                                                                        className="issuedTime"
                                                                        flexDirection={{
                                                                            base: 'column',
                                                                            ml: fullscreen
                                                                                ? 'row'
                                                                                : // : 'column',
                                                                                  'row',
                                                                            xl: 'row',
                                                                        }}
                                                                        alignItems="center"
                                                                        ml={{
                                                                            base: '5px',
                                                                            lg: fullscreen
                                                                                ? '5px'
                                                                                : '0',
                                                                        }}
                                                                        pr={{
                                                                            base: '10px',
                                                                            xs: '20px',
                                                                            lg: fullscreen
                                                                                ? '50px'
                                                                                : '20px',
                                                                            xl: '30px',
                                                                        }}
                                                                        gap={{
                                                                            base: '3px',
                                                                            ml: fullscreen
                                                                                ? '20px'
                                                                                : // : '3px',
                                                                                  '30px',
                                                                            xl: '40px',
                                                                        }}
                                                                        width={
                                                                            fullscreen
                                                                                ? 'auto'
                                                                                : '100%'
                                                                        }
                                                                    >
                                                                        <Flex>
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={calculateLabelSize(
                                                                                    80
                                                                                )}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                from:
                                                                            </Text>

                                                                            {sigwx
                                                                                .filter(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        sigwxLevel ===
                                                                                        i.level
                                                                                )
                                                                                .filter(
                                                                                    (
                                                                                        time
                                                                                    ) =>
                                                                                        time.validfrom ===
                                                                                        sigwxTime
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        issueStamp,
                                                                                        i
                                                                                    ) => (
                                                                                        <Text
                                                                                            whiteSpace="nowrap"
                                                                                            key={`SigwxTime${issueStamp.level}-${i}-${issueStamp.validfrom}`}
                                                                                            display={
                                                                                                sigwxLevel ===
                                                                                                issueStamp.level
                                                                                                    ? 'block'
                                                                                                    : 'none'
                                                                                            }
                                                                                            paddingStart="0"
                                                                                            paddingEnd="0"
                                                                                            fontWeight="400"
                                                                                            py="0px"
                                                                                            fontSize={
                                                                                                adjustDateFontSize
                                                                                            }
                                                                                            lineHeight="1.3"
                                                                                            borderColor="transparent"
                                                                                        >
                                                                                            {issueStamp?.validfrom &&
                                                                                                formatDateTime(
                                                                                                    issueStamp.validfrom,
                                                                                                    timeZone
                                                                                                )}
                                                                                        </Text>
                                                                                    )
                                                                                )}
                                                                        </Flex>
                                                                        <Flex>
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={{
                                                                                    base: calculateLabelSize(
                                                                                        80
                                                                                    ),
                                                                                    ml: calculateLabelSize(
                                                                                        62
                                                                                    ),
                                                                                }}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                to:
                                                                            </Text>

                                                                            {sigwx
                                                                                .filter(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        sigwxLevel ===
                                                                                        i.level
                                                                                )
                                                                                .filter(
                                                                                    (
                                                                                        time
                                                                                    ) =>
                                                                                        time.validfrom ===
                                                                                        sigwxTime
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        issueStamp,
                                                                                        i
                                                                                    ) => {
                                                                                        const validtoIsOutdated =
                                                                                            issueStamp?.validto &&
                                                                                            issueStamp.validto <
                                                                                                timeFormatted
                                                                                        return (
                                                                                            <Text
                                                                                                whiteSpace="nowrap"
                                                                                                key={`SigwxTime${issueStamp.level}-${i}-${issueStamp.validto}`}
                                                                                                display={
                                                                                                    sigwxLevel ===
                                                                                                    issueStamp.level
                                                                                                        ? 'block'
                                                                                                        : 'none'
                                                                                                }
                                                                                                paddingStart="0"
                                                                                                paddingEnd="0"
                                                                                                fontWeight="400"
                                                                                                py="0px"
                                                                                                fontSize={
                                                                                                    adjustDateFontSize
                                                                                                }
                                                                                                lineHeight="1.3"
                                                                                                borderColor="transparent"
                                                                                                color={
                                                                                                    validtoIsOutdated
                                                                                                        ? 'warning.200'
                                                                                                        : 'light.100'
                                                                                                }
                                                                                            >
                                                                                                {issueStamp?.validto &&
                                                                                                    formatDateTime(
                                                                                                        issueStamp.validto,
                                                                                                        timeZone
                                                                                                    )}
                                                                                            </Text>
                                                                                        )
                                                                                    }
                                                                                )}
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Portal
                                                                        style={{
                                                                            height: '100%',
                                                                            width: '100%',
                                                                        }}
                                                                        {...portalLevels}
                                                                    >
                                                                        <Flex
                                                                            width="100%"
                                                                            gap={{
                                                                                base: fourTimeSelections
                                                                                    ? '10px'
                                                                                    : buttonExpand ===
                                                                                      'time'
                                                                                    ? '15px'
                                                                                    : '5px',
                                                                                xs: fourTimeSelections
                                                                                    ? '10px'
                                                                                    : buttonExpand ===
                                                                                      'time'
                                                                                    ? '10px'
                                                                                    : '5px',
                                                                                sm: fourTimeSelections
                                                                                    ? '12px'
                                                                                    : buttonExpand ===
                                                                                      'time'
                                                                                    ? '15px'
                                                                                    : '10px',
                                                                                smd: '15px',
                                                                                md:
                                                                                    buttonExpand ===
                                                                                        'time' &&
                                                                                    fourTimeSelections
                                                                                        ? '10px'
                                                                                        : buttonExpand ===
                                                                                          'level'
                                                                                        ? '5px'
                                                                                        : fullscreen
                                                                                        ? '25px'
                                                                                        : '5px',
                                                                                lg: fourTimeSelections
                                                                                    ? '15px'
                                                                                    : '25px',
                                                                                xl: '15px',
                                                                                '3xl': '20px',
                                                                            }}
                                                                            justifyContent={{
                                                                                base: 'center',
                                                                                '3xl': 'space-evenly',
                                                                            }}
                                                                            alignItems="center"
                                                                            mt={{
                                                                                base: '20px',
                                                                                '2xl': fullscreen
                                                                                    ? '0px'
                                                                                    : '10px',
                                                                                '3xl': '0',
                                                                            }}
                                                                            mb={{
                                                                                base: '20px',
                                                                                '2xl': fullscreen
                                                                                    ? '0px'
                                                                                    : '20px',
                                                                                '3xl': '0px',
                                                                            }}
                                                                            pr={{
                                                                                base: '0',
                                                                                xl: fullscreen
                                                                                    ? '30px'
                                                                                    : '0',
                                                                                '2xl': '30px',
                                                                            }}
                                                                            flexDirection={{
                                                                                base:
                                                                                    maxAltitude <=
                                                                                    10000
                                                                                        ? 'column'
                                                                                        : 'row',
                                                                                sm:
                                                                                    maxAltitude <=
                                                                                        10000 &&
                                                                                    fourTimeSelections
                                                                                        ? 'column'
                                                                                        : 'row',
                                                                                smd: 'row',
                                                                                md:
                                                                                    maxAltitude <=
                                                                                        10000 &&
                                                                                    fourTimeSelections
                                                                                        ? 'column'
                                                                                        : 'row',
                                                                                ml: 'row',
                                                                            }}
                                                                        >
                                                                            <Flex>
                                                                                <ButtonGroup
                                                                                    borderRadius="20px"
                                                                                    px="2px"
                                                                                    overflow="hidden"
                                                                                    bg="rgba(8, 38, 71, 0.04)"
                                                                                    boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                                                                >
                                                                                    {sigwx
                                                                                        .filter(
                                                                                            (
                                                                                                i
                                                                                            ) =>
                                                                                                sigwxLevel ===
                                                                                                i.level
                                                                                        )
                                                                                        .filter(
                                                                                            (
                                                                                                chart
                                                                                            ) =>
                                                                                                parseISO(
                                                                                                    chart.validfrom
                                                                                                ) <=
                                                                                                periodTo
                                                                                        )
                                                                                        .sort(
                                                                                            (
                                                                                                a,
                                                                                                b
                                                                                            ) => {
                                                                                                const firA =
                                                                                                    a.validfrom
                                                                                                const firB =
                                                                                                    b.validfrom
                                                                                                if (
                                                                                                    firA <
                                                                                                    firB
                                                                                                ) {
                                                                                                    return -1
                                                                                                }
                                                                                                if (
                                                                                                    firA >
                                                                                                    firB
                                                                                                ) {
                                                                                                    return 1
                                                                                                } else {
                                                                                                    return 0
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                time,
                                                                                                i
                                                                                            ) => (
                                                                                                <Button // SIGWX TIME
                                                                                                    key={`SigwxBriefingChartsTime${time.validfrom}-${i}`}
                                                                                                    bg="transparent"
                                                                                                    variant="outline"
                                                                                                    fontSize="0.9rem"
                                                                                                    fontWeight="600"
                                                                                                    height={{
                                                                                                        base: '32px',
                                                                                                        md: '28px',
                                                                                                    }}
                                                                                                    width={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : buttonExpand ===
                                                                                                                      'time' &&
                                                                                                                  fourTimeSelections
                                                                                                                ? '64px'
                                                                                                                : '70px',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '70px'
                                                                                                                : '88px',
                                                                                                        smd:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : '88px',
                                                                                                        sm:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '80px'
                                                                                                                : '88px',
                                                                                                        md:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '80px'
                                                                                                                : '88px',
                                                                                                        lg: fourTimeSelections
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                        xl: '88px',
                                                                                                    }}
                                                                                                    minWidth={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '64px'
                                                                                                                : '70px',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '70px'
                                                                                                                : '88px',
                                                                                                        sm:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '80px'
                                                                                                                : '88px',
                                                                                                        smd:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : '88px',
                                                                                                        md:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0px'
                                                                                                                : fourTimeSelections
                                                                                                                ? '80px'
                                                                                                                : '88px',
                                                                                                        lg: fourTimeSelections
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                        xl: '88px',
                                                                                                    }}
                                                                                                    opacity={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '1',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '1',
                                                                                                        lg: '1',
                                                                                                    }}
                                                                                                    visibility={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? 'hidden'
                                                                                                                : 'visible',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? 'hidden'
                                                                                                                : 'visible',
                                                                                                        lg: 'visible',
                                                                                                    }}
                                                                                                    marginInlineStart="0px !important"
                                                                                                    border="none"
                                                                                                    justifyContent="center"
                                                                                                    paddingLeft={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '2px',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '2px',
                                                                                                        lg: '2px',
                                                                                                    }}
                                                                                                    paddingRight={{
                                                                                                        base:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '2px',
                                                                                                        xs:
                                                                                                            buttonExpand ===
                                                                                                                'level' &&
                                                                                                            sigwxTimePosition !==
                                                                                                                i
                                                                                                                ? '0'
                                                                                                                : '2px',
                                                                                                        lg: '2px',
                                                                                                    }}
                                                                                                    py="2px"
                                                                                                    transition="all ease 400ms"
                                                                                                    {...noFocus}
                                                                                                    onClick={() => {
                                                                                                        setSigwxTime(
                                                                                                            time.validfrom
                                                                                                        )
                                                                                                        setSigwxTimePosition(
                                                                                                            i
                                                                                                        )
                                                                                                        setButtonExpand(
                                                                                                            'time'
                                                                                                        )
                                                                                                    }}
                                                                                                    zIndex="1"
                                                                                                    _active={{
                                                                                                        bg: 'none',
                                                                                                    }}
                                                                                                    _focus={{
                                                                                                        bg: 'none',
                                                                                                    }}
                                                                                                    _hover={{
                                                                                                        bg: 'none',
                                                                                                        opacity:
                                                                                                            {
                                                                                                                base:
                                                                                                                    buttonExpand ===
                                                                                                                        'level' &&
                                                                                                                    sigwxTimePosition !==
                                                                                                                        i
                                                                                                                        ? '0'
                                                                                                                        : '1',
                                                                                                                xs:
                                                                                                                    buttonExpand ===
                                                                                                                        'level' &&
                                                                                                                    sigwxTimePosition !==
                                                                                                                        i
                                                                                                                        ? '0'
                                                                                                                        : '1',
                                                                                                                md: '1',
                                                                                                            },
                                                                                                    }}
                                                                                                >
                                                                                                    <Text
                                                                                                        zIndex="1"
                                                                                                        paddingLeft="0px"
                                                                                                        lineHeight="1.3"
                                                                                                        textTransform="uppercase"
                                                                                                        fontSize={{
                                                                                                            base: '0.65rem',
                                                                                                            xs: '0.7rem',
                                                                                                            xl: '0.8rem',
                                                                                                        }}
                                                                                                        color={
                                                                                                            time?.validfrom &&
                                                                                                            time?.validto &&
                                                                                                            sigwxTime ===
                                                                                                                time?.validfrom &&
                                                                                                            time?.validto <
                                                                                                                timeFormatted
                                                                                                                ? 'warning.200'
                                                                                                                : sigwxTime ===
                                                                                                                  time.validfrom
                                                                                                                ? 'light.200'
                                                                                                                : 'light.100'
                                                                                                        }
                                                                                                        fontWeight={
                                                                                                            sigwxTime ===
                                                                                                            time.validfrom
                                                                                                                ? '700'
                                                                                                                : '600'
                                                                                                        }
                                                                                                        opacity={
                                                                                                            sigwxTime ===
                                                                                                            time.validfrom
                                                                                                                ? '1'
                                                                                                                : '0.6'
                                                                                                        }
                                                                                                        _active={{
                                                                                                            color:
                                                                                                                time?.validfrom &&
                                                                                                                time?.validto &&
                                                                                                                sigwxTime ===
                                                                                                                    time?.validfrom &&
                                                                                                                time?.validto <
                                                                                                                    timeFormatted
                                                                                                                    ? 'warning.200'
                                                                                                                    : sigwxTime ===
                                                                                                                      time.validfrom
                                                                                                                    ? 'light.200'
                                                                                                                    : 'light.100',
                                                                                                            fontWeight:
                                                                                                                sigwxTime ===
                                                                                                                time.validfrom
                                                                                                                    ? '700'
                                                                                                                    : '600',
                                                                                                            opacity:
                                                                                                                sigwxTime ===
                                                                                                                time.validfrom
                                                                                                                    ? '1'
                                                                                                                    : '0.6',
                                                                                                        }}
                                                                                                        _selected={{
                                                                                                            color: 'light.10',
                                                                                                            fontWeight:
                                                                                                                '700',
                                                                                                            opacity:
                                                                                                                '1',
                                                                                                        }}
                                                                                                        transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                                                                                    >
                                                                                                        {time?.validfrom &&
                                                                                                            formatTimeAndTimezone(
                                                                                                                formatISO(
                                                                                                                    addHours(
                                                                                                                        parseISO(
                                                                                                                            time.validfrom
                                                                                                                        ),
                                                                                                                        3
                                                                                                                    )
                                                                                                                ),
                                                                                                                timeZone
                                                                                                            )}
                                                                                                    </Text>
                                                                                                </Button>
                                                                                            )
                                                                                        )}

                                                                                    <Flex // BUTTON INDICATOR
                                                                                        position="absolute"
                                                                                        marginInlineStart="0px !important"
                                                                                        mt="2px"
                                                                                        ml="0px"
                                                                                        px={{
                                                                                            base:
                                                                                                buttonExpand ===
                                                                                                    'time' &&
                                                                                                fourTimeSelections
                                                                                                    ? '32px'
                                                                                                    : '35px',
                                                                                            xs: fourTimeSelections
                                                                                                ? '35px'
                                                                                                : '44px',
                                                                                            sm: fourTimeSelections
                                                                                                ? '40px'
                                                                                                : '44px',
                                                                                            smd: '44px',
                                                                                            md: fourTimeSelections
                                                                                                ? '40px'
                                                                                                : '44px',
                                                                                            xl: '44px',
                                                                                        }}
                                                                                        height={{
                                                                                            base: '28px',
                                                                                            md: '24px',
                                                                                        }}
                                                                                        background="white"
                                                                                        borderRadius="20px"
                                                                                        bg="light.10"
                                                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                        transform={{
                                                                                            base:
                                                                                                buttonExpand ===
                                                                                                    'time' &&
                                                                                                `translateX(${sigwxTimePosition}00%)`,
                                                                                            lg: `translateX(${sigwxTimePosition}00%)`,
                                                                                        }}
                                                                                        transition="all 400ms"
                                                                                    ></Flex>
                                                                                </ButtonGroup>
                                                                            </Flex>

                                                                            {maxAltitude >
                                                                            10000 ? (
                                                                                <Flex alignItems="center">
                                                                                    <Tooltip
                                                                                        label="Surface to"
                                                                                        hasArrow
                                                                                        borderRadius="7px"
                                                                                        height="26px"
                                                                                        fontSize="0.7rem"
                                                                                        alignItems="center"
                                                                                        display="flex"
                                                                                        placement="top"
                                                                                        m="5px"
                                                                                        color="light.100"
                                                                                        bg="light.30"
                                                                                    >
                                                                                        <Text
                                                                                            whiteSpace="nowrap"
                                                                                            cursor="default"
                                                                                            textAlign="right"
                                                                                            pr={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                        'time' ||
                                                                                                    maxAltitude <=
                                                                                                        10000
                                                                                                        ? '0px'
                                                                                                        : '7px',
                                                                                                xs:
                                                                                                    (buttonExpand ===
                                                                                                        'time' &&
                                                                                                        fourTimeSelections) ||
                                                                                                    maxAltitude <=
                                                                                                        10000
                                                                                                        ? '0px'
                                                                                                        : '7px',
                                                                                                smd:
                                                                                                    maxAltitude <=
                                                                                                    10000
                                                                                                        ? '0px'
                                                                                                        : '7px',
                                                                                                md:
                                                                                                    (buttonExpand ===
                                                                                                        'time' &&
                                                                                                        fourTimeSelections &&
                                                                                                        !fullscreen) ||
                                                                                                    maxAltitude <=
                                                                                                        10000
                                                                                                        ? '0px'
                                                                                                        : '7px',
                                                                                                lg:
                                                                                                    maxAltitude <=
                                                                                                    10000
                                                                                                        ? '0px'
                                                                                                        : '7px',
                                                                                            }}
                                                                                            fontSize="0.8rem"
                                                                                            fontWeight="600"
                                                                                            width={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                xs:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                smd: '55px',
                                                                                                md:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections &&
                                                                                                    !fullscreen
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                lg: '55px',
                                                                                            }}
                                                                                            minWidth={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                xs:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                smd: '55px',
                                                                                                md:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections &&
                                                                                                    !fullscreen
                                                                                                        ? '0px'
                                                                                                        : '55px',
                                                                                                lg: '55px',
                                                                                            }}
                                                                                            opacity={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0'
                                                                                                        : '1',
                                                                                                xs:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? '0'
                                                                                                        : '1',
                                                                                                smd: '1',
                                                                                                md:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections &&
                                                                                                    !fullscreen
                                                                                                        ? '0'
                                                                                                        : '1',
                                                                                                lg: '1',
                                                                                            }}
                                                                                            visibility={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? 'hidden'
                                                                                                        : 'visible',
                                                                                                xs:
                                                                                                    buttonExpand ===
                                                                                                    'time'
                                                                                                        ? 'hidden'
                                                                                                        : 'visible',
                                                                                                smd: 'visible',
                                                                                                md:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections &&
                                                                                                    !fullscreen
                                                                                                        ? 'hidden'
                                                                                                        : 'visible',
                                                                                                lg: 'visible',
                                                                                            }}
                                                                                            transition="all ease 400ms"
                                                                                        >
                                                                                            SFC
                                                                                            –
                                                                                        </Text>
                                                                                    </Tooltip>
                                                                                    <ButtonGroup
                                                                                        borderRadius="20px"
                                                                                        px="2px"
                                                                                        overflow="hidden"
                                                                                        bg="light.20"
                                                                                        boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    >
                                                                                        {sigwx &&
                                                                                            filteredFlightLevels &&
                                                                                            filteredFlightLevels
                                                                                                .filter(
                                                                                                    (
                                                                                                        time
                                                                                                    ) =>
                                                                                                        time.validfrom ===
                                                                                                        sigwxTime
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        fl,
                                                                                                        i
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <Button // Flight Level
                                                                                                                key={`SigwxButtonFL${fl.level}-${i}-${fl.validfrom}-${fl.validto}`}
                                                                                                                bg="transparent"
                                                                                                                // color="#a8b2c0"
                                                                                                                variant="outline"
                                                                                                                height={{
                                                                                                                    base: '32px',
                                                                                                                    md: '28px',
                                                                                                                }}
                                                                                                                width={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i &&
                                                                                                                              fourTimeSelections
                                                                                                                            ? '46px'
                                                                                                                            : '56px',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i &&
                                                                                                                              fourTimeSelections
                                                                                                                            ? '46px'
                                                                                                                            : '60px',
                                                                                                                    sm:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              fourTimeSelections &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i
                                                                                                                            ? '80px'
                                                                                                                            : '88px',
                                                                                                                    md:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              !fullscreen
                                                                                                                            ? '64px'
                                                                                                                            : '80px',
                                                                                                                    lg: '72px',
                                                                                                                    xl:
                                                                                                                        !fourTimeSelections &&
                                                                                                                        fullscreen
                                                                                                                            ? '72px'
                                                                                                                            : '88px',
                                                                                                                    '2xl': '88px',
                                                                                                                }}
                                                                                                                minWidth={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i &&
                                                                                                                              fourTimeSelections
                                                                                                                            ? '46px'
                                                                                                                            : '56px',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i &&
                                                                                                                              fourTimeSelections
                                                                                                                            ? '46px'
                                                                                                                            : '60px',
                                                                                                                    sm:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : fourTimeSelections &&
                                                                                                                              sigwxLevelPosition ===
                                                                                                                                  i
                                                                                                                            ? '80px'
                                                                                                                            : '88px',
                                                                                                                    md:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0px'
                                                                                                                            : buttonExpand ===
                                                                                                                                  'time' &&
                                                                                                                              !fullscreen
                                                                                                                            ? '64px'
                                                                                                                            : '80px',
                                                                                                                    lg: '72px',
                                                                                                                    xl:
                                                                                                                        !fourTimeSelections &&
                                                                                                                        fullscreen
                                                                                                                            ? '72px'
                                                                                                                            : '88px',
                                                                                                                    '2xl': '88px',
                                                                                                                }}
                                                                                                                opacity={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '1',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '1',
                                                                                                                    lg: '1',
                                                                                                                }}
                                                                                                                visibility={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? 'hidden'
                                                                                                                            : 'visible',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? 'hidden'
                                                                                                                            : 'visible',
                                                                                                                    lg: 'visible',
                                                                                                                }}
                                                                                                                marginInlineStart="0px !important"
                                                                                                                border="none"
                                                                                                                justifyContent="center"
                                                                                                                paddingLeft={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '2px',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '2px',
                                                                                                                    xl: '2px',
                                                                                                                }}
                                                                                                                paddingRight={{
                                                                                                                    base:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '2px',
                                                                                                                    xs:
                                                                                                                        buttonExpand ===
                                                                                                                            'time' &&
                                                                                                                        sigwxLevelPosition !==
                                                                                                                            i
                                                                                                                            ? '0'
                                                                                                                            : '2px',
                                                                                                                    xl: '2px',
                                                                                                                }}
                                                                                                                py="2px"
                                                                                                                transition="all ease 400ms"
                                                                                                                {...noFocus}
                                                                                                                onClick={() => {
                                                                                                                    isPremium
                                                                                                                        ? setShowPricingTiers(
                                                                                                                              true
                                                                                                                          )
                                                                                                                        : setSigwxLevel(
                                                                                                                              fl.level
                                                                                                                          )
                                                                                                                    isPremium
                                                                                                                        ? setSigwxLevelPosition(
                                                                                                                              0
                                                                                                                          )
                                                                                                                        : setSigwxLevelPosition(
                                                                                                                              i
                                                                                                                          )
                                                                                                                    setButtonExpand(
                                                                                                                        'level'
                                                                                                                    )
                                                                                                                }}
                                                                                                                zIndex="1"
                                                                                                                _active={{
                                                                                                                    bg: 'none',
                                                                                                                    color: 'light.100',
                                                                                                                    opacity:
                                                                                                                        '1',
                                                                                                                }}
                                                                                                                _focus={{
                                                                                                                    bg: 'none',
                                                                                                                }}
                                                                                                                _hover={{
                                                                                                                    bg: 'none',
                                                                                                                    opacity:
                                                                                                                        {
                                                                                                                            base:
                                                                                                                                buttonExpand ===
                                                                                                                                    'time' &&
                                                                                                                                sigwxLevelPosition !==
                                                                                                                                    i
                                                                                                                                    ? '0'
                                                                                                                                    : '1',
                                                                                                                            xs:
                                                                                                                                buttonExpand ===
                                                                                                                                    'time' &&
                                                                                                                                sigwxLevelPosition !==
                                                                                                                                    i
                                                                                                                                    ? '0'
                                                                                                                                    : '1',
                                                                                                                            md: '1',
                                                                                                                        },
                                                                                                                }}
                                                                                                            >
                                                                                                                <Text
                                                                                                                    zIndex="1"
                                                                                                                    paddingLeft="0px"
                                                                                                                    lineHeight="1.3"
                                                                                                                    textTransform="uppercase"
                                                                                                                    fontSize={{
                                                                                                                        base: '0.65rem',
                                                                                                                        xs: '0.7rem',
                                                                                                                        xl: '0.8rem',
                                                                                                                    }}
                                                                                                                    fontWeight={
                                                                                                                        sigwxLevel ===
                                                                                                                        fl.level
                                                                                                                            ? '700'
                                                                                                                            : '600'
                                                                                                                    }
                                                                                                                    opacity={
                                                                                                                        sigwxLevel ===
                                                                                                                        fl.level
                                                                                                                            ? '1'
                                                                                                                            : isPremium
                                                                                                                            ? '0.4'
                                                                                                                            : '0.6'
                                                                                                                    }
                                                                                                                    color={
                                                                                                                        sigwxLevel ===
                                                                                                                        fl.level
                                                                                                                            ? 'light.200'
                                                                                                                            : 'light.100'
                                                                                                                    }
                                                                                                                    _active={{
                                                                                                                        color: isPremium
                                                                                                                            ? 'light.100'
                                                                                                                            : 'light.200',
                                                                                                                        opacity:
                                                                                                                            isPremium
                                                                                                                                ? '0.4'
                                                                                                                                : '1',
                                                                                                                    }}
                                                                                                                    _selected={{
                                                                                                                        color: 'light.200',
                                                                                                                        opacity:
                                                                                                                            '1',
                                                                                                                    }}
                                                                                                                    transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                                                >
                                                                                                                    {
                                                                                                                        fl.level
                                                                                                                    }
                                                                                                                </Text>
                                                                                                            </Button>
                                                                                                        )
                                                                                                    }
                                                                                                )}

                                                                                        <Box // BUTTON INDICATOR
                                                                                            willChange="auto"
                                                                                            className="buttonIndicator"
                                                                                            position="absolute"
                                                                                            marginInlineStart="0px !important"
                                                                                            mt="2px"
                                                                                            ml="0px"
                                                                                            px={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections
                                                                                                        ? '23px'
                                                                                                        : '28px',
                                                                                                xs:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections
                                                                                                        ? '23px'
                                                                                                        : '30px',
                                                                                                sm:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    fourTimeSelections
                                                                                                        ? '40px'
                                                                                                        : '44px',
                                                                                                md:
                                                                                                    buttonExpand ===
                                                                                                        'time' &&
                                                                                                    !fullscreen
                                                                                                        ? '32px'
                                                                                                        : '40px',
                                                                                                lg: '36px',
                                                                                                xl:
                                                                                                    !fourTimeSelections &&
                                                                                                    fullscreen
                                                                                                        ? '36px'
                                                                                                        : '44px',
                                                                                                '2xl': '44px',
                                                                                            }}
                                                                                            height={{
                                                                                                base: '28px',
                                                                                                md: '24px',
                                                                                            }}
                                                                                            background="white"
                                                                                            borderRadius="20px"
                                                                                            bg="#ffffff"
                                                                                            boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                            transform={{
                                                                                                base:
                                                                                                    buttonExpand ===
                                                                                                        'level' &&
                                                                                                    `translateX(${sigwxLevelPosition}00%)`,
                                                                                                lg: `translateX(${sigwxLevelPosition}00%)`,
                                                                                            }}
                                                                                            transition="all 400ms"
                                                                                        ></Box>
                                                                                    </ButtonGroup>
                                                                                </Flex>
                                                                            ) : (
                                                                                <Flex
                                                                                    ml={{
                                                                                        base: '0px',
                                                                                        sm: fourTimeSelections
                                                                                            ? '0px'
                                                                                            : '10px',
                                                                                        smd: '25px',
                                                                                        md: fourTimeSelections
                                                                                            ? '0px'
                                                                                            : '10px',
                                                                                        ml: '25px',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        whiteSpace="nowrap"
                                                                                        cursor="default"
                                                                                        textAlign="left"
                                                                                        fontSize="0.8rem"
                                                                                        fontWeight="600"
                                                                                    >
                                                                                        SFC
                                                                                        –
                                                                                        FL100
                                                                                    </Text>
                                                                                    {isPremium &&
                                                                                        maxAltitude <=
                                                                                            10000 && (
                                                                                            <Box //* *** PREMIUM BADGE *** */
                                                                                                // position="absolute"
                                                                                                pl="5px"
                                                                                                mt="-3px"
                                                                                            >
                                                                                                <PremiumBadge />
                                                                                            </Box>
                                                                                        )}
                                                                                </Flex>
                                                                            )}
                                                                        </Flex>
                                                                    </Portal>
                                                                    <Box
                                                                        className="desktopPortal"
                                                                        mx="auto"
                                                                        width="100%"
                                                                        ref={
                                                                            desktop
                                                                        }
                                                                    ></Box>
                                                                </Flex>

                                                                {!hideOnMobile && (
                                                                    <ZoomSlider
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        xy={xy}
                                                                        setScale={
                                                                            setScale
                                                                        }
                                                                        setXy={
                                                                            setXy
                                                                        }
                                                                        maxScale={
                                                                            3
                                                                        }
                                                                        fullscreen={
                                                                            fullscreen
                                                                        }
                                                                    />
                                                                )}
                                                                <IconButton
                                                                    {...noFocus}
                                                                    display={{
                                                                        base: fullscreen
                                                                            ? 'none'
                                                                            : 'flex',
                                                                        md: 'flex',
                                                                    }}
                                                                    color="light.100"
                                                                    width={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    paddingInlineStart="0"
                                                                    paddingInlineEnd="0"
                                                                    height={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    opacity={{
                                                                        base: '0.9',
                                                                        md: '0.8',
                                                                    }}
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    borderRadius="10px"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'none',
                                                                    }}
                                                                    icon={
                                                                        fullscreen ? (
                                                                            <CgClose
                                                                                fontSize="1.6rem"
                                                                                color="light.100"
                                                                            />
                                                                        ) : (
                                                                            <AiOutlineFullscreen
                                                                                fontSize="1.4rem"
                                                                                color="light.100"
                                                                            />
                                                                        )
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            fullscreen
                                                                        ) {
                                                                            setHandleReset(
                                                                                true
                                                                            )
                                                                        } else {
                                                                            setFullscreenHandler(
                                                                                'sigwx'
                                                                            )
                                                                        }
                                                                    }}
                                                                    variant="outline"
                                                                    border="none"
                                                                    ml="15px"
                                                                />
                                                            </Flex>
                                                            {!fullscreen && (
                                                                <Divider
                                                                    height="0px !important"
                                                                    borderColor="#e5e5e5"
                                                                    width="100%"
                                                                    mx="auto"
                                                                    mb="0px"
                                                                />
                                                            )}
                                                            <Flex
                                                                flexDirection={{
                                                                    base: 'column',
                                                                    md: 'column',
                                                                }}
                                                                pt={{
                                                                    lg: fullscreen
                                                                        ? '0px'
                                                                        : '10px',
                                                                }}
                                                                pb={{
                                                                    base: '0px',
                                                                    md: '15px',
                                                                }}
                                                                width="100%"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                height="100%"
                                                            >
                                                                <Box
                                                                    className="mobilePortal"
                                                                    width="100%"
                                                                    minHeight={{
                                                                        base: '74px',
                                                                        lg: '0px',
                                                                    }}
                                                                    zIndex="1"
                                                                    background="white"
                                                                    mt={
                                                                        fullscreen && {
                                                                            base: minimiseOnZoom
                                                                                ? '-75px'
                                                                                : '0px',
                                                                            xl: '0px',
                                                                        }
                                                                    }
                                                                    transition="all ease 300ms"
                                                                    borderBottom={{
                                                                        base:
                                                                            fullscreen &&
                                                                            '1px solid #E2E8F0',
                                                                        xl: 'none',
                                                                    }}
                                                                    ref={mobile}
                                                                ></Box>

                                                                <Box
                                                                    mt={
                                                                        fullscreen && {
                                                                            base: minimiseOnZoom
                                                                                ? '75px'
                                                                                : '0px',
                                                                            xl: '0px',
                                                                        }
                                                                    }
                                                                    transition="all ease 300ms"
                                                                >
                                                                    {sigwx
                                                                        .filter(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                sigwxLevel ===
                                                                                i.level
                                                                        )
                                                                        .filter(
                                                                            (
                                                                                chart
                                                                            ) =>
                                                                                parseISO(
                                                                                    chart.validfrom
                                                                                ) <=
                                                                                periodTo
                                                                        )
                                                                        .filter(
                                                                            (
                                                                                time
                                                                            ) =>
                                                                                sigwxTime ===
                                                                                time.validfrom
                                                                        )
                                                                        .map(
                                                                            (
                                                                                sigwxError,
                                                                                i
                                                                            ) => (
                                                                                <Flex
                                                                                    key={`SigwxBriefingChartError${sigwxError.level}-${i}-${sigwxError.validfrom}-${sigwxError.validto}`}
                                                                                >
                                                                                    {(showOutdatedErrors ||
                                                                                        sigwxError.outofdate ||
                                                                                        showImageLoadErrors) && (
                                                                                        <Flex
                                                                                            ml="-3px"
                                                                                            mb="20px"
                                                                                            justifyContent="center"
                                                                                        >
                                                                                            <ErrorText>
                                                                                                There
                                                                                                has
                                                                                                been
                                                                                                an
                                                                                                issue
                                                                                                with
                                                                                                the
                                                                                                connection
                                                                                                between
                                                                                                PreFlight
                                                                                                and
                                                                                                MetService.
                                                                                                The{' '}
                                                                                                <strong>
                                                                                                    SIGWX
                                                                                                    CHARTS
                                                                                                </strong>{' '}
                                                                                                were
                                                                                                last
                                                                                                validated
                                                                                                with
                                                                                                MetService
                                                                                                more
                                                                                                than{' '}
                                                                                                <strong>
                                                                                                    {formatDistanceToNow(
                                                                                                        parseISO(
                                                                                                            sigwxError.updated
                                                                                                        ),
                                                                                                        {
                                                                                                            addSuffix: true,
                                                                                                        }
                                                                                                    )}
                                                                                                </strong>
                                                                                                .
                                                                                                <br />
                                                                                                There
                                                                                                is
                                                                                                a
                                                                                                high
                                                                                                likelihood
                                                                                                that
                                                                                                the
                                                                                                latest
                                                                                                SIGWX
                                                                                                information
                                                                                                is
                                                                                                not
                                                                                                available
                                                                                                in
                                                                                                PreFlight.
                                                                                                Check
                                                                                                back
                                                                                                again
                                                                                                soon,
                                                                                                or
                                                                                                alternatively
                                                                                                visit{' '}
                                                                                                <a
                                                                                                    style={{
                                                                                                        fontWeight:
                                                                                                            '500',
                                                                                                        textDecoration:
                                                                                                            'underline',
                                                                                                    }}
                                                                                                    href="https://ifis.airways.co.nz/"
                                                                                                >
                                                                                                    IFIS
                                                                                                </a>

                                                                                                .
                                                                                            </ErrorText>
                                                                                        </Flex>
                                                                                    )}
                                                                                </Flex>
                                                                            )
                                                                        )}
                                                                </Box>

                                                                <Flex // IMAGE CONTAINER
                                                                    direction="column"
                                                                    position={{
                                                                        base: 'relative',
                                                                    }}
                                                                    w={{
                                                                        base: '100%',
                                                                    }}
                                                                    h="100%"
                                                                    pt={{
                                                                        base: fullscreen
                                                                            ? '15px'
                                                                            : '0',
                                                                        '2xl': fullscreen
                                                                            ? '25px'
                                                                            : '0',
                                                                        '3xl': fullscreen
                                                                            ? '25px'
                                                                            : '15px',
                                                                    }}
                                                                    px="10px"
                                                                    pb={{
                                                                        base: '5px',
                                                                        md: '10px',
                                                                    }}
                                                                    onClick={
                                                                        fullscreen
                                                                            ? () => {}
                                                                            : () =>
                                                                                  setFullscreenHandler(
                                                                                      'sigwx'
                                                                                  )
                                                                    }
                                                                    cursor={
                                                                        fullscreen
                                                                            ? 'grab'
                                                                            : 'zoom-in'
                                                                    }
                                                                >
                                                                    <PinchDrag
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        setScale={
                                                                            setScale
                                                                        }
                                                                        xy={xy}
                                                                        setXy={
                                                                            setXy
                                                                        }
                                                                        gestureDisabled={
                                                                            !fullscreen
                                                                        }
                                                                        maxZoom={
                                                                            3
                                                                        }
                                                                        maxZoomNewScale={
                                                                            2.999
                                                                        }
                                                                        fullscreenComponent={
                                                                            fullscreen
                                                                        }
                                                                    >
                                                                        {sigwx &&
                                                                            filteredFlightLevels &&
                                                                            filteredFlightLevels
                                                                                .filter(
                                                                                    (
                                                                                        chart
                                                                                    ) =>
                                                                                        parseISO(
                                                                                            chart.validfrom
                                                                                        ) <=
                                                                                        periodTo
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        image,
                                                                                        i
                                                                                    ) => {
                                                                                        return (
                                                                                            <Image
                                                                                                key={`SigwxImage${image.level}-${i}-${image.validfrom}-${image.validto}`}
                                                                                                mx="auto"
                                                                                                px={{
                                                                                                    base: '0px',
                                                                                                    md: '20px',
                                                                                                }}
                                                                                                maxWidth={
                                                                                                    pageScaleWidth
                                                                                                }
                                                                                                visibility={
                                                                                                    sigwxLevel ===
                                                                                                        image.level &&
                                                                                                    sigwxTime ===
                                                                                                        image.validfrom
                                                                                                        ? 'visible'
                                                                                                        : 'hidden'
                                                                                                }
                                                                                                height={
                                                                                                    sigwxLevel ===
                                                                                                        image.level &&
                                                                                                    sigwxTime ===
                                                                                                        image.validfrom
                                                                                                        ? 'auto'
                                                                                                        : '0px'
                                                                                                }
                                                                                                opacity={
                                                                                                    sigwxLevel ===
                                                                                                        image.level &&
                                                                                                    sigwxTime ===
                                                                                                        image.validfrom
                                                                                                        ? '1'
                                                                                                        : '0'
                                                                                                }
                                                                                                onError={
                                                                                                    onImageError
                                                                                                }
                                                                                                transition="all ease-in-out 0ms"
                                                                                                src={`${window.location.origin}/metproxy?url=${image.url}`}
                                                                                                alt="SIGWX CHART UNAVAILABLE"
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                )}
                                                                    </PinchDrag>
                                                                </Flex>

                                                                <Text
                                                                    position={
                                                                        fullscreen
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    bottom={
                                                                        fullscreen
                                                                            ? '25px'
                                                                            : 'unset'
                                                                    }
                                                                    paddingTop="15px"
                                                                    fontSize="11px"
                                                                    color={
                                                                        fullscreen
                                                                            ? 'light.100'
                                                                            : 'light.300'
                                                                    }
                                                                >
                                                                    Time
                                                                    references
                                                                    on chart are
                                                                    issued in
                                                                    UTC
                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Portal>
                                                    <Box ref={scrollRef}></Box>
                                                </AccordionPanel>
                                            )}
                                        </>
                                    )}
                                </AccordionItem>
                            ) : (
                                <Flex
                                    fontFamily="Open Sans"
                                    px="6"
                                    py="4"
                                    minHeight="50px"
                                    flexDirection="column"
                                >
                                    <Text
                                        fontSize={calculateFontSize(0.8)}
                                        lineHeight="1.2"
                                        fontWeight="500"
                                        fontFamily="'Open Sans', sans-serif"
                                        flex="2"
                                        color="light.400"
                                    >
                                        No current SIGWX
                                    </Text>
                                </Flex>
                            )}
                        </Accordion>
                    </Box>
                </Box>
            )}
        </>
    )
}
