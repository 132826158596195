import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'

// import { ReactComponent as AerodromeIcon } from '../../../../../icons/assets/aerodrome.svg'
import { ReactComponent as BriefNoItemsIcon } from '../../../../../icons/assets/brief-no-items.svg'
import { ReactComponent as BriefIcon } from '../../../../../icons/assets/brief.svg'
// import { ReactComponent as WarningIcon } from '../../../../../icons/assets/warning.svg'
import { ReactComponent as SubscriptionIcon } from '../../../../../icons/assets/subscription.svg'
import { ReactComponent as FavouriteIcon } from '../../../../../icons/assets/favourite-add.svg'
import { MdDeleteForever } from 'react-icons/md'

export function ExcludedAerodromesWarningMessage() {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="light.200" as={BriefNoItemsIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Excluded Aerodromes
                </Text>
            </Flex>

            <Text pb="10px" lineHeight="1.2">
                Excluding an aerodrome will remove it from the Briefing and PDF
                export.
            </Text>
            <Text pb="27px" lineHeight="1.2">
                You can reinstate any excluded aerodrome via the Excluded Items
                button.
            </Text>
            <Text pb="0px" lineHeight="1.2">
                Are you sure you want to exclude it?
            </Text>
        </Flex>
    )
}

export function AltExcludedAerodromesWarningMessage({
    showManageFavourites,
    excludedItemsShow,
}) {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="light.200" as={BriefNoItemsIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Excluded Aerodromes
                </Text>
            </Flex>
            <Text pb="12px" lineHeight="1.2">
                {showManageFavourites
                    ? 'Updating your list of excluded aerodromes will also apply to the current Briefing and PDF export as well.'
                    : 'Excluding an aerodrome will remove it from the Briefing and PDF export.'}
            </Text>
            <Text pb="12px" lineHeight="1.2">
                This will apply to <strong>ALL</strong> data types (includes
                NOTAM, METAR, TAF and ATIS) for that aerodrome.
            </Text>
            <Text pb="27px" lineHeight="1.2">
                {showManageFavourites || excludedItemsShow
                    ? 'You can exclude and reinstate aerodromes at any time.'
                    : 'You can reinstate any excluded aerodrome via the Excluded Items button.'}
            </Text>
            <Text pb={{ base: '10px', smd: '0px' }}>
                {showManageFavourites || excludedItemsShow
                    ? 'Are you sure you want to make this change?'
                    : 'Are you sure you want to exclude it?'}
            </Text>
        </Flex>
    )
}

export function ExcludedAWSWarningMessage({
    showManageFavourites,
    excludedItemsShow,
}) {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="light.200" as={BriefNoItemsIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Excluded Automatic Weather Stations
                </Text>
            </Flex>
            <Text pb="12px" lineHeight="1.2">
                {showManageFavourites
                    ? 'Updating your list of excluded weather stations will also apply to the current Briefing and PDF export as well.'
                    : 'Excluding a weather station will remove it from the Briefing and PDF export.'}
            </Text>
            <Text pb="27px" lineHeight="1.2">
                {showManageFavourites || excludedItemsShow
                    ? 'You can exclude and reinstate weather stations at any time.'
                    : 'You can reinstate any excluded weather station via the Excluded Items button.'}
            </Text>
            <Text pb={{ base: '10px', smd: '0px' }}>
                {showManageFavourites || excludedItemsShow
                    ? 'Are you sure you want to make this change?'
                    : 'Are you sure you want to exclude it?'}
            </Text>
        </Flex>
    )
}

export function ConfirmDeleteWarningMessage() {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="#F02C2C" as={MdDeleteForever} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Confirm Delete
                </Text>
            </Flex>
            <Text pb="5px">Are you sure you want to delete?</Text>
        </Flex>
    )
}

export function ConfirmDuplicateWarningMessage() {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="warning.100" as={FavouriteIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Confirm Save Favourite
                </Text>
            </Flex>
            <Text pb="5px">
                You already have a saved Favourite Briefing with the same
                criteria...
            </Text>
            <Text pb="5px">Are you sure you want to save this Favourite?</Text>
        </Flex>
    )
}

export function ConfirmBriefOnlyWarningMessage() {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-4px" justifyContent="left" alignItems="center" pb="20px">
                <Icon boxSize="36px" color="warning.100" as={BriefIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Confirm Briefing Only Mode
                </Text>
            </Flex>

            <Text pb="10px" lineHeight="1.2">
                <strong>Important:</strong>
            </Text>
            <Flex flexDirection="column" pl="15px">
                <Text pb="10px" lineHeight="1.2">
                    Enabling{' '}
                    <span style={{ fontWeight: '700' }}>
                        Briefing Only Mode
                    </span>{' '}
                    will deactivate{' '}
                    <span style={{ fontWeight: '600' }}>AERO</span>,{' '}
                    <span style={{ fontWeight: '600' }}>MET</span> and{' '}
                    <span style={{ fontWeight: '600' }}>ADVS</span> modes, along
                    with their associated map functions.
                </Text>
                <Text pb="10px" lineHeight="1.2">
                    Deactivating these modes may be helpful for users
                    encountering performance issues on certain devices,
                    particularly older models or those with limited graphics
                    capabilities.
                </Text>
                <Text pb="10px" lineHeight="1.2">
                    This will not affect access to a comprehensive briefing from
                    standard briefing areas.
                </Text>
                <Text pb="27px" lineHeight="1.2">
                    You can re-activate these modes by disabling Briefing Only
                    Mode whenever you wish.
                </Text>
            </Flex>

            <Text pb="5px">
                Are you sure you want to enable Briefing Only Mode?
            </Text>
        </Flex>
    )
}

export function ManageSubscriptionMessage({ userTier, userRoles, isPremium }) {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon boxSize="36px" color="light.200" as={SubscriptionIcon} />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Subscription Details
                </Text>
            </Flex>
            {!isPremium && userRoles.includes('Professional') && (
                <Text fontSize="0.95rem" pb="10px" fontWeight="600">
                    You currently have a{' '}
                    <span
                        style={{
                            fontWeight: '700',
                            color: '#8D38E3',
                        }}
                    >
                        PRO
                    </span>{' '}
                    subscription plan.
                </Text>
            )}
            {(isPremium || userTier < 2) && (
                <Text fontSize="0.95rem" fontWeight="600" pb="10px">
                    You do not have an active{' '}
                    <span
                        style={{
                            fontWeight: '700',
                            color: '#007DC8',
                        }}
                    >
                        PLUS
                    </span>{' '}
                    subscription plan.
                </Text>
            )}

            <Text pb="10px">
                This feature will take you to an external payment gateway
                (Stripe) where you can view your payment history if you had a
                previous{' '}
                <span
                    style={{
                        fontWeight: !isPremium && userTier > 2 ? '700' : '600',
                        color:
                            !isPremium && userTier > 2 ? '#007DC8' : 'initial',
                    }}
                >
                    PLUS
                </span>{' '}
                subscription.
            </Text>

            {(isPremium || userTier < 2) && (
                <Text pb="10px">
                    If you wish to purchase a premium subscription plan, please
                    click on the{' '}
                    <span
                        style={{
                            fontWeight: '700',
                            color: '#00BCF8',
                        }}
                    >
                        Upgrade to Premium
                    </span>{' '}
                    button at the bottom of the profile modal window instead.
                </Text>
            )}
            <Text pt="15px" pb={{ base: '10px', smd: '0px' }}>
                Are you sure you want to proceed to Stripe?
            </Text>
        </Flex>
    )
}

export function ErroneousDesignatorsMessage({
    erroneousAerodromes,
    erroneousAws,
}) {
    return (
        <Flex flexDirection="column" width="100%">
            <Flex ml="-8px" justifyContent="left" alignItems="center" pb="15px">
                <Icon
                    boxSize="36px"
                    color="warning.200"
                    as={BriefNoItemsIcon}
                />
                <Text
                    pl="10px"
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="left"
                >
                    Excluded{' '}
                    {erroneousAerodromes.length > 0 &&
                    ((erroneousAws && erroneousAws.length < 1) || !erroneousAws)
                        ? 'Aerodromes'
                        : erroneousAws.length > 0 &&
                          ((erroneousAerodromes &&
                              erroneousAerodromes.length < 1) ||
                              !erroneousAerodromes)
                        ? 'Automatic Weather Stations'
                        : 'Items'}
                </Text>
            </Flex>
            {((erroneousAerodromes && erroneousAerodromes.length > 0) ||
                (erroneousAws && erroneousAws.length > 0)) && (
                <>
                    <Text pb="5px" lineHeight="1.4">
                        Excluded{' '}
                        {erroneousAerodromes.length > 1
                            ? 'Aerodromes'
                            : erroneousAerodromes.length > 0
                            ? 'Aerodrome'
                            : ''}{' '}
                        <span style={{ fontWeight: '700' }}>
                            {erroneousAerodromes &&
                                erroneousAerodromes
                                    .map((name) => name)
                                    .join(', ')}
                        </span>{' '}
                        {erroneousAerodromes.length > 0 &&
                            erroneousAws.length > 0 &&
                            'and'}{' '}
                        {erroneousAws.length > 1
                            ? 'Automatic Weather Stations'
                            : erroneousAws.length > 0
                            ? 'Automatic Weather Station'
                            : ''}{' '}
                        <span style={{ fontWeight: '700' }}>
                            {erroneousAws &&
                                erroneousAws.map((name) => name).join(', ')}
                        </span>{' '}
                        {erroneousAerodromes.length > 1 ||
                        erroneousAws.length > 1 ||
                        (erroneousAerodromes.length === 1 &&
                            erroneousAws.length === 1)
                            ? 'were'
                            : 'was'}{' '}
                        not found within the selected geographical area, and
                        therefore{' '}
                        {erroneousAerodromes.length > 1 ||
                        erroneousAws.length > 1 ||
                        (erroneousAerodromes.length === 1 &&
                            erroneousAws.length === 1)
                            ? 'have'
                            : 'has'}{' '}
                        been removed from this briefing.
                    </Text>
                    <Text pt="10px">
                        To avoid receiving this error, consider reloading this
                        briefing without the excluded items, and then revise
                        your excluded item selections. This ensures accurate
                        information and avoids potential issues.
                    </Text>
                    {/* <Text pt="10px">
                        To avoid receiving this error, consider reloading this
                        briefing without the excluded items, revise your
                        excluded item selections, and then save it as a
                        favourite. This ensures accurate information in your
                        saved criteria and avoids potential issues.
                    </Text> */}
                </>
            )}
        </Flex>
    )
}
