export const config = {
    collections: ['constant', 'met', 'aero', 'advs', 'brief'],
    modes: ['met', 'aero', 'advs', 'brief'],
    menu: {
        aero: [
            { id: 'airspace' },
            { id: 'hazards' },
            { id: 'obstacles' },
            { id: 'navaids' },
            { id: 'vrp' },
            { id: 'icaoWaypoints' },
            { id: 'routes' },
        ],
        met: [
            {
                // Work In Progress (move item to respective category when ready) - ONLY VISIBLE TO ADMINS
                id: 'wip',
                label: 'Work In Progress (admin only)',
                items: [
                    'satelliteCloudVisible',
                    'satelliteCloudInfrared',
                    'satelliteCloudInfraredEnhanced',
                    'satelliteCloudTops',
                    'rainradar',
                    'rainRadarWxTiles',
                    'grafor',
                    'windanimation',
                    'windgrid',
                ],
            },

            {
                id: 'advisories',
                label: 'Weather Advisories',
                items: [
                    'sigmet',
                    'sigwx',
                    'sigmetCharts',
                    'sigwxCharts',
                    'spacewx',
                    'thunderstormOutlook',
                    'vaa',
                ],
            },
            {
                id: 'cloud',
                label: 'Cloud',
                items: [
                    'cloudImagery',
                    'lowcloudforecast',
                    // 'satelliteCloudVisible',
                    // 'satelliteCloudInfrared',
                    // 'satelliteCloudInfraredEnhanced',
                    // 'satelliteCloudTops',
                ],
            },
            {
                id: 'precipitation',
                label: 'Precipitation',
                items: [
                    'rainRadarImagery',
                    'rainforecast',
                    // 'rainradar',
                    // 'rainRadarWxTiles',
                ],
            },
            // {
            //     id: 'wind',
            //     label: 'Aviation Area Winds',
            //     items: ['aaw', 'windanimation', 'windgrid'],
            // },
            // {
            //     id: 'graphicalaviationforecast',
            //     label: 'GRAFOR',
            //     items: ['graforCharts', 'grafor'],
            // },
            {
                id: 'aaw', // delete when above required
            },
            {
                id: 'graforCharts', // delete when above required
            },
            {
                id: 'mslCharts',
            },
            {
                id: 'aws',
            },
            {
                id: 'webcams',
            },
        ],
        advs: [
            { id: 'notam' },
            { id: 'rpas' },
            { id: 'sigmet' },
            { id: 'sigwx' },
        ],
    },
    layers: {
        aerodromes: {
            id: 'aerodromes',
            label: 'Aerodromes',
            collection: [
                {
                    id: 'constant',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/aerodromes`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        briefingareas: {
            id: 'briefingareas',
            label: 'Briefing Areas',
            collection: [
                {
                    id: 'brief',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/briefingareas`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        fir: {
            id: 'fir',
            label: 'Flight Information Region',
            collection: [
                {
                    id: 'constant',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/fir`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        runways: {
            id: 'runways',
            label: 'Runways',
            collection: [
                {
                    id: 'constant',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/runways`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        satellite: {
            id: 'satellite',
            label: 'Satellite',
            collection: [
                {
                    id: 'constant',
                    displayOnDefault: false,
                    tier: 1,
                },
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'raster',
            baseURL: `${window.location.origin}/data/satellite`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        vnc: {
            id: 'vnc',
            label: 'Visual Navigation Chart',
            collection: [
                {
                    id: 'constant',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        thunderstormOutlook: {
            id: 'thunderstormOutlook',
            label: 'Thunderstorm Outlook',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                    externalURL:
                        'https://www.metservice.com/warnings/thunderstorm-outlook',
                },
            ],
            type: 'external',
        },
        vaa: {
            id: 'vaa',
            label: 'Volcanic Ash Advisories',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                    externalURL: 'http://vaac.metservice.com/',
                },
            ],
            type: 'external',
        },
        airspace: {
            id: 'airspace',
            label: 'Airspace',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/airspace`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        hazards: {
            id: 'hazards',
            label: 'Aeronautical Hazards',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/hazards`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        obstacles: {
            id: 'obstacles',
            label: 'Obstacles',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/obstacles`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        vrp: {
            id: 'vrp',
            label: 'Visual Reporting Points',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/vrp`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        icaoWaypoints: {
            id: 'icaoWaypoints',
            label: 'ICAO Waypoints',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/icaowaypoints`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        waypoints: {
            id: 'waypoints',
            label: 'Visual Reporting Points',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/waypoints`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        navaids: {
            id: 'navaids',
            label: 'Navaids',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: true,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/navaids`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        routes: {
            id: 'routes',
            label: 'Routes',
            collection: [
                {
                    id: 'aero',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/routes`,
            contrast: { base: 'standard', imagery: 'medium' },
            wip: true,
        },
        cloudImagery: {
            id: 'cloudImagery',
            label: 'Cloud Imagery',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/satelliteimages`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        lowcloudforecast: {
            id: 'lowcloudforecast',
            label: 'Low Cloud Forecast Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/cloudforecast`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        aws: {
            id: 'aws',
            label: 'Automatic Weather Stations',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/aws`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        webcams: {
            id: 'webcams',
            label: 'Webcams',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/webcams`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        sigmet: {
            id: 'sigmet',
            label: 'SIGMET',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: true,
                    tier: 2,
                },
                {
                    id: 'advs',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/sigmet`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        sigwx: {
            id: 'sigwx',
            label: 'SIGWX',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                },
                {
                    id: 'advs',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/sigwx`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        sigmetCharts: {
            id: 'sigmetCharts',
            label: 'SIGMET Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    displayOnDefaultFree: true,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/sigmet/charts`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        sigwxCharts: {
            id: 'sigwxCharts',
            label: 'SIGWX Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/sigwx/charts`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        mslCharts: {
            id: 'mslCharts',
            label: 'MSL Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/msl/charts`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        grafor: {
            id: 'grafor',
            label: 'GRAFOR',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/grafor`,
            contrast: { base: 'standard', imagery: 'medium' },
            wip: true,
        },
        graforCharts: {
            id: 'graforCharts',
            label: 'GRAFOR Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/grafor/charts`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        aaw: {
            id: 'aaw',
            label: 'Aviation Area Winds',
            sources: ['aaw-areas'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/aaw`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        windanimation: {
            id: 'windanimation',
            label: 'Wind Animation',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                    isDisabled: true,
                },
            ],
            type: 'vector',
            wip: true,
        },
        windgrid: {
            id: 'windgrid',
            label: 'Gridded Winds',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                    isDisabled: true,
                },
            ],
            type: 'vector',
            wip: true,
        },
        rainradar: {
            id: 'rainradar',
            label: 'Rain Radar',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: `${window.location.origin}/data/rainradar`,
            contrast: { base: 'standard', imagery: 'medium' },
            wip: true,
        },
        rainforecast: {
            id: 'rainforecast',
            label: 'Rain Forecast Charts',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
        },
        rainRadarImagery: {
            id: 'rainRadarImagery',
            label: 'Rain Radar',
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: `${window.location.origin}/data/rainradar`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        rainRadarWxTiles: {
            id: 'rainRadarWxTiles',
            label: 'Rain Radar Tiles',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'standard', imagery: 'medium' },
            wip: true,
        },
        spacewx: {
            id: 'spacewx',
            label: 'Space Weather Advisories',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 1,
                },
            ],
            type: 'modal',
            baseURL: null,
        },
        lightning: {
            id: 'lightning',
            label: 'Lightning',
            sources: ['lightning-animated'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: 'https://lightning.api.metraweather.com/v4/strikes',
            contrast: { base: 'standard', imagery: 'medium' },
        },
        satelliteCloudVisible: {
            id: 'satelliteCloudVisible',
            label: 'Visible Cloud',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'high', imagery: 'high' },
            wip: true,
        },
        satelliteCloudTops: {
            id: 'satelliteCloudTops',
            label: 'Cloud Tops',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'high', imagery: 'high' },
            wip: true,
        },
        satelliteCloudInfrared: {
            id: 'satelliteCloudInfrared',
            label: 'Cloud Infrared',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'high', imagery: 'high' },
            wip: true,
        },
        satelliteCloudInfraredEnhanced: {
            id: 'satelliteCloudInfraredEnhanced',
            label: 'Cloud Infrared Enhanced',
            sources: ['dynamic'],
            collection: [
                {
                    id: 'met',
                    displayOnDefault: false,
                    isBuggy: true,
                    tier: 2,
                },
            ],
            type: 'raster',
            baseURL: null,
            contrast: { base: 'high', imagery: 'high' },
            wip: true,
        },
        notam: {
            id: 'notam',
            label: 'NOTAM',
            collection: [
                {
                    id: 'advs',
                    displayOnDefault: true,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/notam`,
            contrast: { base: 'standard', imagery: 'medium' },
        },
        rpas: {
            id: 'rpas',
            label: 'RPAS',
            collection: [
                {
                    id: 'advs',
                    displayOnDefault: false,
                    tier: 2,
                },
            ],
            type: 'vector',
            baseURL: `${window.location.origin}/data/rpas`,
            contrast: { base: 'standard', imagery: 'medium' },
            wip: true,
        },
    },
}
