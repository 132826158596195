import React, {
    useContext,
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Source, Layer, MapContext } from 'react-mapbox-gl'
import { useQuery } from 'react-query'
import formatAAWData from '../functions/formatAawData'
import calcSymbolPlacement from '../functions/dynamicSymbols'
import {
    selectedTimeState,
    timeZoneState,
    altitudeModeState,
    selectedAltitudeState,
    layerStatusState,
    layersLoaded,
    removeLayerErrorHandler,
    layerErrorHandler,
    statusData,
} from '../../../globalState'

export default function AAWLayer() {
    const { getAccessTokenSilently } = useAuth0()
    const selectedTime = useRecoilValue(selectedTimeState)
    const timeZone = useRecoilValue(timeZoneState)
    const selectedAltitude = useRecoilValue(selectedAltitudeState)
    const [altitudeMode, setAltitudeMode] = useRecoilState(altitudeModeState)
    const layerState = useRecoilValue(layerStatusState)
    const status = useRecoilValue(statusData)
    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const [mounted, setMounted] = useState(false)
    const [geomData, setGeomData] = useState(null)
    const setLayerError = useSetRecoilState(layerErrorHandler)
    const removeError = useSetRecoilState(removeLayerErrorHandler)
    const statusRef = useRef(null)
    const [data, setData] = useState({
        type: 'FeatureCollection',
        features: [],
    })

    const mapInstance = useContext(MapContext)

    const layerId = 'aaw'

    useEffect(() => {
        if (!mounted) {
            setLoaded(!layerState[`${layerId}Loading`])
            setMounted(!layerState[`${layerId}Loading`])
        }
    }, [layerState, setLoaded, mounted, loaded])

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    const fetchAAWData = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/aaw/data?t=${Date.now()}`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const fetchGeomData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aaw/areas`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })

        return res.json()
    }, [getAccessTokenSilently])

    const {
        data: aawData,
        status: aawStatus,
        refetch,
    } = useQuery('aaw', fetchAAWData, { refetchOnWindowFocus: false })

    // This useEffect handles errors
    useEffect(() => {
        if (aawData && aawData.length && aawStatus === 'success') {
            removeError({ layer: layerId.toLowerCase(), type: 'fetching' })
        } else if (aawStatus === 'error' || (aawData && !aawData.length)) {
            setLayerError({
                layer: layerId.toLowerCase(),
                type: 'fetching',
            })
        }
    }, [aawData, removeError, setLayerError, aawStatus])

    useEffect(() => {
        if (status && status[layerId.toLowerCase()] && !mounted) {
            // console.log('status ref initiated')
            statusRef.current = status[layerId.toLowerCase()]['version']
            setMounted(true)
        }
    }, [status, layerId, mounted])

    useEffect(() => {
        // When version stamp in status has changed refetch layer times.
        if (
            mounted &&
            statusRef.current !== status[layerId.toLowerCase()]['version'] &&
            !status[layerId.toLowerCase()]['mapOutdated']
        ) {
            statusRef.current = status[layerId.toLowerCase()]['version']
            refetch()
        }
    }, [status, mounted, layerId, refetch])

    useEffect(() => {
        if (altitudeMode !== 'single') {
            setAltitudeMode('single')
        }
    }, [altitudeMode, setAltitudeMode])

    // GeomData is fetched once initially.
    useEffect(() => {
        fetchGeomData().then((json) => setGeomData(json))
    }, [fetchGeomData])

    useEffect(() => {
        setData(formatAAWData(geomData, aawData, selectedTime, timeZone))
    }, [selectedTime, timeZone, aawData, geomData])

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(mapInstance, 'aaw-fill', 'aaw-symbol', 'area')
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [data, mapInstance])

    return (
        <>
            <Source
                id="aaw"
                geoJsonSource={{
                    type: 'geojson',
                    data: data,
                }}
            />
            <Source
                id="aaw-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData,
                }}
            />
            <Layer
                id="aaw-fill"
                sourceId="aaw"
                before="aerodromes-symbol"
                type="fill"
                paint={{
                    'fill-color': ['get', 'colour'],
                    'fill-opacity': 0.33,
                }}
                filter={['!=', '$type', 'LineString']}
            />
            <Layer
                id="aaw-line"
                sourceId="aaw"
                before="aerodromes-symbol"
                type="line"
                paint={{
                    'line-color': '#fff',
                    'line-opacity': 0.66,
                    'line-width': 4,
                }}
                filter={['all', ['==', '$type', 'LineString']]}
            />
            <Layer
                id="aaw-symbol"
                sourceId="aaw-symbol"
                type="symbol"
                layout={{
                    'text-field': [
                        'step',
                        ['zoom'],
                        ['get', 'area'],
                        5,
                        [
                            'format',
                            // Area
                            ['get', 'area'],
                            { 'font-scale': 1 },
                            '\n',
                            {},
                            // Wind Direction
                            [
                                'to-string',
                                [
                                    'at',
                                    [
                                        'index-of',
                                        selectedAltitude,
                                        ['get', 'altitude'],
                                    ],
                                    ['get', 'winddir'],
                                ],
                            ],
                            { 'font-scale': 0.6 },
                            '/',
                            { 'font-scale': 0.6 },
                            // Wind Strength
                            [
                                'to-string',
                                [
                                    'at',
                                    [
                                        'index-of',
                                        selectedAltitude,
                                        ['get', 'altitude'],
                                    ],
                                    ['get', 'windstr'],
                                ],
                            ],
                            { 'font-scale': 0.6 },
                        ],
                        7,
                        [
                            'format',
                            // Area
                            ['get', 'area'],
                            { 'font-scale': 1 },
                            '\n',
                            {},
                            // Becoming
                            [
                                'case',
                                [
                                    'all',
                                    ['to-boolean', ['get', 'between_start']],
                                    ['to-boolean', ['get', 'between_end']],
                                ],
                                [
                                    'concat',
                                    'BCMG BTN ',
                                    ['get', 'between_start_formatted'],
                                    ' - ',
                                    ['get', 'between_end_formatted'],
                                    '\n',
                                ],
                                [
                                    'all',
                                    [
                                        'to-boolean',
                                        ['get', 'between_start_formatted'],
                                    ],
                                    [
                                        '!',
                                        ['to-boolean', ['get', 'between_end']],
                                    ],
                                ],
                                '', // ['concat', 'At ', ['get', 'between_start'], '\n'],
                                '',
                            ],
                            { 'font-scale': 0.6 },
                            ['image', 'windsock'],
                            { 'font-scale': 0.5 },
                            // Wind Direction
                            [
                                'to-string',
                                [
                                    'at',
                                    [
                                        'index-of',
                                        selectedAltitude,
                                        ['get', 'altitude'],
                                    ],
                                    ['get', 'winddir'],
                                ],
                            ],
                            { 'font-scale': 0.7 },
                            '°T ',
                            { 'font-scale': 0.7 },
                            // Wind Strength
                            [
                                'to-string',
                                [
                                    'at',
                                    [
                                        'index-of',
                                        selectedAltitude,
                                        ['get', 'altitude'],
                                    ],
                                    ['get', 'windstr'],
                                ],
                            ],
                            { 'font-scale': 0.7 },
                            ' kt',
                            { 'font-scale': 0.7 },
                            '\n',
                            {},
                            // Temperature
                            [
                                'case',
                                [
                                    'to-boolean',
                                    [
                                        'at',
                                        [
                                            'index-of',
                                            selectedAltitude,
                                            ['get', 'altitude'],
                                        ],
                                        ['get', 'temp'],
                                    ],
                                ],
                                ['image', 'temperature'],
                                '',
                            ],
                            {},
                            [
                                'case',
                                [
                                    'to-boolean',
                                    [
                                        'at',
                                        [
                                            'index-of',
                                            selectedAltitude,
                                            ['get', 'altitude'],
                                        ],
                                        ['get', 'temp'],
                                    ],
                                ],
                                [
                                    'concat',

                                    [
                                        'to-string',
                                        [
                                            'at',
                                            [
                                                'index-of',
                                                selectedAltitude,
                                                ['get', 'altitude'],
                                            ],
                                            ['get', 'temp'],
                                        ],
                                    ],
                                    '°C',
                                ],
                                '',
                            ],
                            { 'font-scale': 0.7 },
                            [
                                'case',
                                ['to-boolean', ['get', 'remark']],
                                ['concat', 'RMK: ', ['get', 'remark']],
                                '',
                            ],
                            { 'font-scale': 0.6 },
                        ],
                    ],
                    'text-size': 20,
                    'text-allow-overlap': true,
                    'text-optional': false,
                }}
            />
        </>
    )
}
