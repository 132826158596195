import { parseISO, formatDistanceToNowStrict } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

// Function to replace full timezone names with abbreviations (date-fns-tz does not do this by default)
export function formattedTimeZone(abbreviateTimeZone) {
    return abbreviateTimeZone
        .replace('Coordinated Universal Time', 'UTC')
        .replace('New Zealand Daylight Time', 'NZDT')
        .replace('New Zealand Standard Time', 'NZST')
}

// EXAMPLE = 22 FEB 2024 10:00 NZDT
export default function formatDateTime(date, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(parseISO(date), timeZone, 'dd MMM yyyy HH:mm zzzz')
    ).toUpperCase()
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 10:00 22 FEB 2024 NZDT
export function formatTimeDate(date, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(parseISO(date), timeZone, 'HH:mm dd MMM yyyy zzzz')
    ).toUpperCase()
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 10:00 22 NZDT
export function formatTimeAndDay(date, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(parseISO(date), timeZone, 'HH:mm dd zzzz')
    ).toUpperCase()
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 2024-02-22T00:24:08
export function formatDateToTimezone(date, timeZone) {
    const formattedTime = formatInTimeZone(
        date,
        timeZone,
        "yyyy-MM-dd'T'HH:mm:ss"
    )
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = Thu Feb 22 2024 12:00:00 NZDT
export function formatTimeDateToTimezone(time, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(
            parseISO(time),
            timeZone,
            'eee MMM dd yyyy HH:mm:ss zzzz'
        )
    )
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = Thu 22 - 11:47
export function dateAndTimeFormatter(time, timeZone, format) {
    const formattedTime = formatInTimeZone(time, timeZone, format)
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 11:48
export function formatTimeOnly(time, timeZone) {
    const formattedTime = formatInTimeZone(parseISO(time), timeZone, 'HH:mm')
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 11:45 NZDT
export function formatTimeAndTimezone(time, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(parseISO(time), timeZone, 'HH:mm zzzz')
    ).toUpperCase()
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = Thu
export function formatDayOnly(time, timeZone) {
    const formattedTime = formatInTimeZone(parseISO(time), timeZone, 'eee')
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = Thu 22
export function formatDayAndDate(date, timeZone) {
    const formattedTime = formatInTimeZone(parseISO(date), timeZone, 'eee dd')
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = 22 Feb 2024 NZDT
export function formatDateAndTimezone(date, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(parseISO(date), timeZone, 'dd MMM yyyy zzzz')
    )
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = NZDT
export function timeZoneAbbreviation(date, timeZone) {
    const formattedTime = formattedTimeZone(
        formatInTimeZone(date, timeZone, 'zzzz')
    ).toUpperCase()
    if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

// EXAMPLE = +13:00
export function timeZoneOffsetUTC(timeZone) {
    const formattedTime = formatInTimeZone(Date.now(), timeZone, 'XXX')
    if (formattedTime && formattedTime === 'Z') {
        return '+00:00' // date-fns-tz returns "Z" for +00:00
    } else if (formattedTime) {
        return formattedTime
    } else {
        return 'ERROR'
    }
}

/* Plain explaination of start and end times for Modals */

// EXAMPLE = ACTIVE, expires 4 hours from now
export function timeRangeInWords(now, start, end) {
    const startDate = parseISO(start)
    const endDate = end && parseISO(end)

    return startDate > now
        ? `Upcoming ${formatDistanceToNowStrict(startDate, {
              addSuffix: true,
          }).replace('in ', '')} from now`
        : end
        ? `ACTIVE, ${
              endDate < now ? 'was due to expire' : 'expires'
          } ${formatDistanceToNowStrict(endDate, {
              addSuffix: true,
          }).replace('in ', '')} from now`
        : 'ACTIVE now, permanent'
}

/* Shortened plain explaination of start and end times for NOTAM Toasts */

// EXAMPLE = ACTIVE, expires in 4 hours
export function timeRangeInAbrevWords(now, start, end) {
    const startDate = parseISO(start)
    const endDate = end && parseISO(end)

    return startDate > now
        ? `Upcoming ${formatDistanceToNowStrict(startDate, {
              addSuffix: true,
          }).replace('in ', '')}`
        : end
        ? `ACTIVE, ${
              endDate < now ? 'was due to expire in' : 'expires in'
          } ${formatDistanceToNowStrict(endDate, {
              addSuffix: true,
          }).replace('in ', '')}`
        : 'ACTIVE now, permanent'
}

/* Shortened plain explaination of start and end times for SIGWX Toasts */

// EXAMPLE = ACTIVE, expires in 4 hours
export function timeRangeInAbrevWordsSigwx(now, start, end) {
    const startDate = parseISO(start)
    const endDate = end && parseISO(end)

    return startDate > now
        ? `Upcoming ${formatDistanceToNowStrict(startDate, {
              addSuffix: true,
          })}`
        : end
        ? `ACTIVE, expires ${formatDistanceToNowStrict(endDate, {
              addSuffix: true,
          })}`
        : 'ACTIVE now'
}

/* Shortened plain explaination of start and end times for RPAS Toasts */

// EXAMPLE = ACTIVE, expires in 4 hours
export function timeRangeInAbrevWordsRpas(now, start, end) {
    const startDate = parseISO(start)
    const endDate = end && parseISO(end)

    return startDate > now
        ? `Upcoming ${formatDistanceToNowStrict(startDate, {
              addSuffix: true,
          })}`
        : end
        ? `ACTIVE, expires ${formatDistanceToNowStrict(endDate, {
              addSuffix: true,
          })}`
        : 'ACTIVE now'
}
