import React from 'react'
import { Badge, Tooltip, Icon, Text } from '@chakra-ui/react'
import { MdOutlineHighlightOff } from 'react-icons/md'

export default function BuggyBadge() {
    return (
        <Tooltip
            label="Item requires further work"
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.8rem"
            alignItems="center"
            display="flex"
            placement="auto"
            ml="5px"
            bg="#800000"
        >
            <Badge
                alignItems="center"
                justifyContent="center"
                display="flex"
                marginRight="5px"
                marginTop="2px"
                ml={2}
                variant="solid"
                bg="#800000"
                color="#cccccc"
                textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                borderRadius="10"
                px="6px"
                pb="0px"
                pt="0px"
                fontSize="10px"
                height="18px"
                lineHeight="1.8"
            >
                Buggy
            </Badge>
        </Tooltip>
    )
}

export function UnavailableBadge({ label }) {
    return (
        <Tooltip
            label={`${label} is currently unavailable`}
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.8rem"
            alignItems="center"
            display="flex"
            placement="auto"
            ml="5px"
            bg="gray.800"
        >
            <Badge
                alignItems="center"
                justifyContent="center"
                display="flex"
                marginRight="0px"
                marginTop="2px"
                ml={'3px'}
                variant="solid"
                borderColor="red.500"
                border="1px solid"
                color="red.500"
                textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                borderRadius="10"
                bg="white"
                px="3px"
                pb="0px"
                pt="0px"
                fontSize="0.6rem"
                fontWeight="700"
                height="18px"
                lineHeight="1.8"
                minWidth="90px"
            >
                <Icon
                    as={MdOutlineHighlightOff}
                    zIndex="1"
                    fontSize="14px"
                    color="red.500"
                />
                <Text pl="2px" pr="3px">
                    UNAVAILABLE
                </Text>
            </Badge>
        </Tooltip>
    )
}
