import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAdminRole } from '../../auth/hooks'
import {
    Box,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    Text,
    Flex,
    Icon,
    Divider,
    Table,
    Tbody,
    Tr,
    Td,
    useDisclosure,
    HStack,
    Center,
} from '@chakra-ui/react'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { useQuery } from 'react-query'
import { isMobileOnly, isIOS, isMobileSafari } from 'react-device-detect'

import {
    timeZoneState,
    fullscreenModalVisibleHandler,
    dataModeState,
    layerSelectionState,
    showErrorTogglesState,
} from '../../globalState'
import { ReactComponent as spacewxicon } from '../../icons/assets/spacewx.svg'
import ErrorToggle from './components/ErrorToggle'
import FullWidthError from './components/FullWidthError'
import ErrorText from './components/ErrorText'

import formatDateTime from '../../util/dateFormatter'

export default function SpaceWxModal() {
    const { getAccessTokenSilently } = useAuth0()
    const isAdmin = useAdminRole()
    const fetchSpaceWx = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/spacewx`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const timeZone = useRecoilValue(timeZoneState)

    const { data, status } = useQuery('spaceWx', fetchSpaceWx, {
        refetchOnWindowFocus: false,
    })
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.spacewx) {
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, layerSelection, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    return (
        <>
            <LightMode>
                <Modal
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    isOpen={isOpen}
                    scrollBehavior="inside"
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            minHeight={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                        >
                            <ModalHeader
                                backgroundColor="light.10"
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    // base: '0px 5px 10px -1px rgba(0,0,0,0.05)',
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            paddingLeft={{
                                                base: '0px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={spacewxicon}
                                                ml={{ base: '0px', md: '4px' }}
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color="light.100"
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="bold"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    Space Weather
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={
                                            isAdmin && showErrorToggle
                                                ? '100%'
                                                : '0'
                                        }
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            minHeight="100px"
                            paddingTop={status === 'loading' ? '200px' : '15px'}
                            px={5}
                            height={{
                                base:
                                    isMobileOnly && isIOS && isMobileSafari
                                        ? 'calc(100% - 155px)'
                                        : 'calc(100% - 140px)',
                                md: '100%',
                            }}
                            bg={{
                                base: '#edf1f7',
                                md: '#f4f7fc',
                            }}
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status === 'loading' && (
                                <div className="pageload">
                                    <Center w="100%" h="100%" mt="-65px">
                                        <Spinner
                                            color={'light.200'}
                                            emptyColor="rgba(255,255,255,0.3)"
                                            thickness="3px"
                                            speed="0.45s"
                                            boxSize="60px"
                                        />
                                    </Center>
                                </div>
                            )}
                            {status !== 'loading' &&
                                (status === 'error' || showNetworkErrors) && (
                                    <FullWidthError
                                        message={
                                            'Error fetching Space Weather Data'
                                        }
                                    />
                                )}
                            {status !== 'loading' &&
                                status !== 'error' &&
                                !showNetworkErrors &&
                                data.length > 0 &&
                                data.map((feat) => (
                                    <>
                                        {(showOutdatedErrors ||
                                            feat.outofdate) && (
                                            <Flex
                                                px="15px"
                                                pt="10px"
                                                mb="15px"
                                                justifyContent="center"
                                            >
                                                <ErrorText>
                                                    There has been an issue with
                                                    the connection between
                                                    PreFlight and MetService.
                                                    This SpaceWx Advisory was
                                                    last validated with
                                                    MetService{' '}
                                                    <strong>
                                                        {formatDistanceToNow(
                                                            parseISO(
                                                                feat.updated
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                    </strong>
                                                    .<br />
                                                    There is a high likelihood
                                                    that the latest SpaceWx
                                                    advisories are not available
                                                    in PreFlight. Check back
                                                    again soon, or alternatively
                                                    visit{' '}
                                                    <a
                                                        style={{
                                                            fontWeight: '500',
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                        href="https://ifis.airways.co.nz/"
                                                    >
                                                        IFIS
                                                    </a>
                                                    .
                                                </ErrorText>
                                            </Flex>
                                        )}
                                        <Flex
                                            marginTop="2"
                                            flexDirection="column"
                                        >
                                            <Flex
                                                width="100%"
                                                key={`${feat.nr}`}
                                            >
                                                <Icon
                                                    boxSize="30px"
                                                    bg="#afbfdb"
                                                    color="#ffffff"
                                                    borderRadius="100%"
                                                    px="4px"
                                                    mt="5px"
                                                    as={spacewxicon}
                                                />
                                                <Box
                                                    width="100%"
                                                    paddingLeft="20px"
                                                >
                                                    <Table
                                                        size="sm"
                                                        variant="normal"
                                                    >
                                                        <Tbody>
                                                            <Tr>
                                                                <Td
                                                                    paddingStart="0"
                                                                    fontWeight="bold"
                                                                    width="auto"
                                                                    minWidth="120px"
                                                                >
                                                                    Advisory #:
                                                                </Td>
                                                                <Td width="100%">
                                                                    {feat.nr}
                                                                </Td>
                                                            </Tr>

                                                            <Tr>
                                                                <Td
                                                                    paddingStart="0"
                                                                    fontWeight="bold"
                                                                    width="auto"
                                                                    minWidth="120px"
                                                                >
                                                                    Issued:
                                                                </Td>
                                                                <Td width="100%">
                                                                    {feat?.issued &&
                                                                        formatDateTime(
                                                                            feat.issued,
                                                                            timeZone
                                                                        )}
                                                                </Td>
                                                            </Tr>

                                                            <Tr>
                                                                <Td
                                                                    paddingStart="0"
                                                                    fontWeight="bold"
                                                                    width="auto"
                                                                    minWidth="120px"
                                                                >
                                                                    Issuing
                                                                    Centre:
                                                                </Td>
                                                                <Td width="100%">
                                                                    {feat.swxc ===
                                                                    'ACFJ'
                                                                        ? 'ACFJ Consortium'
                                                                        : feat.swxc ===
                                                                          'NOAA'
                                                                        ? 'NOAA Space Weather Prediction Service'
                                                                        : feat.swxc ===
                                                                          'PECASUS'
                                                                        ? 'PECASUS Consortium'
                                                                        : feat.swxc}
                                                                </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                    <Text
                                                        marginTop="10px"
                                                        paddingLeft="20px"
                                                        marginBottom="10px"
                                                        whiteSpace="pre-line"
                                                    >
                                                        {feat.text}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                            <Divider
                                                variant="primary"
                                                width="100%"
                                            />
                                        </Flex>
                                    </>
                                ))}

                            {status !== 'loading' &&
                                status !== 'error' &&
                                !showNetworkErrors &&
                                data &&
                                data.length === 0 && (
                                    <Flex width="100%">
                                        <Text
                                            width="100%"
                                            marginTop="30px"
                                            marginBottom="30px"
                                            whiteSpace="pre-line"
                                            color="light.100"
                                            fontSize="0.9rem"
                                            textAlign="center"
                                        >
                                            No space weather information is
                                            available at this time
                                        </Text>
                                    </Flex>
                                )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
