import {
    extendTheme,
    withDefaultColorScheme,
    withDefaultVariant,
} from '@chakra-ui/react'

import {
    modalStyles as Modal,
    drawerStyles as Drawer,
    headingStyles as Heading,
    badgeStyles as Badge,
    dividerStyles as Divider,
    tableStyles as Table,
    buttonStyles as Button,
    checkboxStyles as Checkbox,
    sliderStyles as Slider,
    inputStyles as Input,
} from './components/componentStyles'

const inputSelectStyles = {
    variants: {
        filled: {
            field: {
                _focus: {
                    borderColor: 'brand.200',
                },
            },
        },
    },
    sizes: {
        md: {
            field: {
                borderRadius: 'none',
            },
        },
    },
}

const theme = extendTheme(
    {
        initialColorMode: 'light',
        cssVarPrefix: 'preflight',

        // THEME COLORS
        colors: {
            brand: {
                50: '#95D600', // Aeropath Green
                100: '#706F6B', // Aeropath Grey
                200: '#00D599', // PreFlight Text (Teal)
                300: '#FFFFFF', // PreFlight Text Dark Mode
                400: '#a2e032',
                500: '#8ac919',
                strong: '#0F1419', // Dark Gray
                free: '#DCE6F1', // FREE Plan
                plus: '#007DC8', // PLUS Plan
                pro: '#8D38E3', // PRO Plan
                premium: '#00BCF8', // Premium Blue
                freeHover: '#D0DEEE', // FREE Plan Hover
                plusHover: '#016BAA', // PLUS Plan Hover
                proHover: '#782BC6', // PRO Plan Hover
                premiumHover: '#00B0E8', // Premium Blue
            },
            light: {
                10: '#FFFFFF', // Main Nav Background (Default)
                20: '#F7F8F9', // Main Nav Background (selected/open)
                25: '#F5F6F8', // Main Nav Background (selected/open)
                30: '#F5F8FB', // Nav Folder Items Background (open)
                35: '#F8FAFD', // Nav Folder Items Background (open)
                40: '#FBFDFF', // Nav Folder Items Background (hover)
                45: '#F6F8FA', // Nav Folder Items Background (hover)
                50: 'rgba(255, 255, 255, 0.7)', // Nav Subfolder Background
                51: 'rgba(255, 255, 255, 0.92)', // Nav Subfolder Background (FIREFOX ONLY)
                52: 'rgba(156, 207, 223, 0.09)', // Nav Subfolder Icon Ring
                53: 'rgba(156, 207, 223, 0.15)', // Nav Subfolder Icon Ring
                54: 'rgba(255, 255, 255, 0.8)', //
                55: 'rgba(249, 251, 253, 0.8)', //
                60: 'rgba(0, 0, 0, 0.03)', // Nav Subfolder Item (selected/hover) (FIREFOX ONLY)
                61: 'rgba(0, 0, 0, 0.03)', // Nav Subfolder Item (selected/hover)
                70: '#273440', // Subfolder Background
                80: '#4C5763', // Dark Text Hover
                90: '#f0f2f6', // Aerodrome Drawer Tab BG
                100: '#2A3744', // Navigation Dark Grey Accent (Main Navigation, Main Headings etc)
                101: '#52647a', // Medium Grey Accent - Lighter Text
                102: '#a0abb9', // Light Grey Accent - Lighter Text
                103: '#909aa7', // Light Grey Accent - Lighter Text
                104: '#a0b9c5', // Light Grey Accent - Lighter Text
                105: '#91b6c9',
                200: '#00D599', // Active Green Accent (selected items)
                201: '#00C599', // Active Green Accent (selected items) Dark Hover
                202: '#66bb99', // Active Green Accent (selected items) Darker
                203: '#21FFC1', // Active Green Accent (dark backgrounds)
                204: '#00f1ab', // Active Green Accent (lighter)
                300: '#A1AFC0', // Inactive Grey Accent (Icons)
                400: '#8A95A0', // Inactive Grey Accent (Text)
                500: '', // DO NOT USE FOR NOW!
                600: 'rgba(247, 249, 251 ,1)', // Modal Menu Shading
                601: 'rgba(250, 252, 254 ,1)', // Modal Menu Shading
                602: 'rgb(243, 245, 247)',
                700: 'rgba(255, 255, 255, 1)', // Light Background Color
                800: 'rgba(255,255,255,1)', // *** TEST ***

                // Standard Text
                900: '#202A35', // Standard Dark Text Color (when used with white backgrounds)
            },

            dark: {
                10: '#2d3e4f', // Main Nav Background Color (Default)
                20: '#273746', // Main Nav Background Color (selected/open)
                30: '#22303c', // Nav Folder Items Background Color (open)
                40: '#1f2b36', // Nav Folder Items Background Color (hover)
                41: '#1d242b', // Toggle Hover
                42: '#546373', // Toggle Button
                50: 'rgba(22, 33, 44, 0.75)', // Nav Subfolder Background Color
                51: 'rgba(34, 44, 54, 0.92)', // Nav Subfolder Background Color (FIREFOX ONLY)
                52: 'rgba(34, 44, 54, 0.08)', // Nav Subfolder Ring
                53: 'rgba(34, 44, 54, 0.15)', // Nav Subfolder Ring
                60: 'rgba(22, 33, 44, 0.12)', // Nav Subfolder Item (selected/hover)
                61: 'rgba(22, 33, 44, 0.2)', // Nav Subfolder Item (selected/hover) (FIREFOX ONLY)
                70: '#4d5862', // Subfolder Background Color
                80: '#FFFFFF', // Light Text Hover
                81: 'rgba(255, 255, 255, 0.4)', // Light Text Hover
                90: '#F8F9FA', // Navigation Very Light Grey Accent (Main Navigation)
                100: '#CED4DC', // Navigation Light Grey Accent (Folder and Sub-folder Navigation)
                200: '#1deeaa', // Active Green Accent (selected items)
                201: '#17dcaa', // Active Green Accent (selected items) Dark Hover
                500: '', // DO NOT USE FOR NOW!

                // Mobile semi-transparent (testing purposes only at the moment)
                300: 'rgba(20, 23, 26, 0.4)', // Main Nav Background Color (with menu layer behind)
                301: 'rgba(20, 23, 26, 0.5)', // Main Nav Background Color (no layer menu behind, or Hover with menu layer behind)
                302: 'rgba(20, 23, 26, 0.6)', // Main Nav Background Color (Hover with no menu layer behind)
                303: 'rgba(20, 23, 26, 0.68)', // Main Nav Background Color (Hover with no menu layer behind)
                304: 'rgba(20, 23, 26, 0.76)', // Main Nav Background Color (Hover with no menu layer behind)
                305: 'rgba(16, 18, 20, 0.85)', // Main Nav Background Color (Hover with menu layer behind)
                306: 'rgba(80, 90, 100, 0.5)',
                400: 'rgba(54, 69, 83, 1)', // Main Nav Background Color (selected/open)
                600: 'rgba(46, 60, 72, 1)', // Nav Folder Items Background Color (open)
                700: 'rgba(41, 53, 64, 1)', // Nav Folder Items Background Color (hover)
                800: 'rgba(37, 48, 58, 1)', // Nav Subfolder Background Color

                // Standard Text
                900: '#FFFFFF', // Standard Light Text Color (when used with dark backgrounds only)
            },
            gradient: {
                10: '#ECF2F9', // Gradient background top (Mobile)
                100: '#DFE8F1', // Gradient background bottom (Mobile)
                20: '#F1F6FB', // Gradient background top (Desktop)
                200: '#F0F8FD', // Gradient background bottom (Desktop)
            },

            // BADGES
            ifr: {
                100: '#92278f', // Purple
            },
            vfr: {
                100: '#0087bc', // Blue
            },
            warning: {
                10: 'rgba(255, 159, 64, 0.1)', // 10% Faded Orange
                20: 'rgba(255, 159, 64, 0.2)', // 20% Faded Orange
                30: 'rgba(255, 159, 64, 0.3)', // 30% Faded Orange
                50: 'rgba(255, 159, 64, 0.5)', // 50% Faded Orange
                100: '#FF9F40', // Orange
                200: '#FF7F00', // Orange
            },

            //MISC
            webcam: {
                100: '#2D778E',
            },
        },
        fonts: {
            heading: 'Open Sans',
            body: 'Open Sans',
        },

        // OVERALL BORDER RADIUS
        radii: {
            none: '0',
            sm: '0.125rem',
            base: '0.25rem',
            md: '0.375rem',
            lg: '0.5rem',
            xl: '0.75rem',
            '2xl': '1rem',
            '3xl': '1.5rem',
            full: '9999px',
        },

        // BREAKPOINT VALUES (Responsive styling)
        // DO NOT CHANGE!!! as this will likely break responsive behaviours
        // Most components are set from mobile to change at "md" to desktop view
        breakpoints: {
            xs: '25em', // 400px (custom)
            xsm: '27em', // 432px (custom)
            sm: '30em', // 480px - DO NOT CHANGE!!
            smd: '36em', // 576px (custom)
            md: '48em', // 768px - DO NOT CHANGE!!
            ml: '54em', // 864px (custom)
            lg: '62em', // 992px - DO NOT CHANGE!!
            xl: '80em', // 1280px - DO NOT CHANGE!!
            '2xl': '96em', // 1536px - DO NOT CHANGE!!
            '3xl': '110em', // 1760px (custom)
        },

        // REMOVE FOCUS FROM ALL BUTTONS
        _focus: {
            border: 'none',
        },

        // HEADING STYLES
        styles: {
            global: {
                h1: {
                    fontWeight: '700',
                    lineHeight: '1.3',
                    fontSize: '1.2rem',
                    fontFamily: ['Poppins', 'Open Sans', 'sans-serif'],
                },
                h2: {
                    fontSize: '1.05rem',
                    fontWeight: '600',
                    lineHeight: '1.3',
                    fontFamily: ['Poppins', 'Open Sans', 'sans-serif'],
                    letterSpacing: '1%',
                },
                h3: {
                    fontSize: '0.95rem',
                    fontWeight: '500',
                    lineHeight: '1.3',
                    fontFamily: ['Poppins', 'Open Sans', 'sans-serif'],
                    letterSpacing: '1%',
                },
                normal: {
                    fontSize: '0.9rem',
                    fontWeight: '400',
                    lineHeight: '1.3',
                    fontFamily: ['Open Sans', 'sans-serif'],
                },
            },
        },

        //COMPONENTS (Imported from ComponentStyles file)
        components: {
            Table,
            Button,
            Modal,
            Drawer,
            Heading,
            Badge,
            Divider,
            // Switch,
            Checkbox,
            Slider,
            Input,
            Select: { ...inputSelectStyles },
        },
    },
    withDefaultColorScheme({
        colorScheme: 'brand',
        components: ['Checkbox', 'Modal'],
    }),
    withDefaultVariant({
        variant: 'filled',
        components: ['Input', 'Select'],
    })
)

export default theme
