import React, { useContext, useEffect } from 'react'
import { Source, Layer } from 'react-mapbox-gl'
import { useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import { useColorModeValue } from '@chakra-ui/react'
import {
    layerSelectionState,
    selectedWxTileTimesState,
} from '../../../globalState'

export default function SatelliteLayer() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const times = useRecoilValue(selectedWxTileTimesState)

    const map = useContext(MapContext)

    useEffect(() => {
        map.moveLayer('mapboxSatellite', 'country-label')
        if (layerSelection.met.satelliteCloudVisible) {
            map.moveLayer('mapboxSatellite', `satelliteCloudVisible${times[0]}`)
            map.moveLayer('waterMask', `satelliteCloudVisible${times[0]}`)
        } else if (layerSelection.met.satelliteCloudTops) {
            map.moveLayer('mapboxSatellite', `satelliteCloudTops${times[0]}`)
            map.moveLayer('waterMask', `satelliteCloudTops${times[0]}`)
        } else if (layerSelection.met.satelliteCloudInfrared) {
            map.moveLayer(
                'mapboxSatellite',
                `satelliteCloudInfrared${times[0]}`
            )
            map.moveLayer('waterMask', `satelliteCloudInfrared${times[0]}`)
        } else if (layerSelection.met.satelliteCloudInfraredEnhanced) {
            map.moveLayer(
                'mapboxSatellite',
                `satelliteCloudInfraredEnhanced${times[0]}`
            )
            map.moveLayer(
                'waterMask',
                `satelliteCloudInfraredEnhanced${times[0]}`
            )
        }
    }, [layerSelection, map, times])

    const fillColor = useColorModeValue('#ADD4EB', '#264860')
    const fillOpacity = useColorModeValue(1, 0.7)
    const lineColor = useColorModeValue('#7cb3d3', '#1f3f56')
    const lineOpacity = useColorModeValue(0.7, 0.5)
    const rasterOpacity = useColorModeValue(0.75, 0.7)

    //create conditional function that checks what layer is visible and moves the layer behind the first tile in the group
    if (layerSelection.constant.satellite) {
        return (
            <>
                <Source
                    id="mapboxsatellite"
                    tileJsonSource={{
                        type: 'raster',
                        url: 'mapbox://mapbox.satellite',
                        tileSize: 256,
                    }}
                />
                <Layer
                    id="mapboxsatellite"
                    type="raster"
                    sourceId="mapboxsatellite"
                    before="tunnel-street-minor-low"
                    paint={{
                        'raster-brightness-max': 0.85,
                        'raster-brightness-min': 0.33,
                        'raster-contrast': 0.5,
                        'raster-saturation': -0.33,
                        'raster-opacity': rasterOpacity,
                        // 'raster-fade-duration': 250,
                    }}
                />
                <Layer
                    id="watermask"
                    type="fill"
                    sourceId="composite"
                    sourceLayer="water"
                    before="tunnel-street-minor-case"
                    // paint={{
                    //     //  'fill-color': '#B4CEDB', // Default value
                    //     'fill-color': '#BAD2DE', // changed from {default value}
                    //     // 'fill-opacity': 0.9,
                    // }}
                    paint={{
                        //  'fill-color': '#B4CEDB', // Default value
                        'fill-color': fillColor,
                        'fill-opacity': fillOpacity,
                    }}
                />
                <Layer
                    id="watermaskoutline"
                    type="line"
                    sourceId="composite"
                    sourceLayer="water"
                    before="tunnel-street-minor-case"
                    // paint={{
                    //     'line-color': '#4b96c8', // "#068cdd",
                    //     'line-blur': 0.5,
                    // }}
                    paint={{
                        'line-color': lineColor, // "#068cdd",
                        'line-opacity': lineOpacity,
                        'line-blur': 0.5,
                    }}
                />
            </>
        )
    }
    return null
}
