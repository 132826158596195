import { omit } from 'lodash'

import { config } from '../config'

export default function constructMenu(tier) {
    const { menu, layers } = config
    const menuStructure = {}
    Object.keys(menu).forEach((col) => {
        menuStructure[col] = menu[col].map((i) => {
            if (i.items) {
                return {
                    ...i,
                    items: i.items.map((x) => {
                        const {
                            id,
                            label,
                            collection,
                            type,
                            wip,
                            unavailable,
                        } = layers[x]
                        const collectionInfo = omit(
                            collection.find((i) => i.id === col),
                            ['id', 'displayOnDefault']
                        )
                        return {
                            id,
                            label,
                            ...collectionInfo,
                            type,
                            wip,
                            canUse: collectionInfo.tier <= tier,
                            unavailable,
                        }
                    }),
                }
            } else {
                const { id, label, collection, type, wip, unavailable } =
                    layers[i.id]
                const collectionInfo = omit(
                    collection.find((i) => i.id === col),
                    ['id', 'displayOnDefault']
                )
                return {
                    id,
                    label,
                    ...collectionInfo,
                    type,
                    wip,
                    canUse: collectionInfo.tier <= tier,
                    unavailable,
                }
            }
        })
    })
    return { menuStructure }
}
