import { useEffect, useContext } from 'react'
import { MapContext } from 'react-mapbox-gl'

export default function LinzTopoLayer() {
    const mapInstance = useContext(MapContext)

    useEffect(() => {
        mapInstance.addSource('linz-topo-250k', {
            type: 'raster',
            tiles: [
                'https://tiles-a.data-cdn.linz.govt.nz/services;key=d81fdcbab6aa4c6c9c251cfd68d5fae4/tiles/v4/layer=50798/EPSG:3857/{z}/{x}/{y}.png',
            ],
            tileSize: 256,
            attribution:
                '<a href=“http://data.linz.govt.nz”>Topo Map Sourced from LINZ. CC BY 4.0</a>',
        })

        mapInstance.addSource('linz-topo-50k', {
            type: 'raster',
            tiles: [
                'https://tiles-a.data-cdn.linz.govt.nz/services;key=d81fdcbab6aa4c6c9c251cfd68d5fae4/tiles/v4/layer=50767/EPSG:3857/{z}/{x}/{y}.png',
            ],
            tileSize: 256,
            attribution:
                '<a href=“http://data.linz.govt.nz”>Topo Map Sourced from LINZ. CC BY 4.0</a>',
        })

        mapInstance.addLayer(
            {
                id: 'linz-topo-250k',
                type: 'raster',
                source: 'linz-topo-250k',
                maxZoom: 11.5,
                paint: {
                    'raster-saturation': -0.66,
                    'raster-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.8,
                        11.5,
                        0,
                    ],
                },
            },
            'admin-0-boundary-disputed'
        )

        mapInstance.addLayer(
            {
                id: 'linz-topo-50k',
                type: 'raster',
                source: 'linz-topo-50k',

                minZoom: 10,
                paint: {
                    'raster-saturation': -0.66,
                    'raster-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0,
                        12,
                        0.8,
                    ],
                },
            },
            'linz-topo-250k'
        )

        return () => {
            mapInstance.removeLayer('linz-topo-250k')
            mapInstance.removeLayer('linz-topo-50k')
            mapInstance.removeSource('linz-topo-250k')
            mapInstance.removeSource('linz-topo-50k')
        }
    }, [mapInstance])

    return null
}
