import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AltitudeRangeWhiteIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <g filter="url(#filter0_d_3368_33264)">
                <path
                    d="M4 2.9C4 2.40294 4.44772 2 5 2H18C18.5523 2 19 2.40294 19 2.9C19 3.39706 18.5523 3.8 18 3.8H5C4.44772 3.8 4 3.39706 4 2.9Z"
                    fill="currentColor"
                />
                <path
                    d="M4 19.1C4 18.6029 4.44772 18.2 5 18.2H18C18.5523 18.2 19 18.6029 19 19.1C19 19.5971 18.5523 20 18 20H5C4.44772 20 4 19.5971 4 19.1Z"
                    fill="currentColor"
                />
                <path
                    d="M14.5802 7.51243H12.4998V14.4874H14.5802C15.0315 14.4874 15.2518 14.9831 14.925 15.2632L11.8447 17.9042C11.6516 18.0698 11.348 18.0698 11.1549 17.9042L8.07459 15.2632C7.74781 14.9831 7.96815 14.4874 8.41947 14.4874H10.4998V7.51243H8.41947C7.96815 7.51243 7.74781 7.01678 8.07459 6.73661L11.1549 4.09561C11.348 3.93004 11.6516 3.93004 11.8447 4.09561L14.925 6.73661C15.2518 7.01678 15.0315 7.51243 14.5802 7.51243Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3368_33264"
                    x="3.5"
                    y="1.5"
                    width="19"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="1.5" dy="1.5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3368_33264"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3368_33264"
                        result="shape"
                    />
                </filter>
            </defs>
        </Icon>
    )
}
