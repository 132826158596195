import React, { useContext, useEffect, useState, useRef } from 'react'
import { MapContext } from 'react-mapbox-gl'
import * as turf from '@turf/turf'
import { isMobileOnly, isDesktop } from 'react-device-detect'
import {
    Box,
    Flex,
    Text,
    Button,
    LightMode,
    Stack,
    Spinner,
} from '@chakra-ui/react'

export default function SetMapFavourite(props) {
    const {
        selectDefaultMapLocation,
        setSelectDefaultMapLocation,
        setProfileData,
        updatingSettings,
        isBriefingOnly,
    } = props
    const mapInstance = useContext(MapContext)
    const [mapCoords, setMapCoords] = useState(null)
    const [mapZoom, setMapZoom] = useState(null)
    const [mapZoomMobile, setMapZoomMobile] = useState(null)
    const isMobile = isMobileOnly && !isDesktop
    const startSettingsTimer = useRef(null)

    // GET VIEWPORT COORDINATES
    useEffect(() => {
        if (!isBriefingOnly) {
            const handleMove = (e) => {
                // set zoom levels
                // accounts for differences between desktop and mobile screens
                setMapZoom(
                    isMobile ? e.target.getZoom() + 0.8 : e.target.getZoom()
                )
                setMapZoomMobile(
                    isMobile ? e.target.getZoom() : e.target.getZoom() - 0.8
                )

                // set coordinates
                const getMapViewBounds = () => {
                    const bounds = e.target.getBounds()
                    const mapSW = bounds._sw
                    const mapNE = bounds._ne
                    const mapNW = bounds.getNorthWest()
                    const mapSE = bounds.getSouthEast()
                    const mapNWend = bounds.getNorthWest()
                    return [
                        [mapNW.lng, mapNW.lat],
                        [mapNE.lng, mapNE.lat],
                        [mapSE.lng, mapSE.lat],
                        [mapSW.lng, mapSW.lat],
                        [mapNWend.lng, mapNWend.lat],
                    ]
                }
                var viewportDimensions = getMapViewBounds(mapInstance)
                const viewport = turf.polygon([viewportDimensions])
                var center = turf.center(viewport)
                const mapCoordinates =
                    center && center.geometry && center.geometry.coordinates

                // Round the array values to two decimal places
                const setX = Number(mapCoordinates[0].toFixed(3))
                const setY = Number(mapCoordinates[1].toFixed(3))
                const newMapCenter = [setX, setY]
                setMapCoords(newMapCenter)
            }

            mapInstance.on('move', handleMove)
            return () => mapInstance.off('move', handleMove)
        }
    }, [
        isBriefingOnly,
        mapInstance,
        isMobile,
        setMapZoom,
        setMapZoomMobile,
        setMapCoords,
    ])

    // useEffect(() => {
    //     // console.log('user: ', user)
    //     console.log('mapCoords', mapCoords)
    //     console.log('mapZoom', mapZoom)
    // }, [mapCoords])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        !isBriefingOnly && (
            <LightMode>
                <Box
                    opacity={!selectDefaultMapLocation ? '0' : '1'}
                    visibility={
                        !selectDefaultMapLocation ? 'hidden' : 'visible'
                    }
                    transition="all ease 400ms"
                    bg="rgba(255,255,255,0.95)"
                    position="absolute"
                    w={{ base: '246px', md: '246px' }}
                    top={{ base: 'auto', md: 'auto' }}
                    bottom={{ base: '40px', md: '60px' }}
                    right={{ base: '15px', md: '70px' }}
                    borderRadius="20px"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    zIndex="1400"
                    boxShadow="0px 10px 20px -5px rgba(0,0,0,0.3)"
                >
                    <Box
                        width="100%"
                        background="rgba(255,255,255,1)"
                        borderBottom="1px solid #eaebec"
                        boxShadow="0px 3px 15px -5px rgba(0,0,0,0.2)"
                        clipPath="inset(0px 0px -50px 0px)"
                        px={{ base: '10px', md: '15px' }}
                        pt={{ base: '7px', md: '10px' }}
                        pb={{ base: '6px', md: '10px' }}
                        borderTopRadius="20px"
                    >
                        <Text
                            textAlign="center"
                            color={'light.100'}
                            fontSize={{
                                base: '0.8rem',
                                md: '0.9rem',
                            }}
                            fontWeight={500}
                            marginTop="2px"
                        >
                            SET MAP LOCATION
                        </Text>
                    </Box>
                    <Flex
                        flexDirection="column"
                        justifyContent="space-between"
                        fontSize="0.75rem"
                    >
                        <Flex
                            flexDirection="column"
                            justifyContent="space-between"
                            px="20px"
                            pt="15px"
                            pb="12px"
                            gap="5px"
                        >
                            <Text
                                textAlign="center"
                                color={'gray.500'}
                                fontSize={{
                                    base: '0.7rem',
                                    md: '0.7rem',
                                }}
                                fontWeight="300"
                                lineHeight="1.3"
                                // pb="5px"
                            >
                                Drag and zoom the map to your desired location
                                and then set.
                            </Text>
                            <Text
                                textAlign="center"
                                color={'gray.500'}
                                fontSize={{
                                    base: '0.7rem',
                                    md: '0.7rem',
                                }}
                                fontWeight="300"
                                lineHeight="1.3"
                                pb="5px"
                            >
                                Upon opening PreFlight, the map will
                                automatically zoom to this location.
                            </Text>
                            <Flex
                                color="light.100"
                                justifyContent="center"
                                alignItems="flex-start"
                                pl="15px"
                            >
                                <Text
                                    whiteSpace="pre"
                                    minWidth="90px"
                                    color="gray.500"
                                    fontWeight="500"
                                >
                                    Map Center:
                                </Text>
                                <Flex
                                    width="100%"
                                    color="light.100"
                                    flexDirection="column"
                                >
                                    <Flex
                                        width="100%"
                                        color="light.100"
                                        gap="7px"
                                        height="18px"
                                    >
                                        <Text minWidth="35px" color="gray.400">
                                            Lat:
                                        </Text>
                                        <Text minWidth="55px">
                                            {mapCoords ? mapCoords[1] : ''}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        color="light.100"
                                        gap="7px"
                                        height="18px"
                                    >
                                        <Text minWidth="35px" color="gray.400">
                                            Long:
                                        </Text>
                                        <Text minWidth="55px">
                                            {mapCoords ? mapCoords[0] : ''}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                color="light.100"
                                justifyContent="center"
                                alignItems="center"
                                pl="15px"
                            >
                                <Text
                                    whiteSpace="pre"
                                    // width="100%"
                                    minWidth="90px"
                                    color="gray.500"
                                    fontWeight="500"
                                >
                                    Zoom Level:
                                </Text>
                                <Flex width="100%" color="light.100" gap="7px">
                                    <Text>
                                        {mapZoom
                                            ? Math.round(mapZoom * 10) / 10
                                            : ''}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex width="100%" padding="0px" flexDirection="column">
                            <Box
                                width="100%"
                                padding={{ base: '10px', md: '15px' }}
                                borderTop="1px solid #eaebec"
                            >
                                <Stack
                                    direction="row"
                                    spacing={{ base: '2', md: '4' }}
                                    align="center"
                                >
                                    <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        variant="outline"
                                        color="light.100"
                                        bg="#ffffff"
                                        size="sm"
                                        borderRadius="10px"
                                        width="100%"
                                        onClick={() => {
                                            setMapCoords(null)
                                            setMapZoom(null)
                                            setMapZoomMobile(null)
                                            setSelectDefaultMapLocation(false)
                                        }}
                                        {...noFocus}
                                        _hover={{
                                            background: 'light.20',
                                        }}
                                        _active={{
                                            background: 'light.20',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        color="white"
                                        isDisabled={!mapCoords && !mapZoom}
                                        width="100%"
                                        borderRadius="10px"
                                        background="light.200"
                                        _hover={{
                                            background: 'light.201',
                                        }}
                                        _active={{
                                            background: 'light.201',
                                        }}
                                        size="sm"
                                        onClick={() => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                profileSettings: {
                                                    ...prevState.profileSettings,
                                                    mapcenter: mapCoords,
                                                    mapzoom: mapZoom,
                                                    mapzoom_mobile:
                                                        mapZoomMobile,
                                                },
                                            }))
                                            startSettingsTimer.current =
                                                setTimeout(() => {
                                                    setSelectDefaultMapLocation(
                                                        false
                                                    )
                                                }, 1000)
                                        }}
                                        {...noFocus}
                                    >
                                        {!updatingSettings ? (
                                            'Set'
                                        ) : (
                                            <Spinner
                                                color="white"
                                                boxSize="20px"
                                            />
                                        )}
                                    </Button>
                                </Stack>
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </LightMode>
        )
    )
}
