import React, { useState, useEffect, useCallback } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import {
    Flex,
    Button,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Text,
    Box,
    Stack,
    LightMode,
    VStack,
    useToast,
} from '@chakra-ui/react'
import {
    briefingAreaSourceState,
    briefingOptionsState,
    layerSelectionState,
    layerSelectionHandler,
    briefingDrawActionState,
    briefingCustomAreaState,
    briefingCustomAreaPointCountState,
    isBriefingOnlyState,
} from '../../../globalState'
import { BsDot } from 'react-icons/bs'

export default function BriefingCustom() {
    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )
    const briefingCustomArea = useRecoilValue(briefingCustomAreaState)
    const briefingCustomAreaPointCount = useRecoilValue(
        briefingCustomAreaPointCountState
    )
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const setBriefingDrawAction = useSetRecoilState(briefingDrawActionState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const plotAreaShow = briefingAreaSource === 'customRoute'
    const [buffer, setBuffer] = useState(5)

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const toast = useToast()

    useEffect(() => {
        if (briefingCustomAreaPointCount === 30) {
            toast({
                position: 'top',
                duration: 6000,
                render: () => (
                    <Box
                        cursor="default"
                        pointerEvents="none"
                        color="white"
                        mt="7px"
                        px={5}
                        py={3}
                        bg="#FFAB2E"
                        borderRadius="10px"
                    >
                        Your area is getting complex! Please complete your
                        geometry
                    </Box>
                ),
            })
        } else if (briefingCustomAreaPointCount === 40) {
            toast({
                position: 'top',
                duration: 6000,
                render: () => (
                    <Box
                        cursor="default"
                        pointerEvents="none"
                        color="white"
                        mt="7px"
                        px={5}
                        py={3}
                        bg="red.500"
                        borderRadius="10px"
                    >
                        You have too many points! Please complete your geometry
                    </Box>
                ),
            })
        }
    }, [toast, briefingCustomAreaPointCount])

    useEffect(() => {
        if (briefingCustomArea === null) {
            setBriefingDrawAction('draw')
        }
    }, [briefingCustomArea, setBriefingDrawAction])

    const handleCancel = useCallback(() => {
        setBriefingAreaSource('briefingarea')
        if (layerSelection.brief.briefingareas) {
            setLayerSelection({
                mode: 'brief',
                layer: 'briefingareas',
            })
        }
        setBriefingOptions(true)
    }, [
        layerSelection.brief.briefingareas,
        setBriefingAreaSource,
        setBriefingOptions,
        setLayerSelection,
    ])

    useEffect(() => {
        if (isBriefingOnly) {
            handleCancel()
        }
    }, [isBriefingOnly, handleCancel])

    // when user hits 'Esc' key or
    // when this component unmounts - send cancel instruction to drawTools
    useEffect(() => {
        const handleEsc = (e) => {
            e = e || window.event
            if (e.keyCode === 27) {
                // 'Esc key'
                handleCancel()
            }
        }

        window.addEventListener('keydown', handleEsc)

        return () => {
            setBriefingDrawAction('cancel')
            window.removeEventListener('keydown', handleEsc)
        }
    }, [handleCancel, setBriefingDrawAction])

    const sliderStep = 2.5
    const sliderStepCount = 9
    const sliderValues = Array(sliderStepCount)
        .fill()
        .map((element, index) => index * sliderStep)
    const marks = sliderValues.filter((e, i) => i % 2 === 0)

    return (
        <>
            <LightMode>
                <Box
                    bg="rgba(255,255,255,0.9)"
                    position="absolute"
                    w={{ base: '200px', md: '250px' }}
                    minHeight={{ base: '160px', md: '185px' }}
                    top={{ base: 'auto', md: 'auto' }}
                    bottom={{ base: '40px', md: '60px' }}
                    right={{ base: '15px', md: '65px' }}
                    borderRadius="20px"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    zIndex="1"
                    boxShadow="0px 10px 20px -5px rgba(0,0,0,0.15)"
                >
                    <Box
                        width="100%"
                        background="rgba(255,255,255,0.5)"
                        borderBottom="1px solid #eaebec"
                        px={{ base: '10px', md: '15px' }}
                        pt={{ base: '7px', md: '10px' }}
                        pb={{ base: '6px', md: '10px' }}
                        borderTopRadius="20px"
                    >
                        <Text
                            textAlign="center"
                            color={'light.102'}
                            fontSize={{
                                base: '0.7rem',
                                md: '0.7rem',
                            }}
                            fontWeight="300"
                            lineHeight="1.3"
                            marginTop="2px"
                            marginBottom="3px"
                        >
                            PreFlight Information Briefing
                        </Text>
                        <Text
                            textAlign="center"
                            color={'light.100'}
                            fontSize={{
                                base: '0.8rem',
                                md: '0.9rem',
                            }}
                            fontWeight={500}
                        >
                            PLOT CUSTOM AREA
                        </Text>
                    </Box>
                    <Flex flexDirection="column" justifyContent="space-between">
                        {/* 
                        DISABLED FOR NOW - BUT IN THE FUTURE WE COULD ENABLE THE ROUTE MODE
                        <Box
                            width="100%"
                            padding={{ base: '10px', md: '15px' }}
                            paddingBottom="0px !important"
                        >
                            <Flex width="100%" alignContent="center">
                                <Button
                                    bg="gray.100"
                                    color="#a8b2c0"
                                    variant="outline"
                                    fontSize={{
                                        base: '0.65rem',
                                        md: '11px',
                                    }}
                                    fontWeight="500"
                                    height={{
                                        base: '32px',
                                        md: '30px',
                                    }}
                                    width="100%"
                                    style={{
                                        minWidth: '96px',
                                    }}
                                    justifyContent="initial"
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    borderRadius="20px"
                                    borderColor="#E9EAEB"
                                    {...noFocus}
                                    onClick={() =>
                                        setBriefingAreaSource((prevState) =>
                                            prevState === 'customRoute'
                                                ? 'customArea'
                                                : 'customRoute'
                                        )
                                    }
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Box
                                        mt="0px"
                                        ml="-1px"
                                        px="10px"
                                        height={{
                                            base: '28px',
                                            md: '26px',
                                        }}
                                        width={{ base: '102px', md: '110px' }}
                                        borderRadius="20px"
                                        bg="#ffffff"
                                        border="1px solid"
                                        style={{
                                            borderColor: '#E9EAEB',
                                            transform:
                                                briefingAreaSource ===
                                                'customArea'
                                                    ? 'translateX(0%)'
                                                    : 'translateX(100%)',
                                            transition: 'transform 400ms',
                                        }}
                                    ></Box>
                                    <Flex
                                        justifyContent="space-evenly"
                                        marginLeft={{
                                            base: '-88px',
                                            md: '-108px',
                                        }}
                                        width={{
                                            base: '212px',
                                            md: '220px',
                                        }}
                                    >
                                        <Text
                                            zIndex="1"
                                            paddingLeft="0px"
                                            lineHeight="1.3"
                                            color={
                                                briefingAreaSource ===
                                                'customArea'
                                                    ? 'light.200'
                                                    : 'light.100'
                                            }
                                            _selected={{
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                            _active={{
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                            transition="all ease 200ms"
                                        >
                                            Area
                                        </Text>
                                        <Text
                                            zIndex="1"
                                            paddingLeft={{
                                                base: '20px',
                                                md: '30px',
                                            }}
                                            lineHeight="1.3"
                                            color={
                                                briefingAreaSource ===
                                                'customRoute'
                                                    ? 'light.200'
                                                    : 'light.100'
                                            }
                                            transition="all ease 200ms"
                                            _selected={{
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                            _active={{
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                        >
                                            Route
                                        </Text>
                                    </Flex>
                                </Button>
                            </Flex>
                        </Box> */}
                        <VStack pl={5} pr={5}>
                            {briefingAreaSource === 'customArea' && (
                                <>
                                    <Text
                                        fontSize="0.75rem"
                                        textAlign="center"
                                        color="light.101"
                                        lineHeight="1.5"
                                        pt="15px"
                                    >
                                        Click / tap on the map to plot a custom
                                        area.
                                    </Text>
                                    <Text
                                        fontSize="0.75rem"
                                        textAlign="center"
                                        color="light.101"
                                        lineHeight="1.5"
                                    >
                                        Double click / tap to end.
                                    </Text>
                                </>
                            )}
                            {briefingAreaSource === 'customRoute' && (
                                <>
                                    <Text
                                        fontSize="0.75rem"
                                        textAlign="center"
                                        color="light.101"
                                        pt="15px"
                                    >
                                        Click / tap on the map to plot a custom
                                        route. Use the slider to apply a buffer
                                        around your route.
                                    </Text>
                                    <Text
                                        fontSize="0.75rem"
                                        textAlign="center"
                                        color="light.101"
                                        lineHeight="1.5"
                                    >
                                        Double click / tap to end.
                                    </Text>
                                </>
                            )}
                        </VStack>
                        <Flex
                            width={{ base: '200px', md: '250px' }}
                            direction="column"
                            px={{ base: '20px', md: '20px' }}
                            pt={{
                                base: plotAreaShow ? '5px' : '5px',
                                md: plotAreaShow ? '5px' : '15px',
                            }}
                            pb={{
                                base: plotAreaShow ? '5px' : '5px',
                                md: plotAreaShow ? '5px' : '0px',
                            }}
                            height={{
                                base: plotAreaShow ? '95px' : '0',
                                md: plotAreaShow ? '95px' : '0',
                            }}
                            opacity={plotAreaShow ? '1' : '0'}
                            overflow="hidden"
                            transition="all ease 400ms"
                        >
                            <Slider
                                name="customBriefingRouteBuffer"
                                display={{
                                    base: 'inline-block',
                                    md: 'inline-block',
                                }}
                                transition="all ease 400ms"
                                py="0px"
                                id="slider"
                                aria-label="selected-hours"
                                value={buffer}
                                step={sliderStep}
                                min={sliderValues[0]}
                                max={sliderValues[sliderValues.length - 1]}
                                colorScheme="teal"
                                // onMouseEnter={() => setShowTooltip(true)}
                                // onMouseLeave={() => setShowTooltip(false)}
                                onChange={(val) =>
                                    val >= sliderStep && setBuffer(val)
                                }
                            >
                                <Flex
                                    w="100%"
                                    position={{
                                        base: 'absolute',
                                        lg: 'absolute',
                                    }}
                                    fontFamily="'Open Sans', sans-serif"
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        justifyContent={{
                                            base: 'space-between',
                                            md: 'space-between',
                                        }}
                                        alignItems={{
                                            base: 'center',
                                            md: 'flex-start',
                                        }}
                                        bg={{
                                            base: 'transparent',
                                            lg: 'transparent',
                                        }}
                                        position={{
                                            base: 'relative',
                                            lg: 'relative',
                                        }}
                                        left="0px !important"
                                        borderRadius="10px"
                                        color={{
                                            base: 'light.100',
                                            lg: 'light.100',
                                        }}
                                        w={{
                                            base: 'calc(100% + 6px)',
                                            md: 'calc(100% + 4px)',
                                        }}
                                        ml={{
                                            base: '-2px',
                                            md: '-2px',
                                        }}
                                    >
                                        <Text
                                            fontWeight="500"
                                            fontSize={{
                                                base: '0.7rem',
                                                md: '0.7rem',
                                            }}
                                            pr="8px"
                                            pb={{ base: '0', md: '2px' }}
                                            opacity="0.7"
                                            minWidth="80px"
                                        >
                                            Route buffer:
                                        </Text>
                                        <Flex
                                            w={{ base: '', md: '100%' }}
                                            flexDirection={{
                                                base: 'row',
                                                md: 'row',
                                            }}
                                            justifyContent={{
                                                base: 'space-between',
                                                md: 'flex-end',
                                            }}
                                            alignItems="center"
                                            flexWrap="wrap"
                                        >
                                            <Text
                                                fontWeight={{
                                                    base: '400',
                                                    md: '500',
                                                }}
                                                fontSize={{
                                                    base: '0.7rem',
                                                    md: '0.7rem',
                                                }}
                                                color="light.101"
                                                textAlign="right"
                                            >
                                                {buffer} NM Wide
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <SliderTrack
                                    minHeight="6px"
                                    width="102% !important"
                                    ml="-1%"
                                >
                                    <SliderFilledTrack
                                        background="light.200"
                                        minHeight="6px"
                                    />
                                </SliderTrack>
                                <SliderThumb
                                    height="18px"
                                    width="18px"
                                    marginLeft={{ base: '0px', md: '0px' }}
                                    boxShadow="0px 2px 6px -2px rgba(0,0,0,0.6)"
                                    _focus={{
                                        boxShadow:
                                            '0px 2px 6px -2px rgba(0,0,0,0.6)',
                                    }}
                                />
                                <Flex
                                    justifyContent="space-between"
                                    mx="-7px"
                                    width={{
                                        base: '54%',
                                        md: '53%',
                                    }}
                                >
                                    {marks.map((val, i) => (
                                        <SliderMark
                                            key={`slidemark${val}-${i}`}
                                            value={val}
                                            mt={{
                                                base: '35px',
                                                md: '35px',
                                            }}
                                            position="relative !important"
                                            fontSize="0.6rem"
                                            fontWeight="600"
                                            color="#C7CFD9"
                                            justifyContent="center"
                                            alignItems="center"
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <BsDot
                                                opacity="0.5"
                                                fontSize="0.9rem"
                                            />
                                            <Text mt="-2px" fontWeight="500">
                                                {val}
                                            </Text>
                                        </SliderMark>
                                    ))}
                                </Flex>
                            </Slider>
                        </Flex>

                        <Flex
                            width="100%"
                            padding="0px"
                            minHeight={{ base: '55px', md: '65px' }}
                            flexDirection="column"
                        >
                            <Box
                                width="100%"
                                padding={{ base: '10px', md: '15px' }}
                                borderTop="1px solid #eaebec"
                            >
                                <Stack
                                    direction="row"
                                    spacing={{ base: '2', md: '4' }}
                                    align="center"
                                >
                                    <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        variant="outline"
                                        color="light.100"
                                        bg="#ffffff"
                                        size="sm"
                                        borderRadius="10px"
                                        width="100%"
                                        onClick={handleCancel}
                                        {...noFocus}
                                        _hover={{
                                            background: 'light.20',
                                        }}
                                        _active={{
                                            background: 'light.20',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {/* <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        color="white"
                                        width="100%"
                                        borderRadius="10px"
                                        background="light.200"
                                        _hover={{
                                            background: 'light.201',
                                        }}
                                        _active={{
                                            background: 'light.201',
                                        }}
                                        size="sm"
                                        onClick={() =>
                                            setBriefingStandard(true)
                                        }
                                        {...noFocus}
                                    >
                                        OK
                                    </Button> */}
                                </Stack>
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </LightMode>
        </>
    )
}
