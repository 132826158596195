import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    useRef,
} from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useUserMetaData, useAdminRole } from '../../../../auth/hooks'
import {
    Flex,
    Drawer,
    Center,
    Badge,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    Box,
    useDisclosure,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Spinner,
    useBreakpointValue,
    LightMode,
    IconButton,
    Icon,
    Button,
} from '@chakra-ui/react'
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa'
import {
    isFirefox,
    isMobileOnly,
    isIOS,
    isMobileSafari,
} from 'react-device-detect'
import { useQuery } from 'react-query'
import PremiumBadge from '../../../controls/PremiumBadge'
import { RiStarLine } from 'react-icons/ri'
import { ReactComponent as ExpiredIcon } from '../../../../icons/assets/expired.svg'
import { ErrorBoundary } from 'react-error-boundary'
import { useSwipeable } from 'react-swipeable'
import { parseISO, addMinutes, formatISO, lightFormat } from 'date-fns'
import { formatTimeAndTimezone } from '../../../../util/dateFormatter'
import { wxTranslate } from '../../../../util/metDecode'
import wxDefs from '../../../../definitions/wx.json'
import {
    getMetarOutOfDate,
    getTafOutOfDate,
} from '../../../../util/metDataOutOfDate'
import {
    clickedFeaturesState,
    timeZoneState,
    simulateFreeUserState,
    setModalVisibilityState,
    settingsMenuOpenState,
    showErrorTogglesState,
    mapZoomLevelState,
    zoomToActiveState,
    fullscreenModalVisibleState,
    globalTimeState,
    decodedTextDefaultState,
    userProfileState,
    mfaVerifiedState,
} from '../../../../globalState'
import InfoContent from './Info'
import ChartsDropdown from './Charts'
import NotamContent from './NOTAM'
import TafContent from './TAF'
import MetarContent from './METAR'
import AtisContent from './ATIS'
import WebcamsContent from './webcams'
import FullWidthError from '../FullWidthError'
import ErrorToggle from '../ErrorToggle'

import {
    cloudCoverBgImage,
    cloudCoverDetail,
    cloudCoverTextHighlight,
} from './backgrounds/cloudCoverBg'

import Animations from './components/Animations'
import { cloudCoverBgGradient } from './backgrounds/cloudCoverBg'
import { ReactComponent as AerodromeIcon } from '../../../../icons/assets/aerodrome.svg'
import { ReactComponent as SunriseIcon } from '../../../../icons/assets/sunrise.svg'
import { ReactComponent as SunsetIcon } from '../../../../icons/assets/sunset.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-outline.svg'
import { ReactComponent as NotamIcon } from '../../../../icons/assets/notam-outline.svg'
import { ReactComponent as TafIcon } from '../../../../icons/assets/taf.svg'
import { ReactComponent as MetarIcon } from '../../../../icons/assets/metar.svg'
import { ReactComponent as AtisIcon } from '../../../../icons/assets/atis.svg'
import { ReactComponent as WebcamIcon } from '../../../../icons/assets/camera.svg'
import { ReactComponent as ChartsIcon } from '../../../../icons/assets/aip.svg'

export default function AerodromeModal() {
    const { getAccessTokenSilently } = useAuth0()
    const isAdmin = useAdminRole()
    const timeZone = useRecoilValue(timeZoneState)
    const currentTime = useRecoilValue(globalTimeState)
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [showErrors, setShowErrors] = useState(false)
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [zoomToActive, setZoomToActive] = useRecoilState(zoomToActiveState)
    const userTier = useTier()
    const feat = clickedFeatures[0]
    const handleFullscreenModal = useRecoilValue(fullscreenModalVisibleState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const profileData = useRecoilValue(userProfileState)
    const userMetaData = useUserMetaData()
    const [aerodromeBackgroundsDisabled, setAerodromeBackgroundsDisabled] =
        useState(false)
    const [infotabGraphicsDisabled, setInfotabGraphicsDisabled] =
        useState(false)
    const [fullscreen, setFullscreen] = useState(false)

    const setDecodeDefault = useSetRecoilState(decodedTextDefaultState)
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)

    const mapInstance = useContext(MapContext)
    const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelState)

    const convertType = (type) => {
        switch (type) {
            case 'AD':
                return 'AERODROME'
            case 'HP':
                return 'HELIPORT'
            case 'WD':
                return 'WATERDROME'
            default:
                return 'UNKNOWN'
        }
    }

    const fetchAerodromeData = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/aerodromesv2/${feat.properties.designator}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, refetch, status, remove } = useQuery(
        'aerodromeData',
        fetchAerodromeData,
        {
            refetchInterval: 60000, // refetch data every minute
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    // Fetch Aerodrome Data on load
    useEffect(() => {
        remove()
        refetch()
        setTabIndex(0)
    }, [feat.properties.designator, remove, refetch])

    function onCloseHandler() {
        onClose()
        setClickedFeatures([])
        setZoomLevel(null)
        setMinimiseOnZoom(false)
        setFullscreen(false)
    }

    const designator = data && data?.designator

    const latestMetar =
        data &&
        data.metar &&
        data.metar.length > 0 &&
        data.metar[0].data &&
        !data.metar[0].data.nonereceived
            ? data.metar[0].data
            : null

    const metarActive = data && latestMetar !== null

    const currentMetar =
        data && data.metar && data.metar.length > 0 && data.metar[0]

    const metarIssuedTime =
        currentMetar && currentMetar.issuetime
            ? parseISO(currentMetar.issuetime)
            : null

    const tafIssuedTime =
        data && data.taf && data.taf.validfrom
            ? parseISO(data.taf.validfrom)
            : null

    const metarOutOfDateIssueTime = getMetarOutOfDate(
        metarIssuedTime,
        designator,
        currentTime,
        showErrors
    )

    const tafOutOfDateIssueTime = getTafOutOfDate(
        tafIssuedTime,
        designator,
        currentTime,
        showErrors
    )

    const fog =
        metarActive &&
        latestMetar?.observation &&
        latestMetar.observation.length > 0 &&
        latestMetar.observation[0]?.presentWeather &&
        latestMetar.observation[0].presentWeather
            .map((wx) => wxTranslate(wx))
            .some((condition) =>
                [
                    'Fog',
                    'Shallow fog',
                    'Fog in the vicinity',
                    'Freezing fog',
                    'Partial fog (covering part of the aerodrome)',
                    'Patches of fog',
                    'Mist',
                ].includes(condition)
            )

    const clearsky =
        (metarActive &&
            latestMetar.observation &&
            latestMetar.observation.length > 0 &&
            latestMetar.observation[0].cloud &&
            latestMetar.observation[0].cloud.noCloudDetected &&
            'NCD') ||
        (metarActive &&
            latestMetar.observation &&
            latestMetar.observation.length > 0 &&
            latestMetar.observation[0].cloud === 'NCD') ||
        (metarActive &&
            latestMetar.observation &&
            latestMetar.observation.length > 0 &&
            latestMetar.observation[0].verticalVisibility &&
            latestMetar.observation[0].verticalVisibility.skyClear)

    const metarCloud =
        clearsky ||
        (latestMetar && // Fog
            latestMetar.observation &&
            latestMetar.observation.length > 0 &&
            !latestMetar.observation[0].cloud && // only when no cloud in data
            fog &&
            'Fog') ||
        (['Cumulonimbus', 'Towering Cumulus'].includes(
            metarActive && // CB or TCB
                latestMetar.observation &&
                latestMetar.observation.length > 0 &&
                latestMetar.observation[0].cloud &&
                latestMetar.observation[0].cloud.layer &&
                latestMetar.observation[0].cloud.layer[
                    latestMetar.observation[0].cloud.layer.length - 1
                ].type &&
                latestMetar.observation[0].cloud.layer[
                    latestMetar.observation[0].cloud.layer.length - 1
                ].type
        )
            ? 'Broken'
            : metarActive && // all other cloud types
              latestMetar.observation &&
              latestMetar.observation.length > 0 &&
              latestMetar.observation[0].cloud &&
              latestMetar.observation[0].cloud.layer &&
              !latestMetar.observation[0].cloud.layer[
                  latestMetar.observation[0].cloud.layer.length - 1
              ].type &&
              latestMetar.observation[0].cloud.layer[
                  latestMetar.observation[0].cloud.layer.length - 1
              ].amount)

    // Weather Animations
    const lightRain =
        wxDefs &&
        Object.entries(wxDefs)
            .filter(([, value]) =>
                value.wmoName.toLowerCase().includes('precipitation')
            )
            .filter(([key]) => key.startsWith('-')) // light
            .map(([, value]) => value.translation)

    const rain =
        wxDefs &&
        Object.entries(wxDefs)
            .filter(([, value]) =>
                value.wmoName.toLowerCase().includes('precipitation')
            )
            .filter(([key]) => !key.startsWith('-') && !key.startsWith('+')) // excludes light and heavy
            .map(([, value]) => value.translation)

    const heavyRain =
        wxDefs &&
        Object.entries(wxDefs)
            .filter(([, value]) =>
                value.wmoName.toLowerCase().includes('precipitation')
            )
            .filter(([key]) => key.startsWith('+')) // heavy
            .map(([, value]) => value.translation)

    const weatherString =
        metarActive &&
        latestMetar.observation &&
        latestMetar.observation.length > 0 &&
        latestMetar.observation[0].presentWeather &&
        latestMetar.observation[0].presentWeather
            .map((wx) => wxTranslate(wx))
            .toString()

    const weatherArray = weatherString && weatherString.split(',')

    const filteredLightRain =
        weatherString &&
        weatherArray.filter((item) => lightRain.includes(item.trim()))

    const filteredRain =
        weatherString &&
        weatherArray.filter((item) => rain.includes(item.trim()))

    const filteredHeavyRain =
        weatherString &&
        weatherArray.filter((item) => heavyRain.includes(item.trim()))

    const lightRainPresent = weatherString && filteredLightRain.length > 0
    const rainPresent = weatherString && filteredRain.length > 0
    const heavyRainPresent = weatherString && filteredHeavyRain.length > 0
    const precipitationPresent =
        lightRainPresent || rainPresent || heavyRainPresent

    // Wind Gusts
    const windgusting =
        latestMetar &&
        latestMetar.observation &&
        latestMetar.observation.length > 0 &&
        latestMetar.observation[0].surfaceWind &&
        latestMetar.observation[0].surfaceWind.windGustSpeed

    const infoTab = metarActive && tabIndex === 0

    // Disable Background Imagery
    const disableInfotabBackgrounds =
        (latestMetar && !metarCloud) ||
        (profileData?.profileSettings?.disable_infotab_backgrounds !== undefined
            ? profileData.profileSettings.disable_infotab_backgrounds
            : userMetaData?.profile_settings?.disable_infotab_backgrounds) ||
        false

    const disableInfotabGrpahics =
        (profileData?.profileSettings?.disable_infotab_graphics !== undefined
            ? profileData.profileSettings.disable_infotab_graphics
            : userMetaData?.profile_settings?.disable_infotab_graphics) || false

    useEffect(() => {
        if (disableInfotabBackgrounds) {
            setAerodromeBackgroundsDisabled(true)
        } else if (!disableInfotabBackgrounds) {
            setAerodromeBackgroundsDisabled(false)
        }
    }, [disableInfotabBackgrounds])

    useEffect(() => {
        if (disableInfotabGrpahics) {
            setInfotabGraphicsDisabled(true)
        } else if (!disableInfotabGrpahics) {
            setInfotabGraphicsDisabled(false)
        }
    }, [disableInfotabGrpahics])

    // Close if Fullscreen modal opens
    useEffect(() => {
        if (handleFullscreenModal) {
            onClose()
            setClickedFeatures([])
            setZoomLevel(null)
        }
    }, [handleFullscreenModal, onClose, setClickedFeatures, setZoomLevel])

    // Expand drawer wider

    const screenWidthSmall = window.innerWidth < 864

    useEffect(() => {
        if (infoTab || screenWidthSmall) {
            setFullscreen(false)
        }
        if (!infoTab) {
            setFullscreen(fullscreen)
        }
    }, [fullscreen, infoTab, screenWidthSmall])

    // TODO Breakpoints (mobile > tablet/desktop) ...might be a better/cleaner way to do this??
    const navMenu = useBreakpointValue({
        base: { height: '82vh', borderBottomLeftRadius: '0' },
        md: { height: 'calc(70vh - 1px)', borderBottomLeftRadius: '15px' },
    })
    const closeButtonPositionSmallScreen = useBreakpointValue({
        base: { top: '0px', right: '0px' },
        md: { display: 'none' },
    })
    const closeButtonPositionLargeScreen = useBreakpointValue({
        base: { display: 'none' },
        md: {
            marginRight: '0',
            left: '10px',
            right: 'auto',
        },
    })
    const headerAlign = useBreakpointValue({
        base: { paddingRight: '0' },
        md: { paddingLeft: '40px', paddingRight: '0' },
    })
    const overlayBG = useBreakpointValue({
        base: { background: 'blackAlpha.700' },
        md: { background: 'none', width: '0vw' },
    })
    const tabPanelHeight = useBreakpointValue({
        base: { minHeight: '70vh' },
        md: { minHeight: '70vh' },
    })
    const DrawerSize = useBreakpointValue({
        base: { size: 'full' },
        md: { size: fullscreen ? 'full' : 'lg' },
    })
    const DrawerLayout = useBreakpointValue({
        base: 'bottom',
        md: 'right',
    })
    const tabLayout = useBreakpointValue({
        base: 'horizontal',
        md: 'vertical',
    })

    const infoTabContainerRef = useRef(null)

    // Converting Now Time to a 4 digit number (simple steps)
    const time = Date.now()
    const timeFormatted = formatISO(time, { format: 'basic' })
    const now = lightFormat(parseISO(timeFormatted), 'HHmm')
    const nowAsNumber = Number(now)

    // SUNRISE - plus/minus 30 minutes either side of MCT
    const MCT = data && data.twilighttimes && parseISO(data.twilighttimes.mct)
    const sunriseStart = // as a 4 digit number
        MCT &&
        Number(
            lightFormat(parseISO(formatISO(MCT, { format: 'basic' })), 'HHmm')
        )
    const sunriseEnd = // as a 4 digit number
        MCT &&
        Number(
            lightFormat(
                parseISO(formatISO(addMinutes(MCT, 30), { format: 'basic' })),
                'HHmm'
            )
        )

    // SUNSET - plus/minus 30 minutes either side of ECT
    const ECT = data && data.twilighttimes && parseISO(data.twilighttimes.ect)
    const sunsetStart = // as a 4 digit number
        ECT &&
        Number(
            lightFormat(
                parseISO(formatISO(addMinutes(ECT, -30), { format: 'basic' })),
                'HHmm'
            )
        )
    const sunsetEnd = // as a 4 digit number
        ECT &&
        Number(
            lightFormat(parseISO(formatISO(ECT, { format: 'basic' })), 'HHmm')
        )

    // TIMES OF DAY
    const night =
        (sunriseStart && nowAsNumber < sunriseStart) ||
        (sunsetEnd && nowAsNumber > sunsetEnd)
    const sunrise =
        sunriseStart &&
        sunriseEnd &&
        nowAsNumber >= sunriseStart &&
        nowAsNumber <= sunriseEnd
    const day = nowAsNumber > sunriseEnd && nowAsNumber < sunsetStart
    const sunset =
        sunriseStart &&
        sunriseEnd &&
        nowAsNumber >= sunsetStart &&
        nowAsNumber <= sunsetEnd

    // const timePeriod = 'Night' // *** Use this for testing backgrounds etc (change to suit - must have capital first letter) *** //
    const timePeriod = night
        ? 'Night'
        : sunrise
        ? 'Sunrise'
        : day
        ? 'Day'
        : sunset
        ? 'Sunset'
        : 'Day'

    // Zoom-out function when closing Aerodrome Drawer on button only (clicking overlay does not)
    function zoomOutOnClose() {
        if (
            zoomToActive &&
            clickedFeatures.length === 1 &&
            clickedFeatures[0].layer === 'aerodromes'
        ) {
            mapInstance.flyTo({
                center: clickedFeatures[0].geometry.coordinates,
                zoom: zoomLevel,
                curve: 1.2,
                offset: [0, 0],
            })
            setZoomLevel(null)
        }
    }

    // Background Images on Info Tab (TabIndex = 0)
    const cloudCoverColor =
        cloudCoverBgImage(metarCloud) !== null ? 'light.10' : 'light.100'

    const cloudCoverColorDetail = cloudCoverDetail(metarCloud)
    const cloudCoverColorTextHighlight = cloudCoverTextHighlight(metarCloud)

    const loadingError =
        status === 'error' || status === 'loading' || showNetworkErrors

    const backgroundImage =
        infoTab && !loadingError
            ? cloudCoverBgImage(
                  metarCloud + (timePeriod ? '-' + timePeriod : '')
              )
            : undefined

    const backgroundGradient = infoTab
        ? cloudCoverBgGradient(
              metarCloud + (timePeriod ? '-' + timePeriod : '')
          )
        : !infoTab && timePeriod === 'Sunrise' // used for header when not info tab
        ? 'linear-gradient(rgb(61, 120, 149) 72px, rgb(255, 255, 255) 73px, #ffffff 100%)'
        : !infoTab && timePeriod === 'Sunset' // used for header when not info tab
        ? 'linear-gradient(rgb(57, 102, 124) 72px, rgb(255, 255, 255) 73px, #ffffff 100%)'
        : undefined

    // Enhance saturation and contrast on background images when no rain or wind (otherwise affects lottie animations)
    const nightNCD = clearsky && night
    const backgroundEnhance =
        !lightRain && !rain && !heavyRain && !windgusting && !nightNCD

    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    // added slight time delay to show INFO tab footer text as flickers when alignment changes from under MCT/ECT box to bottom of panel
    const [showFooter, setShowFooter] = useState(false)
    useEffect(() => {
        if (infoTab) {
            const delay = setTimeout(() => {
                setShowFooter(true)
            }, 50)
            return () => {
                clearTimeout(delay)
            }
        } else {
            setShowFooter(false)
        }
    }, [infoTab, setShowFooter])

    // If FREE user, reset to defaults
    useEffect(() => {
        if (isPremium) {
            setZoomToActive(true)
            setAerodromeBackgroundsDisabled(false)
            setDecodeDefault(false)
        }
    }, [
        isPremium,
        setZoomToActive,
        setAerodromeBackgroundsDisabled,
        setDecodeDefault,
    ])

    // const tabSwipeHeader = useSwipeable({
    //     onSwipedLeft: () => {
    //         if (
    //             tabIndex !== 1 + (latestMetar && 1) || // METAR Tab
    //             tabIndex !== 2 + (latestMetar && 1) // TAF Tab
    //         ) {
    //             setTabIndex(
    //                 tabIndex +
    //                     ((tabIndex === 1 + (latestMetar && 1) && !data.taf) ||
    //                         (tabIndex === 2 + (latestMetar && 1) &&
    //                             !data.atis) ||
    //                         (tabIndex === 4 + (latestMetar && 1) && 1))
    //             )
    //         }
    //     },
    //     onSwipedRight: () => {
    //         if (
    //             tabIndex !== 1 + (latestMetar && 1) || // METAR Tab
    //             tabIndex !== 2 + (latestMetar && 1) // TAF Tab
    //         ) {
    //             setTabIndex(
    //                 tabIndex -
    //                     (((tabIndex === 2 + (latestMetar && 1) &&
    //                         !data.metar) ||
    //                         (tabIndex === 3 + (latestMetar && 1) &&
    //                             !data.taf) ||
    //                         (tabIndex === 4 + (latestMetar && 1) &&
    //                             !data.atis)) &&
    //                         1)
    //             )
    //         }
    //     },
    // })

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            onCloseHandler()
            zoomOutOnClose()
        },
    })

    //Tab Button Styles
    const tabStyles = {
        display: 'grid',
        background: 'transparent',
        fontSize: '0.5rem',
        fontWeight: '400',
        lineHeight: '2',
        justifyItems: 'center',
        alignContent: { base: 'baseline', md: 'center' },
        paddingTop: { base: '3', md: '4' },
        paddingEnd: '3',
        paddingBottom: '3',
        paddingStart: '3',
        width: { base: '75px', md: '60px' },
        minWidth: { base: '75px', md: '60px' },
        color: {
            base:
                timePeriod === 'Night' &&
                infoTab &&
                !aerodromeBackgroundsDisabled
                    ? 'light.900'
                    : 'light.100',
            md:
                timePeriod === 'Night' &&
                infoTab &&
                !aerodromeBackgroundsDisabled
                    ? 'light.54'
                    : 'light.100',
        },
        opacity: infoTab ? '1' : '1',
        transition: 'color 0s',
        border: 'none',
        textShadow:
            infoTab && !aerodromeBackgroundsDisabled
                ? '1px 2px 5px rgba(0,0,0,0.06)'
                : 'none',

        _selected: {
            bg: { base: 'transparent', md: '#ffffff' },
            borderLeft: {
                base: '0px',
                md: '1px solid transparent',
            },
            borderRight: {
                base: '0px',
                md: '1px solid #FFFFFF',
            },
            borderRightColor: 'white',
            fontWeight: { base: '400', md: '600' },
            color: 'light.200',
            opacity: '1',
            boxShadow: {
                base: 'none',
                md: '0px 3px 6px -1px rgba(0,0,0,0.08)',
            },
            clipPath: 'inset(-10px 0px -10px 0px)',
        },
        _focus: {
            border: 'none',
        },
        _disabled: {
            display: 'none',
        },
    }
    const InfoTabStyle = {
        display: 'grid',
        bg: aerodromeBackgroundsDisabled && 'transparent',
        fontSize: '0.5rem',
        lineHeight: '2',
        justifyItems: 'center',
        alignContent: { base: 'baseline', md: 'center' },
        paddingTop: { base: '3', md: '4' },
        paddingEnd: '3',
        paddingBottom: '3',
        paddingStart: '3',
        border: 'none',
        transition: 'color 0s',
        width: { base: '70px', md: '60px' },
        minWidth: { base: '70px', md: '60px' },
        textShadow:
            infoTab && !aerodromeBackgroundsDisabled
                ? '1px 2px 5px rgba(0,0,0,0.06)'
                : 'none',
        _selected: {
            bg: {
                base: 'transparent',
                md: aerodromeBackgroundsDisabled ? 'light.30' : 'transparent',
            },
            borderTop: 'none',
            borderLeft: {
                base: '0px',
                md: '1px solid transparent',
            },
            borderRight: {
                base: '0px',
                md: '1px solid transparent',
            },
            fontWeight: '600',
            transition: 'all 0s',
            boxShadow: {
                base: 'none',
                md: aerodromeBackgroundsDisabled
                    ? '0px 3px 6px -1px rgba(0,0,0,0.09)'
                    : '0px 3px 6px -1px rgba(0,0,0,0.1)',
            },
            clipPath: 'inset(-10px 0px -10px 0px)',
            color: {
                base:
                    timePeriod === 'Night'
                        ? 'light.203'
                        : isFirefox
                        ? 'light.200'
                        : 'light.200',
                md: aerodromeBackgroundsDisabled
                    ? 'light.200'
                    : timePeriod === 'Night'
                    ? 'light.203'
                    : metarActive
                    ? cloudCoverColorTextHighlight
                    : 'light.10',
            },
        },
        _focus: {
            border: 'none',
        },
        _disabled: {
            display: 'none',
        },
    }

    const tabText = {
        display: {
            base: 'initial',
            md: 'initial',
        },
    }

    const premiumBadge = (
        <Badge
            display="inline-flex"
            justifyContent="space-evenly"
            alignItems="center"
            ml="2px"
            mr="2px"
            mt="-2px"
            variant="solid"
            bg="rgba(0, 188, 248, 0.1)"
            color="#00B6F1"
            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
            borderRadius="15"
            pl="5px"
            pr="7px"
            pb="0px"
            pt="0px"
            fontSize="0.8rem"
            fontWeight="600"
        >
            <RiStarLine color="#00B6F1" fontSize="14px" />
            <Text ml="3px">Premium</Text>
        </Badge>
    )

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // TEMP badge for Matamata and Wanaka when temporary ATIS service is provided, i.e. Walsh Flying School or Warbirds Over Wanaka
    const tempATIS = designator && ['NZMA', 'NZWF'].includes(designator)

    // useEffect(() => {
    //     console.log(data, 'data')
    //     // console.log(latestMetar, 'latestMetar')
    //     // console.log(metarOutOfDateIssueTime, 'metarOutOfDateIssueTime')
    //     console.log(metarCloud, 'metarCloud')
    // }, [data, latestMetar, metarOutOfDateIssueTime, metarCloud])

    return (
        <LightMode>
            <Drawer
                closeOnOverlayClick={true}
                variant="aerodrome"
                onClose={() => {
                    onCloseHandler()
                    zoomOutOnClose()
                }}
                isOpen={isOpen}
                isCentered
                placement={DrawerLayout}
                onChange={DrawerLayout}
                {...DrawerSize}
                mt="60px"
                containerStyle={{ width: '0vw' }}
                trapFocus={false}
            >
                <DrawerCloseButton
                    {...closeButtonPositionSmallScreen}
                    color={{ base: 'white', md: '#444444' }}
                    zIndex="1301"
                    opacity="1"
                    fontSize="1.1rem"
                    boxShadow="none !important"
                    {...noFocus}
                    width="48px"
                    height="48px"
                />
                <DrawerOverlay
                    {...overlayBG}
                    onClick={() => {
                        onCloseHandler()
                    }}
                />
                {status === 'loading' && (
                    <div className="pageload">
                        <Center w="100%" h="100%" mt="-65px">
                            <Spinner
                                color={'light.200'}
                                emptyColor="rgba(255,255,255,0.3)"
                                thickness="3px"
                                speed="0.45s"
                                boxSize="60px"
                            />
                        </Center>
                    </div>
                )}
                {status !== 'loading' && status === 'success' && (
                    <DrawerContent
                        background={
                            aerodromeBackgroundsDisabled && infoTab
                                ? 'light.30'
                                : metarActive && metarCloud
                                ? backgroundGradient
                                : 'light.10'
                        }
                        backgroundImage={
                            metarActive &&
                            metarCloud &&
                            !aerodromeBackgroundsDisabled
                                ? backgroundGradient
                                : null
                        }
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        height={{ base: 'calc(100% - 47px)', md: '100%' }}
                        maxHeight={{ base: 'calc(100% - 47px)', md: '100%' }}
                        borderTopRadius={{ base: '27px', md: '0px' }}
                        boxShadow={{
                            base: '0px -5px 20px -7px rgba(0,0,0,0.4)',
                            md: '-5px 0px 15px -5px rgba(0,0,0,0.2)',
                        }}
                        width={{
                            base: '100%',
                            md: fullscreen
                                ? 'calc(100% - 50px) !important'
                                : '',
                            lg: fullscreen
                                ? 'calc(100% - 115px) !important'
                                : '',
                            xl: fullscreen ? '85% !important' : '',
                        }}
                        maxWidth={{
                            base: '100%',
                            md: fullscreen
                                ? 'calc(100% - 50px) !important'
                                : '46rem',
                            lg: fullscreen
                                ? 'calc(100% - 115px) !important'
                                : '46rem',
                            xl: fullscreen ? '85% !important' : '46rem',
                        }}
                        //TODO
                        // EXPAND/COLLAPSE DRAWER WIDTH TRANSITION IS BUGGY - its trying to compete with the Drawer opening transition when set to 'all', or bounces from left when set to 'width'.
                        transition={{
                            base: 'unset',
                            md: 'width ease-in 200ms, max-width ease-in 200ms',
                        }}
                    >
                        {/* ANIMATIONS */}
                        {metarCloud && !aerodromeBackgroundsDisabled && (
                            <Flex
                                position="absolute"
                                width="100%"
                                height="100%"
                                borderTopLeftRadius="27px"
                                borderTopRightRadius="27px"
                                overflow="hidden"
                                pointerEvents="none"
                                zIndex="2" // remove if overlapping info
                            >
                                <Animations
                                    metarActive={metarActive}
                                    latestMetar={latestMetar}
                                    timePeriod={timePeriod}
                                    infoTab={infoTab}
                                    lightRain={lightRainPresent}
                                    rain={rainPresent}
                                    heavyRain={heavyRainPresent}
                                    windgusting={windgusting}
                                    clearsky={clearsky}
                                />
                            </Flex>
                        )}

                        <DrawerHeader
                            zIndex="1"
                            // {...tabSwipe}
                            {...headerSwipe}
                            backgroundColor={
                                metarActive &&
                                metarCloud &&
                                !aerodromeBackgroundsDisabled
                                    ? cloudCoverBgImage(
                                          metarCloud + '-' + timePeriod
                                      ) !== null
                                        ? 'rgba(6, 10, 15, 0.6)'
                                        : '#07588C'
                                    : '#07588C'
                            }
                            bgImage={
                                metarActive &&
                                metarCloud &&
                                !aerodromeBackgroundsDisabled
                                    ? cloudCoverBgImage(
                                          metarCloud + '-' + timePeriod
                                      )
                                    : 'linear-gradient(345deg, #3B9EDC 0%, #00436F 90%)'
                            }
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            backgroundBlendMode={
                                metarActive &&
                                metarCloud &&
                                !aerodromeBackgroundsDisabled
                                    ? 'overlay'
                                    : 'unset'
                            }
                            paddingInlineStart={{ base: 4, md: 5 }}
                            paddingInlineEnd={{ base: 4, md: 5 }}
                            minHeight="73px"
                            paddingTop="3"
                            borderTopRadius={{ base: '25px', md: '0px' }}
                            clipPath="inset(0px 0px 0px 0px)"
                        >
                            <DrawerCloseButton
                                {...closeButtonPositionLargeScreen}
                                color="white"
                                opacity="0.6"
                                boxShadow="none !important"
                                _hover={{ opacity: '1' }}
                                {...noFocus}
                                fontSize="1rem"
                                width="40px"
                                height="40px"
                            />
                            <Flex w="100%" {...headerAlign}>
                                <Icon
                                    position="absolute"
                                    pointerEvents="none"
                                    transform={{
                                        base: 'scale(2) translateX(0px) translateY(2px)',
                                        md: 'scale(2.2) translateX(-20px) translateY(1px)',
                                    }}
                                    opacity="0.04"
                                    boxSize="44px"
                                    as={AerodromeIcon}
                                    alt="!"
                                    color="#ffffff"
                                />
                                <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Flex
                                        direction="column"
                                        justifyContent={
                                            !latestMetar
                                                ? 'flex-start'
                                                : 'space-between'
                                        }
                                        alignItems={
                                            !latestMetar
                                                ? 'flex-start'
                                                : 'center'
                                        }
                                    >
                                        <Flex
                                            minWidth="90px"
                                            flexDirection={
                                                !latestMetar ? 'row' : 'column'
                                            }
                                            justifyContent="flex-start"
                                            alignItems="baseline"
                                            paddingLeft="1px"
                                        >
                                            <Text
                                                color="white"
                                                fontSize="md"
                                                fontWeight="500"
                                                paddingTop={
                                                    !latestMetar ? '0' : '1px'
                                                }
                                            >
                                                {designator}
                                            </Text>
                                            <Text
                                                color="white"
                                                fontSize={
                                                    !latestMetar ? '12px' : 'sm'
                                                }
                                                fontWeight="300"
                                                fontStyle="italic"
                                                paddingTop={
                                                    !latestMetar ? '0' : '2px'
                                                }
                                                paddingLeft={
                                                    !latestMetar
                                                        ? '12px'
                                                        : '0px'
                                                }
                                            >
                                                {data.elevation &&
                                                    `ELEV ${data.elevation}`}{' '}
                                            </Text>
                                        </Flex>

                                        {!latestMetar && data.twilighttimes && (
                                            <Flex
                                                direction={{
                                                    base: 'row',
                                                    md: 'row',
                                                }}
                                                justifyContent="space-evenly"
                                                width="auto"
                                                ml="-2px"
                                                gap={{
                                                    base: '2px',
                                                    md: '5px',
                                                }}
                                            >
                                                <Flex
                                                    pr={{
                                                        base: '0.5rem',
                                                        md: '10px',
                                                    }}
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    <SunriseIcon
                                                        width="28px"
                                                        height="28px"
                                                    />
                                                    <Text
                                                        px="3px"
                                                        color={{
                                                            base: 'light.10',
                                                            md: 'white',
                                                        }}
                                                        fontSize="0.8rem"
                                                        textAlign="center"
                                                        fontWeight="400"
                                                        whiteSpace="pre-line"
                                                        textShadow={'none'}
                                                    >
                                                        {data?.twilighttimes
                                                            ?.mct &&
                                                            formatTimeAndTimezone(
                                                                data
                                                                    .twilighttimes
                                                                    .mct,
                                                                timeZone
                                                            )}
                                                    </Text>
                                                </Flex>
                                                <Flex
                                                    direction="row"
                                                    alignItems="center"
                                                    pr={{
                                                        base: '0.5rem',
                                                        md: '10px',
                                                    }}
                                                >
                                                    <SunsetIcon
                                                        width="28px"
                                                        height="28px"
                                                    />
                                                    <Text
                                                        px="3px"
                                                        color={{
                                                            base: 'light.10',
                                                            md: 'white',
                                                        }}
                                                        fontSize="0.8rem"
                                                        textAlign="center"
                                                        fontWeight="400"
                                                        whiteSpace="pre-line"
                                                    >
                                                        {data?.twilighttimes
                                                            ?.ect &&
                                                            formatTimeAndTimezone(
                                                                data
                                                                    .twilighttimes
                                                                    .ect,
                                                                timeZone
                                                            )}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Flex>

                                    <Box position="absolute" right="15px">
                                        <Text
                                            textAlign="right"
                                            color="white"
                                            fontSize={{
                                                base:
                                                    data.name.length > 22
                                                        ? '0.9rem'
                                                        : data.name.length > 16
                                                        ? '1rem'
                                                        : '1.2rem',
                                                md: '1.2rem',
                                            }}
                                            fontWeight="bold"
                                            lineHeight={{
                                                base:
                                                    data.name.length > 22
                                                        ? '1.8'
                                                        : data.name.length > 16
                                                        ? '1.6'
                                                        : '1.3',
                                                md: '1.3',
                                            }}
                                        >
                                            {data.name}
                                        </Text>
                                        <Text
                                            textAlign="right"
                                            color="white"
                                            fontSize="0.8rem"
                                            pt="2px"
                                            fontWeight={500}
                                        >
                                            {convertType(data.type)}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </DrawerHeader>
                        <DrawerBody
                            p={0}
                            background={
                                (loadingError || !infoTab) && 'light.10'
                            }
                            bgImage={
                                metarActive &&
                                metarCloud &&
                                !aerodromeBackgroundsDisabled
                                    ? backgroundImage
                                    : ''
                            }
                            backgroundSize="100% auto"
                            backgroundPosition="0 -70px"
                            backgroundRepeat="no-repeat"
                            filter={
                                isMobileSafari && // <- temp until can check Android
                                backgroundEnhance &&
                                infoTab &&
                                !aerodromeBackgroundsDisabled &&
                                'contrast(107%) saturate(107%)'
                            }
                            overflow="hidden"
                        >
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    width={{
                                        base: '100%',
                                        md: 'calc(100% - 60px)',
                                    }}
                                    height="60px"
                                    py="15px"
                                    px="20px"
                                    ml={{ base: '0px', md: '60px' }}
                                    zIndex="100"
                                    position="absolute"
                                    background={{
                                        base: 'rgba(255,255,255,0.7)',
                                        md: !infoTab && 'rgba(255,255,255,0.7)',
                                    }}
                                    gap="15px"
                                    justifyContent="space-between"
                                    backdropFilter={{
                                        base: 'blur(15px)',
                                        md: !infoTab && 'blur(15px)',
                                    }}
                                >
                                    <Flex
                                        w="auto"
                                        height="27px"
                                        align="center"
                                        borderRadius="25px"
                                        background="white"
                                        color={
                                            metarActive &&
                                            !aerodromeBackgroundsDisabled
                                                ? cloudCoverColor
                                                : 'light.100'
                                        }
                                    >
                                        <ErrorToggle
                                            showErrors={showNetworkErrors}
                                            setShowErrors={setShowNetworkErrors}
                                            label="Simulate Network Error"
                                        />
                                    </Flex>

                                    <Flex
                                        w="auto"
                                        height="27px"
                                        align="center"
                                        borderRadius="25px"
                                        background="white"
                                        color={
                                            metarActive &&
                                            !aerodromeBackgroundsDisabled
                                                ? cloudCoverColor
                                                : 'light.100'
                                        }
                                    >
                                        <ErrorToggle
                                            showErrors={showErrors}
                                            setShowErrors={setShowErrors}
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            {status === 'error' || showNetworkErrors ? (
                                <Center h="100%" w="100%" px="15px">
                                    <FullWidthError
                                        message={`Unable to download data for ${feat.properties.designator}. \nPlease close this pane and try clicking the aerodrome again.`}
                                    />
                                </Center>
                            ) : (
                                <Box w="100%" h="100%">
                                    {data && (
                                        <Tabs
                                            index={tabIndex}
                                            isLazy={true}
                                            onChange={(index) =>
                                                setTabIndex(index)
                                            }
                                            h="100%"
                                            orientation={tabLayout}
                                            isFitted
                                            variant="enclosed-colored"
                                            align={{
                                                base: 'center',
                                                md: 'initial',
                                            }}
                                        >
                                            <Flex
                                                flexDirection={{
                                                    base: 'row',
                                                    md: 'column',
                                                }}
                                                marginTop="auto"
                                                marginBottom={{
                                                    base: '-1px',
                                                    md: '0px',
                                                }}
                                                h={{
                                                    base:
                                                        isMobileOnly &&
                                                        isIOS &&
                                                        isMobileSafari
                                                            ? '85px'
                                                            : '70px',
                                                    md: '100%',
                                                }}
                                                w={{
                                                    base: '100%',
                                                    md: 'auto',
                                                }}
                                                position={{
                                                    base: 'absolute',
                                                    md: 'relative',
                                                }}
                                                bottom={{
                                                    base: '0px',
                                                    md: 'initial',
                                                }}
                                                zIndex="100"
                                            >
                                                <Flex
                                                    flexDirection={{
                                                        base: 'row',
                                                        md: 'column',
                                                    }}
                                                    marginTop="auto"
                                                    marginBottom={{
                                                        base: '-1px',
                                                        md: '0px',
                                                    }}
                                                    h={{
                                                        base:
                                                            isMobileOnly &&
                                                            isIOS &&
                                                            isMobileSafari
                                                                ? '85px'
                                                                : '70px',
                                                        md:
                                                            infoTab &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? 'calc(100% - 72px)'
                                                                : '100%',
                                                    }}
                                                    w={{
                                                        base: '100%',
                                                        md: '60px',
                                                    }}
                                                    position={{
                                                        base: 'absolute',
                                                        md: 'absolute',
                                                    }}
                                                    bottom={{
                                                        base: '0px',
                                                        md: 'unset',
                                                    }}
                                                    top={{
                                                        base: 'unset',
                                                        md:
                                                            infoTab &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? '72px'
                                                                : '0px',
                                                    }}
                                                    boxShadow={{
                                                        base: '0px -5px 10px -1px rgba(0,0,0,0.05)',
                                                        md:
                                                            metarCloud &&
                                                            infoTab &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? 'inset -4px 0px 6px -1px rgba(0,0,0,0.1)'
                                                                : // ? 'none'
                                                                infoTab &&
                                                                  aerodromeBackgroundsDisabled
                                                                ? 'inset -4px 0px 6px -1px rgba(0,0,0,0.07)'
                                                                : 'inset -4px 0px 6px -1px rgba(0,0,0,0.05)',
                                                    }}
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(255,255,255,1)'
                                                            : 'rgba(255,255,255,0.4)',
                                                        md:
                                                            aerodromeBackgroundsDisabled &&
                                                            infoTab
                                                                ? 'rgba(255, 255, 255, 0.6)'
                                                                : aerodromeBackgroundsDisabled
                                                                ? 'light.30'
                                                                : timePeriod ===
                                                                      'Night' &&
                                                                  metarCloud &&
                                                                  infoTab
                                                                ? 'rgba(255, 255, 255, 0.15)'
                                                                : metarCloud &&
                                                                  infoTab
                                                                ? 'rgba(255, 255, 255, 0.5)'
                                                                : 'light.30',
                                                    }}
                                                    backdropFilter="blur(20px)"
                                                    zIndex="100"
                                                ></Flex>
                                                <TabList
                                                    {...navMenu}
                                                    position={{
                                                        base: 'absolute',
                                                        md: 'relative',
                                                    }}
                                                    bottom={{
                                                        base: '0px',
                                                        md: 'initial',
                                                    }}
                                                    overflowX={{
                                                        base: 'scroll',
                                                        md: 'inherit',
                                                    }}
                                                    overflowY={{
                                                        base: 'hidden',
                                                        md: 'inherit',
                                                    }}
                                                    px={{ base: '5', md: '0' }}
                                                    h={{
                                                        base:
                                                            isMobileOnly &&
                                                            isIOS &&
                                                            isMobileSafari
                                                                ? '85px'
                                                                : '70px',
                                                        md: '100%',
                                                    }}
                                                    w={{
                                                        base: '100%',
                                                        md: 'auto',
                                                    }}
                                                    borderBottom="none"
                                                    zIndex="200"
                                                >
                                                    {latestMetar && (
                                                        <Tab
                                                            {...InfoTabStyle}
                                                            id="infoTab"
                                                        >
                                                            <InfoIcon
                                                                width="28px"
                                                                height="28px"
                                                            />
                                                            <Text {...tabText}>
                                                                INFO
                                                            </Text>
                                                        </Tab>
                                                    )}
                                                    <Tab {...tabStyles}>
                                                        <NotamIcon
                                                            width="28px"
                                                            height="28px"
                                                        />
                                                        <Text {...tabText}>
                                                            NOTAM
                                                        </Text>
                                                        {data.notam && (
                                                            <Badge
                                                                marginRight="-23px"
                                                                marginTop={{
                                                                    base: '-77px',
                                                                    md: '-77px',
                                                                }}
                                                                height="16px"
                                                                minWidth="16px"
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                variant="solid"
                                                                bg="warning.100"
                                                                borderRadius="10"
                                                                px="4px"
                                                                py="0px"
                                                                fontSize="0.75rem"
                                                            >
                                                                {
                                                                    data.notam
                                                                        .length
                                                                }
                                                            </Badge>
                                                        )}
                                                    </Tab>
                                                    <Tab
                                                        {...tabStyles}
                                                        isDisabled={!data.metar}
                                                    >
                                                        <MetarIcon
                                                            width="28px"
                                                            height="28px"
                                                        />
                                                        <Text {...tabText}>
                                                            METAR
                                                        </Text>
                                                    </Tab>
                                                    <Tab
                                                        {...tabStyles}
                                                        isDisabled={!data.taf}
                                                    >
                                                        <TafIcon
                                                            width="28px"
                                                            height="28px"
                                                        />
                                                        <Text {...tabText}>
                                                            TAF
                                                        </Text>
                                                    </Tab>
                                                    <Tab
                                                        {...tabStyles}
                                                        isDisabled={!data.atis}
                                                    >
                                                        <AtisIcon
                                                            width="28px"
                                                            height="28px"
                                                        />
                                                        <Text {...tabText}>
                                                            ATIS
                                                        </Text>
                                                        {data.atis && // TEMP badge for Matamata and Wanaka when temporary ATIS service is provided, i.e. Walsh Flying School or Warbirds Over Wanaka
                                                            tempATIS && (
                                                                <Badge
                                                                    marginRight="-23px"
                                                                    marginTop={{
                                                                        base: '-79px',
                                                                        md: '-79px',
                                                                    }}
                                                                    height="14px"
                                                                    minWidth="34px"
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    variant="solid"
                                                                    bg="brand.plus"
                                                                    borderRadius="10"
                                                                    px="4px"
                                                                    py="0px"
                                                                    fontSize="0.6rem"
                                                                >
                                                                    TEMP
                                                                </Badge>
                                                            )}
                                                    </Tab>
                                                    <Tab
                                                        {...tabStyles}
                                                        isDisabled={
                                                            !data.webcams
                                                        }
                                                        alignItems="flex-start"
                                                    >
                                                        <WebcamIcon
                                                            width="28px"
                                                            height="28px"
                                                            opacity={
                                                                isPremium
                                                                    ? '0.4'
                                                                    : '1'
                                                            }
                                                        />
                                                        <Text
                                                            {...tabText}
                                                            opacity={
                                                                isPremium
                                                                    ? '0.4'
                                                                    : '1'
                                                            }
                                                        >
                                                            WEBCAM
                                                            {data.webcams &&
                                                            data.webcams
                                                                .length > 1
                                                                ? 'S'
                                                                : ''}
                                                        </Text>
                                                        {isPremium && ( // PREMIUM BADGE
                                                            <Flex
                                                                position={{
                                                                    base: 'relative',
                                                                    md: 'relative',
                                                                }}
                                                                right={{
                                                                    base: '-10px',
                                                                    md: '-12px',
                                                                }}
                                                                mt={{
                                                                    base: '-45px',
                                                                    md: '-45px',
                                                                }}
                                                            >
                                                                <PremiumBadge />
                                                            </Flex>
                                                        )}
                                                    </Tab>
                                                    <Tab
                                                        id="charts"
                                                        {...tabStyles}
                                                        isDisabled={[
                                                            'NZTB',
                                                            'NZFX',
                                                        ].includes(designator)}
                                                        alignItems="flex-start"
                                                    >
                                                        <ChartsIcon
                                                            width="28px"
                                                            height="28px"
                                                            opacity={
                                                                isPremium
                                                                    ? '0.4'
                                                                    : '1'
                                                            }
                                                        />
                                                        <Text
                                                            {...tabText}
                                                            opacity={
                                                                isPremium
                                                                    ? '0.4'
                                                                    : '1'
                                                            }
                                                        >
                                                            CHARTS
                                                        </Text>
                                                        {isPremium && ( // PREMIUM BADGE
                                                            <Flex
                                                                position={{
                                                                    base: 'relative',
                                                                    md: 'relative',
                                                                }}
                                                                right={{
                                                                    base: '-10px',
                                                                    md: '-12px',
                                                                }}
                                                                mt={{
                                                                    base: '-45px',
                                                                    md: '-45px',
                                                                }}
                                                            >
                                                                <PremiumBadge />
                                                            </Flex>
                                                        )}
                                                    </Tab>
                                                    <Flex
                                                        display={{
                                                            base: 'none',
                                                            md: 'flex',
                                                        }}
                                                        height="100%"
                                                    >
                                                        <Flex
                                                            display={{
                                                                base: 'none',
                                                                ml: infoTab
                                                                    ? 'none'
                                                                    : 'flex',
                                                            }}
                                                            width="100%"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            marginTop="auto"
                                                            paddingBottom="13px"
                                                            flexDirection="column"
                                                        >
                                                            <IconButton
                                                                fontWeight="400"
                                                                color={
                                                                    infoTab &&
                                                                    !aerodromeBackgroundsDisabled
                                                                        ? cloudCoverColorDetail
                                                                        : '#a8b2c0'
                                                                }
                                                                variant="outline"
                                                                fontSize="1rem"
                                                                height="26px"
                                                                width="36px"
                                                                style={{
                                                                    minWidth:
                                                                        '36px',
                                                                }}
                                                                borderColor={
                                                                    infoTab &&
                                                                    !aerodromeBackgroundsDisabled
                                                                        ? cloudCoverColorDetail
                                                                        : 'gray.200'
                                                                }
                                                                paddingLeft="8px"
                                                                paddingRight="8px"
                                                                pb="2px"
                                                                mt="10px"
                                                                borderRadius="10px"
                                                                {...noFocus}
                                                                onClick={() => {
                                                                    setFullscreen(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                                transition="all ease 250ms, color 0s"
                                                                background={
                                                                    infoTab
                                                                        ? 'rgba(255,255,255,0.2)'
                                                                        : 'light.40'
                                                                }
                                                                _hover={{
                                                                    background:
                                                                        infoTab
                                                                            ? 'rgba(255,255,255,0.2)'
                                                                            : '#FFFFFF',
                                                                    transform:
                                                                        'translateY(-1px) scale(1.1)',
                                                                    boxShadow:
                                                                        '0px 3px 10px -4px rgba(0,0,0,0.3)',
                                                                }}
                                                                _focus={{
                                                                    background:
                                                                        infoTab
                                                                            ? 'rgba(255,255,255,0.2)'
                                                                            : '#FFFFFF',
                                                                }}
                                                                _active={{
                                                                    background:
                                                                        infoTab
                                                                            ? 'rgba(255,255,255,0.2)'
                                                                            : '#FFFFFF',
                                                                    transform:
                                                                        'translateY(-1px) scale(1.1)',
                                                                    boxShadow:
                                                                        '0px 3px 10px -4px rgba(0,0,0,0.3)',
                                                                }}
                                                                icon={
                                                                    <Icon
                                                                        mx={
                                                                            '2px'
                                                                        }
                                                                        mt="2px"
                                                                        style={{
                                                                            marginInlineEnd:
                                                                                '0.2rem',
                                                                            marginRight:
                                                                                '0.2rem',
                                                                        }}
                                                                        transform="rotate(45deg)"
                                                                        as={
                                                                            fullscreen
                                                                                ? FaCompressAlt
                                                                                : FaExpandAlt
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </TabList>
                                            </Flex>
                                            <TabPanels
                                                // {...tabSwipe}
                                                position="relative"
                                                w={{
                                                    base: '100%',
                                                    md: '100%',
                                                }}
                                                h="100%"
                                                maxWidth={{
                                                    base: '100%',
                                                    md: '100%',
                                                }}
                                                marginLeft={{
                                                    base: '0',
                                                    md: '0px',
                                                }}
                                                pt={
                                                    isAdmin &&
                                                    showErrorToggle &&
                                                    '60px'
                                                }
                                                paddingBottom={{
                                                    base:
                                                        isMobileOnly &&
                                                        isIOS &&
                                                        isMobileSafari
                                                            ? '85px'
                                                            : '70px',
                                                    md: '0',
                                                }}
                                                overflowY="auto"
                                                zIndex="99"
                                            >
                                                {latestMetar && (
                                                    <TabPanel
                                                        // {...tabSwipe}
                                                        ref={
                                                            infoTabContainerRef
                                                        }
                                                        background="transparent"
                                                        overflowX="hidden"
                                                        whiteSpace="pre-line"
                                                        color={
                                                            metarActive &&
                                                            metarCloud &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? cloudCoverColor
                                                                : 'light.100'
                                                        }
                                                        style={{
                                                            transition:
                                                                'opacity .3s',
                                                        }}
                                                        height={{
                                                            base: 'auto',
                                                            md: '100%',
                                                        }}
                                                        justifyContent={{
                                                            base: 'space-between',
                                                            md:
                                                                metarOutOfDateIssueTime !==
                                                                'NONE'
                                                                    ? 'flex-start'
                                                                    : 'space-between',
                                                        }}
                                                        gap={{
                                                            base: '0px',
                                                            md:
                                                                metarOutOfDateIssueTime !==
                                                                'NONE'
                                                                    ? '15px'
                                                                    : '0px',
                                                        }}
                                                        alignItems="center"
                                                        display="flex"
                                                        flexDirection={{
                                                            base: 'column',
                                                            md: 'column',
                                                        }}
                                                    >
                                                        <ErrorBoundary
                                                            FallbackComponent={() => (
                                                                <FullWidthError
                                                                    message={
                                                                        'Error loading this tab, please try again later.'
                                                                    }
                                                                />
                                                            )}
                                                        >
                                                            <InfoContent
                                                                data={data}
                                                                metarCloud={
                                                                    metarCloud
                                                                }
                                                                metarActive={
                                                                    metarActive
                                                                }
                                                                cloudCoverColor={
                                                                    cloudCoverColor
                                                                }
                                                                timePeriod={
                                                                    timePeriod
                                                                }
                                                                metarOutOfDateIssueTime={
                                                                    metarOutOfDateIssueTime
                                                                }
                                                                infoTabContainerRef={
                                                                    infoTabContainerRef
                                                                }
                                                                aerodromeBackgroundsDisabled={
                                                                    aerodromeBackgroundsDisabled
                                                                }
                                                                infotabGraphicsDisabled={
                                                                    infotabGraphicsDisabled
                                                                }
                                                                showFooter={
                                                                    showFooter
                                                                }
                                                                fog={fog}
                                                                precipitationPresent={
                                                                    precipitationPresent
                                                                }
                                                            />
                                                        </ErrorBoundary>
                                                    </TabPanel>
                                                )}
                                                <TabPanel
                                                    // {...tabSwipe}
                                                    {...tabPanelHeight}
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                >
                                                    <NotamContent
                                                        data={data}
                                                        showErrors={showErrors}
                                                    />
                                                </TabPanel>
                                                <TabPanel
                                                    // {...tabSwipe}
                                                    {...tabPanelHeight}
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                >
                                                    {data.metar && (
                                                        <ErrorBoundary
                                                            FallbackComponent={() => (
                                                                <FullWidthError
                                                                    message={
                                                                        'Error loading METAR data, please try again later.'
                                                                    }
                                                                />
                                                            )}
                                                        >
                                                            <MetarContent
                                                                data={data}
                                                                isPremium={
                                                                    isPremium
                                                                }
                                                                currentMetar={
                                                                    currentMetar
                                                                }
                                                                metarOutOfDateIssueTime={
                                                                    metarOutOfDateIssueTime
                                                                }
                                                                showErrors={
                                                                    showErrors
                                                                }
                                                                mfaExpired={
                                                                    mfaExpired
                                                                }
                                                                fullscreen={
                                                                    fullscreen
                                                                }
                                                                // tabSwipeHeader={
                                                                //     tabSwipeHeader
                                                                // }
                                                            />
                                                        </ErrorBoundary>
                                                    )}
                                                </TabPanel>
                                                <TabPanel
                                                    // {...tabSwipe}
                                                    {...tabPanelHeight}
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                >
                                                    {data.taf && (
                                                        <ErrorBoundary
                                                            FallbackComponent={() => (
                                                                <FullWidthError
                                                                    message={
                                                                        'Error loading TAF info, please try again later.'
                                                                    }
                                                                />
                                                            )}
                                                        >
                                                            <TafContent
                                                                data={data}
                                                                isPremium={
                                                                    isPremium
                                                                }
                                                                tafOutOfDateIssueTime={
                                                                    tafOutOfDateIssueTime
                                                                }
                                                                showErrors={
                                                                    showErrors
                                                                }
                                                                mfaExpired={
                                                                    mfaExpired
                                                                }
                                                                fullscreen={
                                                                    fullscreen
                                                                }
                                                                // tabSwipeHeader={
                                                                //     tabSwipeHeader
                                                                // }
                                                            />
                                                        </ErrorBoundary>
                                                    )}
                                                </TabPanel>
                                                <TabPanel
                                                    // {...tabSwipe}
                                                    {...tabPanelHeight}
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                >
                                                    {data.atis && (
                                                        <AtisContent
                                                            data={data}
                                                            isPremium={
                                                                isPremium
                                                            }
                                                            showErrors={
                                                                showErrors
                                                            }
                                                            mfaExpired={
                                                                mfaExpired
                                                            }
                                                            fullscreen={
                                                                fullscreen
                                                            }
                                                            tempATIS={tempATIS}
                                                        />
                                                    )}
                                                </TabPanel>
                                                <TabPanel
                                                    // {...tabSwipe}
                                                    width="100%"
                                                    height="100%"
                                                    overflow="hidden"
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                    px="0px"
                                                >
                                                    <Flex
                                                        width="100%"
                                                        height={'100%'}
                                                        direction={'column'}
                                                        px={{
                                                            base: minimiseOnZoom
                                                                ? '0px'
                                                                : '15px',
                                                            md: '4',
                                                        }}
                                                        transition="all ease 400ms"
                                                    >
                                                        <Flex
                                                            // {...tabSwipeHeader}
                                                            marginBottom="5px"
                                                            alignContent="center"
                                                            justifyContent="space-between"
                                                            pl={{
                                                                base: minimiseOnZoom
                                                                    ? '15px'
                                                                    : '0px',
                                                                md: '0px',
                                                            }}
                                                            mt={{
                                                                base: minimiseOnZoom
                                                                    ? '-48px'
                                                                    : '0px',
                                                                md: minimiseOnZoom
                                                                    ? '-40px'
                                                                    : '0px',
                                                                ml: minimiseOnZoom
                                                                    ? '-42px'
                                                                    : '0px',
                                                            }}
                                                            opacity={
                                                                minimiseOnZoom
                                                                    ? '0'
                                                                    : '1'
                                                            }
                                                            transition="all ease 400ms"
                                                        >
                                                            <Text
                                                                marginBottom={3}
                                                                fontSize={{
                                                                    base: '0.85rem',
                                                                    xs: '0.9rem',
                                                                    sm: '1rem',
                                                                }}
                                                                fontWeight="bold"
                                                                lineHeight="1.3"
                                                                minHeight="25px"
                                                            >
                                                                WEBCAM
                                                                {data.webcams &&
                                                                data.webcams
                                                                    .length > 1
                                                                    ? 'S'
                                                                    : ''}
                                                            </Text>
                                                        </Flex>
                                                        {!isPremium ? (
                                                            <Flex
                                                                width="100%"
                                                                height="100%"
                                                                marginLeft="auto"
                                                                marginRight="auto"
                                                                flexDirection="column"
                                                            >
                                                                <WebcamsContent
                                                                    data={data}
                                                                    tabIndex={
                                                                        tabIndex
                                                                    }
                                                                    setTabIndex={
                                                                        setTabIndex
                                                                    }
                                                                    showErrors={
                                                                        showErrors
                                                                    }
                                                                    aerodromesFullscreen={
                                                                        fullscreen
                                                                    }
                                                                    minimiseOnZoom={
                                                                        minimiseOnZoom
                                                                    }
                                                                    setMinimiseOnZoom={
                                                                        setMinimiseOnZoom
                                                                    }
                                                                />
                                                            </Flex>
                                                        ) : (
                                                            <Flex
                                                                width={
                                                                    fullscreen
                                                                        ? '90%'
                                                                        : '100%'
                                                                }
                                                                mt="30px"
                                                                marginLeft="auto"
                                                                marginRight="auto"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                            >
                                                                <Text
                                                                    width="100%"
                                                                    textAlign="center"
                                                                    fontWeight="400"
                                                                >
                                                                    Webcams are
                                                                    exclusively
                                                                    provided to{' '}
                                                                    {
                                                                        premiumBadge
                                                                    }{' '}
                                                                    users only
                                                                </Text>
                                                                <Flex mt="25px">
                                                                    <Button
                                                                        {...noFocus}
                                                                        display="inline-flex"
                                                                        justifyContent="space-evenly"
                                                                        alignItems="center"
                                                                        variant="solid"
                                                                        bg="brand.premium"
                                                                        color="#FFFFFF"
                                                                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                                                        borderRadius="10"
                                                                        pl="15px"
                                                                        pr="17px"
                                                                        height={{
                                                                            base: '36px',
                                                                            md: '32px',
                                                                        }}
                                                                        fontSize="0.8rem"
                                                                        fontWeight="600"
                                                                        _hover={{
                                                                            bg: 'brand.premiumHover',
                                                                        }}
                                                                        _active={{
                                                                            bg: 'brand.premiumHover',
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                mfaExpired
                                                                            ) {
                                                                                setShowResetMfaModal(
                                                                                    true
                                                                                )
                                                                            } else {
                                                                                setShowPricingTiersModal(
                                                                                    true
                                                                                )
                                                                                setSettingsMenuOpen(
                                                                                    false
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        {mfaExpired ? (
                                                                            <ExpiredIcon
                                                                                color="#FFFFFF"
                                                                                height="12px"
                                                                                width="12px"
                                                                            />
                                                                        ) : (
                                                                            <RiStarLine color="#FFFFFF" />
                                                                        )}
                                                                        {mfaExpired ? (
                                                                            <Text ml="3px">
                                                                                Your
                                                                                MFA
                                                                                requires
                                                                                reset
                                                                            </Text>
                                                                        ) : (
                                                                            <Text ml="3px">
                                                                                Upgrade
                                                                                to
                                                                                Premium
                                                                            </Text>
                                                                        )}
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                </TabPanel>
                                                <TabPanel
                                                    id="chartsPanel"
                                                    // {...tabSwipe}
                                                    {...tabPanelHeight}
                                                    style={{
                                                        transition:
                                                            'opacity .3s',
                                                    }}
                                                >
                                                    {!isPremium ? (
                                                        <Flex
                                                            width="100%"
                                                            height="100%"
                                                            flexDirection="column"
                                                        >
                                                            <ChartsDropdown
                                                                selectedAerodrome={
                                                                    feat
                                                                        .properties
                                                                        .designator
                                                                }
                                                            />
                                                        </Flex>
                                                    ) : (
                                                        <Flex
                                                            width={'100%'}
                                                            flexDirection="column"
                                                            alignItems="center"
                                                            mt={
                                                                showErrorToggle
                                                                    ? '50px'
                                                                    : '0'
                                                            }
                                                        >
                                                            <Flex
                                                                width={'100%'}
                                                                marginBottom="5px"
                                                                alignContent="flex-start"
                                                                justifyContent="flex-start"
                                                            >
                                                                <Text
                                                                    marginBottom={
                                                                        3
                                                                    }
                                                                    fontSize="1rem"
                                                                    fontWeight="bold"
                                                                >
                                                                    CHARTS
                                                                </Text>
                                                            </Flex>
                                                            <Text
                                                                mt="30px"
                                                                width="100%"
                                                                textAlign="center"
                                                                fontWeight="400"
                                                            >
                                                                Access to AIP
                                                                Charts within
                                                                PreFlight is
                                                                exclusively
                                                                provided to{' '}
                                                                {premiumBadge}{' '}
                                                                users only
                                                            </Text>
                                                            <Flex mt="25px">
                                                                <Button
                                                                    {...noFocus}
                                                                    display="inline-flex"
                                                                    justifyContent="space-evenly"
                                                                    alignItems="center"
                                                                    variant="solid"
                                                                    bg="brand.premium"
                                                                    color="#FFFFFF"
                                                                    textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                                                    borderRadius="10"
                                                                    pl="15px"
                                                                    pr="17px"
                                                                    height={{
                                                                        base: '36px',
                                                                        md: '32px',
                                                                    }}
                                                                    fontSize="0.8rem"
                                                                    fontWeight="600"
                                                                    _hover={{
                                                                        bg: 'brand.premiumHover',
                                                                    }}
                                                                    _active={{
                                                                        bg: 'brand.premiumHover',
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            mfaExpired
                                                                        ) {
                                                                            setShowResetMfaModal(
                                                                                true
                                                                            )
                                                                        } else {
                                                                            setShowPricingTiersModal(
                                                                                true
                                                                            )
                                                                            setSettingsMenuOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    {mfaExpired ? (
                                                                        <ExpiredIcon
                                                                            color="#FFFFFF"
                                                                            height="12px"
                                                                            width="12px"
                                                                        />
                                                                    ) : (
                                                                        <RiStarLine color="#FFFFFF" />
                                                                    )}
                                                                    {mfaExpired ? (
                                                                        <Text ml="3px">
                                                                            Your
                                                                            MFA
                                                                            requires
                                                                            reset
                                                                        </Text>
                                                                    ) : (
                                                                        <Text ml="3px">
                                                                            Upgrade
                                                                            to
                                                                            Premium
                                                                        </Text>
                                                                    )}
                                                                </Button>
                                                            </Flex>
                                                            <Flex
                                                                mt="60px"
                                                                width="100%"
                                                                color="gray.400"
                                                                fontSize="0.8rem"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Text
                                                                    textAlign="center"
                                                                    fontWeight="400"
                                                                >
                                                                    Non-PREMIUM
                                                                    users can
                                                                    still access
                                                                    AIP Charts
                                                                    at
                                                                </Text>
                                                                <Text
                                                                    pl="4px"
                                                                    {...noFocus}
                                                                    fontWeight="600"
                                                                    // target="_blank"
                                                                    // _hover={{
                                                                    //     color: 'light.200',
                                                                    // }}
                                                                    // href="http://www.aip.net.nz"
                                                                >
                                                                    aip.net.nz
                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                    )}
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    )}
                                </Box>
                            )}
                        </DrawerBody>
                    </DrawerContent>
                )}
            </Drawer>
        </LightMode>
    )
}
