import React from 'react'
import OfflineModal from './OfflineModal'
import LayerError from './LayerError'
import Status from './Status'
import MapGLLostModal from './MapGLLostModal'

export default function Alerts() {
    return (
        <>
            <Status />
            <OfflineModal />
            <LayerError />
            <MapGLLostModal />
        </>
    )
}
