import { useEffect, useState, useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    addDays,
    subDays,
    subHours,
    isPast,
    intervalToDuration,
} from 'date-fns'
import useRoles, { useUserMetaData } from '../../auth/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import {
    mfaHandlerState,
    globalTimeState,
    userProfileState,
    mfaVerifiedState,
    modalVisibilityState,
} from '../../globalState'

// Check if MFA Bypass Role has been assigned to the User
export const BypassMFACheck = () => {
    const userRoles = useRoles()
    return userRoles.includes('Bypass MFA') || userRoles.length === 0
}

// Check when MFA was last verified (either in current session or else previously saved to usermetaData)
// will trigger MFA Reset modal to open as soon as MFA time expires
export const MfaLastVerified = () => {
    const userMetaData = useUserMetaData()
    const profileData = useRecoilValue(userProfileState)
    // *** MFA CHECK *** // if mfa_last_verified within the last 14 days
    const lastVerifiedDate =
        userMetaData?.mfa_last_verified &&
        new Date(userMetaData.mfa_last_verified)
    const previouslyVerified =
        profileData?.mfaLastVerified && new Date(profileData.mfaLastVerified)
    return (
        (lastVerifiedDate ? lastVerifiedDate : previouslyVerified) >
        subDays(Date.now(), 14)
    )
}

// Check if MFA has been previously verified (either in current session or else previously saved to usermetaData)
export const MfaPreviouslyVerified = () => {
    const userMetaData = useUserMetaData()
    const profileData = useRecoilValue(userProfileState)

    const previouslyVerified =
        profileData && profileData?.mfaLastVerified
            ? profileData.mfaLastVerified
            : userMetaData && userMetaData?.mfa_last_verified
            ? userMetaData.mfa_last_verified
            : null

    return previouslyVerified
}

// Check if MFA is currently active (either in current session or within last 1 hour)
export const MfaActive = () => {
    const userMetaData = useUserMetaData()
    const profileData = useRecoilValue(userProfileState)
    const lastVerifiedDate =
        userMetaData?.mfa_last_verified &&
        new Date(userMetaData.mfa_last_verified)
    const recentlyActiveVerified = lastVerifiedDate > subHours(Date.now(), 1) // if mfa_last_verified within the last 1 hour

    const activeMfa =
        (profileData && Boolean(profileData?.mfa_verified_active)) ||
        recentlyActiveVerified

    return activeMfa
}

export default function MfaHandler() {
    const userMetaData = useUserMetaData()
    const { isLoading } = useAuth0()
    const updateMfaState = useSetRecoilState(mfaHandlerState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const profileData = useRecoilValue(userProfileState)
    const currentTime = useRecoilValue(globalTimeState)
    const modalVisibility = useRecoilValue(modalVisibilityState)

    // *** MFA CHECK ***

    const expiryDays = 14 /* CONFIG THIS OPTION TO ADD DAYS */

    const recentlyVerified = MfaLastVerified()
    const bypassMFA = BypassMFACheck()
    const activeMfa = MfaActive()
    const [mfaExpiryTime, setMfaExpiryTime] = useState(null)
    const [resetRecently, setResetRecently] = useState(false)

    const expiryTime = useCallback(
        () => {
            let expiryTimestamp
            if (profileData && profileData?.mfaLastVerified) {
                expiryTimestamp = addDays(
                    profileData.mfaLastVerified,
                    expiryDays
                )
            } else if (userMetaData && userMetaData?.mfa_last_verified) {
                expiryTimestamp = addDays(
                    userMetaData.mfa_last_verified,
                    expiryDays
                )
            } else {
                expiryTimestamp = addDays(currentTime, expiryDays)
            }
            return expiryTimestamp
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileData, userMetaData, expiryDays]
    )

    useEffect(() => {
        if (activeMfa) {
            setResetRecently(activeMfa)
        }
    }, [activeMfa])

    useEffect(() => {
        if (!isLoading && !bypassMFA && !resetRecently) {
            const newMfaExpiryTime = expiryTime()
            setMfaExpiryTime(newMfaExpiryTime)
        }
    }, [
        isLoading,
        bypassMFA,
        resetRecently,
        profileData,
        userMetaData,
        expiryDays,
        modalVisibility.resetMfa,
        expiryTime,
    ])

    const mfaExpired = isPast(mfaExpiryTime)

    const mfaTimeDifference =
        userMetaData &&
        mfaExpiryTime &&
        intervalToDuration({
            start: currentTime,
            end: mfaExpiryTime,
        })
    const daysRemaining = mfaTimeDifference && mfaTimeDifference?.days
    const mfaTimeToExpire =
        mfaTimeDifference && daysRemaining < 1 // = 1 day or less
            ? 'WARNING'
            : mfaTimeDifference && daysRemaining >= 1 && daysRemaining < 2 // = 1 to 2 days (includes 1 day and up to 23:59 hours)
            ? 'CAUTION'
            : 'NONE'

    useEffect(() => {
        if (bypassMFA && !isLoading) {
            updateMfaState({
                state: 'BYPASSED',
                days: null,
            })
        } else if (!bypassMFA && !isLoading) {
            // console.log('Verifying MFA status')
            if (mfaExpired || !recentlyVerified) {
                updateMfaState({
                    state: 'EXPIRED',
                    days: 0,
                })
            }
            if (
                recentlyVerified &&
                profileData &&
                userMetaData &&
                Boolean(daysRemaining === 0)
            ) {
                updateMfaState({
                    state: 'WARNING',
                    days: 0,
                })
            }
            if (
                (mfaVerified || recentlyVerified) &&
                profileData &&
                userMetaData &&
                daysRemaining
            ) {
                updateMfaState({
                    state: mfaTimeToExpire,
                    days: daysRemaining,
                })
            }
        }
    }, [
        isLoading,
        bypassMFA,
        mfaVerified,
        mfaExpired,
        profileData,
        userMetaData,
        mfaTimeToExpire,
        daysRemaining,
        recentlyVerified,
        updateMfaState,
    ])

    // const mfaState = useRecoilValue(mfaHandlerState)
    // useEffect(() => {
    //     // console.log(bypassMFA, 'bypassMFA')
    //     // console.log(mfaState, 'mfaState')
    //     // console.log(mfaExpiryTime, 'recentlyVerified')
    //     // console.log(profileData, 'profileData')
    //     // console.log(userMetaData, 'userMetaData')
    // }, [bypassMFA, recentlyVerified, mfaState, profileData, userMetaData])

    return null
}
