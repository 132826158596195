import React from 'react'
import { useRecoilValue } from 'recoil'
import { useTier } from '../../../auth/hooks'
import SigmetLayer from '../met/SIGMET'
import SigWxLayer from '../met/SIGWX'
import NotamLayer from './Notam'
import RpasLayer from './Rpas'
import AdvisoriesList from '../met/advisoriesList'
import AdvisoriesMetaData from '../met/components/AdvisoriesMetaData'
import {
    dataModeState,
    layerSelectionState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../globalState'

export default function AdvsLayers() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    if (dataMode === 'advs') {
        return (
            <>
                {layerSelection.advs.sigmet && !isPremium && <SigmetLayer />}
                {layerSelection.advs.sigwx && !isPremium && <SigWxLayer />}
                {layerSelection.advs.notam && !isPremium && <NotamLayer />}
                {layerSelection.advs.rpas && !isPremium && <RpasLayer />}
                {(layerSelection.advs.sigmet || layerSelection.advs.sigwx) &&
                    !isPremium && <AdvisoriesList />}
                {layerSelection.advs.sigwx && !isPremium && (
                    <AdvisoriesMetaData />
                )}
            </>
        )
    } else {
        return null
    }
}
