import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { MapContext } from 'react-mapbox-gl'
import * as turf from '@turf/turf'
import { Text, Flex, Icon, useBreakpointValue } from '@chakra-ui/react'
import { parseISO, formatISO } from 'date-fns'
import { isFirefox, isDesktop } from 'react-device-detect'
import { ReactComponent as turbulence } from '../../../../../icons/assets/turbulence-symbol-moderate.svg'
import { ReactComponent as wind } from '../../../../../icons/assets/wind.svg'
import { ReactComponent as mountainWaves } from '../../../../../icons/assets/mountain-waves-moderate.svg'
import { ReactComponent as mountainWavesSymbol } from '../../../../../icons/assets/mountain-waves-symbol-moderate.svg'
import { ReactComponent as icing } from '../../../../../icons/assets/icing-moderate.svg'
import { ReactComponent as ice } from '../../../../../icons/assets/ice.svg'
import { ReactComponent as cumulonimbus } from '../../../../../icons/assets/cumulonimbus.svg'
import { ReactComponent as cumulonimbusSymbol } from '../../../../../icons/assets/cumulonimbus-symbol.svg'
import { ReactComponent as vaa } from '../../../../../icons/assets/vaa-symbol.svg'
import { ReactComponent as vac } from '../../../../../icons/assets/vac-symbol.svg'
import { ReactComponent as volcano } from '../../../../../icons/assets/vaa.svg'
import { ReactComponent as radiationSymbol } from '../../../../../icons/assets/radiation-symbol.svg'
import { ReactComponent as DirectionArrow } from '../../../../../icons/assets/direction-arrow.svg'

import {
    statusData,
    sigWxDataState,
    selectedTimeState,
    changeLayerStatusState,
    layerErrorHandler,
} from '../../../../../globalState'

export default function SigwxList(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        expanded,
        dataLoading,
        setDataLoading,
        dataLoadingError,
        setDataLoadingError,
        lowerAltitude,
        upperAltitude,
        setSigwxCount,
        selectedList,
        advsMode,
    } = props
    const [sigWxData, setSigWxData] = useRecoilState(sigWxDataState)
    const changeLayerStatus = useSetRecoilState(changeLayerStatusState)
    const setLayerError = useSetRecoilState(layerErrorHandler)
    const status = useRecoilValue(statusData)
    const [data, setData] = useState(null)
    const mapInstance = useContext(MapContext)
    const [flyTo, setFlyTo] = useState(null)
    const [mapCenter, setMapCenter] = useState(null)

    const selectedTime = useRecoilValue(selectedTimeState)

    const fetchSigwx = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/sigwx`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (status.sigwx.version !== sigWxData.version) {
            fetchSigwx()
                .then((r) => {
                    setDataLoading(false)
                    setData(r)
                    console.log('Updating SIGWX data')
                    // console.log(sigwxData, 'SIGWX Data')
                })
                .catch((error) => {
                    console.log(error)
                    changeLayerStatus({
                        sigwxLoading: false,
                        sigwxError: true,
                        sigwxMounted: false,
                    })
                    setLayerError({
                        layer: 'sigwx',
                        type: 'fetching',
                    })
                    setDataLoadingError(true)
                    setDataLoading(false)
                })
        }
    }, [
        status,
        setDataLoadingError,
        setDataLoading,
        fetchSigwx,
        changeLayerStatus,
        setLayerError,
        sigWxData,
    ])

    // GET VIEWPORT COORDINATES
    const getMapViewBounds = (mapInstance) => {
        const bounds = mapInstance.getBounds()
        const mapSW = bounds._sw
        const mapNE = bounds._ne
        const mapNW = bounds.getNorthWest()
        const mapSE = bounds.getSouthEast()
        const mapNWend = bounds.getNorthWest()

        return [
            [mapNW.lng, mapNW.lat],
            [mapNE.lng, mapNE.lat],
            [mapSE.lng, mapSE.lat],
            [mapSW.lng, mapSW.lat],
            [mapNWend.lng, mapNWend.lat],
        ]
    }
    var viewportDimensions = getMapViewBounds(mapInstance)
    const viewport = turf.polygon([viewportDimensions])

    // CENTER FLYTO
    const mapAlign = useBreakpointValue({
        base: [0, 75],
        md: [75, 0],
    })

    const featPadding = useBreakpointValue({
        base: 25,
        md: 150,
    })

    useEffect(() => {
        if (data) {
            const time = new Date(selectedTime)
            const filteredData =
                data &&
                data[0] &&
                data[0].metadata &&
                data
                    .filter(
                        (featureCollection) =>
                            parseISO(
                                featureCollection.metadata.validFromTime
                            ) <= time &&
                            parseISO(featureCollection.metadata.validToTime) >=
                                time
                    )
                    .map((featureCollection) => ({
                        ...featureCollection,
                        features:
                            featureCollection.features &&
                            featureCollection.features.length
                                ? featureCollection.features.map((feat) => ({
                                      ...feat,
                                      properties: {
                                          ...feat.properties,
                                          center:
                                              feat.geometry &&
                                              feat.geometry.coordinates &&
                                              turf.centerOfMass(feat).geometry
                                                  .coordinates,
                                      },
                                  }))
                                : [],
                    }))

            if (
                filteredData &&
                !filteredData.length &&
                sigWxData.id !== 'default'
            ) {
                setSigWxData({
                    id: 'default',
                    type: 'FeatureCollection',
                    features: [],
                })
            }

            if (
                filteredData &&
                filteredData.length &&
                sigWxData.id !== filteredData[0].id
            ) {
                setSigWxData(filteredData[0])
            }
        }
    }, [data, setSigWxData, selectedTime, sigWxData])

    // on dismount, reset sigWxData
    useEffect(() => {
        return setSigWxData({
            id: 'default',
            type: 'FeatureCollection',
            features: [],
        })
    }, [setSigWxData])

    useEffect(() => {
        if (sigWxData && sigWxData.features && sigWxData.id !== 'default') {
            setSigwxCount(sigWxData.features.length)
        } else {
            setSigwxCount(0)
        }
    }, [sigWxData, setSigwxCount])

    // FLY TO
    useEffect(() => {
        if (flyTo) {
            if (flyTo?.geometry?.type === 'Point') {
                mapInstance.flyTo({
                    center: flyTo?.geometry?.coordinates,
                    zoom: 13,
                })
            } else {
                mapInstance.fitBounds(turf.bbox(flyTo), {
                    offset: mapAlign,
                    padding: featPadding,
                })
            }
        }
        setFlyTo(null)
    }, [flyTo, mapInstance, mapAlign, featPadding])

    useEffect(() => {
        if (!mapCenter) {
            const { lng, lat } = mapInstance.getCenter()
            setMapCenter([lng, lat])
        }
        const handleMove = (e) => {
            const { lng, lat } = e.target.getCenter()
            setMapCenter([lng, lat])
        }
        mapInstance.on('moveend', handleMove)
        return () => {
            mapInstance.off('moveend', handleMove)
        }
    }, [mapInstance, mapCenter, setMapCenter])

    const frequencyFormatter = (freq) => {
        const lookup = {
            isolatedEmbedded: 'Isolated Embedded',
            occasionalEmbedded: 'Occasional Embedded',
            occasional: 'Occasional',
        }
        if (lookup[freq]) {
            return lookup[freq]
        }
        return freq
    }

    const metaData = sigWxData && sigWxData?.metadata
    const sigwxValidTo =
        metaData?.validToTime && formatISO(parseISO(metaData.validToTime))
    const formattedSelectedTime = selectedTime && formatISO(selectedTime)
    const selectedTimePastValidTo = formattedSelectedTime > sigwxValidTo

    // useEffect(() => {
    //     console.log('Data:', data)
    //     console.log('SIGWX:', sigWxData)
    //     // console.log('flyTo:', flyTo)
    // }, [data, sigWxData, flyTo])

    if (
        !dataLoading &&
        !dataLoadingError &&
        mapCenter &&
        sigWxData &&
        sigWxData.features &&
        sigWxData.features.length
    ) {
        return (
            <>
                {!dataLoading &&
                    !dataLoadingError &&
                    mapCenter &&
                    sigWxData &&
                    sigWxData.features &&
                    sigWxData.features.length &&
                    [...sigWxData.features]
                        .sort((a, b) => {
                            const sigwxCenterA = a.properties.center[1]
                            const sigwxCenterB = b.properties.center[1]
                            if (sigwxCenterA < sigwxCenterB) {
                                return 1
                            }
                            if (sigwxCenterA > sigwxCenterB) {
                                return -1
                            } else {
                                return 0
                            }
                        })
                        .map((feat, i) => {
                            const hasVAEruptionSigmet = Boolean(
                                feat?.properties?.alerts &&
                                    JSON.parse(feat?.properties?.alerts)
                                        .length &&
                                    JSON.parse(feat?.properties?.alerts).some(
                                        (value) =>
                                            value.startsWith(
                                                'VA SIGMET in force'
                                            )
                                    )
                            )
                            const SIGWXConfig = {
                                clearAirTurbulence: {
                                    title: 'MODERATE CAT',
                                    icon: turbulence,
                                    watermark: wind,
                                    colour: '#ffcc00',
                                },
                                turbulence: {
                                    title: 'MODERATE TURBULENCE',
                                    icon: turbulence,
                                    watermark: wind,
                                    colour: '#e76f6f',
                                },
                                icing: {
                                    title: 'MODERATE ICING',
                                    icon: icing,
                                    watermark: ice,
                                    colour: '#58ccf1',
                                },
                                mountainWaves: {
                                    title: 'MOUNTAIN WAVES',
                                    icon: mountainWavesSymbol,
                                    watermark: mountainWaves,
                                    colour: '#152238',
                                },
                                cumulonimbus: {
                                    title:
                                        (feat.properties.frequency
                                            ? `${frequencyFormatter(
                                                  feat.properties.frequency
                                              )} `
                                            : '') + 'Cumulonimbus',
                                    icon: cumulonimbusSymbol,
                                    watermark: cumulonimbus,
                                    colour: '#008800',
                                },
                                volcano: {
                                    title: `VOLCANIC ALERT ${
                                        feat?.properties?.alertlevel
                                            ? 'LEVEL ' +
                                              feat.properties.alertlevel
                                            : ''
                                    }`,
                                    icon: hasVAEruptionSigmet ? vac : vaa,
                                    watermark: volcano,
                                    colour: '#eb6aeb',
                                    alertlevel: feat?.properties?.alertlevel
                                        ? feat.properties.alertlevel
                                        : '',
                                },
                                radiation: {
                                    title: 'RADIATION',
                                    icon: radiationSymbol,
                                    watermark: radiationSymbol,
                                    colour: '#9fd923',
                                    radiationcomment: null, // To be updated if new data field added
                                    alertlevel: feat?.properties?.alertlevel
                                        ? feat.properties.alertlevel
                                        : '',
                                },
                            }
                            const config = SIGWXConfig[feat.properties.type]

                            //* measure distance from center of screen to center of sigwx
                            var viewportCenter = turf.point(mapCenter)
                            var sigwxCenter =
                                feat.properties.center &&
                                turf.point(feat.properties.center)
                            var bearing =
                                (Math.round(
                                    turf.bearing(viewportCenter, sigwxCenter)
                                ) +
                                    360) %
                                360

                            //* display distance
                            var options = { units: 'miles' }
                            var distance = Math.round(
                                turf.distance(
                                    viewportCenter,
                                    sigwxCenter,
                                    options
                                ) * 0.86898 // conversion number to convert miles to NM
                            )

                            //* see if sigwx overlaps the viewport edges (partially in view)
                            var sigwxPolygon =
                                feat.geometry.type === 'MultiPolygon' &&
                                feat.geometry.coordinates[0].length > 0
                                    ? turf.polygon([
                                          feat.geometry.coordinates[0][0],
                                      ])
                                    : null
                            var overlap =
                                feat.geometry.type === 'MultiPolygon' &&
                                feat.geometry.coordinates[0].length > 0
                                    ? turf.booleanOverlap(
                                          viewport,
                                          sigwxPolygon
                                      )
                                    : null

                            //* see if sigwx center sits within the viewport edges (fully in view)
                            var withinViewport = turf.booleanPointInPolygon(
                                sigwxCenter,
                                viewport
                            )
                            return (
                                <Flex
                                    key={`${feat.properties.id}-${i}`}
                                    display={
                                        advsMode && selectedList === 'sigmet'
                                            ? 'none'
                                            : 'flex'
                                    }
                                    bg={
                                        isFirefox
                                            ? 'rgba(255,255,255,0.9)'
                                            : 'rgba(255,255,255,0.75)'
                                    }
                                    backdropFilter="blur(5px)"
                                    _hover={{
                                        opacity: '1',
                                        cursor: 'pointer',
                                        background: isFirefox
                                            ? 'rgba(255,255,255,1)'
                                            : 'rgba(255,255,255,0.9)',
                                    }}
                                    onClick={() => {
                                        if (
                                            !(
                                                lowerAltitude >
                                                    feat.properties
                                                        .upperfilter ||
                                                upperAltitude <
                                                    feat.properties.lowerfilter
                                            )
                                        ) {
                                            setFlyTo(feat)
                                        }
                                    }}
                                    opacity={
                                        lowerAltitude >
                                            feat.properties.upperfilter ||
                                        upperAltitude <
                                            feat.properties.lowerfilter
                                            ? '0.5' // if selected altitude range is outside sigwx levels
                                            : !overlap && !withinViewport
                                            ? '0.85' // if sigwx is not within viewport
                                            : '1'
                                    }
                                    borderRadius="20px"
                                    pl={expanded ? '5px' : '0'}
                                    pr={expanded ? '9px' : '0'}
                                    transition="all ease 200ms"
                                    mt={{ base: '0px', md: '0px' }}
                                    mb={{
                                        base: '10px',
                                        md:
                                            isDesktop &&
                                            i + 1 === sigWxData.features.length
                                                ? '3px'
                                                : '10px',
                                    }}
                                    flexDirection="column"
                                >
                                    <Flex
                                        my={expanded ? '3px' : '0'}
                                        width="100%"
                                        flexDirection="row"
                                        minHeight="32px"
                                    >
                                        <Flex alignItems="center">
                                            {config && (
                                                <Icon
                                                    bg={
                                                        (overlap ||
                                                            withinViewport) &&
                                                        upperAltitude >=
                                                            feat.properties
                                                                .lowerfilter &&
                                                        lowerAltitude <=
                                                            feat.properties
                                                                .upperfilter
                                                            ? config.colour
                                                            : 'transparent'
                                                    }
                                                    color={
                                                        (overlap ||
                                                            withinViewport) &&
                                                        upperAltitude >=
                                                            feat.properties
                                                                .lowerfilter &&
                                                        lowerAltitude <=
                                                            feat.properties
                                                                .upperfilter
                                                            ? 'white'
                                                            : 'light.100'
                                                    }
                                                    borderRadius="100%"
                                                    px="2px"
                                                    pb={
                                                        feat?.properties
                                                            ?.type ===
                                                            'volcano' &&
                                                        hasVAEruptionSigmet
                                                            ? '1px'
                                                            : '0px'
                                                    }
                                                    boxSize="28px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    as={config.icon}
                                                />
                                            )}
                                            {feat?.properties?.type ===
                                                'volcano' &&
                                                feat?.properties
                                                    ?.alertlevel && ( //  *** ALERT LEVEL TEXT FOR VAA ***
                                                    <Flex
                                                        position="absolute"
                                                        width="28px"
                                                        justifyContent="center"
                                                        color={
                                                            (overlap ||
                                                                withinViewport) &&
                                                            upperAltitude >=
                                                                feat.properties
                                                                    .lowerfilter &&
                                                            lowerAltitude <=
                                                                feat.properties
                                                                    .upperfilter
                                                                ? 'white'
                                                                : 'light.100'
                                                        }
                                                    >
                                                        {config && (
                                                            <Text
                                                                mt={
                                                                    hasVAEruptionSigmet
                                                                        ? '5px'
                                                                        : '0px'
                                                                }
                                                                pb={
                                                                    hasVAEruptionSigmet
                                                                        ? '0px'
                                                                        : '1px'
                                                                }
                                                                lineHeight="1.4"
                                                                fontSize="0.55rem"
                                                                fontWeight="600"
                                                                letterSpacing="-1px"
                                                            >
                                                                {
                                                                    config.alertlevel
                                                                }
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                )}
                                        </Flex>
                                        <Flex
                                            pl="7px"
                                            lineHeight="1.5"
                                            width="100%"
                                            py={expanded ? '1px' : '0'}
                                            flexDirection="column"
                                            color="light.100"
                                            alignItems="center"
                                        >
                                            <Flex
                                                width="100%"
                                                height="100%"
                                                flexDirection="row"
                                                alignItems="flex-start"
                                                justifyContent="space-between"
                                                // borderBottom="1px solid rgba(0,0,0,0.05)"
                                            >
                                                {config && (
                                                    <Text
                                                        whiteSpace="pre-line"
                                                        fontSize="10px"
                                                        fontWeight="700"
                                                        lineHeight="1.3"
                                                        pt="1px"
                                                        textTransform="uppercase"
                                                    >
                                                        {config.title}
                                                    </Text>
                                                )}

                                                <Flex
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    color={'light.100'}
                                                >
                                                    {!overlap &&
                                                        !withinViewport && (
                                                            <>
                                                                <Text
                                                                    fontSize="10px"
                                                                    fontWeight="700"
                                                                    marginRight="5px"
                                                                    whiteSpace="pre-line"
                                                                >
                                                                    {distance}
                                                                    NM
                                                                    {/* {mapInstance
                                                    .getBounds()
                                                    .contains({
                                                        lon: feat.properties
                                                            .center[0],
                                                        lat: feat.properties
                                                            .center[1],
                                                    })
                                                    ? 'true'
                                                    : 'false'} */}
                                                                </Text>
                                                                <Icon
                                                                    boxSize="14px"
                                                                    transform={`rotate(${bearing}deg)`}
                                                                    as={
                                                                        DirectionArrow
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    {(overlap ||
                                                        withinViewport) && (
                                                        <Text
                                                            fontSize="10px"
                                                            fontWeight="500"
                                                            whiteSpace="pre-line"
                                                        >
                                                            {/* {configObsfcst.title} */}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                height="100%"
                                                width="100%"
                                                fontSize="10px"
                                                fontWeight="400"
                                                minHeight="15px"
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Flex flexDirection="row">
                                                    {feat.properties.type ===
                                                        'volcano' &&
                                                    feat.properties.volcano ? (
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .volcano
                                                            }
                                                        </Text>
                                                    ) : feat.properties.type ===
                                                      'radiation' ? (
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                config.radiationcomment
                                                            }
                                                        </Text>
                                                    ) : feat.properties
                                                          .level ? (
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .level
                                                            }
                                                        </Text>
                                                    ) : (
                                                        feat.properties
                                                            .lowerlabel &&
                                                        feat.properties
                                                            .upperlabel && (
                                                            <Flex
                                                                flexDirection="row"
                                                                fontWeight={
                                                                    lowerAltitude >
                                                                        feat
                                                                            .properties
                                                                            .upperfilter ||
                                                                    upperAltitude <
                                                                        feat
                                                                            .properties
                                                                            .lowerfilter
                                                                        ? '600'
                                                                        : '400'
                                                                }
                                                                color={
                                                                    (lowerAltitude >
                                                                        feat
                                                                            .properties
                                                                            .upperfilter ||
                                                                        upperAltitude <
                                                                            feat
                                                                                .properties
                                                                                .lowerfilter) &&
                                                                    (overlap ||
                                                                        withinViewport)
                                                                        ? '#c44d4d'
                                                                        : 'light.100'
                                                                }
                                                            >
                                                                {lowerAltitude >
                                                                    feat
                                                                        .properties
                                                                        .upperfilter ||
                                                                upperAltitude <
                                                                    feat
                                                                        .properties
                                                                        .lowerfilter ? (
                                                                    <Text>
                                                                        Outside
                                                                        altitude
                                                                        filter
                                                                        range
                                                                    </Text>
                                                                ) : (
                                                                    <>
                                                                        <Text whiteSpace="pre-line">
                                                                            {feat
                                                                                .properties
                                                                                .lowerlabel &&
                                                                                `${
                                                                                    feat
                                                                                        .properties
                                                                                        .lowerlabel ===
                                                                                    'XXX'
                                                                                        ? 'XXX'
                                                                                        : feat.properties.lowerlabel.substring(
                                                                                              0,
                                                                                              2
                                                                                          ) ===
                                                                                              'FL' ||
                                                                                          feat
                                                                                              .properties
                                                                                              .lowerlabel ===
                                                                                              'SFC'
                                                                                        ? feat
                                                                                              .properties
                                                                                              .lowerlabel
                                                                                        : feat
                                                                                              .properties
                                                                                              .lowerlabel +
                                                                                          ' ft'
                                                                                }`}
                                                                        </Text>
                                                                        <Text px="3px">
                                                                            –
                                                                        </Text>
                                                                        <Text whiteSpace="pre-line">
                                                                            {feat
                                                                                .properties
                                                                                .upperlabel &&
                                                                                `${
                                                                                    feat
                                                                                        .properties
                                                                                        .upperlabel ===
                                                                                    'XXX'
                                                                                        ? 'XXX'
                                                                                        : feat.properties.upperlabel.substring(
                                                                                              0,
                                                                                              2
                                                                                          ) ===
                                                                                          'FL'
                                                                                        ? feat
                                                                                              .properties
                                                                                              .upperlabel
                                                                                        : feat
                                                                                              .properties
                                                                                              .upperlabel +
                                                                                          ' ft'
                                                                                }`}
                                                                        </Text>
                                                                    </>
                                                                )}
                                                            </Flex>
                                                        )
                                                    )}
                                                </Flex>
                                                <Flex flexDirection="row">
                                                    <Text whiteSpace="pre-line">
                                                        {/* {feat.properties.movement} */}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            height="auto"
                                            width="36px"
                                            minWidth="36px"
                                            overflow="hidden"
                                            mr="-3px"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                        >
                                            {config && (
                                                <Icon
                                                    sx={{
                                                        transform:
                                                            feat.properties
                                                                .type ===
                                                            'radiation'
                                                                ? 'scale(1.4)'
                                                                : 'scale(1.2)',
                                                    }}
                                                    color="rgba(20,25,30, 0.07)"
                                                    py="2px"
                                                    boxSize="32px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    as={config.watermark}
                                                />
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        })}
            </>
        )
    } else {
        return (
            <Flex // NO DATA
                display={
                    advsMode && selectedList === 'sigmet' ? 'none' : 'flex'
                }
                width="100%"
                justifyContent="center"
                alignItems="center"
                px="5px"
                h={{ base: '38px', md: '38px' }}
                borderRadius="20px"
                backdropFilter={expanded ? 'blur(10px)' : 'none'}
                background="rgba(255,255,255,0.1)"
                textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                border="1px solid"
                borderColor="rgba(255,255,255,0.7)"
                color="white"
                zIndex="1"
                fontWeight="500"
                fontSize={{ base: '0.7rem', xs: '0.75rem', md: '0.8rem' }}
                whiteSpace="pre-line"
                textAlign="center"
                lineHeight="1.2"
            >
                {selectedTimePastValidTo ? (
                    <Text>SIGWX forecast does not cover the selected time</Text>
                ) : sigWxData &&
                  ((sigWxData.features && sigWxData.features.length === 0) ||
                      sigWxData.metadata) ? (
                    <Text>No SIGWX forecasts issued for selected time</Text>
                ) : (
                    <Text>NO SIGWX information received</Text>
                )}
            </Flex>
        )
    }
}
