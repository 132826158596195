import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Icon, Portal } from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { orientationState, isBriefingOnlyState } from '../globalState'
import { isFirefox, isMobileOnly, isIOS } from 'react-device-detect'

import { ReactComponent as CautionIcon } from '../icons/assets/caution.svg'

export default function RotationWarning() {
    const [orientation, setOrientation] = useState(
        window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape'
    )
    const [show, setShow] = useState(false)
    const setRotationWarning = useSetRecoilState(orientationState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const startTimer = useRef(null)
    useEffect(() => {
        if (orientation === 'Portrait') {
            setRotationWarning('Portrait')
        }
        if (orientation === 'Landscape') {
            setRotationWarning('Landscape')
        }
    }, [orientation, setRotationWarning])

    const screenWidthSmall = window.innerWidth < 768

    // // Trigger to detect when screen orientation has changed
    useEffect(() => {
        const handleOrientation = (e) => {
            if (e.matches) {
                setOrientation('Portrait')
                setShow(false)
            } else {
                setOrientation('Landscape')
                setShow(true)
                startTimer.current = setTimeout(() => {
                    setShow(false)
                }, 60000) // Set to disappear after 1 minute
            }
        }
        let portrait = window.matchMedia('(orientation: portrait)')
        portrait.addEventListener('change', handleOrientation)
        return () => {
            portrait.removeEventListener('change', handleOrientation)
        }
    }, [])

    // console.log(orientation, 'orientation')
    // console.log(show, 'show')

    return (
        <>
            {isMobileOnly && !isBriefingOnly && (
                <Portal>
                    <Flex
                        width="100%"
                        minWidth="100vw"
                        alignItems="center"
                        justifyContent="center"
                        pl={screenWidthSmall ? '0px' : '50px'}
                    >
                        <Flex
                            opacity={
                                orientation === 'Landscape' && show ? '1' : '0'
                            }
                            visibility={
                                orientation === 'Landscape' && show
                                    ? 'visible'
                                    : 'hidden'
                            }
                            mt={
                                screenWidthSmall &&
                                orientation === 'Landscape' &&
                                show
                                    ? '45px'
                                    : orientation === 'Landscape' && show
                                    ? '15px'
                                    : '-5px'
                            }
                            transition="all ease-in-out 300ms"
                            // pointerEvents="none"
                            cursor="pointer"
                            position="relative"
                            width="max-content"
                            // transform="translate(-50%, 0%)"
                            zIndex="99999"
                            color="white"
                            borderRadius="20px"
                            backdropFilter="blur(2px)"
                            pl={4}
                            pr={6}
                            py={2}
                            bg={
                                isFirefox
                                    ? 'rgba(45, 62, 79, 0.8)'
                                    : 'rgba(45, 62, 79, 0.6)'
                            }
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => setShow(false)}
                        >
                            <Icon
                                as={CautionIcon}
                                width="36px"
                                height="36px"
                                color="#FFFFFF"
                                mr="10px"
                            />
                            <Flex flexDirection="column">
                                <Text
                                    fontWeight="600"
                                    fontSize="0.9rem"
                                    whiteSpace="pre"
                                >
                                    Please rotate your{' '}
                                    {isMobileOnly & isIOS ? 'iPhone' : 'device'}{' '}
                                    to portrait view
                                </Text>
                                <Text
                                    fontWeight="400"
                                    fontSize="0.8rem"
                                    whiteSpace="pre"
                                >
                                    PreFlight is best used in portrait on mobile
                                    devices
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Portal>
            )}
        </>
    )
}
