import React, { useRef, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    useBreakpointValue,
    Icon,
    Divider,
} from '@chakra-ui/react'
import useConfirm from '../../alerts/warning-message/components/useConfirm'
import { ManageSubscriptionMessage } from '../../alerts/warning-message/components/WarningMessages'
import { isDesktop } from 'react-device-detect'
import { ReactComponent as Question } from '../../../../icons/assets/question.svg'
import { ReactComponent as SubscriptionIcon } from '../../../../icons/assets/subscription.svg'
import { ReactComponent as PasswordIcon } from '../../../../icons/assets/padlock.svg'
import { ReactComponent as ExpiredIcon } from '../../../../icons/assets/expired.svg'
import { RiStarLine } from 'react-icons/ri'
import { setModalVisibilityState } from '../../../../globalState'

export default function ProfileTab(props) {
    const {
        userRoles,
        profileData,
        setProfileData,
        mfaTimeToExpire,
        daysRemaining,
        setShowResetMfaModal,
        userTier,
        activeMfa,
        disableSettings,
        previouslyVerified,
        mfaExpired,
        isPremium,
        getAccessTokenSilently,
        plusSubscriptionOnly,
    } = props

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const { isConfirmed } = useConfirm() // alert message handler

    const setShowWhyMFAModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    const premiumMfaBypassed =
        !isPremium &&
        userTier > 1 &&
        mfaTimeToExpire &&
        mfaTimeToExpire === 'BYPASSED'

    const mfaCurrent =
        mfaTimeToExpire !== 'EXPIRED' &&
        mfaTimeToExpire !== 'BYPASSED' &&
        mfaTimeToExpire !== 'WARNING' &&
        mfaTimeToExpire !== 'CAUTION' &&
        daysRemaining &&
        daysRemaining > 3

    const focus = {
        _focus: {
            borderColor: 'brand.200',
        },
    }
    const initialFocusRef = useRef()

    const popoverPlacement = useBreakpointValue({
        base: 'auto',
        lg: 'right-end',
    })
    const infoButtonSize = useBreakpointValue({
        base: '1.4rem',
        md: '1.3rem',
    })
    const iconSizeTab = useBreakpointValue({
        base: { width: '20px', height: '20px' },
        md: { width: '20px', height: '20px' },
    })
    const iconSizePassword = useBreakpointValue({
        base: { width: '18px', height: '18px' },
        md: { width: '18px', height: '18px' },
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const settingsButtonStyle = {
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'blue.600',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        bg: 'blue.600',
        fontSize: {
            base: '0.75rem',
            lg: '0.8rem',
        },
        opacity: {
            base: '0.9',
            md: '0.9',
        },
        fontWeight: '500',
        alignContent: 'center',
        color: 'light.10',
        height: {
            base: '32px',
            md: '32px',
        },
        py: '7px',
        pl: '13px',
        pr: '18px',
        borderRadius: '20px',
        transition: 'all ease 250ms',
        width: {
            base: 'auto',
            md: 'auto',
        },
        minWidth: {
            base: '186px',
            lg: '200px',
        },
        _hover: {
            opacity: '1',
            color: 'light.10',
            borderColor: 'blue.700',
            bg: 'blue.700',
        },
        _active: {
            opacity: '1',
            color: 'light.10',
            borderColor: 'blue.700',
            bg: 'blue.700',
        },
        _focus: {
            opacity: '1',
            color: 'light.10',
            borderColor: 'blue.700',
            bg: 'blue.700',
        },
    }

    return (
        <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            justifyContent="space-between"
            alignItems={{ base: 'center', xl: 'flex-start' }}
            width="100%"
            height={{
                base: 'auto',
                xl: '100%',
            }}
            padding="0px"
        >
            <Flex
                flexDirection="column"
                justifyContent="space-between"
                alignItems={{ base: 'center', xl: 'flex-start' }}
                width="100%"
                height="100%"
                minHeight={{
                    base: 'auto',
                    lg: activeMfa || premiumMfaBypassed ? '248px' : 'auto',
                    xl: '340px',
                }}
                padding="0px"
            >
                <Box // PROFILE FIELDS
                    width="100%"
                    height="100%"
                    p="20px"
                    pt={{
                        base: '30px',
                        md: '25px',
                    }}
                    pb={{
                        base: '25px',
                        xl: '25px',
                    }}
                >
                    <Flex // Prompt to encourage user to update their user name
                        visibility={
                            !profileData.firstName || !profileData.lastName
                                ? 'visible'
                                : 'hidden'
                        }
                        opacity={
                            !profileData.firstName || !profileData.lastName
                                ? '1'
                                : '0'
                        }
                        height={
                            !profileData.firstName || !profileData.lastName
                                ? '22px'
                                : '0'
                        }
                        pt={{
                            base:
                                !profileData.firstName || !profileData.lastName
                                    ? '38px'
                                    : '0',
                            md: '0',
                        }}
                        pb={{
                            base:
                                !profileData.firstName || !profileData.lastName
                                    ? '20px'
                                    : '0',
                            md: '0',
                        }}
                        transition="all ease-in-out 300ms"
                        justifyContent="center"
                        alignItems="flex-end"
                        color="light.200"
                        fontSize="0.9rem"
                        fontWeight="600"
                        lineHeight={{
                            base: '1.3',
                            md: '2.5',
                        }}
                        width="100%"
                        textAlign={{
                            base: 'center',
                            md: 'left',
                        }}
                        whiteSpace="pre"
                    >
                        PLEASE UPDATE YOUR PROFILE
                    </Flex>

                    <FormControl
                        isRequired
                        // isInvalid={isError}
                    >
                        <Flex // FIRST AND LAST NAME
                            justifyContent="space-evenly"
                            flexDirection={{
                                base: 'column',
                                lg: 'row',
                            }}
                        >
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="100%"
                                mr={{
                                    base: '0px',
                                    lg: '30px',
                                }}
                                onClick={() => {
                                    if (disableSettings) {
                                        setShowResetMfaModal(true)
                                    }
                                }}
                            >
                                <FormLabel
                                    htmlFor="first-name"
                                    fontSize="0.8rem"
                                    fontWeight="400"
                                    color="gray.500"
                                >
                                    First name
                                </FormLabel>
                                <Input
                                    {...focus}
                                    cursor={
                                        disableSettings
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    color={
                                        disableSettings
                                            ? 'gray.600'
                                            : 'light.900'
                                    }
                                    _placeholder={{
                                        color: disableSettings
                                            ? 'gray.400'
                                            : 'gray.500',
                                    }}
                                    fontSize="0.9rem"
                                    height="9"
                                    variant="primary"
                                    id="first-name"
                                    placeholder="First name"
                                    value={profileData.firstName}
                                    onChange={(e) => {
                                        if (!disableSettings) {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                ...{
                                                    firstName: e.target.value,
                                                },
                                            }))
                                        }
                                    }}
                                />
                            </Flex>
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="100%"
                                mt={{
                                    base: '15px',
                                    lg: '0px',
                                }}
                                onClick={() => {
                                    if (disableSettings) {
                                        setShowResetMfaModal(true)
                                    }
                                }}
                            >
                                <FormLabel
                                    htmlFor="last-name"
                                    fontSize="0.8rem"
                                    fontWeight="400"
                                    color="gray.500"
                                >
                                    Last name
                                </FormLabel>
                                <Input
                                    cursor={
                                        disableSettings
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    color={
                                        disableSettings
                                            ? 'gray.600'
                                            : 'light.900'
                                    }
                                    _placeholder={{
                                        color: disableSettings
                                            ? 'gray.400'
                                            : 'gray.500',
                                    }}
                                    variant="primary"
                                    {...focus}
                                    fontSize="0.9rem"
                                    height="9"
                                    id="last-name"
                                    placeholder="Last name"
                                    value={profileData.lastName}
                                    onChange={(e) => {
                                        if (!disableSettings) {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                ...{
                                                    lastName: e.target.value,
                                                },
                                            }))
                                        }
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </FormControl>
                    <FormControl>
                        <Flex // CAA ID and ORGANISATION
                            justifyContent="space-evenly"
                            flexDirection={{
                                base: 'column',
                                lg: 'row',
                            }}
                            mt={{
                                base: '15px',
                                lg: '15px',
                            }}
                            mb={{
                                base: '15px',
                                md: '0px',
                            }}
                            pb={{
                                base: '15px',
                                lg: '0px',
                            }}
                        >
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="100%"
                                mr={{
                                    base: '0px',
                                    lg: '30px',
                                }}
                                onClick={() => {
                                    if (disableSettings) {
                                        setShowResetMfaModal(true)
                                    }
                                }}
                            >
                                <FormLabel
                                    htmlFor="CAAID"
                                    fontSize="0.8rem"
                                    fontWeight="400"
                                    color="gray.500"
                                >
                                    CAA ID
                                </FormLabel>
                                <Input
                                    {...focus}
                                    cursor={
                                        disableSettings
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    color={
                                        disableSettings
                                            ? 'gray.600'
                                            : 'light.900'
                                    }
                                    _placeholder={{
                                        color: disableSettings
                                            ? 'gray.400'
                                            : 'gray.500',
                                    }}
                                    fontSize="0.9rem"
                                    height="9"
                                    variant="primary"
                                    id="caaid"
                                    placeholder="CAA ID Number"
                                    value={profileData.caaId}
                                    onChange={(e) => {
                                        if (!disableSettings) {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                ...{
                                                    caaId: e.target.value,
                                                },
                                            }))
                                        }
                                    }}
                                />
                            </Flex>
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="100%"
                                mt={{
                                    base: '15px',
                                    lg: '0px',
                                }}
                                onClick={() => {
                                    if (disableSettings) {
                                        setShowResetMfaModal(true)
                                    }
                                }}
                            >
                                <FormLabel
                                    htmlFor="Organisation"
                                    fontSize="0.8rem"
                                    fontWeight="400"
                                    color="gray.500"
                                >
                                    Organisation
                                </FormLabel>
                                <Input
                                    {...focus}
                                    cursor={
                                        disableSettings
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    color={
                                        disableSettings
                                            ? 'gray.600'
                                            : 'light.900'
                                    }
                                    _placeholder={{
                                        color: disableSettings
                                            ? 'gray.400'
                                            : 'gray.500',
                                    }}
                                    fontSize="0.9rem"
                                    height="9"
                                    variant="primary"
                                    id="organisation"
                                    placeholder="Organisation"
                                    value={profileData.organization}
                                    onChange={(e) => {
                                        if (!disableSettings) {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                ...{
                                                    organization:
                                                        e.target.value,
                                                },
                                            }))
                                        }
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </FormControl>
                </Box>

                {!activeMfa && !premiumMfaBypassed && (
                    <Flex
                        px={{
                            base: '15px',
                            md: '20px',
                        }}
                        justifyContent="center"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                        gap={{ base: '10px', md: '5px' }}
                        flexDirection={{ base: 'column', md: 'row' }}
                        borderTop="1px solid #eaeaea"
                        borderBottom={{
                            base: '1px solid #eaeaea',
                            xl: 'unset',
                        }}
                        pt={{
                            base: '30px',
                            lg: '25px',
                            xl: '20px',
                        }}
                        width="100%"
                        minHeight={{
                            base: 'auto',
                            md: '100px',
                            lg: '80px',
                            xl: '70px',
                        }}
                    >
                        <Tooltip
                            hasArrow
                            px="7px"
                            py="5px"
                            fontSize="0.7rem"
                            alignItems="center"
                            textAlign="center"
                            lineHeight="1.2"
                            display={isDesktop ? 'flex' : 'none'}
                            whiteSpace="pre-line"
                            label={`${
                                !previouslyVerified ? 'Set' : 'Reset'
                            } your Multi-factor Authentication`}
                            placement="top"
                            marginBottom="5px"
                            borderRadius="10px"
                            maxWidth="350px"
                            bgColor={'gray.500'}
                        >
                            <Button
                                variant="outline"
                                border="1px solid"
                                minHeight={{
                                    base: '36px',
                                    md: '28px',
                                }}
                                minWidth={
                                    !previouslyVerified ? '90px' : '106px'
                                }
                                height={{
                                    base: '36px',
                                    md: '28px',
                                }}
                                gap="3px"
                                bg={
                                    mfaTimeToExpire &&
                                    (mfaTimeToExpire === 'EXPIRED' ||
                                        mfaTimeToExpire === 'WARNING')
                                        ? '#FFF5F5'
                                        : mfaTimeToExpire &&
                                          mfaTimeToExpire === 'CAUTION'
                                        ? '#FFF5EB'
                                        : 'gray.100'
                                }
                                _hover={{
                                    bg: 'brand.premium',
                                    borderColor: 'brand.premium',
                                    color: 'light.10',
                                }}
                                _active={{
                                    bg: 'brand.premium',
                                    borderColor: 'brand.premium',
                                    color: 'light.10',
                                }}
                                boxShadow="none"
                                borderColor={
                                    mfaTimeToExpire &&
                                    (mfaTimeToExpire === 'EXPIRED' ||
                                        mfaTimeToExpire === 'WARNING')
                                        ? 'red.500'
                                        : mfaTimeToExpire &&
                                          mfaTimeToExpire === 'CAUTION'
                                        ? 'warning.100'
                                        : 'gray.300'
                                }
                                color={
                                    mfaTimeToExpire &&
                                    (mfaTimeToExpire === 'EXPIRED' ||
                                        mfaTimeToExpire === 'WARNING')
                                        ? 'red.500'
                                        : mfaTimeToExpire &&
                                          mfaTimeToExpire === 'CAUTION'
                                        ? 'warning.200'
                                        : 'gray.400'
                                }
                                transition="all ease 100ms"
                                borderRadius="20"
                                px="5px"
                                pb="2px"
                                pt="2px"
                                fontSize="0.75rem"
                                fontWeight="600"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                cursor="pointer"
                                onClick={() => {
                                    if (!activeMfa) {
                                        setShowResetMfaModal(true)
                                    }
                                }}
                            >
                                {mfaExpired && (
                                    <ExpiredIcon height="15px" width="15px" />
                                )}
                                <Text pl={!mfaExpired && '5px'} pr="5px">
                                    {previouslyVerified && userTier > 1
                                        ? 'RESET MFA'
                                        : 'SET MFA'}
                                </Text>
                            </Button>
                        </Tooltip>

                        <Flex // MFA
                            flexDirection="column"
                            justifyContent="center"
                            alignItems={{ base: 'center', md: 'flex-start' }}
                            px={
                                !activeMfa && !premiumMfaBypassed
                                    ? '15px'
                                    : '5px'
                            }
                            pt={{ base: '5px', md: '0px' }}
                            pb={{
                                base: '25px',
                                md: '20px',
                            }}
                            gap={{ base: '10px', md: '5px' }}
                            width="100%"
                            zIndex="10"
                        >
                            {!premiumMfaBypassed &&
                                (!previouslyVerified ||
                                    (previouslyVerified &&
                                        Boolean(daysRemaining >= 0))) && (
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            md: 'row',
                                        }}
                                        gap={'10px'}
                                        justifyContent="center"
                                        alignItems="center"
                                        cursor={
                                            activeMfa ? 'default' : 'pointer'
                                        }
                                        color={
                                            mfaTimeToExpire &&
                                            (mfaTimeToExpire === 'EXPIRED' ||
                                                mfaTimeToExpire === 'WARNING')
                                                ? 'red.500'
                                                : mfaTimeToExpire &&
                                                  mfaTimeToExpire === 'CAUTION'
                                                ? 'warning.100'
                                                : 'gray.400'
                                        }
                                        _hover={{
                                            color: activeMfa
                                                ? 'gray.400'
                                                : mfaTimeToExpire &&
                                                  (mfaTimeToExpire ===
                                                      'EXPIRED' ||
                                                      mfaTimeToExpire ===
                                                          'WARNING')
                                                ? 'red.600'
                                                : mfaTimeToExpire &&
                                                  mfaTimeToExpire === 'CAUTION'
                                                ? 'warning.200'
                                                : 'light.200',
                                            borderColor:
                                                mfaTimeToExpire &&
                                                (mfaTimeToExpire ===
                                                    'EXPIRED' ||
                                                    mfaTimeToExpire ===
                                                        'WARNING')
                                                    ? 'red.600'
                                                    : mfaTimeToExpire &&
                                                      mfaTimeToExpire ===
                                                          'CAUTION'
                                                    ? 'warning.200'
                                                    : 'light.200',
                                        }}
                                        onClick={() => {
                                            if (!activeMfa) {
                                                setShowResetMfaModal(true)
                                            }
                                        }}
                                    >
                                        {!activeMfa && !isPremium && (
                                            <>
                                                {!previouslyVerified ? (
                                                    <Flex
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        flexWrap="wrap"
                                                        cursor="default"
                                                        fontSize={{
                                                            base: '0.8rem',
                                                            md: '0.8rem',
                                                        }}
                                                        fontWeight="400"
                                                        color="gray.400"
                                                        px={{
                                                            base: '15px',
                                                            md: '0px',
                                                        }}
                                                        transition="all ease 300ms"
                                                    >
                                                        <Flex
                                                            height="18px"
                                                            color="rgba(0, 188, 248, 1)"
                                                            borderRadius="20px"
                                                            fontSize="0.85rem"
                                                            fontWeight="500"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            gap="2px"
                                                            mr="4px"
                                                        >
                                                            <RiStarLine
                                                                color="rgba(0, 188, 248, 1)"
                                                                fontSize="1rem"
                                                            />
                                                            PREMIUM
                                                        </Flex>
                                                        <Text
                                                            zIndex="1"
                                                            textAlign="center"
                                                            fontSize="0.85rem"
                                                        >
                                                            accounts require
                                                            Multi-factor
                                                            Authentication
                                                        </Text>
                                                    </Flex>
                                                ) : mfaExpired ? (
                                                    <Text
                                                        fontSize="0.8rem"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                    >
                                                        Your MFA has expired.
                                                        Reset it now to
                                                        reactivate your premium
                                                        services.
                                                    </Text>
                                                ) : mfaTimeToExpire &&
                                                  mfaTimeToExpire ===
                                                      'EXPIRED' ? (
                                                    <Text
                                                        fontSize="0.8rem"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                    >
                                                        Your MFA will expire
                                                        after this session.
                                                        Reset it now to avoid
                                                        disruption to your
                                                        premium services.
                                                    </Text>
                                                ) : mfaTimeToExpire &&
                                                  mfaTimeToExpire ===
                                                      'WARNING' ? (
                                                    <Text
                                                        fontSize="0.8rem"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                    >
                                                        Your MFA is about to
                                                        expire. Reset it now to
                                                        avoid disruption to your
                                                        premium services.
                                                    </Text>
                                                ) : mfaTimeToExpire &&
                                                  mfaTimeToExpire ===
                                                      'CAUTION' ? (
                                                    <Text
                                                        fontSize="0.8rem"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                    >
                                                        Your MFA will expire
                                                        soon. You can reset it
                                                        now to avoid disruption
                                                        to your premium
                                                        services.
                                                    </Text>
                                                ) : null}
                                            </>
                                        )}
                                        {/* {mfaCurrent && (
                                    <Text
                                        fontSize="0.8rem"
                                        textAlign={{
                                            base: 'center',
                                            md: 'left',
                                        }}
                                    >
                                        Your MFA will require resetting in{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            {`${
                                                daysRemaining >= 13
                                                    ? '14'
                                                    : daysRemaining + 1 // rounded to next day to account for days plus hours
                                            }`}
                                        </span>{' '}
                                        days.
                                    </Text>
                                )} */}
                                    </Flex>
                                )}
                            {!isPremium &&
                                userTier > 1 &&
                                mfaTimeToExpire &&
                                mfaTimeToExpire === 'BYPASSED' && (
                                    <Tooltip
                                        hasArrow
                                        px="10px"
                                        py="5px"
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        textAlign="center"
                                        lineHeight="1.2"
                                        display={isDesktop ? 'flex' : 'none'}
                                        whiteSpace="pre-line"
                                        label="Please contact Support if you require this to be enabled"
                                        placement="top"
                                        marginBottom="5px"
                                        borderRadius="10px"
                                        maxWidth="350px"
                                        bgColor={'gray.500'}
                                    >
                                        <Text
                                            cursor={'default'}
                                            fontSize="0.8rem"
                                            color="gray.400"
                                            textAlign={{
                                                base: 'center',
                                                md: 'left',
                                            }}
                                        >
                                            Multi-factor Authentication is
                                            currently disabled
                                        </Text>
                                    </Tooltip>
                                )}
                            {disableSettings && (
                                <Flex
                                    justifyContent="center"
                                    gap="5px"
                                    width={{ base: '100%', md: 'auto' }}
                                    minHeight="24px"
                                    cursor="default"
                                    alignItems={{
                                        base: 'center',
                                        md: 'flex-start',
                                    }}
                                    flexDirection={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <Text
                                        minHeight="20px"
                                        color="gray.400"
                                        textAlign={{
                                            base: 'center',
                                            md: 'start',
                                        }}
                                        fontSize="0.8rem"
                                    >
                                        {`${
                                            !previouslyVerified ||
                                            (isPremium &&
                                                userTier > 1 &&
                                                !previouslyVerified) ||
                                            userTier === 1
                                                ? ''
                                                : 'Resetting'
                                        } MFA is ${
                                            !mfaCurrent && !isPremium
                                                ? 'also'
                                                : ''
                                        } required to edit your profile${
                                            !previouslyVerified ||
                                            (isPremium && !previouslyVerified)
                                                ? ''
                                                : '.'
                                        }`}
                                    </Text>
                                    <Popover placement="top">
                                        <PopoverTrigger>
                                            <Flex
                                                mt={{ base: '0px', md: '-5px' }}
                                                width="30px"
                                                height="28px"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="10px"
                                                color="gray.400"
                                                opacity="0.8"
                                                cursor="pointer"
                                            >
                                                <Icon
                                                    as={Question}
                                                    width={infoButtonSize}
                                                    height={infoButtonSize}
                                                />
                                            </Flex>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            color="white"
                                            bg="gray.600"
                                            px="15px"
                                            borderColor="gray.600"
                                            borderRadius="20px"
                                            fontSize="0.8rem"
                                            alignItems="center"
                                            textAlign="center"
                                            lineHeight="1.4"
                                            fontFamily="Open Sans"
                                        >
                                            <PopoverArrow bg="gray.600" />
                                            <PopoverBody minWidth="130px">
                                                {`For security, ${
                                                    !previouslyVerified ||
                                                    (isPremium &&
                                                        !previouslyVerified)
                                                        ? ''
                                                        : 'resetting your'
                                                }
                                        Multi-factor Authentication is required
                                        to help ensure your Profile is only
                                        updated by you`}
                                            </PopoverBody>
                                            <PopoverFooter
                                                pt="3px"
                                                pb="12px"
                                                border="0"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Button
                                                    ref={initialFocusRef}
                                                    fontStyle="italic"
                                                    fontSize="0.7rem"
                                                    borderRadius="25px"
                                                    color="white"
                                                    px="15px"
                                                    bg="rgba(255,255,255,0.1)"
                                                    cursor="pointer"
                                                    _hover={{
                                                        opacity: '1',
                                                        bg: 'rgba(255,255,255,0.3)',
                                                        textShadow:
                                                            '1px 3px 15px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                    _active={{
                                                        opacity: '1',
                                                        bg: 'rgba(255,255,255,0.2)',
                                                    }}
                                                    _focus={{
                                                        opacity: '1',
                                                        bg: 'rgba(255,255,255,0.2)',
                                                    }}
                                                    zIndex="1"
                                                    height="32px"
                                                    transition="all linear 200ms"
                                                    onClick={() => {
                                                        setShowWhyMFAModal(true)
                                                    }}
                                                >
                                                    <Text
                                                        fontWeight="500"
                                                        letterSpacing="0.5px"
                                                    >
                                                        Why do we need
                                                        Multi-factor
                                                        Authentication?
                                                    </Text>
                                                </Button>
                                            </PopoverFooter>
                                        </PopoverContent>
                                    </Popover>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                )}
            </Flex>

            <Box
                height={{
                    base: 'auto',
                    xl: '100%',
                }}
                minWidth={{ base: '100%', xl: '260px' }}
                maxWidth={{ base: '100%', xl: '280px' }}
                pt={{
                    base: '30px',
                    md: '30px',
                    lg: '25px',
                    xl: '35px',
                }}
                pb={{
                    base: '25px',
                    lg: '15px',
                }}
                px="10px"
                borderTop={
                    (activeMfa || premiumMfaBypassed) && {
                        base: '1px solid #eaeaea',
                        xl: 'none',
                    }
                }
                borderLeft={{ base: 'none', xl: '1px solid #eaeaea' }}
            >
                <Flex
                    width="100%"
                    flexDirection="column"
                    px={{
                        base: '10px',
                        lg: '0px',
                        xl: '10px',
                    }}
                    gap={{
                        base: '20px',
                        md: '20px',
                    }}
                    height={{
                        base: 'auto',
                        md: '100%',
                    }}
                    minHeight={{
                        base: 'auto',
                        xl: '290px',
                    }}
                    justifyContent={{
                        base: 'center',
                        md: 'space-between',
                    }}
                    alignItems="center"
                >
                    <Flex
                        width="100%"
                        flexDirection={{
                            base: 'column',
                            sm: 'row',
                            xl: 'column',
                        }}
                        gap={{
                            base: '15px',
                            md: '20px',
                        }}
                        justifyContent={{
                            base: 'center',
                            xl: 'space-evenly',
                        }}
                        alignItems="center"
                    >
                        <Button
                            {...noFocus}
                            {...settingsButtonStyle}
                            onClick={async () => {
                                const accessToken =
                                    await getAccessTokenSilently()
                                let triggerFetch = false

                                if (mfaExpired || disableSettings) {
                                    setShowResetMfaModal(true)
                                } else if (plusSubscriptionOnly) {
                                    triggerFetch = true
                                } else {
                                    const showSubscriptionWarning =
                                        await isConfirmed(
                                            // Show subscription warning message
                                            'confirm-ok',
                                            <ManageSubscriptionMessage
                                                userTier={userTier}
                                                userRoles={userRoles}
                                                isPremium={isPremium}
                                            />
                                        )

                                    if (showSubscriptionWarning) {
                                        triggerFetch = true
                                    }
                                }

                                if (triggerFetch) {
                                    fetch(
                                        `${window.location.origin}/managesubscription`,
                                        {
                                            headers: {
                                                email: profileData.email,
                                                authorization: `Bearer ${accessToken}`,
                                            },
                                        }
                                    )
                                        .then((res) => res.json())
                                        .then((session) =>
                                            window.location.replace(session.url)
                                        )
                                }
                            }}
                        >
                            <SubscriptionIcon {...iconSizeTab} />
                            <Text ml="5px">
                                {plusSubscriptionOnly
                                    ? 'Manage Subscription'
                                    : 'Subscription Details'}
                            </Text>
                        </Button>

                        <Button
                            {...noFocus}
                            {...settingsButtonStyle}
                            onClick={async () => {
                                if (mfaExpired || disableSettings) {
                                    setShowResetMfaModal(true)
                                } else {
                                    const accessToken =
                                        await getAccessTokenSilently()
                                    fetch(
                                        `${window.location.origin}/changepassword`,
                                        {
                                            headers: {
                                                email: profileData.email,
                                                authorization: `Bearer ${accessToken}`,
                                            },
                                        }
                                    )
                                        .then((res) => res.json())
                                        .then((data) => {
                                            if (data.ticket) {
                                                window.location.href =
                                                    data.ticket
                                            }
                                        })
                                }
                            }}
                        >
                            <PasswordIcon
                                color={{
                                    base: 'light.100',
                                    md: 'light.100',
                                }}
                                {...iconSizePassword}
                            />
                            <Text ml="5px">Change Password</Text>
                        </Button>
                    </Flex>

                    <Flex
                        width="100%"
                        flexDirection="column"
                        gap={{
                            base: '5px',
                            md: '15px',
                        }}
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="300"
                        color="gray.500"
                        fontSize="0.75rem"
                    >
                        {plusSubscriptionOnly && (
                            <Text textAlign="center" opacity="0.9">
                                Click{' '}
                                <span
                                    style={{
                                        fontWeight: '500',
                                    }}
                                >
                                    Manage Subscription
                                </span>{' '}
                                to update your payment details or to cancel your
                                subscription plan.
                            </Text>
                        )}
                        <Flex
                            width="100%"
                            flexDirection="column"
                            gap={{
                                base: '5px',
                                md: '7px',
                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {!activeMfa && !premiumMfaBypassed && (
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    gap="5px"
                                    width="100%"
                                    cursor="default"
                                >
                                    <Text textAlign="center" opacity="0.9">
                                        {`${
                                            plusSubscriptionOnly
                                                ? 'Management of your subscription or'
                                                : 'Access to subscription details or changing your'
                                        } password
                                    ${
                                        previouslyVerified && userTier > 1
                                            ? 'will require a reset of your'
                                            : 'requires'
                                    } MFA${plusSubscriptionOnly ? '.' : ''} `}
                                    </Text>
                                </Flex>
                            )}
                            {(plusSubscriptionOnly ||
                                (!activeMfa && !premiumMfaBypassed)) && (
                                <Popover placement={popoverPlacement}>
                                    <PopoverTrigger>
                                        <Flex
                                            flexDirection="column"
                                            gap={{
                                                base: '10px',
                                                md: '10px',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Flex
                                                mt={{
                                                    base: '0px',
                                                    md: '-3px',
                                                }}
                                                width="30px"
                                                height="28px"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="10px"
                                                color="gray.400"
                                                opacity="0.8"
                                                cursor="pointer"
                                            >
                                                <Icon
                                                    as={Question}
                                                    width={infoButtonSize}
                                                    height={infoButtonSize}
                                                />
                                            </Flex>
                                        </Flex>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        color="white"
                                        bg="gray.600"
                                        px="5px"
                                        pt="5px"
                                        pb="7px"
                                        borderColor="gray.600"
                                        borderRadius="15px"
                                        fontSize="0.8rem"
                                        alignItems="center"
                                        textAlign="center"
                                        lineHeight="1.4"
                                        fontFamily="Open Sans"
                                        minWidth={{
                                            base: '320px',
                                            xs: '340px',
                                            sm: '380px',
                                            smd: '420px',
                                        }}
                                        fontWeight="300"
                                    >
                                        <PopoverArrow
                                            bg="gray.600"
                                            mt={{ base: '0px', md: '-3px' }}
                                        />
                                        <PopoverBody>
                                            {plusSubscriptionOnly && (
                                                <>
                                                    <Text fontSize="0.75rem">
                                                        Cancelling your{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '500',
                                                            }}
                                                        >
                                                            PLUS
                                                        </span>{' '}
                                                        subscription plan will
                                                        revert your account to
                                                        the{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '500',
                                                            }}
                                                        >
                                                            FREE
                                                        </span>{' '}
                                                        version at the end of
                                                        the current subscription
                                                        month you've already
                                                        paid for. This allows
                                                        continued access to most
                                                        of PreFlight's services
                                                        for free thereafter.
                                                    </Text>
                                                    <Text
                                                        pt="5px"
                                                        fontSize="0.75rem"
                                                    >
                                                        You can easily upgrade
                                                        back to a{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '500',
                                                            }}
                                                        >
                                                            PLUS
                                                        </span>{' '}
                                                        plan again at any time
                                                        on this account if you
                                                        wish.
                                                    </Text>
                                                    <Text
                                                        pt="5px"
                                                        fontSize="0.75rem"
                                                    >
                                                        Please note that refunds
                                                        are not provided for the
                                                        remaining portion of the
                                                        cancelled month should
                                                        you choose to cancel
                                                        before the end of your
                                                        current month billing
                                                        cycle.
                                                    </Text>
                                                </>
                                            )}
                                            {!activeMfa &&
                                                !premiumMfaBypassed && (
                                                    <>
                                                        {plusSubscriptionOnly && (
                                                            <Divider
                                                                my="13px"
                                                                opacity="0.4"
                                                            />
                                                        )}
                                                        <Text fontSize="0.75rem">
                                                            For security,
                                                            {previouslyVerified
                                                                ? ' resetting your '
                                                                : ' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                Multi-factor
                                                                Authentication
                                                            </span>{' '}
                                                            is required to help
                                                            ensure your account
                                                            details are only
                                                            accessed by you
                                                        </Text>
                                                    </>
                                                )}
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                {/*   <FormControl // SETTINGS
                                                        isRequired
                                                        // isInvalid={isError}
                                                    >
                                                     <Flex
                                                            justifyContent="space-evenly"
                                                            flexDirection={{
                                                                base: 'column',
                                                                md: 'column',
                                                            }}
                                                            mt={{
                                                                base: '0px',
                                                                md: '0px',
                                                            }}
                                                        >
                                                            <Flex
                                                                justifyContent="flex-start"
                                                                flexDirection="column"
                                                                width="100%"
                                                                mr={{
                                                                    base: '0px',
                                                                    md: '30px',
                                                                }}
                                                            >
                                                                <FormLabel
                                                                    htmlFor="Password"
                                                                    fontSize="0.8rem"
                                                                    fontWeight="400"
                                                                >
                                                                    Settings
                                                                </FormLabel>

                                                                 <InputGroup size="md">
                                                                    <Input
                                                                        {...focus}
                                                                        fontSize="0.9rem"
                                                                        height="9"
                                                                        variant="primary"
                                                                        id="password"
                                                                        pr="4.5rem"
                                                                        type={
                                                                            showPassword
                                                                                ? 'text'
                                                                                : 'password'
                                                                        }
                                                                        placeholder="Enter password"
                                                                    />
                                                                    <InputRightElement
                                                                        height="36px"
                                                                        marginRight="-14px"
                                                                        width="4.5rem"
                                                                    >
                                                                        <Button
                                                                            {...noFocus}
                                                                            h="1.75rem"
                                                                            size="xs"
                                                                            onClick={
                                                                                handleClick
                                                                            }
                                                                        >
                                                                            {showPassword ? (
                                                                                <BiHide fontSize="1.2rem" />
                                                                            ) : (
                                                                                <BiShowAlt fontSize="1.2rem" />
                                                                            )}
                                                                        </Button>
                                                                    </InputRightElement>
                                                                </InputGroup>
                                                            </Flex>
                                                            <Flex
                                                                justifyContent="flex-start"
                                                                flexDirection="column"
                                                                width="100%"
                                                                mt={{
                                                                    base: '25px',
                                                                    md: '25px',
                                                                }}
                                                            >
                                                                <FormLabel
                                                                    htmlFor="Password"
                                                                    fontSize="0.8rem"
                                                                    fontWeight="400"
                                                                >
                                                                    Confirm
                                                                    Password
                                                                </FormLabel>

                                                                {/* <InputGroup size="md">
                                                                    <Input
                                                                        {...focus}
                                                                        fontSize="0.9rem"
                                                                        height="9"
                                                                        variant="primary"
                                                                        id="password"
                                                                        pr="4.5rem"
                                                                        type={
                                                                            showPassword
                                                                                ? 'text'
                                                                                : 'password'
                                                                        }
                                                                        placeholder="Enter password"
                                                                    />
                                                                    <InputRightElement
                                                                        height="36px"
                                                                        marginRight="-14px"
                                                                        width="4.5rem"
                                                                    >
                                                                        <Button
                                                                            {...noFocus}
                                                                            h="1.75rem"
                                                                            size="xs"
                                                                            onClick={
                                                                                handleClick
                                                                            }
                                                                        >
                                                                            {showPassword ? (
                                                                                <BiHide fontSize="1.2rem" />
                                                                            ) : (
                                                                                <BiShowAlt fontSize="1.2rem" />
                                                                            )}
                                                                        </Button>
                                                                    </InputRightElement>
                                                                </InputGroup>
                                                            </Flex>
                                                        </Flex>
                                                    </FormControl> */}
            </Box>
        </Flex>
    )
}
