import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import {
    Box,
    Flex,
    Text,
    Button,
    useBreakpointValue,
    Tooltip,
    Icon,
} from '@chakra-ui/react'
import PremiumBadge from '../../../controls/PremiumBadge'
import {
    setModalVisibilityState,
    settingsMenuOpenState,
} from '../../../../globalState'
import { GoCheck } from 'react-icons/go'
import { ReactComponent as Question } from '../../../../icons/assets/question.svg'

export default function SettingsToggle(props) {
    const {
        setProfileData,
        toggleActive,
        profileSettingsState,
        profileSettingText,
        tooltipLabel,
        userTier,
        isPremium,
        showPremiumBadge,
        mfaExpired,
        toggleSwitch,
        toggleCheck,
        toggleIndicator,
        toggleOnIcon,
        onCloseHandler,
    } = props

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const questionButtonSize = useBreakpointValue({
        base: '1.2rem',
        md: '1.1rem',
    })

    const updateProfileSettings = () => {
        setProfileData((prevState) => {
            const profileSettings = prevState.profileSettings || {}
            const newSetting = profileSettings[profileSettingsState]
            const newProfileSetting =
                newSetting !== undefined ? !newSetting : true
            return {
                ...prevState,
                profileSettings: {
                    ...profileSettings,
                    [profileSettingsState]: newProfileSetting,
                },
            }
        })
    }

    return (
        <Flex
            fontWeight="400"
            cursor="pointer"
            color="light.80"
            onClick={() => {
                if (showPremiumBadge) {
                    if (!isPremium) {
                        updateProfileSettings()
                    } else if (mfaExpired && userTier > 1) {
                        setShowResetMfaModal(true)
                        setSettingsMenuOpen(false)
                    } else if (isPremium) {
                        setShowPricingTiersModal(true)
                        setSettingsMenuOpen(false)
                        onCloseHandler()
                    }
                } else {
                    updateProfileSettings()
                }
            }}
            _hover={{
                color: 'light.900',
            }}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            fontSize={{
                base: '0.8rem',
                lg: '0.8rem',
            }}
            height="32px"
        >
            <Text
                lineHeight="1.4"
                width={{
                    base: '100%',
                    sm: 'auto',
                }}
                opacity={showPremiumBadge && isPremium ? '0.4' : '1'}
            >
                {profileSettingText}
            </Text>
            <Tooltip
                hasArrow
                px="7px"
                py="5px"
                fontSize="0.7rem"
                alignItems="center"
                textAlign="center"
                lineHeight="1.2"
                display="flex"
                whiteSpace="pre-line"
                label={tooltipLabel}
                placement="top"
                marginLeft="7px"
                marginBottom="5px"
                borderRadius="10px"
                bgColor={'gray.500'}
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    pl="8px"
                    color="gray.400"
                    opacity="0.8"
                >
                    <Icon
                        as={Question}
                        width={questionButtonSize}
                        height={questionButtonSize}
                    />
                </Flex>
            </Tooltip>
            <Tooltip
                px="7px"
                py="5px"
                height="24px"
                fontSize="0.7rem"
                alignItems="center"
                textAlign="center"
                lineHeight="1.2"
                display="flex"
                label={toggleActive ? 'Enabled' : 'Disabled'}
                placement="left"
                marginRight="-10px"
                borderRadius="10px"
                border="1px solid #ededed"
                color="gray.600"
                bgColor={'white'}
            >
                <Flex my="auto" ml="auto" pl="10px">
                    {showPremiumBadge && isPremium && (
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            pr="10px"
                        >
                            <PremiumBadge />
                        </Flex>
                    )}
                    <Button
                        isDisabled={showPremiumBadge && isPremium}
                        {...toggleSwitch}
                        background={toggleActive ? 'light.200' : 'gray.200'}
                        _hover={{
                            background: toggleActive ? 'light.201' : 'gray.300',
                        }}
                        _focus={{
                            background: toggleActive ? 'light.201' : 'gray.300',
                        }}
                        _active={{
                            background: toggleActive ? 'light.201' : 'gray.300',
                        }}
                    >
                        <Box
                            {...toggleCheck}
                            opacity={toggleActive ? '0.6' : '0'}
                        >
                            <GoCheck fontSize="1rem" />
                        </Box>
                        <Box
                            {...toggleIndicator}
                            background="light.10"
                            transform={
                                toggleActive
                                    ? 'translateX(110%)'
                                    : 'translateX(0%)'
                            }
                        >
                            <Box
                                {...toggleOnIcon}
                                opacity={toggleActive ? '1' : '0.4'}
                            ></Box>
                        </Box>
                    </Button>
                </Flex>
            </Tooltip>
        </Flex>
    )
}
