import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Icon,
    Link,
    Box,
    useBreakpointValue,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../../globalState'

import { RiStarLine } from 'react-icons/ri'
import { ReactComponent as MobileSMSIcon } from '../../../icons/assets/mobile-sms.svg'
import { ReactComponent as MobileAuthenticatorIcon } from '../../../icons/assets/mobile-authenticator.svg'
import { ReactComponent as EmailIcon } from '../../../icons/assets/email.svg'
import { ReactComponent as Bulletpoint } from '../../../icons/assets/bulletpoint.svg'

export default function WhyMFAModal() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShowWhyMFAModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const setShowTermsModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'terms', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.whyMfa ? onOpen() : onClose()
    }, [modalVisibility.whyMfa, onOpen, onClose])

    function onCloseHandler() {
        setShowWhyMFAModal(false)
    }
    const showPremiumBadge = useBreakpointValue({
        base: false,
        smd: true,
    })
    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'whyMfa'}
                    size="3xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    isCentered
                    trapFocus={false}
                    motionPreset="slideInBottom"
                >
                    <ModalOverlay />

                    <ModalContent mx="15px">
                        <ModalHeader
                            zIndex="1"
                            pt="4"
                            pb="4"
                            textAlign="center"
                            background="white"
                            color="light.100"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            About Multi-factor Authentication
                        </ModalHeader>
                        <ModalBody
                            background="light.20"
                            style={{ padding: 0 }}
                            fontSize="0.8rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight={{
                                    base: 'calc(100vh - 220px)',
                                    md: 'calc(100vh - 200px)',
                                }}
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt="30px"
                                pb="60px"
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                <Flex
                                    gap="5px"
                                    pt="15px"
                                    pb="20px"
                                    pl={{
                                        base: '15px',
                                        md: '20px',
                                    }}
                                    pr={{
                                        base: '15px',
                                        smd: '5px',
                                        md: '20px',
                                    }}
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        flexWrap="wrap"
                                        fontSize="0.9rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Text pb="0px" pr="5px">
                                            MFA is a requirement for –
                                        </Text>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Flex
                                                display={
                                                    showPremiumBadge
                                                        ? 'flex'
                                                        : 'none'
                                                }
                                                gap="5px"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Text>Access to</Text>
                                                <Flex
                                                    height="20px"
                                                    bg="rgba(0, 188, 248, 1)"
                                                    color="#FFFFFF"
                                                    fontFamily="'Open Sans', sans-serif"
                                                    pl="4px"
                                                    pr="6px"
                                                    borderRadius="20px"
                                                    fontSize="0.75rem"
                                                    fontWeight="600"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    gap="4px"
                                                    width="90px"
                                                >
                                                    <RiStarLine
                                                        color="#FFFFFF"
                                                        fontSize="0.9rem"
                                                    />
                                                    PREMIUM
                                                </Flex>
                                            </Flex>
                                            <Text textAlign="left">
                                                {showPremiumBadge
                                                    ? ''
                                                    : 'Access to PREMIUM'}{' '}
                                                features on{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#007DC8',
                                                    }}
                                                >
                                                    PLUS
                                                </span>{' '}
                                                and{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#8D38E3',
                                                    }}
                                                >
                                                    PRO
                                                </span>{' '}
                                                subscription plans.
                                            </Text>
                                        </Flex>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Text textAlign="left">
                                                Managing account details and
                                                password changes (all users).
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Text pt="30px" fontSize="0.9rem">
                                    <strong>What is MFA?</strong>
                                </Text>
                                <Flex
                                    width={{ base: '100%', sm: 'auto' }}
                                    flexDirection="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    px="9px"
                                >
                                    <Text pt="10px">
                                        Multi-factor Authentication (MFA), also
                                        known as 'two-step verification', is an
                                        enhanced security method that requires
                                        users to provide two or more
                                        verification factors to access their
                                        accounts.
                                    </Text>
                                    <Text pt="10px">
                                        Instead of solely relying on a username
                                        and password, MFA adds an extra layer of
                                        protection, significantly reducing the
                                        risk of unauthorised access to your
                                        account.
                                    </Text>
                                    <Text pt="10px">
                                        We provide Multi-factor Authentication
                                        through a 6-digit verification code
                                        obtained through one of the three
                                        verification options below.
                                    </Text>
                                </Flex>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Verification Options</strong>
                                </Text>
                                <Flex
                                    pt="20px"
                                    pb={{ base: '20px', smd: '10px' }}
                                    width={{ base: '100%', sm: '100%' }}
                                    flexDirection={{
                                        base: 'column',
                                        smd: 'row',
                                    }}
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                    px={{ base: '0px', smd: '20px' }}
                                    gap="30px"
                                >
                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={MobileSMSIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            color="light.200"
                                            fontWeight="500"
                                            fontSize="0.9rem"
                                        >
                                            SMS
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{ base: '15px', smd: '0px' }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a text message with verification
                                            code sent to your mobile
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={MobileAuthenticatorIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            fontWeight="500"
                                            color="light.200"
                                            fontSize="0.9rem"
                                        >
                                            Authenticator
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{ base: '15px', smd: '0px' }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a time-based verification code
                                            from an Authenticator app, such as
                                            Google Authenticator
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={EmailIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            fontWeight="500"
                                            color="light.200"
                                            fontSize="0.9rem"
                                        >
                                            Email
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{ base: '15px', smd: '0px' }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a verification code sent to your
                                            email address
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Text pl="10px" pt="10px">
                                    The Authenticator App and Email options
                                    cater to users in areas where WiFi is
                                    available, but limited or no cellular
                                    connectivity, enabling easy access to
                                    PreFlight's premium services, and secure
                                    account management, without relying on SMS
                                    messages.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Why do we need MFA?</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    MFA is an important and necessary
                                    requirement for users primarily for the
                                    following reasons –
                                </Text>
                                <Text
                                    pl="10px"
                                    pt="15px"
                                    color="gray.500"
                                    fontSize="0.9rem"
                                    fontWeight="500"
                                >
                                    Security
                                </Text>
                                <Text pl="25px" pt="5px">
                                    The security of our user's accounts is
                                    paramount. By integrating MFA, we ensure an
                                    additional level of security to safeguard
                                    against unauthorised access. This proactive
                                    approach significantly reduces the risk of
                                    data breaches and ensures the
                                    confidentiality of sensitive information.
                                </Text>
                                <Text pl="25px" pt="10px">
                                    MFA has become a standard practice across
                                    various sectors, including government
                                    agencies, in response to evolving
                                    cybersecurity threats. Its integration
                                    aligns with prevailing security standards,
                                    underscoring our commitment to adhering to
                                    best practices in safeguarding user data.
                                    Furthermore, to minimise privacy risks, we
                                    collect only essential personal information.
                                </Text>
                                <Text
                                    pl="10px"
                                    pt="20px"
                                    color="gray.500"
                                    fontSize="0.9rem"
                                    fontWeight="500"
                                >
                                    Managing Premium Services
                                </Text>
                                <Text pl="25px" pt="5px">
                                    In tandem with security, MFA plays a pivotal
                                    role in the management of premium services
                                    offered by PreFlight. Our Premium
                                    subscription plan unlocks additional
                                    features and functionality beyond the free
                                    services. MFA also helps to address a known
                                    issue with non-paying users gaining access
                                    to these premium services, particularly in
                                    shared device environments. This assures
                                    that only eligible subscribers enjoy the
                                    benefits of our premium features, thereby
                                    ensuring the long-term sustainability of
                                    PreFlight's operating model.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>
                                        Why do we have Premium Services?
                                    </strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Premium services are integral to the
                                    successful continuation of PreFlight.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    While it would be great to offer PreFlight
                                    as a free, all-inclusive package,
                                    unfortunately the current operating model
                                    for the supply of aviation weather data in
                                    NZ does not facilitate this, as compared to
                                    that in some other countries (where costs
                                    are covered in the flight fees and levies
                                    paid each flight).
                                </Text>
                                <Text pl="10px" pt="10px">
                                    In New Zealand the provision of aviation
                                    services is partially funded by the Ministry
                                    of Transport. For PreFlight, this only
                                    covers those Meteorological products that
                                    are available in the{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#94A3B7',
                                        }}
                                    >
                                        FREE
                                    </span>{' '}
                                    tier. MetService, as a State Owned
                                    Enterprise, must also operate as a
                                    commercial company.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    No additional funding was provided for the
                                    development of PreFlight, without this
                                    source of revenue, PreFlight would not have
                                    been possible as a replacement to the
                                    recently retired and outdated MetFlight.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    The recurring revenue generated from premium
                                    subscriptions helps cover PreFlight's
                                    initial build costs, on-going maintenance,
                                    support and future enhancements. This is
                                    necessary for the sustainability and
                                    development of the web-app, ensuring it
                                    remains relevant and continues to provide
                                    our users with useful, fit-for-purpose
                                    information for their flights.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Management of PreFlight's premium services
                                    for individuals, or organisations using it
                                    for commercial purposes, is in line with the
                                    PreFlight{' '}
                                    <Link
                                        color="light.200"
                                        fontWeight="400"
                                        onClick={() => {
                                            setShowResetMfaModal(false)
                                            setShowWhyMFAModal(false)
                                            setShowTermsModal(true)
                                        }}
                                    >
                                        Terms and Conditions
                                    </Link>
                                    .
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>
                                        When will I be required to use MFA?
                                    </strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    MFA will be prompted for the following –
                                </Text>
                                <Box pl="35px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Making changes to your account's
                                            personal details.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Changing your password.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Access to premium features on{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            or{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    color: '#8D38E3',
                                                }}
                                            >
                                                PRO
                                            </span>{' '}
                                            subscription plans.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Managing a premium subscription
                                            plan.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Accessing premium subscription
                                            payments history (applicable for
                                            current and previous premium plans).
                                        </li>
                                    </ul>
                                </Box>
                                <Text pl="10px" pt="10px">
                                    For{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        premium
                                    </span>{' '}
                                    subscription users only –
                                </Text>
                                <Text pl="38px" pt="10px">
                                    You are required to enable MFA during the
                                    initial upgrade to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    or{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    subscription plan. The MFA remains valid for{' '}
                                    <strong>14 days</strong> until it requires
                                    resetting. After each 14-day period, a new
                                    code will be needed upon your next login.
                                </Text>
                                <Text pl="38px" pt="10px">
                                    Within three days before your MFA expires, a
                                    notification will appear in the admin
                                    dashboard while you are logged into the
                                    web-app. This prompt allows you to
                                    conveniently reset your MFA, ensuring
                                    continuous access to premium services
                                    without potential disruptions or the need to
                                    reset at an inconvenient time.
                                </Text>
                                <Text pl="38px" pt="5px">
                                    Please note that changes to your premium
                                    subscription plan, profile details, or
                                    password, will necessitate resetting MFA
                                    when initiated, irrespective of the 14-day
                                    period's validity.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>
                                        Minimising the impacts of MFA
                                    </strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    MFA is an important part of the security and
                                    continuity of premium services.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    We understand that MFA may be inconvenient,
                                    particularly for users who like to update
                                    information while flying. Although we can
                                    see the benefits of having updated
                                    information swiftly available particularly
                                    on longer journeys, PreFlight is
                                    specifically designed for pre-flight
                                    planning, and is not intended nor endorsed
                                    for in-flight use. Updating your information
                                    before departure ensures you have the most
                                    relevant data and insights when it matters
                                    most. The emphasis on pre-flight planning
                                    helps pilots make informed decisions for
                                    safer flights.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    All safety-related information required for
                                    pre-flight planning, as previously provided
                                    through MetFlight, is available in the
                                    standard format for all users, regardless of
                                    their subscription plan –{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#94A3B7',
                                        }}
                                    >
                                        FREE
                                    </span>
                                    ,{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    or{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>
                                    . Subscribers to a Premium plan get this
                                    information presented in an enhanced format
                                    along with additional user-friendly benefits
                                    and features.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    PreFlight will still provide all the{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#94A3B7',
                                        }}
                                    >
                                        FREE
                                    </span>{' '}
                                    tier services when a premium user's MFA has
                                    expired and is yet to be reset. Upon
                                    resetting your MFA, premium services will be
                                    reactivated.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    As a compromise between convenience and
                                    security, MFA is only required periodically,
                                    not with every login. For security, this
                                    excludes management of a user's premium
                                    subscription plan or profile settings, which
                                    is required any time these are updated.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Further Information</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    If you would like to understand more about
                                    MFA, or want to discuss any issues or
                                    concerns you may have, please{' '}
                                    <Link
                                        color="light.200"
                                        href="mailto:helpdesk@airwaysinternational.co.nz?subject=Query about PreFlight MFA"
                                    >
                                        contact us
                                    </Link>
                                    .
                                </Text>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
