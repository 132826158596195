import React, { useContext, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import DrawTools from './drawtools'
import Search from './search'
import TimeSlider from './sliders/time-slider'
import Clock from './Clock'
import AltitudeDisplay from './AltitudeDisplay'
import MapControls from './mapcontrols'
import UserIntro from './user-intro'
import {
    dataModeState,
    layerSelectionState,
    mapControlsExpandState,
    isBriefingOnlyState,
} from '../../globalState'

import './userback-override.css'

export default function Controls() {
    const mapInstance = useContext(MapContext)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const [expanded, setExpanded] = useRecoilState(mapControlsExpandState)
    const [altitudeSliderExpanded, setAltitudeSliderExpanded] = useState(true)
    const [sliderVisible, setSliderVisible] = useState(true)
    const [rulerMode, setRulerMode] = useState(false)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)

    return (
        <>
            {!isBriefingOnly && (
                <DrawTools rulerMode={rulerMode} setRulerMode={setRulerMode} />
            )}
            {!isBriefingOnly && <Search mapInstance={mapInstance} />}
            {(dataMode === 'advs' ||
                (dataMode === 'met' &&
                    (layerSelection.met.aaw ||
                        layerSelection.met.sigwx ||
                        layerSelection.met.grafor ||
                        layerSelection.met.rainradar ||
                        layerSelection.met.rainRadarWxTiles ||
                        layerSelection.met.satelliteCloudVisible ||
                        layerSelection.met.satelliteCloudTops ||
                        layerSelection.met.satelliteCloudInfrared ||
                        layerSelection.met.satelliteCloudInfraredEnhanced))) &&
                !isBriefingOnly && <TimeSlider />}
            {!isBriefingOnly && (
                <MapControls
                    expanded={expanded}
                    setExpanded={setExpanded}
                    altitudeSliderExpanded={altitudeSliderExpanded}
                    setAltitudeSliderExpanded={setAltitudeSliderExpanded}
                    sliderVisible={sliderVisible}
                    setSliderVisible={setSliderVisible}
                    rulerMode={rulerMode}
                    setRulerMode={setRulerMode}
                />
            )}
            {!isBriefingOnly && <Clock />}
            {!isBriefingOnly && (
                <AltitudeDisplay
                    mapToolsExpanded={expanded}
                    altitudeSliderExpanded={altitudeSliderExpanded}
                    altitudeSliderVisible={sliderVisible}
                />
            )}
            <UserIntro />
        </>
    )
}
