import React from 'react'
import { useRecoilValue } from 'recoil'
import { Flex, Box, Text, Icon } from '@chakra-ui/react'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { briefingParamsState } from '../../../../../globalState'

import { ReactComponent as AWSIcon } from '../../../../../icons/assets/aws.svg'
import { ReactComponent as METARIcon } from '../../../../../icons/assets/metar.svg'
import { ReactComponent as TAFIcon } from '../../../../../icons/assets/taf.svg'
import { ReactComponent as ATISIcon } from '../../../../../icons/assets/atis.svg'
import { ReactComponent as NOTAMIcon } from '../../../../../icons/assets/notam-outline.svg'

export default function NoInfo(props) {
    const {
        briefingData,
        awsWithNoData,
        awsWithNoDataList,
        dataLoading,
        isPremium,
    } = props
    const params = useRecoilValue(briefingParamsState)

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const noInfoOptions = [
        {
            dataType: 'NOTAM',
            icon: NOTAMIcon,
        },
        {
            dataType: 'METAR',
            icon: METARIcon,
        },
        {
            dataType: 'TAF',
            icon: TAFIcon,
        },
        {
            dataType: 'ATIS',
            icon: ATISIcon,
        },
    ]

    return (
        <>
            {/* <div id="noInfoContainer" style={{ marginTop: '1px' }} ref={ref}></div> */}
            <Box
                bg="rgba(255,255,255,1)"
                w={{ base: '100%', md: '100%' }}
                borderRadius="20px"
                fontFamily="'Poppins', 'Open Sans', sans-serif"
                zIndex="1"
                transition="all ease 300ms"
                boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                _hover={{
                    boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                }}
                marginBottom="80px"
                pb="6px"
                overflow="hidden"
            >
                <Box
                    bg="white"
                    w={{ base: '100%', md: '100%' }}
                    padding="15px"
                    minHeight="30px"
                    border="none"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    zIndex="1"
                >
                    <Flex
                        direction="column"
                        alignItems="flex-start"
                        width="100%"
                        gap="20px"
                    >
                        {briefingData.res.aerodromes &&
                            noInfoOptions.map((data) => (
                                <Flex
                                    key={`AerodromesNoInfo${data.dataType}`}
                                    my="auto"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    width="100%"
                                >
                                    <Flex
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        ml="3px"
                                        mr="15px"
                                    >
                                        <Icon
                                            as={data.icon}
                                            width="28px"
                                            height="28px"
                                            color="#1778b7"
                                        />
                                    </Flex>
                                    <Flex
                                        my="auto"
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        width="100%"
                                    >
                                        <Text
                                            textAlign="left"
                                            color="#1778b7"
                                            fontSize={calculateFontSize(0.8)}
                                            fontWeight="500"
                                            lineHeight="1.3"
                                            pt="5px"
                                            pb="3px"
                                        >
                                            Aerodromes with no{' '}
                                            <strong>{data.dataType}</strong>{' '}
                                            information:
                                        </Text>
                                        <Flex
                                            flexDirection="row"
                                            flexWrap="wrap"
                                        >
                                            <Text
                                                textAlign="left"
                                                flexDirection="row"
                                                color="gray.500"
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                                fontWeight="400"
                                                lineHeight="1.3"
                                                pr="5px"
                                            >
                                                {briefingData.res.aerodromes &&
                                                    briefingData.res.aerodromes
                                                        .filter((aerodrome) =>
                                                            data.dataType ===
                                                            'NOTAM'
                                                                ? !aerodrome.notam
                                                                : data.dataType ===
                                                                  'METAR'
                                                                ? !aerodrome.metar
                                                                : data.dataType ===
                                                                  'TAF'
                                                                ? !aerodrome.taf
                                                                : data.dataType ===
                                                                  'ATIS'
                                                                ? !aerodrome.atis
                                                                : null
                                                        )
                                                        .sort((a, b) => {
                                                            const aerodromeA =
                                                                a.designator
                                                            const aerodromeB =
                                                                b.designator
                                                            if (
                                                                aerodromeA <
                                                                aerodromeB
                                                            ) {
                                                                return -1
                                                            }
                                                            if (
                                                                aerodromeA >
                                                                aerodromeB
                                                            ) {
                                                                return 1
                                                            } else {
                                                                return 0
                                                            }
                                                        })
                                                        .map(
                                                            (aerodrome) =>
                                                                aerodrome.designator
                                                        )
                                                        .join(', ')}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}

                        {!isPremium &&
                            !dataLoading && // List of AWS with no data
                            params.aws &&
                            briefingData &&
                            briefingData.res &&
                            briefingData.res.aws && (
                                <Flex
                                    my="auto"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    width="100%"
                                >
                                    <Flex
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        ml="5px"
                                        mr="12px"
                                    >
                                        <AWSIcon
                                            width="28px"
                                            height="28px"
                                            color="#1778b7"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        alignItems="flex-start"
                                        width="100%"
                                        gap="20px"
                                    >
                                        <Flex
                                            my="auto"
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            width="100%"
                                        >
                                            <Text
                                                textAlign="left"
                                                color="#1778b7"
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                                fontWeight="500"
                                                lineHeight="1.3"
                                                pt="6px"
                                                pb="5px"
                                            >
                                                Automatic Weather Stations with
                                                no information:
                                            </Text>
                                            <Flex
                                                flexDirection="row"
                                                flexWrap="wrap"
                                            >
                                                <Text
                                                    textAlign="left"
                                                    flexDirection="row"
                                                    color="gray.500"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    fontWeight="400"
                                                    lineHeight="1.3"
                                                    pr="5px"
                                                >
                                                    {awsWithNoData
                                                        ? awsWithNoDataList
                                                        : 'None'}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )}
                    </Flex>
                </Box>
            </Box>
        </>
    )
}
