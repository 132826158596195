import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Center,
    Flex,
    Text,
    Select,
    IconButton,
    Tooltip,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useQuery } from 'react-query'
import { useAdminRole } from '../../../../auth/hooks'

import Viewer from '../PDFViewer'
import { showErrorTogglesState } from '../../../../globalState'
import { ReactComponent as TabIcon } from '../../../../icons/assets/new-tab.svg'

import ErrorText from '../ErrorText'
import ErrorToggle from '../ErrorToggle'
import FullWidthError from '../FullWidthError'

export default function Charts({ selectedAerodrome }) {
    const { getAccessTokenSilently } = useAuth0()
    const isAdmin = useAdminRole()
    const [options, setOptions] = useState(null)
    const [value, setValue] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [selectedChart, setSelectedChart] = useState('')
    const [chartsForSelectedAerodrome, setChartsForSelectedAerodrome] =
        useState([])
    const aerodromeCharts = chartsForSelectedAerodrome.length > 0
    const fetchCharts = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/chartlist/${selectedAerodrome}`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, refetch, status } = useQuery('charts', fetchCharts, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        setSelectedChart('')
        refetch()
    }, [selectedAerodrome, refetch])

    useEffect(() => {
        if (selectedAerodrome && data && data?.data?.readAipCharts) {
            setChartsForSelectedAerodrome(data.data.readAipCharts)
        }
    }, [data, selectedAerodrome, setChartsForSelectedAerodrome])

    useEffect(() => {
        if (chartsForSelectedAerodrome.length) {
            setOptions(
                chartsForSelectedAerodrome.map((ch) => {
                    return (
                        <option key={ch.Name} value={ch.Name}>
                            {ch.Title}
                        </option>
                    )
                })
            )
        }
    }, [chartsForSelectedAerodrome])

    const handleChange = (e) => {
        if (Boolean(e.target.value === '' || e.target.value === null)) {
            setSelectedChart('')
            setValue('')
        } else if (Boolean(e.target.value !== '' && e.target.value !== null)) {
            const filename = e.target.value
            setValue(filename)
            const chartData = chartsForSelectedAerodrome.filter(
                (chart) => chart.Name === filename
            )[0]
            setSelectedChart(chartData.URL)
        } else {
            setSelectedChart('')
            setValue('')
        }
    }

    const noFocus = {
        _focus: { borderColor: 'none', boxShadow: 'none' },
    }

    return (
        <>
            <Flex
                mt={selectedChart ? '-48px' : '0px'}
                marginBottom={{ base: '20px', sm: '20px' }}
                justifyContent="space-between"
                gap="10px"
                transition="all ease 250ms"
            >
                <Flex>
                    <Text
                        marginBottom={2}
                        fontSize={{
                            base: '0.85rem',
                            xs: '0.9rem',
                            sm: '1rem',
                        }}
                        fontWeight="bold"
                        lineHeight="1.3"
                        minHeight={{ base: '20px', smd: '20px' }}
                    >
                        AERODROME CHARTS
                    </Text>
                </Flex>
            </Flex>
            {isAdmin && showErrorToggle && (
                <Flex
                    w="100%"
                    pb="15px"
                    align="center"
                    justifyContent={{
                        base: 'flex-end',
                        md: 'flex-end',
                    }}
                >
                    <ErrorToggle
                        showErrors={showErrors}
                        setShowErrors={setShowErrors}
                    />
                </Flex>
            )}
            {(!data || (data && !data.data)) && status !== 'loading' && (
                <Flex mb="15px" mr="10px" justifyContent="center">
                    <ErrorText>
                        There has been an issue with the connection between
                        PreFlight and the AIP database. <br />
                        Check back again soon, or alternatively visit{' '}
                        <a
                            style={{
                                fontWeight: '500',
                                textDecoration: 'underline',
                            }}
                            href="https://www.aip.net.nz/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            AIP
                        </a>{' '}
                        online for aerodrome charts.
                    </ErrorText>
                </Flex>
            )}
            {!showErrors && status !== 'error' ? (
                <>
                    <Flex w="100%" mt={isAdmin && showErrorToggle && '10px'}>
                        <Select
                            fontFamily="'Open Sans', sans-serif"
                            placeholder={
                                !showErrors && status !== 'error'
                                    ? 'Select Chart'
                                    : 'Error loading aerodrome charts'
                            }
                            isDisabled={status === 'error'}
                            aria-label="Select Chart"
                            value={value}
                            onChange={handleChange}
                            variant="outline"
                            borderRadius="md"
                            borderColor={'gray.200'}
                            cursor="pointer"
                            fontSize="0.9rem"
                            {...noFocus}
                        >
                            {options}
                        </Select>
                        {status === 'success' && (
                            <Tooltip
                                hasArrow
                                placement="top"
                                label="Open PDF"
                                borderRadius="5"
                            >
                                <IconButton
                                    aria-label="Search database"
                                    icon={<TabIcon />}
                                    marginLeft="3"
                                    bg="light.700"
                                    onClick={() =>
                                        window.open(selectedChart, '_blank')
                                    }
                                    isDisabled={!selectedChart}
                                    variant="outline"
                                />
                            </Tooltip>
                        )}
                    </Flex>
                    {selectedChart && (
                        <Center
                            w="100%"
                            height={{
                                base: 'calc(100vh - 263px)',
                                md: 'calc(100vh - 150px)',
                            }}
                            pt={'15px'}
                        >
                            <Viewer
                                aerodromeCharts={aerodromeCharts}
                                fileUrl={`${
                                    window.location.origin
                                }/data/chart/${encodeURIComponent(
                                    selectedChart
                                )}`}
                            />
                        </Center>
                    )}
                </>
            ) : (
                <FullWidthError
                    message={`Error fetching charts for ${selectedAerodrome}. Try again later.`}
                />
            )}
        </>
    )
}
