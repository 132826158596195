import { useCallback, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTier } from '../../../auth/hooks'
import {
    Flex,
    useColorModeValue,
    Text,
    Box,
    Button,
    Portal,
    Tooltip,
} from '@chakra-ui/react'
import { isMobileOnly, isIOS, isMobileSafari } from 'react-device-detect'
import { timeZoneAbbreviation } from '../../../util/dateFormatter'
import {
    menuExpandedState,
    closeLayerSelectorHandler,
    fullscreenModalVisibleState,
    briefingStandardState,
    dataModeState,
    setModalVisibilityState,
    settingsMenuOpenState,
    simulateFreeUserState,
    timeZoneState,
    mfaVerifiedState,
    isBriefingOnlyState,
} from '../../../globalState'

import MainNavBG from './assets/MainNavBG'
import Collections from './components/SidebarCollections'
import { ReactComponent as FeedbackIcon } from '../../../icons/assets/feedback.svg'
import PremiumBadge from '../PremiumBadge'

export default function Sidebar() {
    const [menu, setMenu] = useState(false)
    const [timeZone, setTimeZone] = useRecoilState(timeZoneState)
    const fullscreenModalOpen = useRecoilValue(fullscreenModalVisibleState)
    const briefingModal = useRecoilValue(briefingStandardState)
    const menuExpanded = useRecoilValue(menuExpandedState)
    const closeLayerSelector = useSetRecoilState(closeLayerSelectorHandler)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const menuOpen = Object.keys(menuExpanded)
        .map((dataMode) => menuExpanded[dataMode])
        .some((value) => value)

    const handleChangeTimeZone = () => {
        const newZone =
            timeZone === 'UTC'
                ? Intl.DateTimeFormat().resolvedOptions().timeZone
                : 'UTC'
        setTimeZone(newZone)
    }

    const dataMode = useRecoilState(dataModeState)

    const mobileNavColor = useColorModeValue('light.10', 'dark.10')
    const mobileNavLumpBorder = useColorModeValue('#e9edf3', 'dark.10')
    const mainNavBgColor = useColorModeValue('light.10', 'dark.10')

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        // ** Main Nav Menu **
        <>
            <Portal>
                <Flex
                    pos="fixed"
                    className={{
                        base: 'iosmobile',
                        md: 0,
                    }}
                    left={0}
                    bottom={{
                        base:
                            isMobileOnly && isIOS && isMobileSafari
                                ? '0px'
                                : '0px',
                        md: 0,
                    }}
                    h={{
                        base: isBriefingOnly
                            ? isMobileOnly && isIOS && isMobileSafari
                                ? '55px'
                                : '40px'
                            : isMobileOnly && isIOS && isMobileSafari
                            ? '85px'
                            : '70px',

                        md: '100%',
                    }}
                    paddingBottom={{
                        base:
                            isMobileOnly && isIOS && isMobileSafari
                                ? '25px'
                                : '10px',
                        md: 'none',
                    }}
                    boxShadow={{
                        base:
                            fullscreenModalOpen || briefingModal
                                ? 'none'
                                : '0px -7px 15px -7px rgba(0, 0, 0, 0.15)',
                        md: '5px 0px 15px -5px rgba(0, 0, 0, 0.15)',
                    }}
                    borderRadius="none"
                    w={{ base: '100vw', md: '50px' }}
                    flexDir={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                    bg={useColorModeValue('light.10', 'dark.10')}
                    zIndex={{ base: '1400', md: '1400' }}
                >
                    {dataMode[0] !== 'brief' && (
                        <Box // Shaded background when menu opens on mobile
                            background={{
                                base:
                                    fullscreenModalOpen && menuOpen
                                        ? 'rgba(0,0,0,0.5)'
                                        : 'transparent',
                                md: 'none',
                            }}
                            opacity={
                                fullscreenModalOpen && menuOpen ? '1' : '0'
                            }
                            transition="all ease-in-out 400ms"
                            transitionDelay="0ms"
                            height={{
                                base:
                                    isMobileOnly && isIOS && isMobileSafari
                                        ? 'calc(100% - 85px)'
                                        : 'calc( 100% - 70px)',
                                md: '100%',
                            }}
                            width={{
                                base: '100%',
                                md: 'calc(100% - 50px)',
                            }}
                            visibility={
                                fullscreenModalOpen && menuOpen
                                    ? 'visible'
                                    : 'hidden'
                            }
                            display={{
                                base: 'flex',
                                md: 'none',
                            }}
                            position="fixed"
                            top="0px"
                            left={{ base: '0px', md: 'unset' }}
                            right={{ base: 'unset', md: '0px' }}
                            onClick={() => {
                                closeLayerSelector()
                            }}
                        />
                    )}
                    <Flex
                        id={'modeMenu'}
                        position={'relative'}
                        flexDir={{ base: 'row', md: 'column' }}
                        justifyContent={{
                            base: 'space-around',
                            md: 'flex-start',
                        }}
                        w="100%"
                        height={{
                            base: isBriefingOnly
                                ? isMobileOnly && isIOS && isMobileSafari
                                    ? '55px'
                                    : '40px'
                                : isMobileOnly && isIOS && isMobileSafari
                                ? '85px'
                                : '70px',
                            md: 'auto',
                        }}
                        pb={{
                            base:
                                isMobileOnly && isIOS && isMobileSafari
                                    ? '25px'
                                    : '10px',
                            md: '0',
                        }}
                        opacity={menu ? 1 : 0}
                        transition={'opacity .5s'}
                        px={{
                            base: isBriefingOnly ? '0rem' : '2rem',
                            md: '0rem',
                        }}
                        mt={{ base: '0', md: '70px' }}
                        alignItems={'center'}
                        as="nav"
                        zIndex="1"
                    >
                        {/* Collections are generated from config.js */}
                        <Collections menu={menu} setMenu={setMenu} />
                    </Flex>

                    <Flex // SLIDING LUMP ABOVE MAIN NAV MENU ITEMS
                        position="absolute"
                        display={{ base: 'flex', md: 'none' }}
                        width="100%"
                        top="0"
                        flexDir={{ base: 'row', md: 'column' }}
                        justifyContent={{
                            base: 'space-between',
                            md: 'flex-start',
                        }}
                        px={{ base: '2rem', md: '0rem' }}
                        alignItems={'center'}
                        height={{
                            base:
                                isMobileOnly && isIOS && isMobileSafari
                                    ? '100%'
                                    : '100%',
                            md: '100%',
                        }}
                        borderTop={{
                            base: fullscreenModalOpen ? '1px solid' : 'none',
                            md: 'none',
                        }}
                        borderColor={mobileNavLumpBorder}
                    >
                        {!isBriefingOnly && (
                            <Flex
                                width="25%"
                                marginTop={{
                                    base:
                                        isMobileOnly && isIOS && isMobileSafari
                                            ? '-48px'
                                            : '-33px',
                                    md: '0',
                                }}
                                height="auto"
                                justifyContent="center"
                                transform={
                                    dataMode[0] === 'met'
                                        ? 'translateX(100%)'
                                        : dataMode[0] === 'advs'
                                        ? 'translateX(200%)'
                                        : dataMode[0] === 'brief'
                                        ? 'translateX(300%)'
                                        : 'translateX(0%)'
                                }
                                color={mobileNavColor}
                                transition="transform ease-in-out 250ms"
                                pointerEvents="none"
                            >
                                <MainNavBG
                                    fullscreenModalOpen={fullscreenModalOpen}
                                    width="62px"
                                    height="auto"
                                    color={mainNavBgColor}
                                />
                            </Flex>
                        )}
                    </Flex>

                    <Flex // UTC/LOCAL TIME SWITCH
                        flexDir="column"
                        w="100%"
                        alignItems={'center'}
                        display={{ base: 'none', md: 'block' }}
                        mb={3}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            pt={'20px'}
                            flexDirection="column"
                        >
                            <Tooltip
                                label="Send Us Feedback"
                                hasArrow
                                placement="right"
                                bg={useColorModeValue('dark.10', 'light.10')}
                            >
                                <Button
                                    {...noFocus}
                                    p="0"
                                    height="38px"
                                    width="38px"
                                    alignItems="center"
                                    style={{
                                        minWidth: '38px',
                                    }}
                                    borderRadius="100%"
                                    bg={useColorModeValue(
                                        'light.10',
                                        'dark.10'
                                    )}
                                    transition={'opacity .5s'}
                                    opacity={menu ? 0.8 : 0}
                                    color={useColorModeValue(
                                        'brand.strong',
                                        'dark.100'
                                    )}
                                    onClick={() => window.Userback.open()}
                                    _active={{
                                        opacity: '1',
                                        color: useColorModeValue(
                                            'light.900',
                                            'dark.80'
                                        ),
                                        bg: useColorModeValue(
                                            'light.20',
                                            'dark.20'
                                        ),
                                    }}
                                    _focus={{
                                        opacity: '1',
                                        color: useColorModeValue(
                                            'light.900',
                                            'dark.80'
                                        ),
                                        bg: useColorModeValue(
                                            'light.20',
                                            'dark.20'
                                        ),
                                    }}
                                    _hover={{
                                        opacity: '1',
                                        color: useColorModeValue(
                                            'light.900',
                                            'dark.80'
                                        ),
                                        bg: useColorModeValue(
                                            'light.20',
                                            'dark.20'
                                        ),
                                    }}
                                >
                                    <FeedbackIcon width="24px" height="24px" />
                                </Button>
                            </Tooltip>
                        </Flex>
                        <Flex
                            alignItems="center"
                            fontSize="sm"
                            justifyContent="center"
                            pt={'10px'}
                            mb={'-2px'}
                            flexDirection="column"
                            transition={'opacity .5s'}
                            opacity={menu ? 1 : 0}
                        >
                            <Button
                                id={'timeSwitch'}
                                opacity={isPremium ? '0.6 !important' : '1'}
                                bg={useColorModeValue('light.20', 'dark.20')}
                                fontSize="11px"
                                fontWeight="600"
                                height="40px"
                                width="38px"
                                alignItems="flex-start"
                                style={{
                                    minWidth: '38px',
                                }}
                                justifyContent="initial"
                                paddingLeft="2px"
                                paddingRight="2px"
                                py="2px"
                                boxShadow={
                                    'inset 0px 0px 3px 0px rgba(0,0,0,0.15)'
                                }
                                border="none"
                                borderRadius="11px"
                                {...noFocus}
                                index={timeZone === 'UTC' ? 0 : 1}
                                onClick={() => {
                                    if (mfaExpired) {
                                        setShowResetMfaModal(true)
                                        setSettingsMenuOpen(false)
                                    } else if (isPremium) {
                                        setShowPricingTiersModal(true)
                                        setSettingsMenuOpen(false)
                                    } else {
                                        handleChangeTimeZone(
                                            (prevState) => !prevState
                                        )
                                    }
                                }}
                                _active={{
                                    bg: useColorModeValue(
                                        'light.30',
                                        'dark.30'
                                    ),
                                }}
                                _focus={{
                                    bg: useColorModeValue(
                                        'light.30',
                                        'dark.30'
                                    ),
                                }}
                                _hover={{
                                    bg: useColorModeValue(
                                        'light.30',
                                        'dark.30'
                                    ),
                                }}
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="start"
                                >
                                    <Box
                                        mt="0px"
                                        ml="0px"
                                        px="16px"
                                        height="18px"
                                        width="34px"
                                        background="white"
                                        borderRadius="20px"
                                        bg={useColorModeValue(
                                            '#ffffff',
                                            '#455769'
                                        )}
                                        boxShadow={
                                            '0px 0px 3px -1px rgba(0,0,0,0.3)'
                                        }
                                        border="none"
                                        style={{
                                            transform:
                                                timeZone === 'UTC'
                                                    ? 'translateY(0%)'
                                                    : 'translateY(100%)',
                                            transition: 'transform 400ms',
                                        }}
                                        transform={
                                            timeZone === 'UTC'
                                                ? 'translateX(0%)'
                                                : 'translateX(100%)'
                                        }
                                        transition="transform 400ms"
                                    ></Box>
                                    <Flex
                                        justifyContent="space-evenly"
                                        marginTop="-22px"
                                        marginLeft="0px"
                                        flexDirection="column"
                                        width="34px"
                                        height="36px"
                                    >
                                        <Text
                                            height="18px"
                                            zIndex="1"
                                            fontSize="8px"
                                            fontWeight="700"
                                            letterSpacing="0.8px"
                                            textAlign="center"
                                            paddingTop="8px"
                                            lineHeight="1.3"
                                            opacity={
                                                timeZone === 'UTC' ? '1' : '0.4'
                                            }
                                            color={useColorModeValue(
                                                'brand.strong',
                                                'dark.80'
                                            )}
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            UTC
                                        </Text>
                                        <Text
                                            height="18px"
                                            zIndex="1"
                                            fontSize="8px"
                                            fontWeight="700"
                                            letterSpacing="0.8px"
                                            textAlign="center"
                                            paddingTop="8px"
                                            lineHeight="1.3"
                                            opacity={
                                                timeZone === 'UTC' ? '0.4' : '1'
                                            }
                                            color={useColorModeValue(
                                                'brand.strong',
                                                'dark.80'
                                            )}
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            {timeZoneAbbreviation(
                                                new Date(),
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone
                                            )}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Button>
                            {/* *** PREMIUM BADGE *** */}
                            {isPremium && (
                                <Flex
                                    position="absolute"
                                    right="5px"
                                    mt="-40px"
                                >
                                    <PremiumBadge />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Portal>
        </>
    )
}
