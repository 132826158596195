import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
    mapGlLostState,
    isBriefingOnlyState,
    briefingStandardState,
    fullscreenModalVisibleState,
} from '../../../globalState'
import {
    Modal,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    LightMode,
    Text,
    Icon,
    Box,
    ButtonGroup,
    Link,
} from '@chakra-ui/react'

import { isIOS, isMobileOnly } from 'react-device-detect'
import RefreshIcon from '../../../icons/menu-icons/RefreshIcon'

export default function MapGLLostModal() {
    const mapGlLost = useRecoilValue(mapGlLostState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const briefingStandard = useRecoilValue(briefingStandardState)
    const fullscreen = useRecoilValue(fullscreenModalVisibleState)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (mapGlLost && !isBriefingOnly && !briefingStandard && !fullscreen) {
            onOpen()
        } else {
            onClose()
        }
    }, [
        mapGlLost,
        isBriefingOnly,
        briefingStandard,
        fullscreen,
        onOpen,
        onClose,
    ])

    function refreshPage() {
        setTimeout(() => window.location.reload())
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant="alert"
                    size="4xl"
                    isOpen={isOpen}
                    isCentered
                    closeOnOverlayClick={false}
                    trapFocus={false}
                    scrollBehavior={'inside'}
                >
                    <ModalOverlay
                        borderWidth={4}
                        borderColor="#FF4F44"
                        borderTopRadius={{
                            // reset these values to '0' for now pending further testing on mobile devices
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        borderBottomRadius={{
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        bg={'blackAlpha.600'}
                        // pointerEvents={hide ? 'none' : 'initial'}
                    />

                    <ModalContent borderRadius="15" bg="white">
                        <ModalHeader
                            bg="white"
                            color="light.100"
                            borderBottom="1px solid #eaeaea"
                            paddingStart="6"
                            textAlign="center"
                        >
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                                pr={2}
                            >
                                <Icon // Icon used directly in file to prevent source break when in connection error
                                    boxSize={8}
                                    viewBox="0 0 24 24"
                                    color="red.500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.7305 7.51986C16.086 2.82671 7.91402 2.82671 2.26948 7.51986C1.95356 7.77354 1.91143 8.23863 2.16417 8.55573L11.4313 20.2252C11.5788 20.3943 11.7894 20.5 12 20.5C12.2317 20.5 12.4423 20.3943 12.5687 20.2252L21.8358 8.55573C22.0886 8.23863 22.0464 7.79468 21.7305 7.51986ZM12.125 6C11.7475 6.02723 11.3971 6.19059 11.1275 6.46287C10.8578 6.76238 10.723 7.17079 10.777 7.55198L11.5049 13.2153C11.6936 13.1609 11.9093 13.1337 12.125 13.1337C12.3407 13.1337 12.5564 13.1609 12.7721 13.2153L13.473 7.55198V7.36139C13.473 6.59901 12.8799 6 12.125 6ZM10.75 15.6386C10.75 16.3738 11.3701 17 12.125 17C12.8799 17 13.473 16.401 13.5 15.6114C13.5 14.8762 12.8799 14.25 12.125 14.25C11.3701 14.25 10.75 14.8762 10.75 15.6386Z"
                                        fill="#E53E3E"
                                    />
                                </Icon>
                                <Box pl={2} pr={3} fontFamily="Open Sans">
                                    Map Failed to Load
                                </Box>
                            </Flex>
                        </ModalHeader>
                        <ModalBody
                            textAlign="center"
                            bg="white"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            fontSize={{ base: '0.8rem', md: '0.9rem' }}
                            boxShadow="inset 0px 10px 15px -5px rgba(0,0,0,0.07)"
                        >
                            <Flex
                                overflowX="hidden"
                                px={{ base: 0, md: 10 }}
                                pt={5}
                                alignItems="baseline"
                                flexDirection="column"
                                textAlign="center"
                                width="100%"
                                gap="10px"
                                fontSize="0.8rem"
                            >
                                <Text
                                    fontSize="0.95rem"
                                    fontWeight="600"
                                    textAlign="center"
                                    width="100%"
                                    px="10px"
                                    pt="5px"
                                    color="gray.500"
                                >
                                    We apologise for the inconvenience, but it
                                    seems that the map has unexpectedly failed
                                    to load
                                </Text>
                                <Text
                                    textAlign="center"
                                    width="100%"
                                    fontSize="0.85rem"
                                >
                                    We have been unable to determine the
                                    specific cause of this error
                                </Text>
                                {/* <Divider mt="15px" mb="15px" /> */}
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    mt="20px"
                                    mb="15px"
                                    px="15px"
                                    pt="15px"
                                    pb="17px"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    bg="gray.50"
                                >
                                    <Flex
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems={'center'}
                                        gap="5px"
                                        pt="5px"
                                    >
                                        <Text
                                            fontSize="0.9rem"
                                            color="gray.500"
                                            fontWeight="700"
                                            textTransform={'uppercase'}
                                        >
                                            Immediate Solution
                                        </Text>
                                        <Text
                                            fontWeight="600"
                                            fontSize="0.85rem"
                                        >
                                            Please refresh your browser now to
                                            reload the PreFlight
                                            web-application.
                                            <br /> If you are using a mobile
                                            device, you may need to close and
                                            restart the application running
                                            PreFlight.
                                        </Text>
                                    </Flex>
                                    <Flex
                                        pt="12px"
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems={'center'}
                                        gap="5px"
                                        mb="5px"
                                    >
                                        <Text
                                            fontSize="0.9rem"
                                            fontWeight="700"
                                            color="gray.500"
                                            textTransform={'uppercase'}
                                        >
                                            Recommended Action
                                        </Text>
                                        <Text
                                            fontWeight="600"
                                            fontSize="0.85rem"
                                        >
                                            We recommend updating your device to
                                            the latest software version.
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontWeight="400"
                                        fontSize="0.85rem"
                                        pt="5px"
                                        mb="5px"
                                    >
                                        If the issue persists, please get in
                                        touch with{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            PreFlight Support
                                        </span>{' '}
                                        by submitting a request using the{' '}
                                        <strong>Send us Feedback</strong> tool
                                        within the PreFlight web-app, or
                                        emailing:{' '}
                                        <Link
                                            color="light.200"
                                            href="mailto:support@aeropath.co.nz"
                                        >
                                            support@aeropath.co.nz
                                        </Link>
                                        .
                                    </Text>
                                </Flex>
                                {/* <Divider mt="10px" mb="10px" /> */}
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    letterSpacing="0px"
                                    fontSize="0.8rem"
                                >
                                    <Text
                                        fontSize="0.85rem"
                                        fontWeight="700"
                                        color="gray.500"
                                    >
                                        iOS Users
                                    </Text>
                                    <Text>
                                        If you are using an Apple iOS device,
                                        this issue has likely occurred as a
                                        result of a known intermittent bug
                                        within{' '}
                                        <span style={{ fontWeight: '700' }}>
                                            Apple's iOS
                                        </span>
                                        . This bug specifically impacts MapGL
                                        software on iPhones and iPads running
                                        Apple's recent iOS versions 16 and 17.
                                    </Text>
                                    <Text>
                                        Our mapping software, powered by MapBox,
                                        has been confirmed as affected by this
                                        issue. Please note that such occurrences
                                        are rare and happen infrequently.
                                    </Text>
                                    <Text>
                                        iOS versions{' '}
                                        <span style={{ color: '#00D599' }}>
                                            17.1
                                        </span>{' '}
                                        or later are known to have fewer issues,
                                        although currently are not entirely
                                        immune. While the problem is primarily
                                        associated with Apple's iOS, it could
                                        potentially impact other platforms as
                                        well.
                                    </Text>
                                    <Text>
                                        The PreFlight development team is
                                        actively monitoring this situation and
                                        anticipates a resolution from Apple
                                        soon.
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    gap="10px"
                                    mt="20px"
                                    px="15px"
                                    pt="12px"
                                    pb="15px"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    bg="gray.50"
                                >
                                    <Text
                                        fontSize="0.85rem"
                                        fontWeight="700"
                                        color="gray.500"
                                    >
                                        Additional Recommendations
                                    </Text>
                                    <Text>
                                        Additionally, we suggest optimising
                                        device performance by limiting the
                                        number of apps concurrently running in
                                        the background and periodically clearing
                                        your browser cache.
                                    </Text>
                                    <Text>
                                        Certain background apps on mobiles and
                                        tablets can occasionally impact device
                                        web browser performance, and adopting
                                        this proactive approach can assist users
                                        in identifying and mitigating any
                                        potential factors that may affect the
                                        efficiency of their device.
                                    </Text>
                                    <Text>
                                        Moreover, sometimes certain cookies and
                                        cached files that may relate to
                                        performance issues could be stored in
                                        the browser's history. Clearing the
                                        cache is beneficial in removing any
                                        potential memory blockages that might
                                        contribute to the identified concerns.
                                    </Text>
                                </Flex>
                                <Text
                                    fontSize="0.8rem"
                                    textAlign="center"
                                    width="100%"
                                    mt="20px"
                                    mb="40px"
                                >
                                    We appreciate your understanding as we work
                                    to ensure a seamless experience for our
                                    users.
                                </Text>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            px="15px"
                            pt="15px"
                            pb="16px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ButtonGroup
                                gap="10px"
                                width="100%"
                                justifyContent="center"
                            >
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    variant="solid"
                                    leftIcon={
                                        <RefreshIcon
                                            color="white"
                                            boxSize={5}
                                        />
                                    }
                                    borderRadius="30px"
                                    onClick={refreshPage}
                                    w="60%"
                                    maxWidth="200px"
                                    _focus={{ border: 'none' }}
                                >
                                    Refresh
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
