import { config } from '../config'
import canUse from './canUse'

export default function returnUsableLayers(tier) {
    const { menu, layers } = config
    const layerInfo = {}
    Object.keys(menu).forEach(l => {
        menu[l].forEach(({ id, items }) => {
            !items
                ? (layerInfo[id + '-' + l] = {
                      type: 'layer',
                      canUse: canUse(l, id, tier),
                      mode: l,
                      label: layers[id].label,
                      id,
                  })
                : items.forEach(itm => {
                      layerInfo[itm + '-' + l] = {
                          type: 'layer',
                          canUse: canUse(l, itm, tier),
                          mode: l,
                          label: layers[itm].label,
                          id: itm,
                      }
                  })
        })
    })
    return layerInfo
}

// if (m.toLowerCase().search(query.toLowerCase()) !== -1) {
//     return m
// }
