import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useAdminRole } from '../../../../auth/hooks'
import { isFirefox } from 'react-device-detect'
import {
    Box,
    Button,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    Text,
    Flex,
    Fade,
    Icon,
    Center,
    Portal,
    useDisclosure,
    useColorModeValue,
    useBreakpointValue,
    CircularProgress,
    CircularProgressLabel,
    HStack,
    Badge,
    Tooltip,
} from '@chakra-ui/react'
import Image from '../../../components/Image'
import { useQuery } from 'react-query'
import { isMobileOnly, isIOS, isMobileSafari } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'
import { parseISO, formatDistanceToNow } from 'date-fns'
import PinchDrag, { useScale } from '../../components/PinchDrag'
import ZoomSlider from '../../components/ZoomSlider'

import ErrorToggle from '../../components/ErrorToggle'
import FullWidthError from '../../components/FullWidthError'
import ErrorText from '../../components/ErrorText'

import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
    simulateFreeUserState,
    setModalVisibilityState,
    rainRadarSelection,
    mfaVerifiedState,
    rainRadarRangeRingState,
} from '../../../../globalState'

import PremiumBadge from '../../../controls/PremiumBadge'
import formatDateTime from '../../../../util/dateFormatter'
import TimeSlider from './components/TimeSlider'
import RainRadarLegend from './components/RainRadarLegend'
import RadarIcon from '../../../../icons/menu-icons/RadarIcon'
import RangeIcon from '../../../../icons/menu-icons/RangeIcon'
import ReflectivityIcon from '../../../../icons/menu-icons/ReflectivityIcon'
import { ReactComponent as rainRadarIcon } from '../../../../icons/assets/rain-radar.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-nocircle.svg'
import { BsDot } from 'react-icons/bs'
import radarscope10nm30nm from './assets/radarscope-10nm-30nm.svg'
import radarscope25nm75nm from './assets/radarscope-25nm-75nm.svg'
import radarscope50nm150nm from './assets/radarscope-50nm-150nm.svg'
import radarscope20km60km from './assets/radarscope-20km-60km.svg'
import radarscope50km100km from './assets/radarscope-50km-100km.svg'
import radarscope100km300km from './assets/radarscope-100km-300km.svg'

export default function RainRadarImageLooper() {
    const { getAccessTokenSilently } = useAuth0()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const isAdmin = useAdminRole()
    const [selectedRegion, setSelectedRegion] =
        useRecoilState(rainRadarSelection)
    const [selectedSource, setSelectedSource] = useState(null)
    const [selectedRange, setSelectedRange] = useState(null)
    const [selectedSet, setSelectedSet] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [previousSelectedTime, setPreviousSelectedTime] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [looping, setLooping] = useState(false)
    const [speed, setSpeed] = useState('1x')
    const [selectedRangering, setSelectedRangering] = useRecoilState(
        rainRadarRangeRingState
    )
    const [selectedSetTimes, setSelectedSetTimes] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState([])
    const [detailImagesLoaded, setDetailImagesLoaded] = useState([])
    const [percentLoaded, setPercentLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [regions, setRegions] = useState([])
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [dbzHide, setDbzHide] = useState(false)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const [detailsShow, setDetailsShow] = useState(false)
    const [reflectivityShow, setReflectivityShow] = useState(false)
    const timeZone = useRecoilValue(timeZoneState)
    const [selectedSourcePosition, setSelectedSourcePosition] = useState(0)

    const { isOpen, onOpen, onClose } = useDisclosure()

    // PinchZoom
    const { scale, setScale, xy, setXy } = useScale()
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.rainRadarImagery) {
            setImagesLoaded([])
            setDetailImagesLoaded([])
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, layerSelection, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    const fetchRainRadarImagery = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/rainradar`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const { data, status } = useQuery(
        `rainRadarImagery`,
        fetchRainRadarImagery,
        { refetchOnWindowFocus: false }
    )

    // useEffect(() => { // this is fallback option if any bugs/issues with useEffect below
    //     if (data) {
    //         const dataArray = Object.keys(data)
    //         setRegions(dataArray)
    //     }
    // }, [data])

    useEffect(() => {
        if (data) {
            const dataArray = Object.keys(data)
            const containsNewZealand = dataArray.includes('New Zealand')
            // Reorder the array if "New Zealand" is present
            // Add Southland to bottom aswell
            const reorderedData = containsNewZealand
                ? [...dataArray]
                      //   .filter((value) => value !== 'New Zealand') // use this for testing only
                      .sort((a, b) => {
                          if (a === 'New Zealand') {
                              return -1
                          } else if (b === 'New Zealand') {
                              return 1
                          }
                          return 0
                      })
                      .sort((a, b) => {
                          if (a === 'Southland') {
                              return 1
                          } else if (b === 'Southland') {
                              return -1
                          }
                          return 0
                      })
                : dataArray
            setRegions(reorderedData)
        }
    }, [data])

    const nzIsSelected = selectedRegion === 'New Zealand'
    const regionIsSelected = selectedRegion !== 'New Zealand'
    const includesNzImage =
        regions.includes('New Zealand') && Boolean(data['New Zealand']?.length)

    const isOnlyNZ =
        regions && regions.length === 1 && regions[0] === 'New Zealand' // this loads an error message in Menu if regions are offline

    const singleSourceOnly =
        !nzIsSelected &&
        selectedRegion &&
        data &&
        data[selectedRegion] &&
        data[selectedRegion].length < 2

    useEffect(() => {
        const filteredRegionsWithData =
            data &&
            Object.keys(
                Object.fromEntries(
                    Object.entries(data).filter(
                        ([key, value]) => value !== null
                    )
                )
            )
        if (
            selectedRegion &&
            filteredRegionsWithData?.some((value) => value === selectedRegion)
        ) {
            setSelectedRegion(selectedRegion)
        } else if (regions) {
            filteredRegionsWithData &&
                setSelectedRegion(filteredRegionsWithData[0])
        }

        if (
            data &&
            regions &&
            regions.length &&
            includesNzImage &&
            ((selectedRegion && selectedRegion === 'New Zealand') ||
                selectedRegion === null)
        ) {
            // console.log('firing 1')
            setSelectedSource('radar')
            setSelectedRange('900km')
            if (isPremium) {
                setSelectedSourcePosition(1)
            } else {
                setSelectedSourcePosition(0)
            }
        } else if (isPremium && data && regions && regions.length) {
            // console.log('firing 2')
            setSelectedSource('aerodrome')
            setSelectedRange('130km')
            setSelectedSourcePosition(1)
        } else if (data && regions && regions.length) {
            // console.log('firing 3')
            setSelectedSource('radar')
            setSelectedRange('300km')
            setSelectedSourcePosition(0)
        }
    }, [
        isPremium,
        selectedRegion,
        data,
        includesNzImage,
        regions,
        setSelectedRegion,
    ])

    useEffect(() => {
        if (
            data &&
            regions &&
            selectedRegion &&
            selectedSource &&
            selectedRange
        ) {
            setImagesLoaded([])
            setDetailImagesLoaded([])
            const selected =
                data &&
                (data[selectedRegion || Object.keys(data)[0]] || []).filter(
                    (i) => i.type === selectedSource
                )[0]
            const rangeSelected =
                selected && selected.ranges && selected.ranges[selectedRange]
                    ? selectedRange
                    : selected &&
                      selected.ranges &&
                      Object.keys(selected.ranges)[0]

            if (selectedRange !== rangeSelected) {
                setSelectedRange(rangeSelected)
            } else {
                setSelectedSet(selected.ranges[rangeSelected])
                setSelectedTime(
                    selected.ranges[rangeSelected]['images'][
                        selected.ranges[rangeSelected]['images'].length - 1
                    ]['time']
                )
                setSelectedSetTimes(
                    selected.ranges[rangeSelected]['images'].map((i) => i.time)
                )
            }
        }
    }, [data, regions, selectedRange, selectedSource, selectedRegion])

    useEffect(() => {
        selectedSet
            ? setSelectedSetTimes(selectedSet.images.map((i) => i.time))
            : selectedSet &&
              setSelectedSetTimes(selectedSet.images[selectedSet.length - 1])
    }, [selectedSet])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded([])
            setDetailImagesLoaded([])
        }
    }, [showNetworkErrors])

    // useEffect(() => {
    //     data &&
    //         selectedSet &&
    //         setLoaded(
    //             imagesLoaded === selectedSet.images.length &&
    //                 detailImagesLoaded === selectedSet.images.length
    //         )
    //     if (selectedSet && selectedSet.images) {
    //         const percent =
    //             selectedSet.images && 100 / selectedSet.images.length
    //         setPercentLoaded(imagesLoaded * percent)
    //     }
    // }, [data, detailImagesLoaded, imagesLoaded, selectedSet])

    useEffect(() => {
        if (selectedSet) {
            const loadedArray = selectedSet.images.map((i) =>
                imagesLoaded.includes(i.url)
            )

            const loadedDetailArray = selectedSet.images.map((i) =>
                detailImagesLoaded.includes(i.url)
            )

            const loaded = loadedArray.filter((i) => i)
            const detailLoaded = loadedDetailArray.filter((i) => i)
            const percent = Math.round(
                (100 / selectedSet.images.length) *
                    ((loaded.length + detailLoaded.length) / 2)
            )

            setPercentLoaded(percent)
        }
    }, [selectedSet, detailImagesLoaded, imagesLoaded])

    useEffect(() => {
        if (selectedSet && selectedSet.images.length) {
            setLoaded(Math.round(percentLoaded) === 100)
        }
    }, [selectedSet, percentLoaded])

    function firstImageLoaded() {
        return (
            selectedSet?.images?.[selectedSet.images.length - 1]?.url &&
            imagesLoaded.includes(
                selectedSet.images[selectedSet.images.length - 1].url
            )
        )
    }

    //TODO: reset image load count to reset when the modal is shown again.
    function onMainImageLoad(id) {
        setImagesLoaded((i) => [...i, id])
    }

    function onDetailImageLoad(id) {
        setDetailImagesLoaded((i) => [...i, id])
    }

    const rangeRing =
        selectedRange === '300km'
            ? selectedRangering === 'nm'
                ? radarscope50nm150nm
                : radarscope100km300km
            : selectedRange === '70km'
            ? selectedRangering === 'nm'
                ? radarscope10nm30nm
                : radarscope20km60km
            : selectedRange === '130km'
            ? selectedRangering === 'nm'
                ? radarscope25nm75nm
                : radarscope50km100km
            : null

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const screenWidthSmall = window.innerWidth < 992

    // MOBILE SOURCE CHANGE BY SWIPE LEFT/RIGHT
    const sourceSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (
                isPremium &&
                regionIsSelected &&
                selectedRegion &&
                data &&
                data[selectedRegion] === 'radar'
            ) {
                setShowPricingTiers(true)
            } else if (
                screenWidthSmall &&
                selectedSourcePosition > 0 &&
                !isPremium
            ) {
                setSelectedSource(
                    data[selectedRegion][selectedSourcePosition - 1].type
                )
                setSelectedSourcePosition(selectedSourcePosition - 1)
                setScale(1)
                setXy({
                    x: 0,
                    y: 0,
                })
            }
        },
        onSwipedRight: () => {
            if (screenWidthSmall && selectedSourcePosition < 1) {
                setSelectedSource(
                    data[selectedRegion][selectedSourcePosition + 1].type
                )
                setSelectedSourcePosition(selectedSourcePosition + 1)
                setScale(1)
                setXy({
                    x: 0,
                    y: 0,
                })
            }
        },
    })

    // MOBILE RADAR INFO SHOW BY SWIPE DOWN
    const infoSwipe = useSwipeable({
        onSwipedDown: () => {
            setDetailsShow(true)
        },
        onSwipedUp: () => {
            setDetailsShow(false)
        },
    })

    // MOBILE REFLECTIVITY SHOW BY SWIPE DOWN // TODO - disabled for now due to pinchZoom

    const legendSwipe = useSwipeable({
        onSwipedLeft: () => {
            setDbzHide(true)
        },
        onSwipedRight: () => {
            setDbzHide(false)
        },
    })

    // const reflectivityswipe = useSwipeable({
    //     onSwipedDown: () => {
    //         if (scale === 1) {
    //             setReflectivityShow(true)
    //         }
    //     },
    //     onSwipedUp: () => {
    //         if (scale === 1) {
    //             setReflectivityShow(false)
    //         }
    //     },
    //     onSwipedLeft: () => {
    //         if (scale === 1) {
    //             selectedRange === '70km'
    //                 ? setSelectedRange('130km')
    //                 : setSelectedSource('aerodrome')
    //         }
    //     },
    //     onSwipedRight: () => {
    //         if (scale === 1) {
    //             selectedRange === '130km'
    //                 ? setSelectedRange('70km')
    //                 : setSelectedSource('radar')
    //         }
    //     },
    // })

    //PORTAL REFERENCES
    const mobileInfo = useRef()
    const desktopInfo = useRef()

    const portalInfo = useBreakpointValue({
        base: { containerRef: mobileInfo },
        lg: { containerRef: desktopInfo },
    })

    function isOutOfDate() {
        const itemData =
            data &&
            selectedTime &&
            selectedSet.images.filter((i) => selectedTime === i.time)[0]
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow = showOutdatedErrors || isOutOfDate().outofdate

    const errorHeight = useBreakpointValue({
        base:
            380 +
            (nzIsSelected && 12) +
            (isAdmin && showErrorToggle && 30) +
            (errorShow && 80),
        md:
            200 +
            (regionIsSelected && 40) +
            (isAdmin && showErrorToggle && 30) +
            (errorShow && 75),
        lg: 150 + (errorShow && 70),
        xl: 150 + (errorShow && 40),
    })

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    // useEffect(() => {
    //     console.log('Data: ', data)
    //     //     console.log('regions: ', regions)
    //     //     console.log('isOnlyNZ: ', isOnlyNZ)
    //     console.log('includesNzImage ', includesNzImage)
    //     console.log('nzIsSelected ', nzIsSelected)
    //     console.log('selectedSource ', selectedSource)
    //     console.log('selectedRange ', selectedRange)
    //     console.log('selectedSourcePosition ', selectedSourcePosition)
    //     console.log('selectedRegion ', selectedRegion)
    //     //     // // console.log('filteredRegionsWithData ', filteredRegionsWithData)
    // }, [data, isOnlyNZ, regions, selectedRegion, includesNzImage])

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            lg: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }} // added flex here as header height not sticking causing spacing issues elswhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor={{
                                    base: '#D9DFE5',
                                    ml: '#E2E8F0',
                                }}
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        h="100%"
                                        w="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                md: '180px',
                                            }}
                                            paddingLeft={{
                                                base: '40px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={rainRadarIcon}
                                                ml={{
                                                    base: minimiseActive
                                                        ? '0px'
                                                        : '5px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    Rain Radar
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: minimiseActive
                                                    ? '140px'
                                                    : '100px',
                                                md: '500px',
                                                ml: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Flex
                                                color="light.100"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                pr={{
                                                    base: '0px',
                                                    md: '10px',
                                                    ml: '10px',
                                                }}
                                                pt={{
                                                    base: '1px',
                                                    lg: '0px',
                                                }}
                                                width={{
                                                    base: '40px',
                                                    ml: '100%',
                                                }}
                                                opacity={{
                                                    base: minimiseActive
                                                        ? '1'
                                                        : '0',
                                                    ml: '1',
                                                }}
                                                visibility={{
                                                    base: minimiseActive
                                                        ? 'visible'
                                                        : 'hidden',
                                                    ml: 'visible',
                                                }}
                                                transition="all ease 300ms"
                                            >
                                                <ZoomSlider
                                                    scale={scale}
                                                    xy={xy}
                                                    setScale={setScale}
                                                    setXy={setXy}
                                                    maxScale={8}
                                                />
                                            </Flex>
                                            <Button // REFLECTIVITY BUTTON (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    reflectivityShow
                                                        ? '1'
                                                        : '0.8'
                                                }
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setReflectivityShow(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                    if (reflectivityShow) {
                                                        setDbzHide(false)
                                                    }
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                    background: 'none',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                    background: 'none',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color={
                                                        reflectivityShow
                                                            ? 'light.200'
                                                            : 'light.100'
                                                    }
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="36px"
                                                    style={{
                                                        minWidth: '36px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <ReflectivityIcon
                                                        boxSize="24px"
                                                        color={
                                                            reflectivityShow
                                                                ? 'light.200'
                                                                : 'light.100'
                                                        }
                                                    />
                                                </Flex>
                                            </Button>

                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    detailsShow ? '1' : '0.8'
                                                }
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setDetailsShow(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                    background: 'none',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                    background: 'none',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        as={InfoIcon}
                                                        boxSize="26px"
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={
                                            isAdmin && showErrorToggle
                                                ? '100%'
                                                : '0'
                                        }
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS && isMobileSafari
                                        ? 'calc(100% - 155px)'
                                        : 'calc(100% - 140px)',
                                lg: 'calc(100% - 70px)',
                            }}
                            background="#edf1f7"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Flex
                                    flexDirection={{
                                        base: 'column-reverse',
                                        md: 'row',
                                    }}
                                    w="100%"
                                    h="100%"
                                    bg={{
                                        base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 70%)',
                                        md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 80%)',
                                    }}
                                >
                                    <Flex // LEFT MENU //
                                        colSpan={2}
                                        mb={{
                                            base: minimiseOnZoom
                                                ? '-90px'
                                                : '0px',
                                            md: '0px',
                                        }}
                                        transition="all ease 300ms"
                                        position="relative"
                                        width={{ base: '100%', md: '200px' }}
                                        minWidth={{ base: '100%', md: '200px' }}
                                        minHeight={{
                                            base: '55px',
                                            md: '100%',
                                        }}
                                        background={{
                                            base: 'light.55',
                                            md: 'light.10',
                                        }}
                                        backdropFilter={{
                                            base: 'blur(20px)',
                                            md: 'none',
                                        }}
                                        borderRight={{
                                            base: 'none',
                                            md: '1px solid #E2E8F0',
                                        }}
                                        flexDirection={{
                                            base: 'row',
                                            md: 'column',
                                        }}
                                        pb={{ base: '1px', md: '0px' }}
                                        zIndex="102"
                                    >
                                        <Flex
                                            flexDirection={{
                                                base: 'row',
                                                md: 'column',
                                            }}
                                            alignItems={{
                                                base: 'center',
                                                md: 'unset',
                                            }}
                                            // This needs to be set in order to allow scrolling
                                            h={{
                                                base: '100%',
                                                md: '100%',
                                            }}
                                            overflowX={{
                                                base: 'auto',
                                                md: 'hidden',
                                            }}
                                            overflowY={{
                                                base: 'hidden',
                                                md: 'auto',
                                            }}
                                            pl={{
                                                base: '15px',
                                                md: '0px',
                                            }}
                                            pr={{
                                                base: '15px',
                                                md: '0px',
                                            }}
                                            borderTop={{
                                                base: '1px solid #E2E8F0',
                                                md: 'none',
                                            }}
                                        >
                                            {includesNzImage && (
                                                <Flex // NEW ZEALAND
                                                    flexDirection={{
                                                        base: 'row',
                                                        md: 'column',
                                                    }}
                                                    pt={{
                                                        base: '0px',
                                                        md: '15px',
                                                    }}
                                                    height={{
                                                        base: 'auto',
                                                        md:
                                                            status ===
                                                                'loading' ||
                                                            (regions &&
                                                                !regions.includes(
                                                                    'New Zealand'
                                                                ))
                                                                ? '0px'
                                                                : '55px',
                                                    }}
                                                    transition="all ease 300ms"
                                                >
                                                    {regions
                                                        .filter(
                                                            (region) =>
                                                                region ===
                                                                'New Zealand'
                                                        )
                                                        .map((nz, i) => (
                                                            <Button
                                                                key={`regionButton-${nz}`}
                                                                {...noFocus}
                                                                bg="transparent !important"
                                                                justifyContent={{
                                                                    base: 'center',
                                                                    md: !includesNzImage
                                                                        ? 'space-between'
                                                                        : 'flex-start',
                                                                }}
                                                                zIndex="2"
                                                                color={
                                                                    selectedRegion ===
                                                                    nz
                                                                        ? 'light.200'
                                                                        : 'light.100'
                                                                }
                                                                fontSize={{
                                                                    base:
                                                                        selectedRegion ===
                                                                        nz
                                                                            ? '0.8rem'
                                                                            : '0.8rem',
                                                                    md: '13px',
                                                                }}
                                                                fontWeight={{
                                                                    base: '500',
                                                                    md: '400',
                                                                }}
                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                textAlign={{
                                                                    base: 'center',
                                                                    md: 'left',
                                                                }}
                                                                pl={{
                                                                    base: '20px',
                                                                    md: '20px',
                                                                }}
                                                                pr={{
                                                                    base:
                                                                        i ===
                                                                        nz.length
                                                                            ? '35px'
                                                                            : '20px',

                                                                    md: '10px',
                                                                }}
                                                                letterSpacing={{
                                                                    base:
                                                                        selectedRegion ===
                                                                        nz
                                                                            ? '0.1px'
                                                                            : '0.1px',
                                                                    md: '0.1px',
                                                                }}
                                                                onClick={() => {
                                                                    if (
                                                                        includesNzImage
                                                                    ) {
                                                                        setSelectedSource(
                                                                            'radar'
                                                                        )
                                                                        setSelectedRange(
                                                                            '900km'
                                                                        )
                                                                        setSelectedRegion(
                                                                            nz
                                                                        )
                                                                        setScale(
                                                                            1
                                                                        )
                                                                        setXy({
                                                                            x: 0,
                                                                            y: 0,
                                                                        })
                                                                        if (
                                                                            isPremium
                                                                        ) {
                                                                            setSelectedSourcePosition(
                                                                                1
                                                                            )
                                                                        } else {
                                                                            setSelectedSourcePosition(
                                                                                0
                                                                            )
                                                                        }
                                                                    }
                                                                }}
                                                                transition="all ease 300ms"
                                                                w={{
                                                                    base: 'max-content',
                                                                    md: '100%',
                                                                }}
                                                                minWidth={{
                                                                    base: 'max-content',
                                                                    md: '100%',
                                                                }}
                                                                height="40px"
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                    color: !includesNzImage
                                                                        ? 'light.100'
                                                                        : 'light.200',
                                                                }}
                                                                userSelect="none"
                                                            >
                                                                <Text
                                                                    opacity={
                                                                        includesNzImage
                                                                            ? '1'
                                                                            : '0.5'
                                                                    }
                                                                >
                                                                    {nz}
                                                                </Text>
                                                                {/* *** OFFLINE BADGE *** */}
                                                                {!includesNzImage && (
                                                                    <Flex
                                                                        // position="absolute"
                                                                        ml="5px"
                                                                        zIndex="100"
                                                                    >
                                                                        <OfflineBadge />
                                                                    </Flex>
                                                                )}
                                                            </Button>
                                                        ))}

                                                    <Flex // MENU SLIDING BACKGROUND
                                                        position="absolute"
                                                        width="206px"
                                                        display={{
                                                            base: 'none',
                                                            md: 'flex',
                                                        }}
                                                        opacity={
                                                            nzIsSelected
                                                                ? '1'
                                                                : '0'
                                                        }
                                                        visibility={
                                                            nzIsSelected
                                                                ? 'visible'
                                                                : 'hidden'
                                                        }
                                                        transition="opacity ease 0.4s"
                                                    >
                                                        <Box
                                                            mt="0px"
                                                            ml="0px"
                                                            mr="0px"
                                                            height="40px"
                                                            width="100%"
                                                            marginBottom="0px"
                                                            background="light.20"
                                                            borderRightRadius="7px"
                                                        >
                                                            <Box
                                                                width="100%"
                                                                position="absolute"
                                                                height="100%"
                                                                borderRightRadius="7px"
                                                                border="1px solid"
                                                                borderLeft="none"
                                                                borderColor="#e2e8f0"
                                                                opacity="1"
                                                            ></Box>
                                                            <Box
                                                                background="light.200"
                                                                width="3px"
                                                                position="absolute"
                                                                right="5px"
                                                                top="14%"
                                                                height="72%"
                                                                borderRadius="5px"
                                                            ></Box>
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            )}

                                            <Flex // REGIONS
                                                display={{
                                                    base: regions.includes(
                                                        'New Zealand'
                                                    )
                                                        ? 'flex'
                                                        : 'none',
                                                    md: 'flex',
                                                }}
                                                mt={{
                                                    base: '0px',
                                                    md: '20px',
                                                }}
                                                fontSize="0.65rem"
                                                fontWeight="400"
                                                fontFamily="'Open Sans', sans-serif"
                                                flex="1"
                                                textAlign="left"
                                                paddingLeft={{
                                                    base: regions.includes(
                                                        'New Zealand'
                                                    )
                                                        ? '10px'
                                                        : '0px',
                                                    md: '21px',
                                                }}
                                                paddingRight={{
                                                    base: regions.includes(
                                                        'New Zealand'
                                                    )
                                                        ? '10px'
                                                        : '0px',
                                                    md: '5px',
                                                }}
                                                maxHeight="40px"
                                                alignItems="center"
                                                borderBottom={{
                                                    base: 'none',
                                                    md: '1px solid #E2E8F0',
                                                }}
                                                marginBottom={{
                                                    base: '0px',
                                                    md: '15px',
                                                }}
                                            >
                                                <Flex
                                                    display={{
                                                        base: 'flex',
                                                        md: 'none',
                                                    }}
                                                >
                                                    <Icon
                                                        as={BsDot}
                                                        fontSize="1.5rem"
                                                        color="gray.300"
                                                    />
                                                </Flex>
                                                <Text
                                                    display={{
                                                        base: 'none',
                                                        md: 'flex',
                                                    }}
                                                    marginTop="auto"
                                                    marginBottom="auto"
                                                    color="light.300"
                                                    fontSize="11px"
                                                    fontWeight="400"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                >
                                                    Regions
                                                </Text>
                                            </Flex>
                                            <Flex
                                                flexDirection={{
                                                    base: 'row',
                                                    md: 'column',
                                                }}
                                            >
                                                {!isOnlyNZ &&
                                                    regions
                                                        .filter(
                                                            (region) =>
                                                                region !==
                                                                'New Zealand'
                                                        )
                                                        .map((region, i) => {
                                                            const hasRadarImagery =
                                                                data &&
                                                                Boolean(
                                                                    data[region]
                                                                        ?.length
                                                                )

                                                            // check if radar images are present for respective region
                                                            const regionHasRadarImages =
                                                                data[region]
                                                                    .filter(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            value.type ===
                                                                            'radar'
                                                                    )
                                                                    .some(
                                                                        (v) =>
                                                                            v
                                                                                .ranges[
                                                                                '300km'
                                                                            ] &&
                                                                            v
                                                                                .ranges[
                                                                                '300km'
                                                                            ]
                                                                                .images &&
                                                                            v
                                                                                .ranges[
                                                                                '300km'
                                                                            ]
                                                                                .images
                                                                                .length >
                                                                                0
                                                                    )

                                                            // check if aerodrome images are present for respective region
                                                            const regionHasAerodromeImages =
                                                                data[region]
                                                                    .filter(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            value.type ===
                                                                            'aerodrome'
                                                                    )
                                                                    .some(
                                                                        (v) =>
                                                                            v
                                                                                .ranges[
                                                                                '70km'
                                                                            ] &&
                                                                            v
                                                                                .ranges[
                                                                                '70km'
                                                                            ]
                                                                                .images &&
                                                                            v
                                                                                .ranges[
                                                                                '70km'
                                                                            ]
                                                                                .images
                                                                                .length >
                                                                                0 &&
                                                                            v
                                                                                .ranges[
                                                                                '130km'
                                                                            ] &&
                                                                            v
                                                                                .ranges[
                                                                                '130km'
                                                                            ]
                                                                                .images &&
                                                                            v
                                                                                .ranges[
                                                                                '130km'
                                                                            ]
                                                                                .images
                                                                                .length >
                                                                                0
                                                                    )

                                                            // this could be improved to allow either radar type to still show if the other is offline
                                                            const selectedRegionOffline =
                                                                !hasRadarImagery ||
                                                                !(
                                                                    regionHasRadarImages &&
                                                                    regionHasAerodromeImages
                                                                )

                                                            return (
                                                                <Button
                                                                    key={`regionButton-${region}`}
                                                                    {...noFocus}
                                                                    bg="transparent !important"
                                                                    justifyContent={{
                                                                        base: 'center',
                                                                        md: selectedRegionOffline
                                                                            ? 'space-between'
                                                                            : 'flex-start',
                                                                    }}
                                                                    zIndex="2"
                                                                    color={
                                                                        selectedRegion ===
                                                                        region
                                                                            ? 'light.200'
                                                                            : 'light.100'
                                                                    }
                                                                    fontSize={{
                                                                        base:
                                                                            selectedRegion ===
                                                                            region
                                                                                ? '0.8rem'
                                                                                : '0.8rem',
                                                                        md: '13px',
                                                                    }}
                                                                    fontWeight={{
                                                                        base: '500',
                                                                        md: '400',
                                                                    }}
                                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                    textAlign={{
                                                                        base: 'center',
                                                                        md: 'left',
                                                                    }}
                                                                    pl={{
                                                                        base: '20px',
                                                                        md: '20px',
                                                                    }}
                                                                    pr={{
                                                                        base:
                                                                            i ===
                                                                            region.length
                                                                                ? '35px'
                                                                                : '20px',

                                                                        md: '10px',
                                                                    }}
                                                                    letterSpacing={{
                                                                        base:
                                                                            selectedRegion ===
                                                                            region
                                                                                ? '0.1px'
                                                                                : '0.1px',
                                                                        md: '0.1px',
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            !selectedRegionOffline
                                                                        ) {
                                                                            if (
                                                                                nzIsSelected &&
                                                                                !isPremium
                                                                            ) {
                                                                                setSelectedSource(
                                                                                    'radar'
                                                                                )
                                                                            } else if (
                                                                                isPremium
                                                                            ) {
                                                                                setSelectedSource(
                                                                                    'aerodrome'
                                                                                )
                                                                                setSelectedRange(
                                                                                    '130km'
                                                                                )
                                                                                setSelectedSourcePosition(
                                                                                    1
                                                                                )
                                                                            }
                                                                            setSelectedRegion(
                                                                                region
                                                                            )
                                                                            setScale(
                                                                                1
                                                                            )
                                                                            setXy(
                                                                                {
                                                                                    x: 0,
                                                                                    y: 0,
                                                                                }
                                                                            )
                                                                        }
                                                                    }}
                                                                    transition="all ease 300ms"
                                                                    w={{
                                                                        base: 'max-content',
                                                                        md: '100%',
                                                                    }}
                                                                    minWidth={{
                                                                        base: 'max-content',
                                                                        md: '100%',
                                                                    }}
                                                                    height="40px"
                                                                    _hover={{
                                                                        cursor: selectedRegionOffline
                                                                            ? 'not-allowed'
                                                                            : 'pointer',
                                                                        color: selectedRegionOffline
                                                                            ? 'light.100'
                                                                            : 'light.200',
                                                                    }}
                                                                    userSelect="none"
                                                                >
                                                                    <Text
                                                                        opacity={
                                                                            selectedRegionOffline
                                                                                ? '0.5'
                                                                                : '1'
                                                                        }
                                                                    >
                                                                        {region}
                                                                    </Text>
                                                                    {/* *** OFFLINE BADGE *** */}
                                                                    {selectedRegionOffline && (
                                                                        <Flex
                                                                            // position="absolute"
                                                                            ml="5px"
                                                                            zIndex="100"
                                                                        >
                                                                            <OfflineBadge />
                                                                        </Flex>
                                                                    )}
                                                                </Button>
                                                            )
                                                        })}
                                                {status !== 'loading' &&
                                                    !isOnlyNZ && (
                                                        <Flex // MENU SLIDING BACKGROUND
                                                            position="absolute"
                                                            width="206px"
                                                            display={{
                                                                base: 'none',
                                                                md: 'flex',
                                                            }}
                                                            transform={
                                                                nzIsSelected ||
                                                                status ===
                                                                    'loading' ||
                                                                !regions.length
                                                                    ? 'none'
                                                                    : includesNzImage
                                                                    ? `translateY(${
                                                                          regions.indexOf(
                                                                              selectedRegion
                                                                          ) - 1
                                                                      }00%)`
                                                                    : `translateY(${regions.indexOf(
                                                                          selectedRegion
                                                                      )}00%)`
                                                            }
                                                            opacity={
                                                                regionIsSelected
                                                                    ? '1'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                regionIsSelected
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            transition="all ease 0.3s"
                                                        >
                                                            <Box
                                                                mt="0px"
                                                                ml="0px"
                                                                mr="0px"
                                                                height="40px"
                                                                width="100%"
                                                                marginBottom="0px"
                                                                background="light.20"
                                                                borderRightRadius="7px"
                                                            >
                                                                <Box
                                                                    width="100%"
                                                                    position="absolute"
                                                                    height="100%"
                                                                    borderRightRadius="7px"
                                                                    border="1px solid"
                                                                    borderLeft="none"
                                                                    borderColor="#e2e8f0"
                                                                    opacity="1"
                                                                ></Box>
                                                                <Box
                                                                    background="light.200"
                                                                    width="3px"
                                                                    position="absolute"
                                                                    right="5px"
                                                                    top="14%"
                                                                    height="72%"
                                                                    borderRadius="5px"
                                                                ></Box>
                                                            </Box>
                                                        </Flex>
                                                    )}

                                                {isOnlyNZ && (
                                                    <Flex
                                                        px="5px"
                                                        w={{
                                                            base: 'max-content',
                                                            md: '100%',
                                                        }}
                                                        minWidth={{
                                                            base: 'max-content',
                                                            md: '100%',
                                                        }}
                                                        height="40px"
                                                        zIndex="2"
                                                        color={'red.500'}
                                                        fontSize={{
                                                            base: '0.8rem',
                                                            md: '13px',
                                                        }}
                                                        fontWeight={{
                                                            base: '500',
                                                            md: '400',
                                                        }}
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                        pl={{
                                                            base: '20px',
                                                            md: '20px',
                                                        }}
                                                        pr={{
                                                            base: '20px',

                                                            md: '10px',
                                                        }}
                                                        justifyContent={{
                                                            base: 'center',
                                                            md: 'flex-start',
                                                        }}
                                                        alignItems="center"
                                                    >
                                                        Regions currently
                                                        unavailable
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        flexDirection={{
                                            base: 'column-reverse',
                                            lg: 'row',
                                        }}
                                        position={{
                                            base: 'absolute',
                                            md: 'relative',
                                        }}
                                        top={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? '100px'
                                                    : '70px',
                                            md: '0px',
                                        }}
                                        w="100%"
                                        h={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? 'calc(100% - 100px)'
                                                    : 'calc(100% - 70px)',
                                            md: '100%',
                                        }}
                                    >
                                        <Flex // IMAGE DISPLAY AND TIME SLIDER
                                            width="100%"
                                            h="100%"
                                            zIndex="100"
                                            position={'relative'}
                                            flexDirection="column"
                                            alignItems="center"
                                            whiteSpace="pre-line"
                                            overflowX="hidden"
                                            overflowY="hidden"
                                            pt={{
                                                base:
                                                    regionIsSelected &&
                                                    minimiseOnZoom
                                                        ? '50px'
                                                        : '0px',
                                                ml: '0px',
                                            }}
                                            transition="all ease 300ms"
                                        >
                                            {data && errorShow && (
                                                <Flex
                                                    px="15px"
                                                    pt="5px"
                                                    pb="5px"
                                                    mt={
                                                        regionIsSelected &&
                                                        minimiseOnZoom &&
                                                        '-50px'
                                                    }
                                                    transition="all ease 300ms"
                                                    width="100%"
                                                    justifyContent="center"
                                                    zIndex="1"
                                                    backdropFilter="blur(4px)"
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                        md: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                    }}
                                                >
                                                    <ErrorText>
                                                        There has been an issue
                                                        with the connection
                                                        between PreFlight and
                                                        MetService. This imagery
                                                        was last validated with
                                                        MetService{' '}
                                                        <strong>
                                                            {formatDistanceToNow(
                                                                parseISO(
                                                                    isOutOfDate()
                                                                        .updated
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </strong>
                                                        .<br />
                                                        There is a possibility
                                                        that we are unable to
                                                        display imagery that is
                                                        up to date. Please check
                                                        back again soon.
                                                    </ErrorText>
                                                </Flex>
                                            )}

                                            <Flex
                                                w="100%"
                                                maxWidth={`calc(100vh - ${errorHeight}px)`}
                                                h="100%"
                                                maxHeight={{
                                                    base: 'calc(100vh - 120px)',
                                                    lg: '100%',
                                                }}
                                                minHeight={{
                                                    base: '0',
                                                    lg: '100%',
                                                }}
                                                pos={'relative'}
                                                alignItems="baseline"
                                                justifyContent="center"
                                                padding="20px"
                                                paddingBottom={{
                                                    base: '210px',
                                                    md: '155px',
                                                    lg: '100px',
                                                }}
                                                transition="all ease-in-out 300ms"
                                            >
                                                <Box
                                                    w="100%"
                                                    h="100%"
                                                    pos="relative"
                                                >
                                                    {status !== 'loading' &&
                                                        status === 'error' && (
                                                            <Text
                                                                pb="10px"
                                                                mt="-10px"
                                                                textAlign="center"
                                                                fontWeight="500"
                                                            >
                                                                Error loading
                                                                data
                                                            </Text>
                                                        )}
                                                    {status === 'loading' && (
                                                        <Flex
                                                            zIndex="102"
                                                            position="absolute"
                                                            top="0px"
                                                            left="0px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            width={'100%'}
                                                            pt="34vh"
                                                            px={{
                                                                base: '10px',
                                                                md: '30px',
                                                            }}
                                                        >
                                                            <Spinner
                                                                color={
                                                                    'light.200'
                                                                }
                                                                thickness="3px"
                                                                speed="0.45s"
                                                                boxSize="60px"
                                                            />
                                                        </Flex>
                                                    )}
                                                    {status !== 'loading' &&
                                                        !loaded &&
                                                        !!percentLoaded && (
                                                            <>
                                                                <Center
                                                                    top={0}
                                                                    left={0}
                                                                    w="100%"
                                                                    h="100%"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    position="absolute"
                                                                    zIndex={109}
                                                                >
                                                                    <CircularProgress
                                                                        value={
                                                                            percentLoaded
                                                                        }
                                                                        size="80px"
                                                                        thickness="4px"
                                                                        color="light.200"
                                                                    >
                                                                        <CircularProgressLabel
                                                                            color="white"
                                                                            textShadow="0px 1px 3px rgba(0,0,0,0.5)"
                                                                        >
                                                                            {`${Math.round(
                                                                                percentLoaded
                                                                            )}%`}
                                                                        </CircularProgressLabel>
                                                                    </CircularProgress>
                                                                </Center>
                                                            </>
                                                        )}
                                                    <PinchDrag
                                                        scale={scale}
                                                        setScale={setScale}
                                                        xy={xy}
                                                        setXy={setXy}
                                                        style={{
                                                            zIndex: '101',
                                                        }}
                                                        maxZoom={8}
                                                        maxZoomNewScale={7.999}
                                                    >
                                                        <Box
                                                            w="100%"
                                                            h="0"
                                                            borderRadius="10px"
                                                            pt={
                                                                loaded
                                                                    ? '100%'
                                                                    : 0
                                                            }
                                                            pos="relative"
                                                            zIndex={101}
                                                        >
                                                            {selectedSet &&
                                                                selectedSet
                                                                    .images
                                                                    .length && (
                                                                    <Fade
                                                                        key={`rainImg${
                                                                            selectedSet
                                                                                .images
                                                                                .length -
                                                                            1
                                                                        }`}
                                                                        in={
                                                                            !loaded ||
                                                                            selectedTime ===
                                                                                selectedSet
                                                                                    .images[
                                                                                    selectedSet
                                                                                        .images
                                                                                        .length -
                                                                                        1
                                                                                ]
                                                                                    .time ||
                                                                            previousSelectedTime ===
                                                                                selectedSet
                                                                                    .images[
                                                                                    selectedSet
                                                                                        .images
                                                                                        .length -
                                                                                        1
                                                                                ]
                                                                                    .time
                                                                        }
                                                                    >
                                                                        <Box
                                                                            pos="absolute"
                                                                            zIndex={
                                                                                selectedTime ===
                                                                                selectedSet
                                                                                    .images[
                                                                                    selectedSet
                                                                                        .images
                                                                                        .length -
                                                                                        1
                                                                                ]
                                                                                    .time
                                                                                    ? 5
                                                                                    : 4
                                                                            }
                                                                            top="0px"
                                                                            left={{
                                                                                base: '0px',
                                                                                md: nzIsSelected
                                                                                    ? '10%'
                                                                                    : '0px',
                                                                            }}
                                                                            transition="all ease 200ms"
                                                                            w="100%"
                                                                            h="0"
                                                                            pt="calc(100% * (100 / 100))"
                                                                            opacity={
                                                                                loaded
                                                                                    ? 1
                                                                                    : 0.7
                                                                            }
                                                                        >
                                                                            {selectedSet
                                                                                .images[
                                                                                selectedSet
                                                                                    .images
                                                                                    .length -
                                                                                    1
                                                                            ]
                                                                                .url && (
                                                                                <Image
                                                                                    src={`${
                                                                                        window
                                                                                            .location
                                                                                            .origin
                                                                                    }/metproxy?url=${
                                                                                        selectedSet
                                                                                            .images[
                                                                                            selectedSet
                                                                                                .images
                                                                                                .length -
                                                                                                1
                                                                                        ]
                                                                                            .url
                                                                                    }`}
                                                                                    key={`rainRadarImg-${
                                                                                        selectedSet
                                                                                            .images
                                                                                            .length -
                                                                                        1
                                                                                    }`}
                                                                                    pos="absolute"
                                                                                    top="0px"
                                                                                    w={{
                                                                                        base: '100%',
                                                                                        md: nzIsSelected
                                                                                            ? '80%'
                                                                                            : '100%',
                                                                                    }}
                                                                                    h={{
                                                                                        base: nzIsSelected
                                                                                            ? 'auto'
                                                                                            : '100%',
                                                                                        md: '100%',
                                                                                    }}
                                                                                    transition="all ease 200ms"
                                                                                    fit="cover"
                                                                                    objectPosition={
                                                                                        'left top'
                                                                                    }
                                                                                    borderRadius="10px"
                                                                                    onLoad={() =>
                                                                                        onMainImageLoad(
                                                                                            selectedSet
                                                                                                .images[
                                                                                                selectedSet
                                                                                                    .images
                                                                                                    .length -
                                                                                                    1
                                                                                            ]
                                                                                                .url
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </Fade>
                                                                )}
                                                            {firstImageLoaded() &&
                                                                selectedSet &&
                                                                selectedSet.images &&
                                                                selectedSet.images
                                                                    .slice(
                                                                        0,
                                                                        selectedSet
                                                                            .images
                                                                            .length -
                                                                            1
                                                                    )
                                                                    //  .filter((image) => image.)
                                                                    .map(
                                                                        (
                                                                            i,
                                                                            idx
                                                                        ) => (
                                                                            <Fade
                                                                                key={`rainImg${idx}`}
                                                                                in={
                                                                                    (loaded &&
                                                                                        (selectedTime ===
                                                                                            i.time ||
                                                                                            previousSelectedTime ===
                                                                                                i.time)) ||
                                                                                    (!loaded &&
                                                                                        idx ===
                                                                                            0)
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    pos="absolute"
                                                                                    zIndex={
                                                                                        selectedTime ===
                                                                                        i.time
                                                                                            ? 5
                                                                                            : 4
                                                                                    }
                                                                                    top="0px"
                                                                                    left={{
                                                                                        base: '0px',
                                                                                        md: nzIsSelected
                                                                                            ? '10%'
                                                                                            : '0px',
                                                                                    }}
                                                                                    transition="all ease 200ms"
                                                                                    w="100%"
                                                                                    h="0"
                                                                                    pt="calc(100% * (100 / 100))"
                                                                                    opacity={
                                                                                        loaded
                                                                                            ? 1
                                                                                            : 0.7
                                                                                    }
                                                                                >
                                                                                    {i.url && (
                                                                                        <Image
                                                                                            src={`${window.location.origin}/metproxy?url=${i.url}`}
                                                                                            key={`rainRadarImg-${idx}`}
                                                                                            pos="absolute"
                                                                                            top="0px"
                                                                                            w={{
                                                                                                base: '100%',
                                                                                                md: nzIsSelected
                                                                                                    ? '80%'
                                                                                                    : '100%',
                                                                                            }}
                                                                                            h={{
                                                                                                base: nzIsSelected
                                                                                                    ? 'auto'
                                                                                                    : '100%',
                                                                                                md: '100%',
                                                                                            }}
                                                                                            transition="all ease 200ms"
                                                                                            fit="cover"
                                                                                            objectPosition={
                                                                                                'left top'
                                                                                            }
                                                                                            borderRadius="10px"
                                                                                            onLoad={() =>
                                                                                                onMainImageLoad(
                                                                                                    i.url
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Box>
                                                                            </Fade>
                                                                        )
                                                                    )}
                                                            <Box
                                                                pos="absolute"
                                                                top={0}
                                                                left={0}
                                                                zIndex={7}
                                                                opacity={
                                                                    loaded &&
                                                                    selectedRangering !==
                                                                        'off'
                                                                        ? 1
                                                                        : 0
                                                                }
                                                                w="100%"
                                                                h="0"
                                                                pt={
                                                                    loaded &&
                                                                    selectedRangering !==
                                                                        'off'
                                                                        ? 'calc(100% * (100 / 100))'
                                                                        : '0'
                                                                }
                                                                transition={
                                                                    'opacity .5s'
                                                                }
                                                            >
                                                                {selectedSet &&
                                                                    selectedSet
                                                                        .rangerings[
                                                                        selectedRangering
                                                                    ] && (
                                                                        <Image
                                                                            // src={`${window.location.origin}/metproxy?url=${selectedSet.rangerings[selectedRangering]}`}
                                                                            src={
                                                                                rangeRing
                                                                            }
                                                                            pos="absolute"
                                                                            top={
                                                                                0
                                                                            }
                                                                            w="100%"
                                                                            h="100%"
                                                                            fit="cover"
                                                                            objectPosition={
                                                                                'left top'
                                                                            }
                                                                            borderRadius="10px"
                                                                        />
                                                                    )}
                                                            </Box>
                                                        </Box>
                                                    </PinchDrag>
                                                </Box>
                                            </Flex>
                                            <Flex
                                                w="100%"
                                                pb="20px"
                                                height={{
                                                    base: '140px',
                                                    lg: '85px',
                                                }}
                                                bottom={{
                                                    base: minimiseOnZoom
                                                        ? '-45px'
                                                        : '65px',
                                                    md: minimiseOnZoom
                                                        ? '-45px'
                                                        : '0px',
                                                    ml: '0px',
                                                }}
                                                alignItems="center"
                                                flexDirection="column"
                                                justifyContent={{
                                                    base: 'flex-start',
                                                    lg: 'flex-start',
                                                }}
                                                opacity={!loaded ? '0.15' : '1'}
                                                transition="all ease 300ms"
                                                position="absolute"
                                                zIndex="101"
                                            >
                                                <Flex // IMAGE TIME
                                                    h="30px"
                                                    mb="5px"
                                                    pt="3px"
                                                    pb="3px"
                                                    px="15px"
                                                    justify={'center'}
                                                    alignItems="center"
                                                    fontWeight="bold"
                                                    fontSize="0.8rem"
                                                    textAlign="center"
                                                    color="light.100"
                                                    backdropFilter={
                                                        selectedTime &&
                                                        'blur(4px)'
                                                    }
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={
                                                        selectedTime && {
                                                            base: isFirefox
                                                                ? 'rgba(223, 232, 241, 0.8)'
                                                                : 'rgba(223, 232, 241, 0.3)',
                                                            md: isFirefox
                                                                ? 'rgba(240, 248, 253, 0.8)'
                                                                : 'rgba(240, 248, 253, 0.3)',
                                                        }
                                                    }
                                                    borderRadius="20px"
                                                >
                                                    {selectedTime &&
                                                        formatDateTime(
                                                            selectedTime,
                                                            timeZone
                                                        )}
                                                </Flex>
                                                {!loaded && (
                                                    <Box
                                                        position="absolute"
                                                        height="80px"
                                                        width="100%"
                                                        zIndex="1"
                                                        marginTop="0px"
                                                    ></Box>
                                                )}
                                                {selectedSetTimes && (
                                                    <Flex // TIME SLIDER
                                                        w="100%"
                                                        px="20px"
                                                        maxWidth={{
                                                            base: '700px',
                                                            md: '800px',
                                                            lg: '900px',
                                                        }}
                                                        justify={'center'}
                                                    >
                                                        <TimeSlider
                                                            style={{
                                                                width: 'calc(100% - 10px)',
                                                            }}
                                                            times={
                                                                selectedSetTimes
                                                            }
                                                            selectedTime={
                                                                selectedTime
                                                            }
                                                            setSelectedTime={
                                                                setSelectedTime
                                                            }
                                                            setPreviousSelectedTime={
                                                                setPreviousSelectedTime
                                                            }
                                                            speed={speed}
                                                            setSpeed={setSpeed}
                                                            playing={playing}
                                                            setPlaying={
                                                                setPlaying
                                                            }
                                                            looping={looping}
                                                            setLooping={
                                                                setLooping
                                                            }
                                                            mostRecent={true}
                                                        />
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                        <Flex // RIGHT MENU (CONTROLS AND LEGEND)
                                            pos="relative"
                                            flexDirection={{
                                                base: 'row',
                                                lg: 'column',
                                            }}
                                            h={{
                                                base: '50px',
                                                lg: '100%',
                                            }}
                                            minHeight={{
                                                base: '50px',
                                                lg: '100%',
                                            }}
                                            width={{
                                                base: '100%',
                                                lg: '250px',
                                            }}
                                            minWidth={{
                                                base: '100%',
                                                lg: '250px',
                                            }}
                                            transition="all ease 300ms"
                                            mt={{
                                                base: minimiseOnZoom
                                                    ? '-50px'
                                                    : status !== 'loading' &&
                                                      regions &&
                                                      regionIsSelected
                                                    ? '-1px'
                                                    : '-50px',
                                                lg: '0',
                                            }}
                                            background={{
                                                base: 'light.55',
                                                lg: 'light.10',
                                            }}
                                            borderLeft={{
                                                base: 'none',
                                                lg: '1px solid #E2E8F0',
                                            }}
                                            boxShadow={{
                                                base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                lg: 'none',
                                            }}
                                            zIndex="101"
                                            clipPath="inset(0px 0px -1500px 0px)"
                                        >
                                            {status !== 'loading' && (
                                                <>
                                                    <Flex
                                                        w={{
                                                            base: '100%',
                                                            md: 'calc(100vw - 250px)',
                                                            lg: '100%',
                                                        }}
                                                        h={{
                                                            base: '54px',
                                                            lg: '94px',
                                                        }}
                                                        minHeight={{
                                                            base: '54px',
                                                            lg: '94px',
                                                        }}
                                                        position={{
                                                            base: 'fixed',
                                                            lg: 'relative',
                                                        }}
                                                        bottom={{
                                                            base:
                                                                isMobileOnly &&
                                                                isIOS &&
                                                                isMobileSafari
                                                                    ? '140px'
                                                                    : '125px',
                                                            md: '0px',
                                                            lg: '0px',
                                                        }}
                                                        background={{
                                                            base: 'light.55',
                                                            lg: 'light.10',
                                                        }}
                                                        backdropFilter={{
                                                            base: 'blur(20px)',
                                                            ml: 'none',
                                                        }}
                                                        clipPath={{
                                                            base: 'inset(-100px 0px 0px 0px)',
                                                            lg: 'inset(0px 0px 0px 0px)',
                                                        }}
                                                        boxShadow={{
                                                            base: '0px -5px 30px -5px rgba(0,0,0,0.2)',
                                                            lg: 'none',
                                                        }}
                                                        direction={{
                                                            base: 'row',
                                                            lg: 'column',
                                                        }}
                                                        justifyContent={{
                                                            base: 'center',
                                                            lg: 'center',
                                                        }}
                                                        alignItems={{
                                                            base: 'center',
                                                            lg: 'center',
                                                        }}
                                                        mt={3}
                                                        mb={{
                                                            base: minimiseOnZoom
                                                                ? '-120px'
                                                                : '-1px',
                                                            md: minimiseOnZoom
                                                                ? '-65px'
                                                                : '-1px',
                                                            ml: '-1px',
                                                        }}
                                                        transition="all ease 300ms"
                                                        px={{
                                                            base: '20px',
                                                            lg: '20px',
                                                        }}
                                                        pb={{
                                                            base: '1px',
                                                            lg: '3',
                                                        }}
                                                    >
                                                        <Flex
                                                            {...sourceSwipe}
                                                            flexDirection={{
                                                                base: 'row',
                                                                lg: 'column',
                                                            }}
                                                            gap={{
                                                                base: '0px',
                                                                lg: '0px',
                                                            }}
                                                            borderRadius="17px"
                                                            px="2px"
                                                            width={{
                                                                base: '100%',
                                                                lg: '210px',
                                                            }}
                                                            maxWidth={{
                                                                base: '430px',
                                                                lg: '100%',
                                                            }}
                                                            height={{
                                                                base: '34px',
                                                                lg:
                                                                    nzIsSelected ||
                                                                    singleSourceOnly
                                                                        ? '32px'
                                                                        : '64px',
                                                            }}
                                                            minHeight={{
                                                                base: '34px',
                                                                lg:
                                                                    nzIsSelected ||
                                                                    singleSourceOnly
                                                                        ? '32px'
                                                                        : '64px',
                                                            }}
                                                            overflow="hidden"
                                                            bg={
                                                                nzIsSelected ||
                                                                singleSourceOnly
                                                                    ? 'none'
                                                                    : 'rgba(8, 38, 71, 0.04)'
                                                            }
                                                            boxShadow={
                                                                nzIsSelected ||
                                                                singleSourceOnly
                                                                    ? 'none'
                                                                    : 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)'
                                                            }
                                                            justifyContent={
                                                                (nzIsSelected ||
                                                                    singleSourceOnly) &&
                                                                'center'
                                                            }
                                                        >
                                                            {selectedRegion &&
                                                            data &&
                                                            data[selectedRegion]
                                                                ? data[
                                                                      selectedRegion
                                                                  ].map(
                                                                      (
                                                                          set,
                                                                          i
                                                                      ) => (
                                                                          <Button
                                                                              key={`set-${set.description}`}
                                                                              size="sm"
                                                                              width={{
                                                                                  base: '100%',
                                                                                  lg: '100%',
                                                                              }}
                                                                              maxWidth={{
                                                                                  base: '213px',
                                                                                  lg: '100%',
                                                                              }}
                                                                              borderRadius="18px"
                                                                              height={{
                                                                                  base: '34px',
                                                                                  lg: '32px',
                                                                              }}
                                                                              minHeight={{
                                                                                  base: '34px',
                                                                                  lg: '32px',
                                                                              }}
                                                                              bg="transparent"
                                                                              variant="outline"
                                                                              marginInlineStart="0px !important"
                                                                              border="none"
                                                                              justifyContent="center"
                                                                              paddingLeft="2px"
                                                                              paddingRight="2px"
                                                                              py="2px"
                                                                              {...noFocus}
                                                                              zIndex="1"
                                                                              _active={{
                                                                                  bg: 'none',
                                                                              }}
                                                                              _focus={{
                                                                                  bg: 'none',
                                                                              }}
                                                                              _hover={{
                                                                                  bg: 'none',
                                                                                  opacity:
                                                                                      '1',
                                                                              }}
                                                                              color={
                                                                                  nzIsSelected ||
                                                                                  singleSourceOnly
                                                                                      ? 'gray.500'
                                                                                      : selectedSource &&
                                                                                        selectedSource ===
                                                                                            set.type &&
                                                                                        regionIsSelected
                                                                                      ? 'light.200'
                                                                                      : 'light.100'
                                                                              }
                                                                              isDisabled={
                                                                                  nzIsSelected ||
                                                                                  singleSourceOnly
                                                                              }
                                                                              opacity="1 !important"
                                                                              cursor={
                                                                                  nzIsSelected ||
                                                                                  singleSourceOnly
                                                                                      ? 'default !important'
                                                                                      : 'pointer'
                                                                              }
                                                                              fontWeight={
                                                                                  selectedSource &&
                                                                                  selectedSource ===
                                                                                      set.type &&
                                                                                  regionIsSelected
                                                                                      ? '400'
                                                                                      : '400'
                                                                              }
                                                                              fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                              fontSize={{
                                                                                  base: '0.75rem',
                                                                                  md: '13px',
                                                                              }}
                                                                              transition="all ease 300ms"
                                                                              onClick={() => {
                                                                                  if (
                                                                                      isPremium &&
                                                                                      regionIsSelected &&
                                                                                      set.type ===
                                                                                          'radar'
                                                                                  ) {
                                                                                      setShowPricingTiers(
                                                                                          true
                                                                                      )
                                                                                  } else if (
                                                                                      isPremium
                                                                                  ) {
                                                                                      setSelectedSource(
                                                                                          'aerodrome'
                                                                                      )
                                                                                      setSelectedRange(
                                                                                          '130km'
                                                                                      )
                                                                                  } else {
                                                                                      setSelectedSource(
                                                                                          set.type
                                                                                      )
                                                                                      setSelectedSourcePosition(
                                                                                          i
                                                                                      )
                                                                                  }
                                                                                  setScale(
                                                                                      1
                                                                                  ) // Reset the zoom scale to 1
                                                                                  setXy(
                                                                                      {
                                                                                          x: 0,
                                                                                          y: 0,
                                                                                      }
                                                                                  ) // Reset x and y to 0
                                                                              }}
                                                                          >
                                                                              <Text
                                                                                  opacity={
                                                                                      isPremium &&
                                                                                      set.type ===
                                                                                          'radar' &&
                                                                                      regionIsSelected
                                                                                          ? '0.4'
                                                                                          : '1'
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      set.description
                                                                                  }
                                                                              </Text>
                                                                              {/* *** PREMIUM BADGE *** */}
                                                                              {isPremium &&
                                                                                  set.type ===
                                                                                      'radar' &&
                                                                                  regionIsSelected && (
                                                                                      <Flex
                                                                                          // position="absolute"
                                                                                          ml="5px"
                                                                                          mr="-2px"
                                                                                      >
                                                                                          <PremiumBadge />
                                                                                      </Flex>
                                                                                  )}
                                                                          </Button>
                                                                      )
                                                                  )
                                                                : null}
                                                            {!nzIsSelected &&
                                                                !singleSourceOnly && (
                                                                    <Flex // BUTTON INDICATOR
                                                                        position="absolute"
                                                                        marginInlineStart="0px !important"
                                                                        mt="2px"
                                                                        ml="0px"
                                                                        px="44px"
                                                                        height={{
                                                                            base: '30px',
                                                                            md: '30px',
                                                                        }}
                                                                        width={{
                                                                            base: 'calc(50% - 22px)',
                                                                            lg: '205px',
                                                                        }}
                                                                        maxWidth={{
                                                                            base: '213px',
                                                                            lg: '100%',
                                                                        }}
                                                                        background="white"
                                                                        borderRadius="20px"
                                                                        bg="light.10"
                                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                        transform={{
                                                                            base: `translateX(${selectedSourcePosition}00%)`,
                                                                            lg: `translateY(${selectedSourcePosition}00%)`,
                                                                        }}
                                                                        transition="transform 400ms"
                                                                    ></Flex>
                                                                )}
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        zIndex="20"
                                                        width="100%"
                                                        flexDirection="column"
                                                        height={{
                                                            base: 'auto',
                                                            lg: regionIsSelected
                                                                ? '250px'
                                                                : '0px',
                                                        }}
                                                        minHeight={{
                                                            base: 'auto',
                                                            lg: regionIsSelected
                                                                ? '250px'
                                                                : '0px',
                                                        }}
                                                        transition="all ease 300ms"
                                                        borderTop="1px solid"
                                                        borderBottom={
                                                            regionIsSelected
                                                                ? '1px solid'
                                                                : '0px solid'
                                                        }
                                                        borderColor="gray.200"
                                                        clipPath="inset(0px 0px 0px 0px)"
                                                    >
                                                        <Flex
                                                            mt={{
                                                                base: '0px',
                                                                lg: '10px',
                                                            }}
                                                            w="100%"
                                                            justify={'center'}
                                                            pt={{
                                                                base: '0',
                                                                lg: '10px',
                                                            }}
                                                            height={{
                                                                base: '50px',
                                                                lg: 'max-content',
                                                            }}
                                                            background={{
                                                                base: 'light.600',
                                                                lg: 'none',
                                                            }}
                                                            position={{
                                                                base: 'absolute',
                                                                lg: 'relative',
                                                            }}
                                                            zIndex="2"
                                                            pb={{
                                                                base: '0px',
                                                                lg: '18px',
                                                            }}
                                                            borderBottom="1px solid"
                                                            borderColor="gray.200"
                                                            opacity={
                                                                regionIsSelected
                                                                    ? '1'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                regionIsSelected
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            transition="all ease 250ms"
                                                        >
                                                            <Flex
                                                                {...infoSwipe}
                                                                w="100%"
                                                                alignItems="center"
                                                                flexDirection={{
                                                                    base: 'row',
                                                                    lg: 'column',
                                                                }}
                                                                justifyContent={{
                                                                    base: 'space-around',
                                                                    lg: 'center',
                                                                }}
                                                                pl={{
                                                                    base: '17px',
                                                                    lg: '0',
                                                                }}
                                                                pr={{
                                                                    base: '30px',
                                                                    sm: '5px',
                                                                    lg: '0',
                                                                }}
                                                                borderBottom={{
                                                                    base: reflectivityShow
                                                                        ? '1px solid #E2E8F0'
                                                                        : '1px solid #d9dfe5',
                                                                    lg: 'none',
                                                                }}
                                                                transition="all ease 300ms"
                                                                gap={{
                                                                    base:
                                                                        selectedSource ===
                                                                        'radar'
                                                                            ? '20px'
                                                                            : '0px',
                                                                    xs: '0px',
                                                                    lg: '0px',
                                                                }}
                                                            >
                                                                <Flex
                                                                    w={{
                                                                        base: '100%',
                                                                        md: '182px',
                                                                        lg: '190px',
                                                                    }}
                                                                    ml={{
                                                                        base: 'auto',
                                                                        md: '10px',
                                                                        lg: '-6px',
                                                                    }}
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Flex
                                                                        pl={{
                                                                            base: '0px',
                                                                            lg: '3px',
                                                                        }}
                                                                        width={{
                                                                            base: '34px',
                                                                            lg: '40px',
                                                                        }}
                                                                        minWidth="34px"
                                                                    >
                                                                        <RadarIcon color="#5F7487" />
                                                                    </Flex>
                                                                    <Flex
                                                                        borderRadius="17px"
                                                                        px="2px"
                                                                        overflow="hidden"
                                                                        bg={
                                                                            nzIsSelected
                                                                                ? 'none'
                                                                                : 'rgba(8, 38, 71, 0.04)'
                                                                        }
                                                                        boxShadow={
                                                                            nzIsSelected
                                                                                ? 'none'
                                                                                : 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)'
                                                                        }
                                                                        size={{
                                                                            base: 'xs',
                                                                            md: 'xs',
                                                                        }}
                                                                        fontSize={{
                                                                            base: '0.7rem !important',
                                                                            md: '0.7rem',
                                                                        }}
                                                                        height={{
                                                                            base: '32px',
                                                                            lg: '30px',
                                                                        }}
                                                                        width={{
                                                                            base: '151px',
                                                                            xs: '169px',
                                                                            smd: '223px',
                                                                            lg: '151px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: '151px',
                                                                            xs: '169px',
                                                                            smd: '223px',
                                                                            lg: '151px',
                                                                        }}
                                                                        variant="outline"
                                                                    >
                                                                        <Button
                                                                            size="xs"
                                                                            height={{
                                                                                base: '32px',
                                                                                lg: '30px',
                                                                            }}
                                                                            mr="-px"
                                                                            width={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            minWidth={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            onClick={() =>
                                                                                setSelectedRangering(
                                                                                    'km'
                                                                                )
                                                                            }
                                                                            fontSize={{
                                                                                base: '0.7rem',
                                                                                md: '0.7rem',
                                                                            }}
                                                                            color={
                                                                                selectedRangering &&
                                                                                selectedRangering ===
                                                                                    'km'
                                                                                    ? 'light.200'
                                                                                    : 'light.100'
                                                                            }
                                                                            borderRadius="18px"
                                                                            bg="transparent"
                                                                            variant="outline"
                                                                            marginInlineStart="0px !important"
                                                                            border="none"
                                                                            justifyContent="center"
                                                                            paddingLeft="2px"
                                                                            paddingRight="2px"
                                                                            py="2px"
                                                                            zIndex="1"
                                                                            _active={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _focus={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _hover={{
                                                                                bg: 'none',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                        >
                                                                            KM
                                                                        </Button>
                                                                        <Button
                                                                            size="xs"
                                                                            height={{
                                                                                base: '32px',
                                                                                lg: '30px',
                                                                            }}
                                                                            mr="-px"
                                                                            width={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            minWidth={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            onClick={() =>
                                                                                setSelectedRangering(
                                                                                    'nm'
                                                                                )
                                                                            }
                                                                            fontSize={{
                                                                                base: '0.7rem',
                                                                                md: '0.7rem',
                                                                            }}
                                                                            color={
                                                                                selectedRangering &&
                                                                                selectedRangering ===
                                                                                    'nm'
                                                                                    ? 'light.200'
                                                                                    : 'light.100'
                                                                            }
                                                                            borderRadius="18px"
                                                                            bg="transparent"
                                                                            variant="outline"
                                                                            marginInlineStart="0px !important"
                                                                            border="none"
                                                                            justifyContent="center"
                                                                            paddingLeft="2px"
                                                                            paddingRight="2px"
                                                                            py="2px"
                                                                            zIndex="1"
                                                                            _active={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _focus={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _hover={{
                                                                                bg: 'none',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                        >
                                                                            NM
                                                                        </Button>
                                                                        <Button
                                                                            size="xs"
                                                                            height={{
                                                                                base: '32px',
                                                                                lg: '30px',
                                                                            }}
                                                                            mr="-px"
                                                                            width={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            minWidth={{
                                                                                base: '50px',
                                                                                xs: '56px',
                                                                                smd: '74px',
                                                                                lg: '50px',
                                                                            }}
                                                                            onClick={() =>
                                                                                setSelectedRangering(
                                                                                    'off'
                                                                                )
                                                                            }
                                                                            fontSize={{
                                                                                base: '0.7rem',
                                                                                md: '0.7rem',
                                                                            }}
                                                                            color={
                                                                                selectedRangering &&
                                                                                selectedRangering ===
                                                                                    'off'
                                                                                    ? 'light.200'
                                                                                    : 'light.100'
                                                                            }
                                                                            borderRadius="18px"
                                                                            bg="transparent"
                                                                            variant="outline"
                                                                            marginInlineStart="0px !important"
                                                                            border="none"
                                                                            justifyContent="center"
                                                                            paddingLeft="2px"
                                                                            paddingRight="2px"
                                                                            py="2px"
                                                                            zIndex="1"
                                                                            _active={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _focus={{
                                                                                bg: 'none',
                                                                            }}
                                                                            _hover={{
                                                                                bg: 'none',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                        >
                                                                            OFF
                                                                        </Button>
                                                                        <Flex // BUTTON INDICATOR
                                                                            position="absolute"
                                                                            marginInlineStart="0px !important"
                                                                            mt="2px"
                                                                            ml="0px"
                                                                            height={{
                                                                                base: '28px',
                                                                                lg: '26px',
                                                                            }}
                                                                            width={{
                                                                                base: '49px',
                                                                                xs: '55px',
                                                                                smd: '73px',
                                                                                lg: '49px',
                                                                            }}
                                                                            minWidth={{
                                                                                base: '49px',
                                                                                xs: '55px',
                                                                                smd: '73px',
                                                                                lg: '49px',
                                                                            }}
                                                                            background="white"
                                                                            borderRadius="20px"
                                                                            bg="light.10"
                                                                            boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                            transform={`translateX(${
                                                                                selectedRangering &&
                                                                                (selectedRangering ===
                                                                                'km'
                                                                                    ? 0
                                                                                    : selectedRangering ===
                                                                                      'nm'
                                                                                    ? 1
                                                                                    : selectedRangering ===
                                                                                      'off'
                                                                                    ? 2
                                                                                    : 0)
                                                                            }00%)`}
                                                                            transition="transform 400ms"
                                                                        ></Flex>
                                                                    </Flex>
                                                                </Flex>

                                                                <Flex
                                                                    w={{
                                                                        base: 'calc(100% - 50px)',
                                                                        sm: 'calc(100% - 80px)',
                                                                        md: 'auto',
                                                                        lg: '100%',
                                                                    }}
                                                                    ml={{
                                                                        base: '0px',
                                                                        md: '15px',
                                                                        ml: '0px',
                                                                    }}
                                                                    mr={{
                                                                        base: '0px',
                                                                        smd: '-25px',
                                                                        lg: '0px',
                                                                    }}
                                                                    alignItems="center"
                                                                    justifyContent={{
                                                                        base: 'space-evenly',
                                                                        md: 'flex-start',
                                                                        lg: 'center',
                                                                    }}
                                                                    flexDirection={{
                                                                        base: 'row-reverse',
                                                                        lg: 'column',
                                                                    }}
                                                                >
                                                                    <Flex
                                                                        w={{
                                                                            base: '125px',
                                                                            smd: '190px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: '125px',
                                                                            md: '190px',
                                                                        }}
                                                                        justify={
                                                                            'left'
                                                                        }
                                                                        alignItems="center"
                                                                        mt={{
                                                                            base: '0px',
                                                                            lg: '15px',
                                                                        }}
                                                                    >
                                                                        <Flex
                                                                            ml={{
                                                                                base: '0px',
                                                                                md: '-4px',
                                                                            }}
                                                                            width="40px"
                                                                            minWidth="40px"
                                                                        >
                                                                            <RangeIcon
                                                                                color="#5F7487"
                                                                                boxSize={
                                                                                    8
                                                                                }
                                                                            />
                                                                        </Flex>
                                                                        <Flex
                                                                            borderRadius="17px"
                                                                            px="2px"
                                                                            overflow="hidden"
                                                                            bg={
                                                                                selectedSource ===
                                                                                'radar'
                                                                                    ? 'light.60'
                                                                                    : 'rgba(8, 38, 71, 0.04)'
                                                                            }
                                                                            boxShadow={
                                                                                selectedSource ===
                                                                                'radar'
                                                                                    ? 'none'
                                                                                    : 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)'
                                                                            }
                                                                            justifyContent={
                                                                                selectedSource ===
                                                                                    'radar' &&
                                                                                'center'
                                                                            }
                                                                            size={{
                                                                                base: 'xs',
                                                                                md: 'xs',
                                                                            }}
                                                                            fontSize={{
                                                                                base: '0.7rem !important',
                                                                                md: '0.7rem',
                                                                            }}
                                                                            height={{
                                                                                base: '32px',
                                                                                lg: '30px',
                                                                            }}
                                                                            width={{
                                                                                base:
                                                                                    selectedSource ===
                                                                                    'radar'
                                                                                        ? '80px'
                                                                                        : '102px',
                                                                                xs: '114px',
                                                                                smd: '150px',
                                                                                md: '150px',
                                                                            }}
                                                                            minWidth={{
                                                                                base:
                                                                                    selectedSource ===
                                                                                    'radar'
                                                                                        ? '80px'
                                                                                        : '102px',
                                                                                xs: '114px',
                                                                                smd: '150px',
                                                                                md: '150px',
                                                                            }}
                                                                            variant="outline"
                                                                        >
                                                                            {data &&
                                                                                selectedRegion &&
                                                                                data[
                                                                                    selectedRegion
                                                                                ] &&
                                                                                selectedSet &&
                                                                                data[
                                                                                    selectedRegion
                                                                                ]
                                                                                    .filter(
                                                                                        (
                                                                                            i
                                                                                        ) =>
                                                                                            i.type ===
                                                                                            selectedSource
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item
                                                                                        ) => {
                                                                                            const rangeButtons =
                                                                                                Object.keys(
                                                                                                    item[
                                                                                                        'ranges'
                                                                                                    ]
                                                                                                )
                                                                                                    .filter(
                                                                                                        (
                                                                                                            range
                                                                                                        ) =>
                                                                                                            range !==
                                                                                                            '900km'
                                                                                                    )
                                                                                                    .map(
                                                                                                        (
                                                                                                            r
                                                                                                        ) => (
                                                                                                            <Button
                                                                                                                key={`rangeBtn-${r}`}
                                                                                                                {...noFocus}
                                                                                                                size="xs"
                                                                                                                height={{
                                                                                                                    base: '32px',
                                                                                                                    lg: '30px',
                                                                                                                }}
                                                                                                                mr="-px"
                                                                                                                width={{
                                                                                                                    base:
                                                                                                                        selectedSource ===
                                                                                                                        'radar'
                                                                                                                            ? '55px'
                                                                                                                            : '50px',
                                                                                                                    xs: '56px',
                                                                                                                    smd: '74px',
                                                                                                                    md: '74px',
                                                                                                                }}
                                                                                                                minWidth={{
                                                                                                                    base:
                                                                                                                        selectedSource ===
                                                                                                                        'radar'
                                                                                                                            ? '55px'
                                                                                                                            : '50px',
                                                                                                                    xs: '56px',
                                                                                                                    smd: '74px',
                                                                                                                    md: '74px',
                                                                                                                }}
                                                                                                                fontSize={{
                                                                                                                    base: '0.7rem',
                                                                                                                    md: '0.7rem',
                                                                                                                }}
                                                                                                                color={
                                                                                                                    selectedSource ===
                                                                                                                    'radar'
                                                                                                                        ? 'light.100'
                                                                                                                        : isPremium &&
                                                                                                                          r !==
                                                                                                                              '130km'
                                                                                                                        ? 'light.100'
                                                                                                                        : selectedRange &&
                                                                                                                          selectedRange ===
                                                                                                                              r
                                                                                                                        ? 'light.200'
                                                                                                                        : 'light.100'
                                                                                                                }
                                                                                                                borderRadius="18px"
                                                                                                                bg="transparent"
                                                                                                                variant="outline"
                                                                                                                marginInlineStart="0px !important"
                                                                                                                border="none"
                                                                                                                justifyContent="center"
                                                                                                                paddingLeft="2px"
                                                                                                                paddingRight="2px"
                                                                                                                py="2px"
                                                                                                                zIndex="1"
                                                                                                                cursor={
                                                                                                                    selectedSource ===
                                                                                                                    'radar'
                                                                                                                        ? 'not-allowed'
                                                                                                                        : 'pointer'
                                                                                                                }
                                                                                                                _active={{
                                                                                                                    bg: 'none',
                                                                                                                }}
                                                                                                                _focus={{
                                                                                                                    bg: 'none',
                                                                                                                }}
                                                                                                                _hover={{
                                                                                                                    bg: 'none',
                                                                                                                    opacity:
                                                                                                                        '1',
                                                                                                                }}
                                                                                                                onClick={() =>
                                                                                                                    isPremium &&
                                                                                                                    r !==
                                                                                                                        '130km'
                                                                                                                        ? setShowPricingTiers(
                                                                                                                              true
                                                                                                                          )
                                                                                                                        : setSelectedRange(
                                                                                                                              r
                                                                                                                          )
                                                                                                                }
                                                                                                            >
                                                                                                                <Text
                                                                                                                    opacity={
                                                                                                                        isPremium &&
                                                                                                                        r !==
                                                                                                                            '130km'
                                                                                                                            ? '0.4'
                                                                                                                            : '1'
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        r
                                                                                                                    }
                                                                                                                </Text>
                                                                                                                {/* *** PREMIUM BADGE *** */}
                                                                                                                {isPremium &&
                                                                                                                    r !==
                                                                                                                        '130km' && (
                                                                                                                        <Flex
                                                                                                                            ml={{
                                                                                                                                base: '-2px',
                                                                                                                                smd: '5px',
                                                                                                                            }}
                                                                                                                            mr={{
                                                                                                                                base: '-8px',
                                                                                                                                smd: '-2px',
                                                                                                                            }}
                                                                                                                            mt={{
                                                                                                                                base: '-13px',
                                                                                                                                smd: '0px',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <PremiumBadge />
                                                                                                                        </Flex>
                                                                                                                    )}
                                                                                                            </Button>
                                                                                                        )
                                                                                                    )

                                                                                            return rangeButtons
                                                                                        }
                                                                                    )}
                                                                            {selectedSource !==
                                                                                'radar' && (
                                                                                <Flex // BUTTON INDICATOR
                                                                                    position="absolute"
                                                                                    marginInlineStart="0px !important"
                                                                                    mt="2px"
                                                                                    ml="0px"
                                                                                    height={{
                                                                                        base: '28px',
                                                                                        lg: '26px',
                                                                                    }}
                                                                                    width={{
                                                                                        base:
                                                                                            selectedSource ===
                                                                                            'radar'
                                                                                                ? '54px'
                                                                                                : '49px',
                                                                                        xs: '55px',
                                                                                        smd: '73px',
                                                                                        md: '73px',
                                                                                    }}
                                                                                    background="white"
                                                                                    borderRadius="20px"
                                                                                    bg="light.10"
                                                                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    transform={`translateX(${
                                                                                        selectedRange &&
                                                                                        (selectedRange ===
                                                                                        '70km'
                                                                                            ? 0
                                                                                            : selectedRange ===
                                                                                              '130km'
                                                                                            ? 1
                                                                                            : 0)
                                                                                    }00%)`}
                                                                                    transition="transform 400ms"
                                                                                ></Flex>
                                                                            )}
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>

                                                        <Flex
                                                            mt="5px"
                                                            w={{
                                                                base: '100%',
                                                                lg: '100%',
                                                            }}
                                                            justify={'center'}
                                                            flexDirection="column"
                                                        >
                                                            {/* <Flex // IMAGE TIME FOR DESKTOP ONLY
                                                    w="100%"
                                                    display={{
                                                        base: 'none',
                                                        lg: 'flex',
                                                    }}
                                                    justify={'center'}
                                                    fontWeight="bold"
                                                    color="light.100"
                                                >
                                                    {selectedTime &&
                                                        formatDateTime(
                                                            selectedTime,
                                                            timeZone
                                                        )}
                                                </Flex> */}
                                                            <Flex
                                                                w="100%"
                                                                justify={
                                                                    'center'
                                                                }
                                                                flexDirection="column"
                                                            >
                                                                <Portal
                                                                    {...portalInfo}
                                                                >
                                                                    <Flex
                                                                        display={
                                                                            regionIsSelected
                                                                                ? 'flex'
                                                                                : 'none'
                                                                        }
                                                                    >
                                                                        {!loaded && (
                                                                            <Box
                                                                                position="absolute"
                                                                                w={{
                                                                                    base: 'calc(100vw - 60px)',
                                                                                    md: 'calc(100vw - 310px)',
                                                                                    lg: '100%',
                                                                                }}
                                                                                paddingTop={{
                                                                                    base: '15px',
                                                                                    lg: '50px',
                                                                                }}
                                                                                transition="all ease-in 300ms"
                                                                            >
                                                                                <Text
                                                                                    pb={{
                                                                                        base: detailsShow
                                                                                            ? '15px'
                                                                                            : '0',
                                                                                        lg: '15px',
                                                                                    }}
                                                                                    opacity={{
                                                                                        base: detailsShow
                                                                                            ? '1'
                                                                                            : '0',
                                                                                        lg: '1',
                                                                                    }}
                                                                                    transition="all ease-in 300ms"
                                                                                    fontSize="0.7rem"
                                                                                    textAlign="center"
                                                                                    color="#abb2bb"
                                                                                >
                                                                                    Loading
                                                                                    information...
                                                                                </Text>

                                                                                <Flex
                                                                                    width="100%"
                                                                                    justifyContent="center"
                                                                                >
                                                                                    <Spinner
                                                                                        size="md"
                                                                                        color="#abb2bb"
                                                                                    />
                                                                                </Flex>
                                                                            </Box>
                                                                        )}
                                                                        <Box
                                                                            display="flex"
                                                                            width="100%"
                                                                            transform="scale(0.5, 0.5)"
                                                                            height={{
                                                                                base: detailsShow
                                                                                    ? '125px'
                                                                                    : '0',
                                                                                lg: 'max-content',
                                                                            }}
                                                                            marginTop={{
                                                                                base: detailsShow
                                                                                    ? '-35px'
                                                                                    : '0',
                                                                                lg: '0',
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                position="relative"
                                                                                w="298px"
                                                                                height={{
                                                                                    base: detailsShow
                                                                                        ? '190px'
                                                                                        : '0',
                                                                                    lg: '190px',
                                                                                }}
                                                                                overflow="hidden"
                                                                                opacity={{
                                                                                    base: detailsShow
                                                                                        ? '1'
                                                                                        : '0',
                                                                                    lg: '1',
                                                                                }}
                                                                                transition="all ease-in-out 300ms"
                                                                                transform={{
                                                                                    base: 'translateX(-35vw)',
                                                                                    md: 'translateX(-30vw)',
                                                                                    lg: 'none',
                                                                                }}
                                                                            >
                                                                                {/* TODO: insert images here and set to opacity to 0.  */}
                                                                                {firstImageLoaded() &&
                                                                                    selectedSet &&
                                                                                    selectedSet.images.map(
                                                                                        (
                                                                                            i,
                                                                                            idx
                                                                                        ) =>
                                                                                            i.url && (
                                                                                                <Image
                                                                                                    src={`${window.location.origin}/metproxy?url=${i.url}`}
                                                                                                    key={`detailImg-${idx}`}
                                                                                                    objectPosition={
                                                                                                        '99.2%'
                                                                                                    }
                                                                                                    position="absolute"
                                                                                                    top={
                                                                                                        '-134px'
                                                                                                    }
                                                                                                    filter="invert(1)"
                                                                                                    w="1165px"
                                                                                                    h="1165px"
                                                                                                    fit="cover"
                                                                                                    opacity={
                                                                                                        loaded
                                                                                                            ? 1
                                                                                                            : 0
                                                                                                    }
                                                                                                    onLoad={() =>
                                                                                                        onDetailImageLoad(
                                                                                                            i.url
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                    )}
                                                                            </Box>
                                                                        </Box>
                                                                    </Flex>
                                                                </Portal>
                                                                <Center
                                                                    w="100%"
                                                                    height={{
                                                                        base: detailsShow
                                                                            ? '120px'
                                                                            : '0',
                                                                        lg: '120px',
                                                                    }}
                                                                    transition="height ease-in-out 300ms"
                                                                >
                                                                    <Box
                                                                        display="flex"
                                                                        width="100%"
                                                                        ref={
                                                                            desktopInfo
                                                                        }
                                                                    ></Box>
                                                                </Center>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex
                                                        width="100%"
                                                        height="50px"
                                                        position="absolute"
                                                        zIndex="10"
                                                        bg="light.30"
                                                        display={{
                                                            base: 'flex',
                                                            lg: 'none',
                                                        }}
                                                    ></Flex>
                                                    <Flex
                                                        {...legendSwipe}
                                                        onClick={() => {
                                                            setDbzHide(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }}
                                                        position={{
                                                            base: 'absolute',
                                                            lg: 'relative',
                                                        }}
                                                        flexDirection={{
                                                            base: 'row',
                                                            lg: 'column',
                                                        }}
                                                        justifyContent="center"
                                                        top={{
                                                            base: '50px',
                                                            lg: 'unset',
                                                        }}
                                                        w="100%"
                                                        height="100%"
                                                        maxHeight={{
                                                            base: '100%',
                                                            lg: '60vh',
                                                            xl: '50vh',
                                                        }}
                                                        marginTop={{
                                                            base: reflectivityShow
                                                                ? '0px'
                                                                : '-50px',
                                                            lg: nzIsSelected
                                                                ? '45px'
                                                                : '15px',
                                                        }}
                                                        transition="margin ease 300ms"
                                                        marginBottom={{
                                                            base: '0px',
                                                            lg: '20px',
                                                        }}
                                                        backdropFilter={{
                                                            base: 'blur(10px)',
                                                            lg: 'none',
                                                        }}
                                                        textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                        background={{
                                                            base: isFirefox
                                                                ? 'rgba(247, 249, 251, 0.95)'
                                                                : 'rgba(247, 249, 251, 0.85)',
                                                            md: isFirefox
                                                                ? 'rgba(247, 249, 251, 0.95)'
                                                                : 'rgba(247, 249, 251, 0.85)',
                                                            lg: 'none',
                                                        }}
                                                        boxShadow={{
                                                            base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                            lg: 'none',
                                                        }}
                                                        px="25px"
                                                    >
                                                        <Flex
                                                            transition="all ease 300ms"
                                                            width={{
                                                                base: '110px',
                                                                lg: '100%',
                                                            }}
                                                            position={{
                                                                base: 'absolute',
                                                                lg: 'relative',
                                                            }}
                                                            top={{
                                                                base: '17px',
                                                                lg: 'unset',
                                                            }}
                                                            left={{
                                                                base: '20px',
                                                                lg: 'unset',
                                                            }}
                                                            minWidth={{
                                                                base: '110px',
                                                                md: '110px',
                                                                lg: '100%',
                                                            }}
                                                            justifyContent={{
                                                                base: 'flex-start',
                                                                lg: 'center',
                                                            }}
                                                            alignItems="center"
                                                            pt={{
                                                                base: '1px',
                                                                lg: '0',
                                                            }}
                                                            pb={{
                                                                base: '0px',
                                                                lg: '10px',
                                                            }}
                                                            flexGrow="1"
                                                        >
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    lg: '1',
                                                                }}
                                                                marginRight={{
                                                                    base: '3px',
                                                                    lg: '3px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.6rem',
                                                                    md: '0.7rem',
                                                                }}
                                                                color="gray.500"
                                                                fontWeight="600"
                                                                lineHeight="1.2"
                                                            ></Text>
                                                            <Text
                                                                transition="all ease 300ms"
                                                                opacity={{
                                                                    base: dbzHide
                                                                        ? '0'
                                                                        : '1',
                                                                    lg: '1',
                                                                }}
                                                                marginRight={{
                                                                    base: '10px',
                                                                    lg: '2px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.6rem',
                                                                    md: '0.7rem',
                                                                }}
                                                                color="gray.500"
                                                                fontWeight="600"
                                                                lineHeight="1.2"
                                                            >
                                                                Reflectivity in
                                                                dBZ
                                                            </Text>
                                                        </Flex>
                                                        <Flex
                                                            ml={{
                                                                base: dbzHide
                                                                    ? '0px'
                                                                    : '95px',
                                                                md: dbzHide
                                                                    ? '0px'
                                                                    : '110px',
                                                                lg: '0',
                                                            }}
                                                            flexDirection={{
                                                                base: 'row',
                                                                lg: 'column',
                                                            }}
                                                            pt={{
                                                                base: '5px',
                                                                lg: '0',
                                                            }}
                                                            justifyContent="center"
                                                            w="100%"
                                                            height="100%"
                                                            transition="all ease 300ms"
                                                        >
                                                            <RainRadarLegend
                                                                legendSwipe={
                                                                    legendSwipe
                                                                }
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </>
                                            )}
                                        </Flex>

                                        <Flex // DROPDOWN DRAWER TO STORE INFO FOR MOBILE ONLY
                                            width={{ base: '100%', lg: '0px' }}
                                            zIndex={detailsShow ? '101' : '1'}
                                            borderBottom={{
                                                base: '1px solid #E2E8F0',
                                                lg: 'none',
                                            }}
                                            mt="-1px"
                                            px="0px"
                                            pt={detailsShow ? '15px' : '0px'}
                                            height={{
                                                base: detailsShow
                                                    ? '200px'
                                                    : '0',
                                                lg: '0',
                                            }}
                                            display={{
                                                base: 'flex',
                                                md: 'flex',
                                                lg: 'none',
                                            }}
                                            transition="all ease 300ms"
                                            bg="#f9fbfd"
                                            borderColor="#E2E8F0"
                                        >
                                            <Flex
                                                width="100%"
                                                flexDirection="column"
                                                opacity={
                                                    detailsShow ? '1' : '0'
                                                }
                                                transition="all ease 300ms"
                                            >
                                                <Text
                                                    pl="21px"
                                                    alignSelf="left"
                                                    fontSize="0.7rem"
                                                    color="gray.500"
                                                    fontWeight="600"
                                                    lineHeight="1.2"
                                                >
                                                    Radar Information
                                                </Text>

                                                {status !== 'loading' && (
                                                    <Flex
                                                        pointerEvents={
                                                            !detailsShow &&
                                                            'none'
                                                        }
                                                        bg="light.10"
                                                        borderRadius="10px"
                                                        minHeight="112px"
                                                        border="1px solid #e9edf3"
                                                        mt={
                                                            detailsShow
                                                                ? '10px'
                                                                : '0'
                                                        }
                                                        mb={
                                                            detailsShow
                                                                ? '20px'
                                                                : '0'
                                                        }
                                                        ml="20px"
                                                        mr="20px"
                                                        px="10px"
                                                        py={
                                                            detailsShow
                                                                ? '10px'
                                                                : '0'
                                                        }
                                                        transition="all ease 300ms"
                                                        flexDirection="column"
                                                    >
                                                        {nzIsSelected && (
                                                            <Text
                                                                pl="5px"
                                                                alignSelf="left"
                                                                fontSize="0.7rem"
                                                                color="gray.400"
                                                                fontWeight="400"
                                                                lineHeight="1.2"
                                                            >
                                                                No information
                                                                available
                                                            </Text>
                                                        )}

                                                        <Box
                                                            width="100%"
                                                            ref={mobileInfo}
                                                        ></Box>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading Rain Radar.<br />Please try again later.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}

const OfflineBadge = () => (
    <Tooltip
        label="Radar is offline or under maintenance"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.8rem"
        alignItems="center"
        display="flex"
        placement="auto"
        ml="5px"
        bg="light.100"
    >
        <Badge
            marginRight="5px"
            marginTop="2px"
            ml="5px"
            bg="transparent"
            variant="outline"
            border="1px solid"
            borderColor="red.500"
            boxShadow="none"
            color="red.500"
            borderRadius="10"
            px="7px"
            pb="0px"
            pt="0px"
            fontSize="10px"
            fontWeight="600"
            height="18px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            Offline
        </Badge>
    </Tooltip>
)
