import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Flex,
    Box,
    Text,
    Button,
    ButtonGroup,
    useBreakpointValue,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    useDisclosure,
    useColorModeValue,
    HStack,
    Icon,
    Link,
    Portal,
} from '@chakra-ui/react'
import Image from '../../components/Image'
import { useSwipeable } from 'react-swipeable'
import { parseISO, formatISO, addHours, formatDistanceToNow } from 'date-fns'
import formatDateTime, {
    formatTimeAndTimezone,
    formatDateToTimezone,
} from '../../../util/dateFormatter'
import {
    isFirefox,
    isMobileOnly,
    isIOS,
    isMobileSafari,
    isDesktop,
} from 'react-device-detect'
import { useQuery } from 'react-query'

import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
    simulateFreeUserState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../globalState'
import { useTier, useAdminRole } from '../../../auth/hooks'

import { ReactComponent as sigwxicon } from '../../../icons/assets/sigwx.svg'
import PremiumBadge from '../../controls/PremiumBadge'
import { ReactComponent as InfoIcon } from '../../../icons/assets/info-nocircle.svg'
import { ReactComponent as PDFIcon } from '../../../icons/assets/pdf.svg'
import PinchDrag, { useScale } from '../components/PinchDrag'
import ZoomSlider from '../components/ZoomSlider'
import ErrorToggle from '../components/ErrorToggle'
import FullWidthError from '../components/FullWidthError'
import ErrorText from '../components/ErrorText'

export default function SigwxModal() {
    const { getAccessTokenSilently } = useAuth0()

    const fetchSigwxCharts = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/gnzsigwx/charts`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const isAdmin = useAdminRole()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const [sigwxLevel, setSigwxLevel] = useState(null)
    const [sigwx, setSigwx] = useState([])
    const [sigwxTime, setSigwxTime] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(0)
    const [imageLoadErrors, setImageLoadErrors] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [userGuideShow, setUserGuideShow] = useState(false)
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const detailsShow = false // const [detailsShow, setDetailsShow] = useState(false)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const time = Date.now()
    const timeFormatted = formatDateToTimezone(formatISO(time), 'utc')

    const pageScale = useBreakpointValue({
        base: 19,
        md: 12,
        lg: 10,
        xl: 8,
    })

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()
    const portalLevels = useBreakpointValue({
        base: { containerRef: mobile },
        md: { containerRef: desktop },
    })

    const pageScaleWidth = pageScale * 5 + '%'

    const { scale, setScale, xy, setXy } = useScale()
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    const [sigwxTimePosition, setSigwxTimePosition] = useState(0)
    const [sigwxLevelPosition, setSigwxLevelPosition] = useState(
        isPremium ? 0 : 2
    )
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const [buttonExpand, setButtonExpand] = useState('time')
    const timeZone = useRecoilValue(timeZoneState)

    const { data, status, refetch } = useQuery(
        `gnzsigwx-charts`,
        fetchSigwxCharts,
        { refetchOnWindowFocus: false }
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    const fourTimeSelections = sigwx && sigwx.length > 9

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    // Set time position if first image is out dated (can occur)
    useEffect(() => {
        if (sigwx && sigwx.length > 0) {
            const currentTime = Date.now()
            const currentTimeFormatted = formatDateToTimezone(
                formatISO(currentTime),
                'utc'
            )
            let indexToSet = 0
            // filter through the sigwx array to find the first current time selection
            for (let i = 0; i < sigwx.length; i++) {
                const validToTime = sigwx[i]?.validto
                const isValid =
                    validToTime && validToTime > currentTimeFormatted
                if (isValid) {
                    indexToSet = i
                    break
                }
            }
            if (indexToSet && sigwx[indexToSet]?.validfrom) {
                setSigwxTime(sigwx[indexToSet].validfrom)
                setSigwxTimePosition(indexToSet)
                // console.log('Updating SIGWX Chart to current time')
            }
        }
    }, [sigwx])

    // MOBILE SLIDE CHANGE BY SWIPE LEFT/RIGHT
    const timeSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (sigwxTimePosition > 0) {
                setSigwxTime(sigwx[sigwxTimePosition - 1].validfrom)
                setSigwxTimePosition(sigwxTimePosition - 1)
                setButtonExpand('time')
            }
        },
        onSwipedRight: () => {
            if (sigwxTimePosition < 2) {
                setSigwxTime(sigwx[sigwxTimePosition + 1].validfrom)
                setSigwxTimePosition(sigwxTimePosition + 1)
                setButtonExpand('time')
            }
        },
    })
    const levelSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (isPremium) {
                setShowPricingTiers(true)
                setSigwxLevelPosition(0)
            } else if (sigwxLevelPosition > 0) {
                setSigwxLevel(
                    sigwx.filter((time) => time.validfrom === sigwxTime)[
                        sigwxLevelPosition - 1
                    ].level
                )
                setSigwxLevelPosition(sigwxLevelPosition - 1)
                setButtonExpand('level')
            }
        },
        onSwipedRight: () => {
            if (isPremium) {
                setShowPricingTiers(true)
                setSigwxLevelPosition(0)
            } else if (sigwxLevelPosition < 2) {
                setSigwxLevel(
                    sigwx.filter((time) => time.validfrom === sigwxTime)[
                        sigwxLevelPosition + 1
                    ].level
                )
                setSigwxLevelPosition(sigwxLevelPosition + 1)
                setButtonExpand('level')
            }
        },
    })

    // USERGUIDE ON MOBILE SHOW BY SWIPE DOWN/UP
    const userGuideSwipe = useSwipeable({
        onSwipedDown: () => {
            setUserGuideShow(true)
        },
        onSwipedUp: () => {
            setUserGuideShow(false)
        },
    })

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        const highestLevel = data && data.slice(-1)
        if (data && data[0] && data[0].level) {
            sigwxRef.current = data
            setSigwx(data)
            isPremium
                ? setSigwxLevel(data[0].level, 0)
                : setSigwxLevel(highestLevel[0].level, 0)
            setSigwxTime(data[0]?.validfrom, 0)
        }
    }, [data, isPremium])

    const sigwxRef = useRef(null)

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.sigwxCharts) {
            sigwxRef.current && sigwxRef.current.length && refetch()
            setLoaded(false)
            setImagesLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [
        onOpen,
        onClose,
        dataMode,
        layerSelection,
        handleFullscreenModal,
        refetch,
    ])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    useEffect(() => {
        if (sigwx && sigwx.length && imagesLoaded) {
            setLoaded(imagesLoaded > 0 && sigwx.length > 0)
        }
    }, [sigwx, imagesLoaded])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded(0)
        }
    }, [showNetworkErrors])

    // *** Check image loading for errors ***
    function onImageLoad() {
        setImagesLoaded((i) => i + 1)
    }
    function onImageError(e) {
        // console.error('Image loading error:', e.target.src)
        setImageLoadErrors((i) => i + 1)
    }
    useEffect(() => {
        if (imagesLoaded + imageLoadErrors === sigwx.length) {
            if (imageLoadErrors > 0) {
                setImageLoadErrors(true)
            } else {
                setImageLoadErrors(false)
            }
        }
    }, [imagesLoaded, imageLoadErrors, sigwx.length])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const itemData =
        data && sigwxLevel && data.filter((i) => sigwxLevel === i.level)[0]

    function isOutOfDate() {
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow =
        showOutdatedErrors || isOutOfDate().outofdate || imageLoadErrors

    // useEffect(() => {
    // console.log('SIGWX Data: ', data)
    // console.log('SIGWX: ', sigwx)
    // }, [data, sigwx])

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            minHeight={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            // added flex here as header height not sticking causing spacing issues elswhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    // base: '0px 5px 10px -1px rgba(0,0,0,0.05)',
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        {...userGuideSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            paddingLeft={{
                                                base: '30px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={sigwxicon}
                                                ml={{ base: '0px', md: '4px' }}
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Box // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    <Text
                                                        display={{
                                                            base: 'block',
                                                            md: 'none',
                                                        }}
                                                    >
                                                        SIGWX Charts
                                                    </Text>
                                                    <Text
                                                        display={{
                                                            base: 'none',
                                                            md: 'block',
                                                        }}
                                                    >
                                                        Graphical NZ Significant
                                                        Weather Charts
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Portal {...portalLevels}>
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                display={{
                                                    base: 'flex',
                                                    md:
                                                        isAdmin &&
                                                        showErrorToggle
                                                            ? 'none'
                                                            : 'flex',
                                                }}
                                            >
                                                <Link
                                                    {...noFocus}
                                                    target="_blank"
                                                    opacity={{
                                                        base: '1',
                                                        md: '0.7',
                                                    }}
                                                    _hover={{ opacity: '1' }}
                                                    href={`${window.location.origin}/pdfs/GRAPHICAL_NZ_SIGWX_User_Guide_2023.pdf`}
                                                >
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        width="auto"
                                                        fontSize={{
                                                            base: '0.8rem',
                                                            md: '12px',
                                                        }}
                                                        lineHeight="1.3"
                                                        color="gray.500"
                                                        fontWeight={{
                                                            base: '500',
                                                            md: '400',
                                                        }}
                                                    >
                                                        <PDFIcon
                                                            width="20px"
                                                            height="20px"
                                                        />
                                                        <Text
                                                            pl="5px"
                                                            whiteSpace="pre"
                                                            pr="10px"
                                                        >
                                                            Graphical NZ SIGWX
                                                            User Guide
                                                        </Text>
                                                    </Flex>
                                                </Link>
                                            </Flex>
                                        </Portal>
                                        <Box ref={desktop}></Box>
                                        <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                            display={{
                                                base: 'inline-flex',
                                                md: 'none',
                                            }}
                                            background="none"
                                            color="light.100"
                                            // opacity={detailsShow ? '1' : '0.8'}
                                            fontSize="10px"
                                            fontWeight="600"
                                            height="40px"
                                            width="40px"
                                            alignItems="center"
                                            justifyContent="center"
                                            paddingLeft="2px"
                                            paddingRight="2px"
                                            borderRadius="10px"
                                            onClick={() => {
                                                setUserGuideShow(
                                                    (prevState) => !prevState
                                                )
                                            }}
                                            _focus={{
                                                opacity: '1 !important',
                                            }}
                                            _active={{
                                                background: 'none',
                                            }}
                                            _hover={{
                                                opacity: '1 !important',
                                            }}
                                        >
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="10px"
                                                color="light.100"
                                                variant="outline"
                                                fontSize="1rem"
                                                height="30px"
                                                width="34px"
                                                style={{
                                                    minWidth: '34px',
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                    background: 'light.20',
                                                }}
                                                _active={{
                                                    background: {
                                                        base: 'none',
                                                        md: 'light.20',
                                                    },
                                                    boxShadow: {
                                                        base: 'none',
                                                        md: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    as={InfoIcon}
                                                    boxSize={5}
                                                />
                                            </Flex>
                                        </Button>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? '100%'
                                                    : '0',
                                            md:
                                                isAdmin && showErrorToggle
                                                    ? 'max-content'
                                                    : '0',
                                        }}
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                        pl="10px"
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                            whiteSpace="pre"
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS && isMobileSafari
                                        ? 'calc(100% - 155px)'
                                        : 'calc(100% - 140px)',
                                md: '100%',
                            }}
                            bg={{
                                base: '#edf1f7',
                                md: '#f4f7fc',
                            }}
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Box
                                    {...userGuideSwipe}
                                    id="sigwxContainer"
                                    height="100%"
                                    ref={sigwxRef}
                                >
                                    {sigwx && (
                                        <Box
                                            bg={{
                                                base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 100%)',
                                                md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 100%)',
                                            }}
                                            h="100%"
                                            w={{ base: '100%', md: '100%' }}
                                            fontFamily="Open Sans"
                                            zIndex="1"
                                            marginTop={0}
                                            marginBottom={0}
                                            overflow="hidden"
                                        >
                                            <Box
                                                zIndex="2"
                                                h="100%"
                                                position="relative"
                                            >
                                                {sigwx && (
                                                    <>
                                                        <Flex // TOP TOOLBAR CONTAINER
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            width="100%"
                                                            minHeight="48px"
                                                            zIndex="2"
                                                            position="relative"
                                                            pl="23px"
                                                            pr="22px"
                                                            py="5px"
                                                            bg="light.600"
                                                            boxShadow={{
                                                                base: '0px 5px 30px -5px rgba(0,0,0,0.15)',
                                                                '2xl': 'none',
                                                            }}
                                                            borderBottom="1px solid #E2E8F0"
                                                        >
                                                            {!loaded && (
                                                                <Flex
                                                                    justifyContent={{
                                                                        base: 'center',
                                                                        md: 'flex-start',
                                                                    }}
                                                                    alignItems="center"
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                    px={{
                                                                        base: '10px',
                                                                        md: '2px',
                                                                    }}
                                                                >
                                                                    <Spinner
                                                                        color={
                                                                            'gray.400'
                                                                        }
                                                                        thickness="2px"
                                                                        speed="0.45s"
                                                                        boxSize="20px"
                                                                    />
                                                                </Flex>
                                                            )}
                                                            {loaded && (
                                                                <>
                                                                    <Flex
                                                                        className="issuedTime"
                                                                        flexDirection={{
                                                                            base: 'column',
                                                                            smd: 'row',
                                                                            md: 'column',
                                                                            ml: 'row',
                                                                        }}
                                                                        alignItems="center"
                                                                        paddingStart={{
                                                                            base: '5px',
                                                                            md: '0',
                                                                        }}
                                                                        color="light.100"
                                                                        opacity={
                                                                            detailsShow
                                                                                ? '0'
                                                                                : '1'
                                                                        }
                                                                        transition="opacity ease-in-out 300ms"
                                                                        gap={{
                                                                            base: '3px',
                                                                            smd: '30px',
                                                                            md: '3px',
                                                                            ml: '30px',
                                                                        }}
                                                                        py={{
                                                                            base: '3px',
                                                                            smd: '0px',
                                                                            md: '3px',
                                                                            ml: '0px',
                                                                        }}
                                                                    >
                                                                        <Flex minWidth="220px">
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width="80px"
                                                                                fontSize="0.8rem"
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                from:
                                                                            </Text>

                                                                            {sigwx
                                                                                .filter(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        sigwxLevel ===
                                                                                        i.level
                                                                                )
                                                                                .filter(
                                                                                    (
                                                                                        time
                                                                                    ) =>
                                                                                        time.validfrom ===
                                                                                        sigwxTime
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        issueStamp,
                                                                                        i
                                                                                    ) => (
                                                                                        <Text
                                                                                            whiteSpace="nowrap"
                                                                                            key={`SigwxTime${issueStamp.level}-${i}-${issueStamp.validfrom}`}
                                                                                            display={
                                                                                                sigwxLevel ===
                                                                                                issueStamp.level
                                                                                                    ? 'block'
                                                                                                    : 'none'
                                                                                            }
                                                                                            paddingStart="0"
                                                                                            paddingEnd="0"
                                                                                            fontWeight="400"
                                                                                            py="0px"
                                                                                            fontSize="0.8rem"
                                                                                            lineHeight="1.3"
                                                                                            borderColor="transparent"
                                                                                        >
                                                                                            {issueStamp?.validfrom &&
                                                                                                formatDateTime(
                                                                                                    issueStamp.validfrom,
                                                                                                    timeZone
                                                                                                )}
                                                                                        </Text>
                                                                                    )
                                                                                )}
                                                                        </Flex>
                                                                        <Flex minWidth="220px">
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={{
                                                                                    base: '80px',
                                                                                    smd: '63px',
                                                                                    md: '80px',
                                                                                    ml: '63px',
                                                                                }}
                                                                                fontSize="0.8rem"
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                to:
                                                                            </Text>

                                                                            {sigwx
                                                                                .filter(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        sigwxLevel ===
                                                                                        i.level
                                                                                )
                                                                                .filter(
                                                                                    (
                                                                                        time
                                                                                    ) =>
                                                                                        time.validfrom ===
                                                                                        sigwxTime
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        issueStamp,
                                                                                        i
                                                                                    ) => {
                                                                                        const validtoIsOutdated =
                                                                                            issueStamp?.validto &&
                                                                                            issueStamp.validto <
                                                                                                timeFormatted
                                                                                        return (
                                                                                            <Text
                                                                                                whiteSpace="nowrap"
                                                                                                key={`SigwxTime${issueStamp.level}-${i}-${issueStamp.validto}`}
                                                                                                display={
                                                                                                    sigwxLevel ===
                                                                                                    issueStamp.level
                                                                                                        ? 'block'
                                                                                                        : 'none'
                                                                                                }
                                                                                                paddingStart="0"
                                                                                                paddingEnd="0"
                                                                                                fontWeight={
                                                                                                    validtoIsOutdated
                                                                                                        ? '500'
                                                                                                        : '400'
                                                                                                }
                                                                                                py="0px"
                                                                                                fontSize="0.8rem"
                                                                                                lineHeight="1.3"
                                                                                                borderColor="transparent"
                                                                                                color={
                                                                                                    validtoIsOutdated
                                                                                                        ? 'warning.200'
                                                                                                        : 'light.100'
                                                                                                }
                                                                                            >
                                                                                                {issueStamp?.validto &&
                                                                                                    formatDateTime(
                                                                                                        issueStamp.validto,
                                                                                                        timeZone
                                                                                                    )}
                                                                                            </Text>
                                                                                        )
                                                                                    }
                                                                                )}
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Flex
                                                                        gap={{
                                                                            base:
                                                                                sigwx &&
                                                                                fourTimeSelections
                                                                                    ? '12px'
                                                                                    : '15px',
                                                                            sm:
                                                                                sigwx &&
                                                                                fourTimeSelections
                                                                                    ? '12px'
                                                                                    : '20px',
                                                                            smd: '20px',
                                                                            md: '20px',
                                                                            lg: '15px',
                                                                            '2xl': '20px',
                                                                        }}
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        position={{
                                                                            base: 'fixed',
                                                                            '2xl': 'relative',
                                                                        }}
                                                                        flexDirection={{
                                                                            base: 'row',
                                                                            smd: 'row',
                                                                        }}
                                                                        mb={{
                                                                            base: minimiseOnZoom
                                                                                ? '-90px'
                                                                                : '-1px',
                                                                            md: '-1px',
                                                                        }}
                                                                        transition="all ease 300ms"
                                                                        bottom={{
                                                                            base:
                                                                                isMobileOnly &&
                                                                                isIOS &&
                                                                                isMobileSafari
                                                                                    ? '85px'
                                                                                    : '70px',
                                                                            md: '0px',
                                                                            '2xl': 'unset',
                                                                        }}
                                                                        pt={{
                                                                            base: '15px',
                                                                            '2xl': '0',
                                                                        }}
                                                                        pb={{
                                                                            base: '20px',
                                                                            '2xl': '0',
                                                                        }}
                                                                        left={{
                                                                            base: '0px',
                                                                            '2xl': 'unset',
                                                                        }}
                                                                        bg={{
                                                                            base: isFirefox
                                                                                ? 'rgba(255,255,255,0.97)'
                                                                                : 'light.55',
                                                                            '2xl': 'transparent',
                                                                        }}
                                                                        zIndex="1"
                                                                        borderBottom={{
                                                                            base: '1px solid #e9edf3',
                                                                            md: 'none',
                                                                        }}
                                                                        boxShadow={{
                                                                            base: '0px -5px 30px -5px rgba(0,0,0,0.15)',
                                                                            '2xl': 'none',
                                                                        }}
                                                                        clipPath="inset(-50px 0px 0px 0px)"
                                                                        backdropFilter="blur(20px)"
                                                                        width={{
                                                                            base: '100%',
                                                                            '2xl': 'auto',
                                                                        }}
                                                                    >
                                                                        <Flex
                                                                            {...timeSwipe}
                                                                        >
                                                                            <ButtonGroup
                                                                                borderRadius="20px"
                                                                                px="2px"
                                                                                overflow="hidden"
                                                                                bg="rgba(8, 38, 71, 0.04)"
                                                                                boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                                                            >
                                                                                {sigwx
                                                                                    .filter(
                                                                                        (
                                                                                            i
                                                                                        ) =>
                                                                                            sigwxLevel ===
                                                                                            i.level
                                                                                    )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) => {
                                                                                            const firA =
                                                                                                a.validfrom
                                                                                            const firB =
                                                                                                b.validfrom
                                                                                            if (
                                                                                                firA <
                                                                                                firB
                                                                                            ) {
                                                                                                return -1
                                                                                            }
                                                                                            if (
                                                                                                firA >
                                                                                                firB
                                                                                            ) {
                                                                                                return 1
                                                                                            } else {
                                                                                                return 0
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            time,
                                                                                            i
                                                                                        ) => (
                                                                                            <Button // SIGWX TIME
                                                                                                key={`SigwxChartsTime${time.validfrom}-${time.validfrom}-${time.validto}`}
                                                                                                bg="transparent"
                                                                                                variant="outline"
                                                                                                fontSize="0.9rem"
                                                                                                fontWeight="600"
                                                                                                height={{
                                                                                                    base: '32px',
                                                                                                    md: '28px',
                                                                                                }}
                                                                                                width={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : '72px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '72px'
                                                                                                            : '88px',
                                                                                                    sm:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                    md: '88px',
                                                                                                    xl: '80px',
                                                                                                    '2xl': '88px',
                                                                                                }}
                                                                                                minWidth={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : '72px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '72px'
                                                                                                            : '88px',
                                                                                                    sm:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                    md: '88px',
                                                                                                    xl: '80px',
                                                                                                    '2xl': '88px',
                                                                                                }}
                                                                                                opacity={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '1',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '1',
                                                                                                    md: '1',
                                                                                                }}
                                                                                                visibility={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? 'hidden'
                                                                                                            : 'visible',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? 'hidden'
                                                                                                            : 'visible',
                                                                                                    md: 'visible',
                                                                                                }}
                                                                                                marginInlineStart="0px !important"
                                                                                                border="none"
                                                                                                justifyContent="center"
                                                                                                paddingLeft={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    md: '2px',
                                                                                                }}
                                                                                                paddingRight={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'level' &&
                                                                                                        sigwxTimePosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    md: '2px',
                                                                                                }}
                                                                                                py="2px"
                                                                                                transition="all ease 400ms"
                                                                                                {...noFocus}
                                                                                                onClick={() => {
                                                                                                    setSigwxTime(
                                                                                                        time.validfrom
                                                                                                    )
                                                                                                    setSigwxTimePosition(
                                                                                                        i
                                                                                                    )
                                                                                                    setButtonExpand(
                                                                                                        'time'
                                                                                                    )
                                                                                                }}
                                                                                                zIndex="1"
                                                                                                _active={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _focus={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _hover={{
                                                                                                    bg: 'none',
                                                                                                    opacity:
                                                                                                        {
                                                                                                            base:
                                                                                                                buttonExpand ===
                                                                                                                    'level' &&
                                                                                                                sigwxTimePosition !==
                                                                                                                    i
                                                                                                                    ? '0'
                                                                                                                    : '1',
                                                                                                            xs:
                                                                                                                buttonExpand ===
                                                                                                                    'level' &&
                                                                                                                sigwxTimePosition !==
                                                                                                                    i
                                                                                                                    ? '0'
                                                                                                                    : '1',
                                                                                                            md: '1',
                                                                                                        },
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    zIndex="1"
                                                                                                    paddingLeft="0px"
                                                                                                    lineHeight="1.3"
                                                                                                    textTransform="uppercase"
                                                                                                    fontSize={{
                                                                                                        base: '0.65rem',
                                                                                                        xs: fourTimeSelections
                                                                                                            ? '0.65rem'
                                                                                                            : '0.7rem',
                                                                                                        sm: '0.7rem',
                                                                                                    }}
                                                                                                    color={
                                                                                                        time?.validfrom &&
                                                                                                        time?.validto &&
                                                                                                        sigwxTime ===
                                                                                                            time?.validfrom &&
                                                                                                        time?.validto <
                                                                                                            timeFormatted
                                                                                                            ? 'warning.200'
                                                                                                            : sigwxTime ===
                                                                                                              time.validfrom
                                                                                                            ? 'light.200'
                                                                                                            : 'light.100'
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        sigwxTime ===
                                                                                                        time.validfrom
                                                                                                            ? '700'
                                                                                                            : '600'
                                                                                                    }
                                                                                                    opacity={
                                                                                                        sigwxTime ===
                                                                                                        time.validfrom
                                                                                                            ? '1'
                                                                                                            : '0.6'
                                                                                                    }
                                                                                                    _active={{
                                                                                                        color:
                                                                                                            time?.validfrom &&
                                                                                                            time?.validto &&
                                                                                                            sigwxTime ===
                                                                                                                time?.validfrom &&
                                                                                                            time?.validto <
                                                                                                                timeFormatted
                                                                                                                ? 'warning.200'
                                                                                                                : sigwxTime ===
                                                                                                                  time.validfrom
                                                                                                                ? 'light.200'
                                                                                                                : 'light.100',
                                                                                                        fontWeight:
                                                                                                            sigwxTime ===
                                                                                                            time.validfrom
                                                                                                                ? '700'
                                                                                                                : '600',
                                                                                                        opacity:
                                                                                                            sigwxTime ===
                                                                                                            time.validfrom
                                                                                                                ? '1'
                                                                                                                : '0.6',
                                                                                                    }}
                                                                                                    _selected={{
                                                                                                        color: 'light.10',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                        opacity:
                                                                                                            '1',
                                                                                                    }}
                                                                                                    transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                                                                                >
                                                                                                    {time?.validfrom &&
                                                                                                        formatTimeAndTimezone(
                                                                                                            formatISO(
                                                                                                                addHours(
                                                                                                                    parseISO(
                                                                                                                        time.validfrom
                                                                                                                    ),
                                                                                                                    3
                                                                                                                )
                                                                                                            ),
                                                                                                            timeZone
                                                                                                        )}
                                                                                                </Text>
                                                                                            </Button>
                                                                                        )
                                                                                    )}
                                                                                <Flex // BUTTON INDICATOR
                                                                                    position="absolute"
                                                                                    marginInlineStart="0px !important"
                                                                                    mt="2px"
                                                                                    ml="0px"
                                                                                    px={{
                                                                                        base: '36px',
                                                                                        xs: fourTimeSelections
                                                                                            ? '36px'
                                                                                            : '44px',
                                                                                        sm: fourTimeSelections
                                                                                            ? '40px'
                                                                                            : '44px',
                                                                                        md: '44px',
                                                                                        xl: '40px',
                                                                                        '2xl': '44px',
                                                                                    }}
                                                                                    height={{
                                                                                        base: '28px',
                                                                                        md: '24px',
                                                                                    }}
                                                                                    width="72px"
                                                                                    background="white"
                                                                                    borderRadius="20px"
                                                                                    bg="light.10"
                                                                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    transform={{
                                                                                        base:
                                                                                            buttonExpand ===
                                                                                                'time' &&
                                                                                            `translateX(${sigwxTimePosition}00%)`,
                                                                                        md: `translateX(${sigwxTimePosition}00%)`,
                                                                                    }}
                                                                                    transition="all 400ms"
                                                                                ></Flex>
                                                                            </ButtonGroup>
                                                                        </Flex>

                                                                        <Flex // FL Buttons
                                                                            {...levelSwipe}
                                                                        >
                                                                            <ButtonGroup
                                                                                borderRadius="20px"
                                                                                px="2px"
                                                                                overflow="hidden"
                                                                                bg="rgba(8, 38, 71, 0.04)"
                                                                                boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                                                            >
                                                                                {sigwx
                                                                                    .filter(
                                                                                        (
                                                                                            time
                                                                                        ) =>
                                                                                            time.validfrom ===
                                                                                            sigwxTime
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            l,
                                                                                            i
                                                                                        ) => (
                                                                                            <Button // SIGWX TIME
                                                                                                key={`SigwxBriefingFL${l.level}-${l.validfrom}-${l.validto}`}
                                                                                                bg="transparent"
                                                                                                variant="outline"
                                                                                                fontSize="0.9rem"
                                                                                                fontWeight="600"
                                                                                                height={{
                                                                                                    base: '32px',
                                                                                                    md: '28px',
                                                                                                }}
                                                                                                width={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : buttonExpand ===
                                                                                                                  'time' &&
                                                                                                              sigwxLevelPosition ===
                                                                                                                  i &&
                                                                                                              fourTimeSelections
                                                                                                            ? '46px'
                                                                                                            : '72px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '72px'
                                                                                                            : '88px',
                                                                                                    sm:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections &&
                                                                                                              sigwxLevelPosition ===
                                                                                                                  i
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                    md: '88px',
                                                                                                    xl: '80px',
                                                                                                    '2xl': '88px',
                                                                                                }}
                                                                                                minWidth={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : buttonExpand ===
                                                                                                                  'time' &&
                                                                                                              sigwxLevelPosition ===
                                                                                                                  i &&
                                                                                                              fourTimeSelections
                                                                                                            ? '46px'
                                                                                                            : '72px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections
                                                                                                            ? '72px'
                                                                                                            : '88px',
                                                                                                    sm:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0px'
                                                                                                            : fourTimeSelections &&
                                                                                                              sigwxLevelPosition ===
                                                                                                                  i
                                                                                                            ? '80px'
                                                                                                            : '88px',
                                                                                                    md: '88px',
                                                                                                    xl: '80px',
                                                                                                    '2xl': '88px',
                                                                                                }}
                                                                                                opacity={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '1',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '1',
                                                                                                    md: '1',
                                                                                                }}
                                                                                                visibility={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? 'hidden'
                                                                                                            : 'visible',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? 'hidden'
                                                                                                            : 'visible',
                                                                                                    md: 'visible',
                                                                                                }}
                                                                                                marginInlineStart="0px !important"
                                                                                                border="none"
                                                                                                justifyContent="center"
                                                                                                paddingLeft={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    md: '2px',
                                                                                                }}
                                                                                                paddingRight={{
                                                                                                    base:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    xs:
                                                                                                        buttonExpand ===
                                                                                                            'time' &&
                                                                                                        sigwxLevelPosition !==
                                                                                                            i
                                                                                                            ? '0'
                                                                                                            : '2px',
                                                                                                    md: '2px',
                                                                                                }}
                                                                                                py="2px"
                                                                                                transition="all ease 400ms"
                                                                                                {...noFocus}
                                                                                                onClick={() => {
                                                                                                    isPremium
                                                                                                        ? setShowPricingTiers(
                                                                                                              true
                                                                                                          )
                                                                                                        : setSigwxLevel(
                                                                                                              l.level
                                                                                                          )
                                                                                                    isPremium
                                                                                                        ? setSigwxLevelPosition(
                                                                                                              0
                                                                                                          )
                                                                                                        : setSigwxLevelPosition(
                                                                                                              i
                                                                                                          )
                                                                                                    setButtonExpand(
                                                                                                        'level'
                                                                                                    )
                                                                                                }}
                                                                                                zIndex="1"
                                                                                                _active={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _focus={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _hover={{
                                                                                                    bg: 'none',
                                                                                                    opacity:
                                                                                                        {
                                                                                                            base:
                                                                                                                buttonExpand ===
                                                                                                                    'time' &&
                                                                                                                sigwxLevelPosition !==
                                                                                                                    i
                                                                                                                    ? '0'
                                                                                                                    : '1',
                                                                                                            xs:
                                                                                                                buttonExpand ===
                                                                                                                    'time' &&
                                                                                                                sigwxLevelPosition !==
                                                                                                                    i
                                                                                                                    ? '0'
                                                                                                                    : '1',
                                                                                                            md: '1',
                                                                                                        },
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    zIndex="1"
                                                                                                    paddingLeft="0px"
                                                                                                    lineHeight="1.3"
                                                                                                    textTransform="uppercase"
                                                                                                    fontSize={{
                                                                                                        base: '0.65rem',
                                                                                                        xs: fourTimeSelections
                                                                                                            ? '0.65rem'
                                                                                                            : '0.7rem',
                                                                                                        sm: '0.7rem',
                                                                                                    }}
                                                                                                    color={
                                                                                                        sigwxLevel ===
                                                                                                        l.level
                                                                                                            ? 'light.200'
                                                                                                            : 'light.100'
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        sigwxLevel ===
                                                                                                        l.level
                                                                                                            ? '700'
                                                                                                            : '600'
                                                                                                    }
                                                                                                    opacity={
                                                                                                        sigwxLevel ===
                                                                                                        l.level
                                                                                                            ? '1'
                                                                                                            : isPremium
                                                                                                            ? '0.4'
                                                                                                            : '0.6'
                                                                                                    }
                                                                                                    _active={{
                                                                                                        color:
                                                                                                            sigwxLevel ===
                                                                                                            l.level
                                                                                                                ? 'light.10'
                                                                                                                : 'light.100',
                                                                                                        fontWeight:
                                                                                                            sigwxLevel ===
                                                                                                            l.level
                                                                                                                ? '700'
                                                                                                                : '600',
                                                                                                        opacity:
                                                                                                            sigwxLevel ===
                                                                                                            l.level
                                                                                                                ? '1'
                                                                                                                : '0.6',
                                                                                                    }}
                                                                                                    _selected={{
                                                                                                        color: 'light.10',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                        opacity:
                                                                                                            '1',
                                                                                                    }}
                                                                                                    transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                                                                                >
                                                                                                    {
                                                                                                        l.level
                                                                                                    }
                                                                                                </Text>
                                                                                                {/* *** PREMIUM BADGE *** */}
                                                                                                {isPremium &&
                                                                                                    l.level !==
                                                                                                        'fl100' && (
                                                                                                        <Flex
                                                                                                            position="absolute"
                                                                                                            right="11px"
                                                                                                            mt="-8px"
                                                                                                        >
                                                                                                            <PremiumBadge />
                                                                                                        </Flex>
                                                                                                    )}
                                                                                            </Button>
                                                                                        )
                                                                                    )}
                                                                                <Flex // BUTTON INDICATOR
                                                                                    position="absolute"
                                                                                    marginInlineStart="0px !important"
                                                                                    mt="2px"
                                                                                    ml="0px"
                                                                                    px={{
                                                                                        base:
                                                                                            buttonExpand ===
                                                                                                'time' &&
                                                                                            fourTimeSelections
                                                                                                ? '23px'
                                                                                                : '36px',
                                                                                        xs: fourTimeSelections
                                                                                            ? '36px'
                                                                                            : '44px',
                                                                                        sm: fourTimeSelections
                                                                                            ? '40px'
                                                                                            : '44px',
                                                                                        md: '44px',
                                                                                        xl: '40px',
                                                                                        '2xl': '44px',
                                                                                    }}
                                                                                    height={{
                                                                                        base: '28px',
                                                                                        md: '24px',
                                                                                    }}
                                                                                    width={{
                                                                                        base:
                                                                                            buttonExpand ===
                                                                                                'time' &&
                                                                                            fourTimeSelections
                                                                                                ? '46px'
                                                                                                : '72px',
                                                                                        xs: '72px',
                                                                                    }}
                                                                                    background="white"
                                                                                    borderRadius="20px"
                                                                                    bg="light.10"
                                                                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    transform={{
                                                                                        base:
                                                                                            buttonExpand ===
                                                                                                'level' &&
                                                                                            `translateX(${sigwxLevelPosition}00%)`,
                                                                                        md: `translateX(${sigwxLevelPosition}00%)`,
                                                                                    }}
                                                                                    transition="all 400ms"
                                                                                ></Flex>
                                                                            </ButtonGroup>
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Flex
                                                                        color="light.100"
                                                                        justifyContent="flex-end"
                                                                        alignItems="center"
                                                                        width={{
                                                                            base: '40px',
                                                                            md: '100%',
                                                                        }}
                                                                        opacity={{
                                                                            base: minimiseActive
                                                                                ? '1'
                                                                                : '0',
                                                                            md: '1',
                                                                        }}
                                                                        visibility={{
                                                                            base: minimiseActive
                                                                                ? 'visible'
                                                                                : 'hidden',
                                                                            md: 'visible',
                                                                        }}
                                                                        transition="all ease 300ms"
                                                                    >
                                                                        <ZoomSlider
                                                                            scale={
                                                                                scale
                                                                            }
                                                                            xy={
                                                                                xy
                                                                            }
                                                                            setScale={
                                                                                setScale
                                                                            }
                                                                            setXy={
                                                                                setXy
                                                                            }
                                                                            maxScale={
                                                                                3
                                                                            }
                                                                        />
                                                                    </Flex>
                                                                </>
                                                            )}
                                                        </Flex>

                                                        <Flex // IMAGE CONTAINER
                                                            direction="column"
                                                            position={{
                                                                base: 'relative',
                                                            }}
                                                            w={{ base: '100%' }}
                                                            h="calc(100vh - 115px)"
                                                            overflow={'hidden'}
                                                            pt={{
                                                                base: errorShow
                                                                    ? '0'
                                                                    : '20px',
                                                                md: errorShow
                                                                    ? '0px'
                                                                    : '25px',
                                                            }}
                                                            px="10px"
                                                            pb={{
                                                                base: '0px',
                                                                md: '0px',
                                                            }}
                                                            zIndex="1"
                                                        >
                                                            {data &&
                                                                itemData &&
                                                                errorShow && (
                                                                    <Flex
                                                                        px="15px"
                                                                        pt="10px"
                                                                        mb="15px"
                                                                        justifyContent="center"
                                                                    >
                                                                        <ErrorText>
                                                                            There
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            with
                                                                            the
                                                                            connection
                                                                            between
                                                                            PreFlight
                                                                            and
                                                                            MetService.
                                                                            {isOutOfDate()
                                                                                .updated && (
                                                                                <>
                                                                                    {
                                                                                        ' The SIGWX charts were last validated with MetService more than '
                                                                                    }
                                                                                    <strong>
                                                                                        {formatDistanceToNow(
                                                                                            parseISO(
                                                                                                isOutOfDate()
                                                                                                    .updated
                                                                                            ),
                                                                                            {
                                                                                                addSuffix: true,
                                                                                            }
                                                                                        )}
                                                                                    </strong>
                                                                                    {
                                                                                        '.'
                                                                                    }
                                                                                </>
                                                                            )}
                                                                            <br />
                                                                            There
                                                                            is a
                                                                            possibility
                                                                            that
                                                                            the
                                                                            latest
                                                                            SIGWX
                                                                            information
                                                                            is
                                                                            not
                                                                            available
                                                                            in
                                                                            PreFlight.
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                            <a
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '500',
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                                href="https://ifis.airways.co.nz/"
                                                                            >
                                                                                IFIS
                                                                            </a>
                                                                            .
                                                                        </ErrorText>
                                                                    </Flex>
                                                                )}

                                                            <Box
                                                                className="desktopLink"
                                                                display="flex"
                                                                ref={mobile}
                                                                flexDirection={{
                                                                    base: 'row',
                                                                    md: 'column',
                                                                }}
                                                                alignItems={{
                                                                    base: 'center',
                                                                    md: 'flex-end',
                                                                }}
                                                                justifyContent={{
                                                                    base: 'center',
                                                                    md: 'flex-end',
                                                                }}
                                                                mt={{
                                                                    base: userGuideShow
                                                                        ? '10px'
                                                                        : '-30px',
                                                                    md: '0px',
                                                                }}
                                                                height={{
                                                                    base: userGuideShow
                                                                        ? '37px'
                                                                        : '0px',
                                                                    md: '0px',
                                                                }}
                                                                px={{
                                                                    base: '25px',
                                                                    md: '25px',
                                                                }}
                                                                pb={{
                                                                    base: '30px',
                                                                    md: '0px',
                                                                }}
                                                                transition="all ease-in-out 300ms"
                                                                width={{
                                                                    base: '100%',
                                                                    md: 'unset',
                                                                }}
                                                                color="light.100"
                                                            ></Box>
                                                            <PinchDrag
                                                                scale={scale}
                                                                setScale={
                                                                    setScale
                                                                }
                                                                xy={xy}
                                                                setXy={setXy}
                                                                maxZoom={3}
                                                                maxZoomNewScale={
                                                                    2.999
                                                                }
                                                            >
                                                                {!loaded && (
                                                                    <Flex
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        pt="25vh"
                                                                        px={{
                                                                            base: '10px',
                                                                            md: '30px',
                                                                        }}
                                                                    >
                                                                        <Spinner
                                                                            color={
                                                                                'light.200'
                                                                            }
                                                                            thickness="3px"
                                                                            speed="0.45s"
                                                                            boxSize="60px"
                                                                        />
                                                                    </Flex>
                                                                )}
                                                                {sigwx
                                                                    .filter(
                                                                        (
                                                                            image
                                                                        ) => {
                                                                            if (
                                                                                errorShow
                                                                            ) {
                                                                                return (
                                                                                    sigwxLevel ===
                                                                                    image.level
                                                                                )
                                                                            }
                                                                            return true
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            image,
                                                                            i
                                                                        ) => (
                                                                            <Image
                                                                                key={`SigwxImage${image.level}-${image.validfrom}-${image.validto}-${i}`}
                                                                                maxWidth={
                                                                                    pageScaleWidth
                                                                                }
                                                                                height={
                                                                                    sigwxLevel ===
                                                                                        image.level &&
                                                                                    sigwxTime ===
                                                                                        image.validfrom
                                                                                        ? 'auto'
                                                                                        : '0px'
                                                                                }
                                                                                opacity={
                                                                                    sigwxLevel ===
                                                                                        image.level &&
                                                                                    sigwxTime ===
                                                                                        image.validfrom
                                                                                        ? '1'
                                                                                        : '0'
                                                                                }
                                                                                visibility={
                                                                                    sigwxLevel ===
                                                                                        image.level &&
                                                                                    sigwxTime ===
                                                                                        image.validfrom
                                                                                        ? 'visible'
                                                                                        : 'hidden'
                                                                                }
                                                                                onLoad={
                                                                                    onImageLoad
                                                                                }
                                                                                onError={
                                                                                    onImageError
                                                                                }
                                                                                margin={
                                                                                    'auto'
                                                                                }
                                                                                transition="width 20ms"
                                                                                src={`${window.location.origin}/metproxy?url=${image.url}`}
                                                                                alt="SIGWX CHART UNAVAILABLE"
                                                                            />
                                                                        )
                                                                    )}
                                                            </PinchDrag>
                                                        </Flex>
                                                    </>
                                                )}
                                                {isDesktop && (
                                                    <Text
                                                        width="140px"
                                                        textAlign="right"
                                                        display={{
                                                            base: 'none',
                                                            md: 'block',
                                                        }}
                                                        position="absolute"
                                                        bottom={{
                                                            base: '78px',
                                                            '2xl': '15px',
                                                        }}
                                                        right="15px"
                                                        color="gray.400"
                                                        fontWeight="400"
                                                        fontSize="0.65rem"
                                                        lineHeight="1.3"
                                                        opacity="0.7"
                                                    >
                                                        Click and hold on the
                                                        chart to drag it around
                                                        the screen
                                                    </Text>
                                                )}
                                                {sigwx.length < 1 && (
                                                    <Flex
                                                        fontFamily="Open Sans"
                                                        flexDirection="column"
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="0"
                                                            fontWeight="500"
                                                            py="40px"
                                                            minWidth="130px"
                                                            fontSize="0.9rem"
                                                            lineHeight="1.3"
                                                            borderColor="transparent"
                                                        >
                                                            No current GNZSIGWX
                                                            data
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading SIGWX Charts.<br />Please try again later, or visit <a href="https://ifis.airways.co.nz/">IFIS</a>.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
