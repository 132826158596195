import React, { useEffect } from 'react'
import useConfirm from './components/useConfirm'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    // AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    LightMode,
    Portal,
} from '@chakra-ui/react'

const WarningMessage = () => {
    const { id, prompt = '', isOpen = false, proceed, cancel } = useConfirm()

    const confirmEnable = Boolean(id === 'confirm-enable')
    const confirmDelete = Boolean(id === 'confirm-delete')
    const confirmAccept = Boolean(id === 'confirm-accept')
    const confirmSaveFavourite = Boolean(id === 'confirm-save-favourite')
    const confirmAcceptWarning = Boolean(id === 'confirm-accept-warning')

    useEffect(() => {
        const handleKeydown = (e) => {
            if (proceed && isOpen && e.key === 'Enter') {
                proceed()
            }
        }
        window.addEventListener('keydown', handleKeydown)
        return () => window.removeEventListener('keydown', handleKeydown)
    }, [proceed, isOpen])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Portal>
            <LightMode>
                <AlertDialog
                    isOpen={isOpen}
                    onClose={cancel}
                    motionPreset="slideInBottom"
                    isCentered
                    variant="alertMessage"
                    size="2xl"
                    closeOnOverlayClick={false}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent pt="10px">
                            <AlertDialogBody>{prompt}</AlertDialogBody>

                            <AlertDialogFooter pb={5}>
                                {!confirmAccept && !confirmAcceptWarning && (
                                    <Button
                                        {...noFocus}
                                        fontSize="0.8rem"
                                        size="sm"
                                        px="20px"
                                        minWidth="100px"
                                        fontWeight="500"
                                        borderRadius="10px"
                                        color="light.100"
                                        // ref={cancelRef}
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    {...noFocus}
                                    fontSize="0.8rem"
                                    size="sm"
                                    px="20px"
                                    minWidth="100px"
                                    fontWeight="500"
                                    borderRadius="10px"
                                    background={
                                        confirmDelete
                                            ? 'red.500'
                                            : confirmEnable ||
                                              confirmAcceptWarning ||
                                              confirmSaveFavourite
                                            ? 'warning.100'
                                            : 'light.200'
                                    }
                                    color="light.10"
                                    onClick={proceed}
                                    ml={3}
                                    _hover={{
                                        background: confirmDelete
                                            ? 'red.600'
                                            : confirmEnable ||
                                              confirmAcceptWarning ||
                                              confirmSaveFavourite
                                            ? 'warning.200'
                                            : 'light.201',
                                    }}
                                    _active={{
                                        background: confirmDelete
                                            ? 'red.600'
                                            : confirmEnable ||
                                              confirmAcceptWarning ||
                                              confirmSaveFavourite
                                            ? 'warning.200'
                                            : 'light.201',
                                    }}
                                    _focus={{
                                        background: confirmDelete
                                            ? 'red.600'
                                            : confirmEnable ||
                                              confirmAcceptWarning ||
                                              confirmSaveFavourite
                                            ? 'warning.200'
                                            : 'light.201',
                                    }}
                                >
                                    {confirmAccept
                                        ? 'Accept'
                                        : confirmDelete
                                        ? 'Delete'
                                        : confirmEnable
                                        ? 'Enable'
                                        : confirmSaveFavourite
                                        ? 'Save'
                                        : 'OK'}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </LightMode>
        </Portal>
    )
}

export default WarningMessage
