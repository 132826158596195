// Aerodrome Icons
import { ReactComponent as AerodromeVFR } from '../../../../icons/aero/ad-vfr-runway.svg'
import { ReactComponent as AerodromeIFR } from '../../../../icons/aero/ad-ifr-runway.svg'
import { ReactComponent as AerodromeNonPub } from '../../../../icons/aero/ad-nonpub.svg'
import { ReactComponent as HeliportVFR } from '../../../../icons/aero/hp-vfr.svg'
import { ReactComponent as HeliportIFR } from '../../../../icons/aero/hp-ifr.svg'
import { ReactComponent as HeliportNonPub } from '../../../../icons/aero/hp-nonpub.svg'
import { ReactComponent as WaterdromeVFR } from '../../../../icons/aero/ad-water.svg'
import { ReactComponent as AerodromeIFRRunway } from '../../../../icons/aero/ad-ifr-runway-direction.svg'
import { ReactComponent as AerodromeIFRNotam } from '../../../../icons/aero/ad-ifr-notam.svg'
// import { ReactComponent as GrassStrip } from '../../../../icons/aero/ad-grassstrip.svg'

// Aeronautical
import { ReactComponent as LineFIR } from '../assets/line-fir.svg'
import { ReactComponent as LineCTA } from '../assets/line-cta.svg'
import { ReactComponent as LineCTR } from '../assets/line-ctr.svg'
import { ReactComponent as LineCTRSector } from '../assets/line-ctr-sector.svg'
import { ReactComponent as LineCFZ } from '../assets/line-cfz.svg'
import { ReactComponent as LineMBZ } from '../assets/line-mbz.svg'
import { ReactComponent as LineTransit } from '../assets/line-transit-lane.svg'
import { ReactComponent as LineLFZ } from '../assets/line-lfz.svg'
import { ReactComponent as LineMOA } from '../assets/line-moa.svg'
import { ReactComponent as VRPoint } from '../../../../icons/aero/vrp.svg'
import { ReactComponent as IcaoWaypoint } from '../../../../icons/aero/waypoint-icao.svg'
import { ReactComponent as Routes } from '../assets/routes.svg'
import { ReactComponent as DMEIcon } from '../../../../icons/aero/dme.svg'
import { ReactComponent as NDBIcon } from '../../../../icons/aero/ndb.svg'
import { ReactComponent as NDBDMEIcon } from '../../../../icons/aero/ndbdme.svg'
import { ReactComponent as VORIcon } from '../../../../icons/aero/vor.svg'
import { ReactComponent as VORDMEIcon } from '../../../../icons/aero/vordme.svg'
import { ReactComponent as NotamArea } from '../assets/area-notam.svg'
import { ReactComponent as NotamGeneral } from '../../../../icons/aero/notam.svg'
import { ReactComponent as NotamAerodrome } from '../../../../icons/aero/notam-aerodrome.svg'
import { ReactComponent as NotamComms } from '../../../../icons/aero/notam-comms.svg'
import { ReactComponent as NotamDrone } from '../../../../icons/aero/notam-drone.svg'
import { ReactComponent as NotamObstacle } from '../../../../icons/aero/notam-obstacle.svg'
import { ReactComponent as NotamWarning } from '../../../../icons/aero/notam-warning.svg'

// Hazard Icons
import { ReactComponent as Windmill } from '../../../../icons/aero/windmill.svg'
import { ReactComponent as WindmillLit } from '../../../../icons/aero/windmill-lit.svg'
import { ReactComponent as ObstacleLarge } from '../../../../icons/aero/obstacle-large.svg'
import { ReactComponent as ObstacleLargeLit } from '../../../../icons/aero/obstacle-large-lit.svg'
import { ReactComponent as Obstacle } from '../../../../icons/aero/obstacle.svg'
import { ReactComponent as ObstacleLit } from '../../../../icons/aero/obstacle-lit.svg'
import { ReactComponent as MicrowaveTower } from '../../../../icons/aero/microwavetower.svg'
import { ReactComponent as MicrowaveTowerLit } from '../../../../icons/aero/microwavetower-lit.svg'
import { ReactComponent as Rocketry } from '../../../../icons/aero/rocketry.svg'
import { ReactComponent as Parachute } from '../../../../icons/aero/parachute.svg'
import { ReactComponent as FlightTraining } from '../../../../icons/aero/flighttraining.svg'
import { ReactComponent as Glider } from '../../../../icons/aero/glider.svg'
import { ReactComponent as GliderWinching } from '../../../../icons/aero/gliderwinching.svg'
import { ReactComponent as HangGlider } from '../../../../icons/aero/hangglider.svg'
import { ReactComponent as ModelAircraft } from '../../../../icons/aero/modelacft-large.svg'
import { ReactComponent as FiringActivity } from '../../../../icons/aero/firingactivity.svg'
import { ReactComponent as SensitiveFauna } from '../../../../icons/aero/sensitivefauna-large.svg'
import { ReactComponent as Explosive } from '../../../../icons/aero/explosive.svg'
import { ReactComponent as Drone } from '../../../../icons/aero/drone.svg'
import { ReactComponent as JetEfflux } from '../../../../icons/aero/jetefflux.svg'
import { ReactComponent as Kite } from '../../../../icons/aero/kite.svg'
import { ReactComponent as MetBalloon } from '../../../../icons/aero/metballoon.svg'
import { ReactComponent as Wire } from '../../../../icons/aero/wire.svg'
import { ReactComponent as Parasailing } from '../../../../icons/aero/parasailing.svg'
import { ReactComponent as Radar } from '../../../../icons/aero/radar.svg'

// Meteorological
// import { ReactComponent as SIGMETDuststorm } from '../../../../icons/met/duststorm.svg'
import { ReactComponent as SIGMETIcing } from '../../../../icons/met/icing-severe.svg'
// import { ReactComponent as SIGMETLightning } from '../../../../icons/met/lightning.svg'
import { ReactComponent as SIGMETMountainWave } from '../../../../icons/met/mountainwave-severe.svg'
import { ReactComponent as SIGMETRadioActiveCloud } from '../../../../icons/met/radioactivecloud.svg'
import { ReactComponent as SIGMETThunderstorm } from '../../../../icons/met/thunderstorm.svg'
import { ReactComponent as SIGMETTropicalCyclone } from '../../../../icons/met/sigmet-tropical-cyclone.svg'
import { ReactComponent as SIGMETTurbulence } from '../../../../icons/met/turbulence-severe.svg'
import { ReactComponent as SIGMETVolcano } from '../../../../icons/met/vac-symbol.svg'
import { ReactComponent as SIGWXCumulonimbus } from '../../../../icons/met/cumulonimbus.svg'
import { ReactComponent as SIGWXIcing } from '../../../../icons/met/icing-moderate.svg'
import { ReactComponent as SIGWXMountainWave } from '../../../../icons/met/mountainwave-moderate.svg'
import { ReactComponent as SIGWXClearAirTurbulence } from '../../../../icons/met/clearairturbulence-moderate.svg'
import { ReactComponent as SIGWXTurbulence } from '../../../../icons/met/turbulence-moderate.svg'
import { ReactComponent as SIGWXVolcano } from '../../../../icons/met/vaa-symbol.svg'

const aerodromesDetails = [
    {
        label: 'Aerodrome Published IFR',
        comment: '',
        size: 6,
        icon: AerodromeIFR,
    },
    {
        label: 'Aerodrome Published VFR',
        comment: '',
        size: 6,
        icon: AerodromeVFR,
    },
    {
        label: 'Aerodrome Non-Published',
        comment: '',
        size: 6,
        icon: AerodromeNonPub,
    },
    {
        label: 'Heliport Published IFR',
        comment: '',
        size: 6,
        icon: HeliportIFR,
    },
    {
        label: 'Heliport Published VFR',
        comment: '',
        size: 6,
        icon: HeliportVFR,
    },
    {
        label: 'Heliport Non-Published',
        comment: '',
        size: 6,
        icon: HeliportNonPub,
    },
    {
        label: 'Waterdrome Published',
        comment: '',
        size: 6,
        icon: WaterdromeVFR,
    },
    {
        label: '',
        comment: 'Line across symbol denotes runway orientation',
        size: 6,
        icon: AerodromeIFRRunway,
        border: 'top',
    },
    {
        label: '',
        comment: 'Orange dot denotes NOTAMs issued for aerodrome',
        size: 6,
        icon: AerodromeIFRNotam,
    },
    // {
    //     label: 'Grass Strip',
    //     comment: '',
    //     size: 6,
    //     icon: GrassStrip,
    // },
]

const airspaceDetails = [
    {
        label: 'NZ Flight Information Region Boundary',
        comment: 'NZZC',
        size: 7,
        icon: LineFIR,
        role: 'all',
    },
    {
        label: 'Control Area',
        comment: '(CTA)',
        size: 7,
        icon: LineCTA,
        role: 'all',
    },
    {
        label: 'Control Zone',
        comment: '(CTR)',
        size: 7,
        icon: LineCTR,
        role: 'all',
    },
    {
        label: 'Control Zone Sector',
        comment: '',
        size: 7,
        icon: LineCTRSector,
        role: 'all',
    },
    {
        label: 'General Aviation Area',
        comment: '(G)',
        size: 7,
        icon: LineTransit,
        role: 'all',
    },
    {
        label: 'Transit Lane',
        comment: '(T)',
        size: 7,
        icon: null,
        role: 'all',
    },
    {
        label: 'Low Flying Zone',
        comment: '(LFZ)',
        size: 7,
        icon: LineLFZ,
        role: 'all',
    },
    {
        label: 'Common Frequency Zone',
        comment: '(CFZ)',
        size: 7,
        icon: LineCFZ,
        role: 'all',
    },
    {
        label: 'Mandatory Broadcast Zone',
        comment: '(MBZ)',
        size: 7,
        icon: LineMBZ,
        role: 'all',
    },
    {
        label: 'Danger Area',
        comment: '(D)',
        size: 7,
        icon: LineMOA,
        role: 'all',
    },
    {
        label: 'Military Operating Area',
        comment: '(M)',
        size: 7,
        icon: null,
        role: 'all',
    },
    {
        label: 'Restricted Area',
        comment: '(R)',
        size: 7,
        icon: null,
        role: 'all',
    },
    {
        label: 'Volcanic Hazard Zone',
        comment: '(V)',
        size: 7,
        icon: null,
        role: 'all',
    },
]

const hazardsDetails = [
    {
        label: 'RPAS Activity',
        comment: '(Drone)',
        size: 5,
        icon: Drone,
        alignFlex: 'row',
    },
    {
        label: 'Explosive Activity',
        comment: '',
        size: 6,
        icon: Explosive,
    },
    {
        label: 'Firing Activity',
        comment: '',
        size: 6,
        icon: FiringActivity,
    },
    {
        label: 'Flight Training Activity',
        comment: '',
        size: 6,
        icon: FlightTraining,
    },
    {
        label: 'Glider Activity',
        comment: '',
        size: 6,
        icon: Glider,
    },
    {
        label: 'Glider Winching',
        comment: 'Includes max winch height',
        size: 6,
        icon: GliderWinching,
        alignComment: 'left',
    },
    {
        label: 'Hanglider/Paraglider Activity',
        comment: '',
        size: 6,
        icon: HangGlider,
    },
    {
        label: 'Jet Efflux',
        comment: '',
        size: 6,
        icon: JetEfflux,
    },
    {
        label: 'Kite Flying Activity',
        comment: '',
        size: 6,
        icon: Kite,
    },
    {
        label: 'Meteorological Balloon',
        comment: '',
        size: 6,
        icon: MetBalloon,
    },
    {
        label: 'Model Aircraft / UAS',
        comment: '',
        size: 6,
        icon: ModelAircraft,
    },
    {
        label: 'Parachute Landing Area',
        comment: '',
        size: 6,
        icon: Parachute,
    },
    {
        label: 'Parasailing Activity',
        comment: '',
        size: 6,
        icon: Parasailing,
    },
    {
        label: 'Rocketry',
        comment: '',
        size: 6,
        icon: Rocketry,
    },
    {
        label: 'Sensitive Fauna',
        comment: '',
        size: 6,
        icon: SensitiveFauna,
    },
]

const obstaclesDetails = [
    {
        label: 'Obstacle',
        comment: '<400 ft AGL',
        size: 5,
        icon: Obstacle,
    },
    {
        label: 'Obstacle - Lit',
        comment: '<400 ft AGL',
        size: 5,
        icon: ObstacleLit,
    },
    {
        label: 'Obstacle Large',
        comment: '>400 ft AGL',
        size: 6,
        icon: ObstacleLarge,
    },
    {
        label: 'Obstacle Large - Lit',
        comment: '>400 ft AGL',
        size: 6,
        icon: ObstacleLargeLit,
    },
    {
        label: 'Microwave Tower',
        comment: '',
        size: 6,
        icon: MicrowaveTower,
    },
    {
        label: 'Microwave Tower - Lit',
        comment: '',
        size: 6,
        icon: MicrowaveTowerLit,
    },
    {
        label: 'Wind Turbine',
        comment: '',
        size: 6,
        icon: Windmill,
    },
    {
        label: 'Wind Turbine - Lit',
        comment: '',
        size: 6,
        icon: WindmillLit,
    },
    {
        label: 'Wire Hazard',
        comment: '',
        size: 6,
        icon: Wire,
        alignComment: 'left',
    },
    {
        label: null,
        comment: 'Highest point AMSL of structures securing a wire span',
        size: 6,
        icon: null,
        alignComment: 'left',
    },
    {
        label: 'AIS or MET Radar',
        comment: '',
        size: 5,
        icon: Radar,
    },
]

const navaidsDetails = [
    {
        label: 'DME',
        comment: '',
        size: 6,
        icon: DMEIcon,
    },
    {
        label: 'NDB',
        comment: '',
        size: 6,
        icon: NDBIcon,
    },
    {
        label: 'NDB/DME',
        comment: '',
        size: 6,
        icon: NDBDMEIcon,
    },
    {
        label: 'VOR',
        comment: '',
        size: 6,
        icon: VORIcon,
    },
    {
        label: 'VOR/DME',
        comment: '',
        size: 6,
        icon: VORDMEIcon,
    },
]

const visualNavDetails = [
    {
        label: 'Visual Reporting Point',
        comment: '',
        size: 6,
        icon: VRPoint,
        role: 'all',
    },
    {
        label: 'ICAO Waypoint',
        comment: '',
        size: 5,
        icon: IcaoWaypoint,
        role: 'all',
    },
    {
        label: 'IFR Routes',
        comment: '',
        size: 6,
        icon: Routes,
        role: 'premium',
    },
]

const sigmetDetails = [
    // {
    //     label: 'Dust Storm',
    //     comment: '',
    //     size: 6,
    //     icon: SIGMETDuststorm,
    // },
    {
        label: 'Radioactive Cloud',
        comment: '',
        size: 6,
        icon: SIGMETRadioActiveCloud,
    },
    {
        label: 'Severe Icing',
        comment: '',
        size: 6,
        icon: SIGMETIcing,
    },
    {
        label: 'Severe Mountain Waves',
        comment: '',
        size: 6,
        icon: SIGMETMountainWave,
    },
    {
        label: 'Severe Turbulence',
        comment: '',
        size: 6,
        icon: SIGMETTurbulence,
    },
    {
        label: 'Thunderstorm',
        comment: '',
        size: 6,
        icon: SIGMETThunderstorm,
    },
    {
        label: 'Tropical Cyclone',
        comment: '',
        size: 6,
        icon: SIGMETTropicalCyclone,
    },
    {
        label: 'Volcanic Ash',
        comment: '',
        size: 6,
        icon: SIGMETVolcano,
    },
]

const sigwxDetails = [
    {
        label: 'Cumulonimbus Cloud',
        comment: '',
        size: 6,
        icon: SIGWXCumulonimbus,
    },
    {
        label: 'Moderate Clear Air Turbulence',
        comment: '',
        size: 6,
        icon: SIGWXClearAirTurbulence,
    },
    {
        label: 'Moderate Icing',
        comment: '',
        size: 6,
        icon: SIGWXIcing,
    },
    {
        label: 'Moderate Mountain Waves',
        comment: '',
        size: 6,
        icon: SIGWXMountainWave,
    },
    {
        label: 'Moderate Turbulence',
        comment: '',
        size: 6,
        icon: SIGWXTurbulence,
    },
    {
        label: 'Volcanic Ash',
        comment: '',
        size: 6,
        icon: SIGWXVolcano,
    },
]

const notamDetails = [
    {
        label: 'Aerodrome NOTAM',
        comment: '',
        size: 6,
        icon: NotamAerodrome,
        role: 'all',
    },
    {
        label: 'General NOTAM',
        comment: '',
        size: 6,
        icon: NotamGeneral,
        role: 'all',
    },
    {
        label: 'Navigation Facility NOTAM',
        comment: '',
        size: 6,
        icon: NotamComms,
        role: 'all',
    },
    {
        label: 'Obstacle NOTAM',
        comment: '',
        size: 6,
        icon: NotamObstacle,
        role: 'all',
    },
    {
        label: 'RPAS NOTAM',
        comment: '',
        size: 6,
        icon: NotamDrone,
        role: 'all',
    },
    {
        label: 'Warning NOTAM',
        comment: '',
        size: 6,
        icon: NotamWarning,
        role: 'all',
    },
    {
        label: '',
        comment: 'Denotes NOTAM Area',
        size: 6,
        icon: NotamArea,
        role: 'all',
        border: 'top',
    },
]

// AERO MODE LEGEND
export const aeroModeLegend = [
    {
        label: 'AERODROMES',
        content: aerodromesDetails,
    },
    {
        label: 'AIRSPACE',
        content: airspaceDetails,
    },
    {
        label: 'AERONAUTICAL HAZARDS',
        content: hazardsDetails,
    },
    {
        label: 'OBSTACLES',
        content: obstaclesDetails,
    },
    {
        label: 'NAVIGATION AIDS',
        content: navaidsDetails,
    },
    {
        label: 'NAVIGATION WAYPOINTS',
        content: visualNavDetails,
    },
]

// MET MODE LEGEND
export const metModeLegend = [
    {
        label: 'AERODROMES',
        content: aerodromesDetails,
    },
    {
        label: 'SIGMET',
        content: sigmetDetails,
    },
    {
        label: 'SIGWX',
        content: sigwxDetails,
    },
]

// ADVISORY MODE LEGEND
export const advsModeLegend = [
    {
        label: 'AERODROMES',
        content: aerodromesDetails,
    },
    {
        label: 'NOTAM',
        content: notamDetails,
    },
    {
        label: 'SIGMET',
        content: sigmetDetails,
    },
    {
        label: 'SIGWX',
        content: sigwxDetails,
    },
]

// BRIEFING MODE LEGEND
export const briefModeLegend = [
    {
        label: 'AERODROMES',
        content: aerodromesDetails,
    },
]

// DEFAULT MODE LEGEND (in case a mode fails to load)
export const defaultLegend = [
    {
        label: 'AERODROMES',
        content: aerodromesDetails,
    },
]
