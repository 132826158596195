import React from 'react'
import {
    UnorderedList,
    Box,
    Flex,
    Table,
    Tbody,
    Thead,
    Th,
    Tr,
    Td,
    Link,
    Text,
    OrderedList,
    ListItem,
    Collapse,
} from '@chakra-ui/react'

export default function TermsAndConditions(props) {
    const { showTerms, versionDate } = props

    // List Styles
    const heading = {
        className: 'heading',
        width: '100%',
        paddingTop: '6',
        fontSize: '0.9rem',
        fontWeight: '600',
        paddingBottom: '2',
        color: 'gray.500',
        lineHeight: '1.5',
        borderBottom: '1px solid #e5e5e5',
        marginBottom: '2',
    }
    const firstItem = {
        className: 'first-item',
        paddingLeft: '25px',
        paddingTop: '1',
        paddingBottom: '1',
        fontSize: '0.8rem',
        fontWeight: '400',
        color: 'light.100',
    }
    const secondItem = {
        marginLeft: '80px',
        paddingLeft: '10px',
        paddingTop: '1',
        paddingBottom: '1',
        fontSize: '0.8rem',
        fontWeight: '400',
        color: 'light.100',
    }
    const thirdItem = {
        marginLeft: '110px',
        paddingLeft: '10px',
        paddingTop: '1',
        paddingBottom: '1',
        fontSize: '0.8rem',
        fontWeight: '400',
        color: 'light.100',
    }
    const unorderedFirstItem = {
        marginLeft: '75px',
        paddingLeft: '10px',
        paddingTop: '1',
        paddingBottom: '1',
        fontSize: '0.8rem',
        fontWeight: '400',
        color: 'light.100',
    }

    return (
        <>
            {/* MINIMSED SECTION */}
            <Collapse
                in={showTerms}
                animateOpacity
                style={{
                    overflow: 'initial',
                    width: '100%',
                }}
            >
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    {/* 1 */}
                    <Box minWidth="25px">1</Box>
                    <Box>APPLICATION OF THESE TERMS</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">1.1</Box>
                        <Box>
                            These Terms apply to your use of and access to this
                            PreFlight website (<strong>Website</strong>),
                            including your access to, and use of, all
                            Information and other content on the Website and any
                            services provided by us on, via or in connection
                            with your access to, and use of, the Website and/or
                            or the Information (together,{' '}
                            <strong>Service</strong>
                            ). By using and/or accessing the Service:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            you agree to these Terms; and
                        </ListItem>

                        <ListItem {...secondItem} type="a">
                            where your access and use is on behalf of another
                            person, you confirm that you are authorised to, and
                            do in fact, agree to these Terms on that person’s
                            behalf and that, by agreeing to these Terms on that
                            person’s behalf, that person is bound by these
                            Terms.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">1.2</Box>
                        <Box>
                            If you do not agree to these Terms, you are not
                            authorised to access and use the Service, and you
                            must immediately stop doing so.
                        </Box>
                    </Flex>
                </Flex>

                {/* 2 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">2</Box>
                    <Box>CHANGES</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">2.1</Box>
                        <Box>
                            We reserve the right to change these Terms from time
                            to time by updating them on the Website. You are
                            responsible for ensuring you regularly check these
                            Terms and that you are familiar with the latest
                            Terms. By continuing to use and access the Service,
                            you agree to be bound by the changed Terms. Unless
                            stated otherwise, any update to these Terms will be
                            effective from the time they are posted on the
                            Website.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">2.2</Box>
                        <Box>
                            We may change, suspend, discontinue, or restrict
                            access to the Service (or part of it) without notice
                            or liability.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">2.3</Box>
                        <Box>
                            These Terms were last updated on{' '}
                            <strong>{versionDate}</strong>.
                        </Box>
                    </Flex>
                </Flex>

                {/* 3 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">3</Box>
                    <Box>DEFINITIONS</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">3.1</Box>
                        <Box>In these Terms:</Box>
                    </Flex>
                    <UnorderedList // secondItems
                        width="100%"
                        ml="0"
                    >
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Aeropath</strong> means Aeropath Limited.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>AIM</strong> Information means aeronautical
                            and related information, including the format of
                            that information, which is made available on the
                            Website by Aeropath.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Commercial Use</strong> means using the
                            Information for your internal lawful business or
                            commercial activity use.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Commercial User</strong> means a person who
                            uses the Information for Commercial Use.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>including</strong> and similar words do not
                            imply any limit.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Information</strong> means the AIM
                            Information and/or the Met Information.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Loss</strong> includes any direct, indirect,
                            or consequential loss, loss of profits, savings,
                            revenue or data, and any other claim (including for
                            indemnity, contribution, or otherwise), proceeding,
                            damage, loss, liability and cost, including legal
                            costs on a solicitor and own client basis.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>MetService</strong> means Meteorological
                            Service of New Zealand Limited.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Met Information</strong> means aviation
                            meteorological and related information, including
                            the format of that information, which is made
                            available on the Website by MetService.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Personal information</strong> means
                            information about an identifiable, living
                            individual.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Personal User</strong> means an individual
                            that uses the Information for their own personal
                            use, and not for Commercial Use.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Service</strong> has the meaning given in
                            clause 1.1.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Terms</strong> means these terms and
                            conditions titled PreFlight Terms and Conditions.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>User ID</strong> means your email address
                            used in conjunction with the password required to
                            access certain parts of Service.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>we</strong>, <strong>us</strong> or{' '}
                            <strong>our</strong> means Aeropath and/or
                            MetService.
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>Website</strong> means{' '}
                            <Link
                                href=" https://gopreflight.co.nz"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.gopreflight.co.nz
                            </Link>
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            <strong>you</strong> means you or, if clause 1.1b
                            applies, both you and the other person on whose
                            behalf you are acting.
                        </ListItem>
                    </UnorderedList>
                </Flex>

                {/* 4 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">4</Box>
                    <Box>YOUR OBLIGATIONS</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.1</Box>
                        <Box>
                            If you are a Personal User of Met Information, you
                            warrant that you are a student pilot who complies
                            with Subpart C of Part 61 of the Civil Aviation
                            Rules (or any replacement requirement for a student
                            pilot) or hold either:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            a Recreational Pilot Licence; or
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            a Private Pilot Licence; or
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            a membership or flying certificate issued by an
                            Aviation Recreation Organisation under Part 149 of
                            the Civil Aviation Rules; or
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            a Pilot Licence and that you are using the Service
                            for non-hire or reward and only in connection with
                            flights operated below 10,000 feet.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.2</Box>
                        <Box>If you are a Commercial User:</Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            you agree that any act or omission by any of your
                            personnel which, if carried out by you would be a
                            breach of these Terms, is deemed to be a breach of
                            these Terms by you; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            if we allow your personnel to use the Service, we
                            may limit the number of these persons using the
                            Service concurrently.
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.3</Box>
                        <Box>
                            You must provide true, current and complete
                            information in your dealings with us (including when
                            setting up an account), and must promptly update
                            that information as required so that the information
                            remains true, current and complete.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.4</Box>
                        <Box>You must keep your User ID secure and:</Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            not permit any other person to use your User ID,
                            including not disclosing or providing it to any
                            other person; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            immediately notify us if you become aware of any
                            unauthorised use or disclosure of your User ID, by
                            sending an email to{' '}
                            <Link
                                color="light.200"
                                href="mailto:support@aeropath.aero"
                            >
                                support@aeropath.aero
                            </Link>
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.5</Box>
                        <Box>You must:</Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            not re-supply (or enable the re-supply) of all or
                            part of the Service (whether for consideration or
                            not) to any other person;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            use the Service in a manner that at all times
                            complies with these Terms and all applicable laws,
                            rules and regulations;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            comply with our instructions in respect of your use
                            of the Service;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            not interfere with, damage, undertake any
                            unauthorised upload or modification to, or otherwise
                            harm the Service (or any related system or network),
                            including any attempt to do so;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            not act in any way, or use or introduce anything
                            (including any virus, worm, Trojan horse, timebomb,
                            keystroke logger, spyware or other similar feature)
                            that compromises, or may compromise, the Service or
                            any related system or network, or otherwise attempt
                            to damage or interfere with the Service or any
                            related system or network; and/or
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            access the Service using standard web browsers only
                            and not by any other method. Other methods include
                            use of any spider, robot, scraper, deep-linker,
                            harvester, data miner, or other automated device or
                            means.
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">4.6</Box>
                        <Box>
                            You must pay all subscription amounts due before
                            access to the Service will be granted. Subscriptions
                            are non-refundable. The subscription amounts are set
                            out on the Website and we may change those amounts
                            at any time. The changed amounts will be effective
                            from your next subscription payment date. If you do
                            not want to pay the changed amount, you must notify
                            us of this and immediately cease your use of the
                            Service prior to the next subscription payment date.
                            Where you are:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                    >
                        <ListItem {...secondItem} type="a">
                            a Personal User: You will be directed to make
                            payment by credit or debit card through the
                            Website’s secure electronic payment gateway which is
                            provided by a third-party provider, Stripe, on its
                            terms and conditions. By using the payment gateway
                            you agree to such terms and conditions of use. We
                            disclaim all responsibility for any consequences of
                            such use; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            a Commercial User: You must pay either MetService or
                            Aeropath in the manner advised by us.
                        </ListItem>
                    </OrderedList>
                </Flex>

                {/* 5 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">5</Box>
                    <Box>INTELLECTUAL PROPERTY</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">5.1</Box>
                        <Box>
                            We (and our licensors) own all proprietary and
                            intellectual property rights in the Service
                            (including all information (including Information),
                            data, text, graphics, artwork, photographs, logos,
                            marks, icons, sound recordings, videos and look and
                            feel). You must not copy, reproduce, modify, adapt,
                            distort or vary the Service by any means or in any
                            form without our prior written consent. For clarity,
                            nothing in this paragraph affects the intellectual
                            property rights of a third party in any statements,
                            advertisements and other materials posted on the
                            Website.
                        </Box>
                    </Flex>
                </Flex>

                {/* 6 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">6</Box>
                    <Box>INDEMNITY</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">6.1</Box>
                        <Box>
                            You indemnify and hold each of us (including our
                            subsidiary companies, officers, employees and
                            agents) harmless and keep us indemnified, from and
                            against all Loss incurred or suffered by each or
                            both of us arising from:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            your access to and/or use of (or inability to access
                            and use) the Service (or any part of the Service,
                            e.g. the Website, the AIM Information, or the Met
                            Information);
                        </ListItem>

                        <ListItem {...secondItem} type="a">
                            any breach of these Terms by you, including any
                            breach by a person who accesses and uses the Service
                            by using your User ID and any breach by your
                            personnel where you are a Commercial User; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            any claim made against us by third parties arising
                            from your use of the Information.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">6.2</Box>
                        <Box>
                            If you do not agree to these Terms, you are not
                            authorised to access and use the Service, and you
                            must immediately stop doing so.
                        </Box>
                    </Flex>
                </Flex>

                {/* 7 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">7</Box>
                    <Box>PRIVACY</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.1</Box>
                        <Box>
                            When you provide us with Personal Information, we
                            will comply with the New Zealand Privacy Act 2020.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.2</Box>
                        <Box>
                            When you sign up to the Service, including
                            registering on the Website, you provide various
                            personal details such as your name, company (if
                            any), phone number and email address. This
                            information and any other information provided by
                            you is stored in a secure database. You agree and
                            consent to either of us using that information so
                            that we can communicate with you, provide the
                            Service to you, enforce these Terms, conduct
                            statistical analysis, market products and services
                            to you, conduct credit checks (if necessary), and
                            conduct research and development. To avoid doubt,
                            where you provide Personal Information to one of us
                            (e.g. MetService), you consent to that the Personal
                            Information being shared with the other (e.g.
                            Aeropath) for the purposes described in this Section
                            7 (Privacy).
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.3</Box>
                        <Box>
                            You agree that by providing any personal information
                            to us, such information may be provided to third
                            party agencies (such as the New Zealand Police and
                            the New Zealand Civil Aviation Authority) for the
                            purposes of the maintenance of law and/or public
                            safety and for the purposes set out in clause 7.5.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.4</Box>
                        <Box>
                            Registered users of the Service may view and update
                            any personal information we hold about you by
                            contacting us at{' '}
                            <Link
                                color="light.200"
                                href="mailto:support@aeropath.aero"
                            >
                                support@aeropath.aero
                            </Link>
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.5</Box>
                        <Box>
                            In order to ensure compliance with the law and
                            maintain cyber security standards, we and our
                            authorised contractors monitor use of and
                            interaction with our information systems (including
                            email traffic and web browsing). This monitoring
                            includes activity logging and analysis and
                            occasional formal audit. Information gathered
                            through such monitoring (regardless of whether it
                            relates to or is connected with your involvement in
                            any malicious or illegal activity) may be:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                    >
                        <ListItem {...secondItem} type="a">
                            used to prevent and resolve unauthorised access to
                            or attacks on our information systems;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            provided to others (such as data analysis companies,
                            information security providers, the police and/or
                            other government agencies) to:
                        </ListItem>
                    </OrderedList>
                    <OrderedList
                        flexDirection="column" // thirdItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...thirdItem} type="i">
                            comply with New Zealand or overseas laws;
                        </ListItem>
                        <ListItem {...thirdItem} type="i">
                            improve cyber security; and/or
                        </ListItem>
                        <ListItem {...thirdItem} type="i">
                            assist us to decide what is needed in order to
                            comply with such laws or improve cyber security.
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.6</Box>
                        <Box>
                            Cookies are small pieces of information that a
                            website can store on your computer through your web
                            browser. The Website uses cookies only for
                            temporarily maintaining your user session – the
                            cookies will not be stored to your hard disk. In
                            order to use the Website, your browser must be
                            configured to accept cookies. You can usually
                            configure your browser to warn you before accepting
                            cookies if you wish.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.7</Box>
                        <Box>
                            Your User ID are stored in a secure database with
                            your password being hashed before being stored.
                            Whenever your user details or password are
                            transmitted between the server and your browser, the
                            details are transmitted over Secure Sockets Layer
                            (SSL).
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.8</Box>
                        <Box>
                            In addition to the details you provide, the server
                            (including those of our web host specified below)
                            will record the specific transactions you perform
                            when visiting the Website. This information will
                            only be used for statistical purposes.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.9</Box>
                        <Box>
                            Web server logs are also kept for the purposes of
                            generating site usage statistics and performing
                            security auditing. The logs record information every
                            time you request a page from the server. This
                            information will not be used to identify you except
                            in the event of irregularities in your use of the
                            Website. The information logged includes:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            The time of each page request;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            The pages you requested;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            What web browser you are using;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            Your client IP address;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            The details of any area or path briefing you
                            received.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.10</Box>
                        <Box>
                            Any Personal Information you provide to us may be
                            stored on the secure servers of our trusted service
                            providers, which may be located outside New Zealand.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.11</Box>
                        <Box>
                            You have the right to request access to and
                            correction of any of the Personal Information we
                            hold about you. If you would like to exercise these
                            rights, please email us at{' '}
                            <Link
                                color="light.200"
                                href="mailto:support@aeropath.aero"
                            >
                                support@aeropath.aero
                            </Link>
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">7.12</Box>
                        <Box>
                            The Website is hosted on Amazon Web Services. You
                            may contact us at the email address above for
                            further details of the web host.
                        </Box>
                    </Flex>
                </Flex>

                {/* 8 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">8</Box>
                    <Box>DISCLAIMER</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.1</Box>
                        <Box>
                            The Service is made available to you in good faith
                            and on an “as is” basis. To the maximum extent
                            permitted by law, all warranties express or implied
                            are excluded, including as to accuracy, fitness for
                            purpose, or completeness.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.2</Box>
                        <Box>
                            Without limiting clause 8.1 of these Terms, in
                            respect of the Information:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            for the AIM Information, the information is
                            presented in a format that is NOT considered to be
                            equivalent to AIPNZ documents and charts. The AIM
                            Information is provided for information purposes
                            only and is not to be relied on as a substitute for
                            a comprehensive knowledge of the relevant laws
                            (including all rules, regulations and by-laws
                            (including the New Zealand Civil Aviation Rules))
                            that apply to the operation of an aircraft or
                            operational aviation system. It is your
                            responsibility as an operator to read, understand
                            and operate any aircraft or aviation system in
                            accordance with all such laws (including the New
                            Zealand Civil Aviation Rules);
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            for the Met Information, you acknowledge and agree
                            the uncertainty inherent in forecasts and
                            predictions;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            neither of us, nor New Zealand Civil Aviation
                            Authority, make any representation or warranty that
                            the Information is accurate, up-to-date, or fit for
                            a particular purpose. We disclaim all liability and
                            responsibility for any error, Loss or consequences
                            which may arise from your reliance on the Service;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            if you are a Personal User, the Information is
                            provided to you for information purposes only, for
                            your own personal use. You must use it personally
                            (and not for Commercial Use) and not supply the
                            Information to any other person; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            if you are a Commercial User, the Information is
                            provided to you for information purposes only. You
                            must use it for your Commercial Use only and you
                            must not supply the Information to any other person.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.3</Box>
                        <Box>
                            You agree that, to the maximum extent permitted by
                            law, neither of us is liable to you or any third
                            party for any Loss incurred by you or a third party
                            as a result of any Information (including any
                            forecast or prediction) being or purportedly being
                            wholly or partially incorrect.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.4</Box>
                        <Box>In respect of the Website: </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            where the Website contains any links to other
                            websites, we do not accept any responsibility or
                            liability for the linked website or its content.
                            Responsibility for the content of any advertisements
                            appearing on the Website (including hyperlinks to
                            the advertiser’s own websites) rest solely with the
                            advertisers. The placement of such advertisements
                            does not represent an endorsement by us of the
                            advertiser’s products or services; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            the availability of the Website (and, as a result,
                            the Service) depends on the availability of the
                            Internet. The Website and Service are provided on an
                            “as available” basis and we do not warrant that the
                            Website or the Service (or your use of them) will be
                            error free or uninterrupted. The Website may be
                            withdrawn at times for maintenance. Although we will
                            do our best to ensure the Website is available for
                            you, from time to time unexpected issues occur.
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.5</Box>
                        <Box>
                            You acknowledge that our provision of Service may be
                            dependent upon information you provide to us. We do
                            not assume any responsibility for the quality and/or
                            accuracy of any information you provide to us.
                            Accordingly, you shall ensure that all information
                            you provide to us is true and correct at all times
                            and we disclaim all liability and responsibility in
                            relation to such information or our use of it
                            howsoever arising.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.6</Box>
                        <Box>
                            To the maximum extent permitted by law, we and our
                            licensors have no liability or responsibility to you
                            or any other person for any Loss in connection with:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            a all or any part of the Service being unavailable
                            or performing slowly;
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            b any error in, or omission from, any Information;
                            and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            c any exposure to viruses or other forms of
                            interference which may damage your computer system
                            or expose you to fraud when you access or use the
                            Service. To avoid doubt, you are responsible for
                            ensuring the process by which you access and use
                            Service protects you from this.
                        </ListItem>
                    </OrderedList>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.7</Box>
                        <Box>
                            You are responsible for ensuring that your access to
                            and use of Service is not illegal or prohibited, and
                            for your own compliance with applicable laws.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">8.8</Box>
                        <Box>
                            The Website uses a number of technologies that are
                            licenced from third parties. By using the Website,
                            you agree to adhere to the terms of use outlined by
                            these parties, which may be amended and updated from
                            time to time. A full list of technologies referenced
                            by the Website and their relevant terms of use can
                            be found below.
                        </Box>
                    </Flex>
                </Flex>

                {/* 9 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">9</Box>
                    <Box>LIABILITY EXCLUSION</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">9.1</Box>
                        <Box>To the maximum extent permitted by law:</Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        <ListItem {...secondItem} type="a">
                            your access and use of the Service is at your own
                            risk; and
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            we (and our licensors) have no liability to you or
                            any third party for Loss suffered or incurred by you
                            or any third party, whether such liability arises in
                            contract, in tort (including negligence), equity,
                            breach of statutory duty, or in any other way, in
                            respect of or arising out of or in any way connected
                            with these Terms, your access to, use of, or
                            reliance upon (or your inability to access, use, or
                            rely on) the Service, including related to the
                            operations of any aircraft or operational aviation
                            system by you or an operator, or with the provision
                            of the Service.
                        </ListItem>
                    </OrderedList>

                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">9.2</Box>
                        <Box>
                            Except to the extent permitted by law, nothing in
                            these Terms has the effect of contracting out of the
                            New Zealand Consumer Guarantees Act 1993 or any
                            other consumer protection law that cannot be
                            contracted out of. To the extent that our liability
                            cannot be excluded but can be limited under that Act
                            or law, our liability is limited (at our option) to:
                        </Box>
                    </Flex>
                    <OrderedList
                        flexDirection="column" // secondItems
                        width="100%"
                    >
                        <ListItem {...secondItem} type="a">
                            the fees paid for the relevant service or
                            Information; or
                        </ListItem>
                        <ListItem {...secondItem} type="a">
                            the cost of reperforming the relevant service or
                            remedying, repairing, enhancing or replacing the
                            relevant Information.
                        </ListItem>
                    </OrderedList>
                </Flex>

                {/* 10 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">10</Box>
                    <Box>TERMINATION AND SUSPENSION</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">10.1</Box>
                        <Box>
                            Without prejudice to any other right or remedy
                            available to us, we reserve the right to terminate
                            or suspend your access to or use of all or any part
                            of the Service immediately and without notice if we
                            consider that you have breached these Terms, if we
                            agree to cease the provision of the Service, or we
                            otherwise consider it appropriate. Upon termination
                            or suspension, you will not attempt to make further
                            use of the Service.
                        </Box>
                    </Flex>
                </Flex>

                {/* 11 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">11</Box>
                    <Box>GENERAL</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.1</Box>
                        <Box>
                            If we need to contact you, we may do so by posting a
                            notice on the Website or (at our option) emailing
                            you via the email address associated with your
                            account. You agree that this satisfies all legal
                            requirements in relation to written communications.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.2</Box>
                        <Box>
                            You must not assign or transfer any of your rights
                            and obligations under these Terms without our joint
                            prior written consent.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.3</Box>
                        <Box>
                            No failure, delay or indulgence by us in exercising
                            any of our powers or rights under these terms and
                            conditions will operate as a waiver of such powers
                            or rights. For us to waive a right under these
                            Terms, the waiver must be in writing.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.4</Box>
                        <Box>
                            Without limiting clauses 8 and 9, we will have no
                            liability to you for any act, omission or failure
                            associated with the Service arising from any cause
                            beyond our reasonable control.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.5</Box>
                        <Box>
                            These Terms supersede all prior agreements,
                            arrangements, representations and undertakings
                            between you and us and constitute the entire
                            agreement between you and us in relation to the
                            subject matter of these Terms . The parties have not
                            relied on any representation, warranty or agreement
                            relating to the Service that is not expressly set
                            out in the Terms, and no such representation,
                            warranty or agreement has any effect from the date
                            you agreed to these Terms.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.6</Box>
                        <Box>
                            These Terms , and any dispute relating to them or
                            the Service, are governed by and must be interpreted
                            in accordance with the laws of New Zealand. You and
                            we both submit to the non-exclusive jurisdiction of
                            the courts of New Zealand in relation to any dispute
                            connected with these Terms or the Service.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.7</Box>
                        <Box>
                            Clauses which, by their nature, are intended to
                            survive termination of these Terms, including
                            clauses 5, 7, 8, 9, 10, and 11, continue in force
                            after any expiry or termination.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.8</Box>
                        <Box>
                            If any part or provision of these Terms is or
                            becomes illegal, unenforceable, or invalid, that
                            part or provision is deemed to be modified to the
                            extent required to remedy the illegality,
                            unenforceability or invalidity. If a modification is
                            not possible, the part or provision must be treated
                            for all purposes as severed from these Terms. The
                            remainder of these Terms will be binding on you.
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">11.9</Box>
                        <Box>
                            These Terms create rights and benefits for each of
                            Aeropath and MetService and are enforceable by them
                            jointly and/or severally, i.e. one or both of us may
                            take action against you for breach of these Terms.
                        </Box>
                    </Flex>
                </Flex>

                {/* 12 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">12</Box>
                    <Box>RECOMMENDED BROWSERS</Box>
                </Flex>
                <Flex
                    flexDirection="column" // firstItems
                    width="100%"
                >
                    <Flex
                        flexDirection="row" // firstItems
                        width="100%"
                        {...firstItem}
                    >
                        <Box minWidth="30px">12.1</Box>
                        <Box>
                            To utilise the Website’s functionality to its
                            fullest extent we recommend using any of the
                            following desktop browsers and mobile operating
                            systems:
                        </Box>
                    </Flex>
                    <UnorderedList // secondItems
                        width="100%"
                        ml="0"
                    >
                        <ListItem {...unorderedFirstItem} type="circle">
                            Google Chrome (current release) with JavaScript and
                            Cookies enabled
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            iOS 15+
                        </ListItem>
                        <ListItem {...unorderedFirstItem} type="circle">
                            Android OS 12+
                        </ListItem>
                    </UnorderedList>
                </Flex>

                {/* 13 */}
                <Flex
                    flexDirection="row" // firstItems
                    width="100%"
                    {...heading}
                >
                    <Box minWidth="25px">13</Box>
                    <Box>ATTRIBUTION</Box>
                </Flex>
                <Text
                    paddingTop="1"
                    paddingBottom="1"
                    fontSize="0.8rem"
                    fontWeight="400"
                    color="light.100"
                >
                    AIM Information contains New Zealand Civil Aviation
                    Authority information.
                </Text>
                <Text
                    paddingLeft="25px"
                    paddingBottom="1"
                    fontSize="0.8rem"
                    fontWeight="400"
                    color="light.100"
                >
                    © New Zealand Civil Aviation Authority 2022
                </Text>
                <Text
                    paddingLeft="25px"
                    paddingBottom="12px"
                    fontSize="0.8rem"
                    fontWeight="400"
                    color="light.100"
                >
                    © Aeropath Limited 2022
                </Text>
                <Text
                    paddingTop="1"
                    paddingBottom="1"
                    fontSize="0.8rem"
                    fontWeight="400"
                    color="light.100"
                >
                    Met Information is owned by MetService.
                </Text>
                {/* </Flex> */}

                <Text fontSize="0.8rem" fontWeight="400" color="light.100">
                    The following technologies were incorporated into this
                    website. The providers of these technologies and their
                    respective terms of use are listed below.
                </Text>
                <Flex
                    flexDirection="column"
                    pt="10px"
                    pb={{ base: '20px', md: '30px' }}
                    paddingLeft={{
                        base: '0px',
                        md: '23px',
                    }}
                    width="100%"
                >
                    <Table variant="simple" fontSize="0.8rem">
                        <Thead fontWeight="400">
                            <Tr>
                                <Th py="7px" color="gray.400">
                                    Technology Provider
                                </Th>
                                <Th py="7px" color="gray.400">
                                    Terms of Service
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody fontWeight="300" color="light.100">
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Amazon Web Services
                                </Td>
                                <Td py="7px" pl="10px">
                                    <Link
                                        color="light.200"
                                        href="https://aws.amazon.com/legal/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        aws.amazon.com/legal
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Auth0
                                    <br />
                                    <br />
                                </Td>
                                <Td
                                    py="7px"
                                    pl="10px"
                                    display="flex"
                                    flexDirection="column"
                                    gap="2px"
                                >
                                    <Link
                                        color="light.200"
                                        href="https://auth0.com/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        auth0.com/privacy
                                    </Link>
                                    <Link
                                        color="light.200"
                                        href="https://www.okta.com/agreements/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        okta.com/agreements
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Land Information New Zealand
                                </Td>
                                <Td py="7px" pl="10px">
                                    <Link
                                        color="light.200"
                                        href="https://www.linz.govt.nz/linz-copyright"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        linz.govt.nz/linz-copyright
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Mapbox
                                </Td>
                                <Td py="7px" pl="10px">
                                    <Link
                                        color="light.200"
                                        href="https://www.mapbox.com/tos/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        mapbox.com/tos
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    PubNub
                                    <br />
                                    <br />
                                </Td>
                                <Td
                                    py="7px"
                                    pl="10px"
                                    display="flex"
                                    flexDirection="column"
                                    gap="2px"
                                >
                                    <Link
                                        pb="5px"
                                        color="light.200"
                                        href="https://www.pubnub.com/legal/terms-and-conditions/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        pubnub.com/legal/terms-and-conditions
                                    </Link>
                                    <Link
                                        color="light.200"
                                        href="https://www.pubnub.com/legal/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        pubnub.com/legal/privacy-policy
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Stripe
                                </Td>
                                <Td py="7px" pl="10px">
                                    <Link
                                        color="light.200"
                                        href="https://stripe.com/en-au/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        stripe.com/en-au/privacy
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Twilio
                                </Td>
                                <Td py="7px" pl="10px">
                                    <Link
                                        color="light.200"
                                        href="https://www.twilio.com/public-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        twilio.com/public-policy
                                    </Link>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td py="7px" pl="10px">
                                    Userback
                                    <br />
                                    <br />
                                </Td>
                                <Td
                                    py="7px"
                                    pl="10px"
                                    display="flex"
                                    flexDirection="column"
                                    gap="2px"
                                >
                                    <Link
                                        color="light.200"
                                        href=" https://www.userback.io/terms"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        userback.io/terms
                                    </Link>
                                    <Link
                                        color="light.200"
                                        href="https://www.userback.io/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        userback.io/privacy
                                    </Link>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Flex>
            </Collapse>
        </>
    )
}
