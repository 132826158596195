import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import ReactMapboxGl from 'react-mapbox-gl'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserMetaData } from '../auth/hooks'
import { isMobileOnly, isIOS, isMobileSafari } from 'react-device-detect'

// import getToken from '../metraweathercdn/getToken'
import Controls from './controls/index'
import MapColorModeHandler from './functions/MapColorModeHandler'
import SourceStatusHandler from './functions/SourceStatusHandler'
import MfaHandler from './functions/MfaHandler'
import FullscreenModalHandler from './functions/FullscreenModalHandler'
import ContrastHandler from './functions/ContrastHandler'
import MapClickHandler from './actions/MapClickHandler'
import FlyTo from './actions/FlyTo'
import SettingsMenu from './settings-menu'
import Icons from './icons'
import BaseLayers from './layers/baselayers'
import Layers from './layers'
import HoverToasts from './hovertoasts'
import Modals from './modals'
import Sidebar from './controls/sidebar'
import RouteHandler from './actions/RouteHandler'
import RotationWarning from '../device-warning/RotationWarning'

import {
    mapBoundsState,
    limitMapBoundsState,
    mapGlLostState,
} from '../globalState'

import 'mapbox-gl/dist/mapbox-gl.css'

// override default mapbox style
import './map.css'

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'
/* eslint-disable import/no-webpack-loader-syntax */
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker' // Load worker code separately with worker-loader

/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.workerClass = MapboxWorker // Wire up loaded worker to be used instead of the default

export default function Map() {
    const limitMapBounds = useRecoilValue(limitMapBoundsState)
    const mapBounds = useRecoilValue(mapBoundsState)
    const userMetaData = useUserMetaData()
    const setMapGlLost = useSetRecoilState(mapGlLostState)

    // const [metraWeatherToken, setMetraWeatherToken] = useState(null)
    // useEffect(() => {
    //     const setToken = async () => {
    //         setMetraWeatherToken(await getToken())
    //     }
    //     setToken()
    // }, [])

    const [accessToken, setAccessToken] = useState(null)
    const { getAccessTokenSilently } = useAuth0()
    useEffect(() => {
        const setToken = async () => {
            setAccessToken(await getAccessTokenSilently())
        }
        setToken()
    }, [getAccessTokenSilently])

    const Map = ReactMapboxGl({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        logoPosition: 'bottom-right',
        bearingSnap: 15,
        pitchWithRotate: false, // disable tilting the map
        antialias: true,
        preserveDrawingBuffer: true, // required for taking programmatically screenshots / printing map
        transformRequest: (url, resourceType) => {
            // if (
            //     url.indexOf('https://platforms-data.cdn.metraweather.com/') > -1
            // ) {
            //     return {
            //         url: url,
            //         headers: {
            //             Authorization: 'Bearer' + metraWeatherToken,
            //         },
            //     }
            // }
            if (url.indexOf(window.location.origin) > -1) {
                return {
                    url: url,
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            }
        },
    })

    // map defaults for user profile settings
    const mapCenter = userMetaData?.profile_settings?.mapcenter

    const mapZoom = userMetaData?.profile_settings?.mapzoom

    const mapZoomMobile =
        userMetaData?.profile_settings?.mapzoom_mobile ??
        userMetaData?.profile_settings?.mapzoom

    const zoomSetting =
        isMobileOnly && mapZoomMobile
            ? [mapZoomMobile]
            : isMobileOnly
            ? [4.2]
            : mapZoom
            ? [mapZoom]
            : [5]

    if (/*metraWeatherToken &&*/ accessToken) {
        return (
            <Map
                // eslint-disable-next-line react/style-prop-object
                style="mapbox://styles/mapbox/light-v10"
                center={mapCenter ? mapCenter : [172.62, -41.12]}
                zoom={zoomSetting}
                className={
                    isMobileOnly && isIOS && isMobileSafari
                        ? 'iOSMobile app'
                        : 'app'
                }
                maxBounds={limitMapBounds ? mapBounds : undefined}
                containerStyle={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    width: '100%',
                }}
                onStyleLoad={(map) => {
                    // disables touchPitch on load (as there's not currently a map options prop avaiable via the react-mapbox-gl API)
                    map.touchPitch.disable()

                    // REMOVE UNUSED MAPBOX LAYERS

                    // Remove mapbox national park and landuse layers
                    map.removeLayer('national-park')
                    map.removeLayer('landuse')

                    // Remove mapbox district boundary layers
                    map.removeLayer('admin-1-boundary')
                    map.removeLayer('admin-1-boundary-bg')

                    // Remove mapbox airport layers
                    map.removeLayer('airport-label')
                    map.removeLayer('aeroway-polygon')
                    map.removeLayer('aeroway-line')

                    // Remove mapbox buildings
                    map.removeLayer('building-outline')
                    map.removeLayer('building')

                    // Remove mapbox poi labels
                    map.removeLayer('poi-label')

                    // // ************* Trigger alert message if MapBox fails to load ****************** //
                    // when the WebGL context is lost.
                    map.on('webglcontextlost', () => {
                        console.error(
                            'A WebGL Context Lost event occurred. MapBox failed to load.'
                        )
                        setMapGlLost(true)
                    })

                    map.on('webglcontextrestored', () => {
                        console.error('WebGL Context has been restored.')
                        setMapGlLost(false)
                    })

                    // ********************************************************************************************** //
                    // *** DO NOT REMOVE THESE BELOW - USED FOR TESTING ***
                    // ********************************************************************************************** //

                    // // ************* add a sky layer that will show when the map is highly pitched ****************** //
                    // map.addLayer({
                    // 'id': 'sky',
                    // 'type': 'sky',
                    // 'paint': {
                    // 'sky-type': 'atmosphere',
                    // 'sky-atmosphere-sun': [0.0, 0.0],
                    // 'sky-atmosphere-sun-intensity': 15
                    // }
                    // });

                    // // ************* enable this to console log the zoom level ****************** //
                    // map.on('zoomend', (e) => {
                    //     console.log('Zoom Level:', map.getZoom())
                    // })

                    // // ************* enable this to console log features under a click action ****************** //
                    // map.on('click', (e) =>
                    //     console.log(map.queryRenderedFeatures(e.point))
                    // )

                    // // ************* enable this to console log the list of layers ****************** //
                    // map.on('click', (e) => console.log(map.getStyle().layers))

                    // console log polygon feature of map bounds
                    // map.on('click', () => {
                    //     const bounds = map.getBounds()

                    //     const mapViewPolygon = turf.polygon([
                    //         [
                    //             bounds.getNorthWest().toArray(),
                    //             bounds.getNorthEast().toArray(),
                    //             bounds.getSouthEast().toArray(),
                    //             bounds.getSouthWest().toArray(),
                    //             bounds.getNorthWest().toArray(),
                    //         ],
                    //     ])

                    //     console.log(mapViewPolygon)
                    // })
                }}
            >
                <RouteHandler />
                <Sidebar />
                <MapColorModeHandler />
                <ContrastHandler />
                <FullscreenModalHandler />
                <SourceStatusHandler />
                <MfaHandler />
                <SettingsMenu />
                <Icons />
                <BaseLayers />
                <Layers />
                <Controls />
                <HoverToasts />
                <MapClickHandler />
                <FlyTo />
                <Modals />
                <RotationWarning />
            </Map>
        )
    }

    return null
}
