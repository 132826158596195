import { config } from '../config'

export default function canUse(mode, layer, tier, wipItems) {
    const { layers } = config
    const { tier: layerTier } = layers[layer].collection.filter(
        (l) => l.id === mode
    )[0]
    const { wip } = layers[layer]
    const { unavailable } = layers[layer]
    return (
        (layerTier <= tier && !wip && !unavailable) ||
        (tier === 3 && wipItems && wip && unavailable)
    )
}
