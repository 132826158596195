import React, { useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Table,
    Tbody,
    Tr,
    Td,
    Text,
    Image,
    Flex,
    LightMode,
    Icon,
} from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { clickedFeaturesState } from '../../globalState'

import buildAerodromeIcon from '../icons/dynamic/buildAerodromeIcon'
import { code23Decode } from '../../util/notamDecode'

import aws from '../icons/met/aws.svg'
import webcam from '../icons/met/camera.svg'
import notamSymbol from '../icons/aero/notam.svg'
import notamAerodrome from '../../icons/notam-icons/notam-aerodrome.svg'
import notamComms from '../../icons/notam-icons/notam-comms.svg'
import notamDrone from '../../icons/notam-icons/notam-drone.svg'
import notamObstacle from '../../icons/notam-icons/notam-obstacle.svg'
import notamWarning from '../../icons/notam-icons/notam-warning.svg'
import droneSymbol from '../icons/aero/drone.svg'
import icingModerate from '../icons/met/icing-moderate.svg'
import icingSevere from '../icons/met/icing-severe.svg'
import clearAirTurbulenceModerate from '../icons/met/clearairturbulence-moderate.svg'
import turbulenceModerate from '../icons/met/turbulence-moderate.svg'
import turbulenceSevere from '../icons/met/turbulence-severe.svg'
import mountainWavesSymbolModerate from '../icons/met/mountainwave-moderate.svg'
import mountainWavesSymbolSevere from '../icons/met/mountainwave-severe.svg'
import thunderstormSymbol from '../icons/met/thunderstorm.svg'
import cumulonimbusSymbol from '../icons/met/cumulonimbus.svg'
import vaa from '../icons/met/vaa-symbol.svg'
import vacSymbol from '../icons/met/vac-symbol.svg'
import radiation from '../icons/met/radioactivecloud.svg'
import iconAAW from '../../icons/assets/aaw.svg'
import graforIcon from '../../icons/assets/grafor.svg'
import { ReactComponent as notam } from '../../icons/assets/notam.svg'
import { ReactComponent as wind } from '../../icons/assets/wind.svg'
import { ReactComponent as thunderstorm } from '../../icons/assets/thunderstorm.svg'
import { ReactComponent as ice } from '../../icons/assets/ice.svg'
import { ReactComponent as cumulonimbus } from '../../icons/assets/cumulonimbus.svg'
import { ReactComponent as mountainWavesModerate } from '../../icons/assets/mountain-waves-moderate.svg'
import { ReactComponent as mountainWavesSevere } from '../../icons/assets/mountain-waves-severe.svg'
import { ReactComponent as sigmetIcon } from '../../icons/assets/sigmet.svg'
import { ReactComponent as sigwxIcon } from '../../icons/assets/sigwx.svg'
import { ReactComponent as drone } from '../../icons/assets/drone.svg'
import { ReactComponent as grafor } from '../../icons/assets/grafor.svg'
import { ReactComponent as camera } from '../../icons/assets/camera.svg'
import { ReactComponent as weatherStation } from '../../icons/assets/aws.svg'
import { ReactComponent as aerodrome } from '../../icons/assets/aerodrome.svg'
import { ReactComponent as volcano } from '../../icons/assets/vaa.svg'

function sigWxFrequencyFormatter(freq) {
    const lookup = {
        isolatedEmbedded: 'ISOL EMBD',
        occasionalEmbedded: 'OCNL EMBD',
        occasional: 'OCNL',
    }

    if (lookup[freq]) {
        return lookup[freq]
    }
    return freq
}

function aerodromeIcon(f) {
    const { type, flightrule, rwyazimuth, notam } = f.properties
    return `data:image/svg+xml;base64,${buildAerodromeIcon(
        type,
        flightrule,
        rwyazimuth,
        notam
    )}`
}

function notamIcon(f) {
    if (['WU', 'WZ'].includes(f.properties.code23)) {
        return notamDrone
    } else if (['OB', 'OL'].includes(f.properties.code23)) {
        return notamObstacle
    } else if (f.properties.code23 === 'FA') {
        return notamAerodrome
    } else if (['W', 'R'].includes(f.properties.code23.charAt(0))) {
        return notamWarning
    } else if (['C', 'N'].includes(f.properties.code23.charAt(0))) {
        return notamComms
    } else {
        return notamSymbol
    }
}

function metIcon(f) {
    const sigwxAlerts =
        f?.properties?.alerts &&
        JSON.parse(f.properties.alerts).length &&
        JSON.parse(f.properties.alerts)

    const hasVAEruptionSigmet =
        f.properties.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.some((value) => value.startsWith('VA SIGMET in force'))

    if (f.layer === 'sigwx' || f.layer === 'sigwx-symbol-static') {
        if (f?.properties?.type) {
            if (f.properties.type === 'clearAirTurbulence') {
                return clearAirTurbulenceModerate
            } else if (f.properties.type === 'turbulence') {
                return turbulenceModerate
            } else if (f.properties.type === 'icing') {
                return icingModerate
            } else if (f.properties.type.toLowerCase() === 'mountainwaves') {
                return mountainWavesSymbolModerate
            } else if (f.properties.type === 'cumulonimbus') {
                return cumulonimbusSymbol
            } else if (f.properties.type === 'volcano' && hasVAEruptionSigmet) {
                return vacSymbol
            } else if (f.properties.type === 'volcano') {
                return vaa
            } else if (f.properties.type === 'radiation') {
                return radiation
            } else {
                return sigwxIcon
            }
        } else {
            return sigwxIcon
        }
    }

    if (f.layer === 'sigmet') {
        if (f?.properties?.phenomenon) {
            if (f.properties.phenomenon === 'SEV TURB') {
                return turbulenceSevere
            } else if (f.properties.phenomenon === 'SEV ICE') {
                return icingSevere
            } else if (f.properties.phenomenon === 'SEV MTW') {
                return mountainWavesSymbolSevere
            } else if (f.properties.phenomenon === 'SEV EMBD TS') {
                return thunderstormSymbol
            } else if (f.properties.phenomenon === 'EMBD TS') {
                return thunderstormSymbol
            } else if (f.properties.phenomenon === 'EMBD TSGR') {
                return thunderstormSymbol
            } else if (f.properties.phenomenon === 'VA ERUPTION') {
                return vacSymbol
            } else {
                return sigmetIcon
            }
        } else {
            return sigmetIcon
        }
    }
}

function metWatermark(f) {
    if (f.layer === 'sigwx' || f.layer === 'sigwx-symbol-static') {
        if (
            f.properties.type === 'turbulence' ||
            f.properties.type === 'clearAirTurbulence'
        ) {
            return wind
        } else if (f.properties.type === 'icing') {
            return ice
        } else if (f.properties.type.toLowerCase() === 'mountainwaves') {
            return mountainWavesModerate
        } else if (f.properties.type === 'cumulonimbus') {
            return cumulonimbus
        } else if (f.properties.type === 'volcano') {
            return volcano
        } else if (f.properties.type === 'radiation') {
            return radiation
        } else {
            return sigwxIcon
        }
    }

    if (f.layer === 'sigmet') {
        if (f.properties.phenomenon === 'SEV TURB') {
            return wind
        } else if (f.properties.phenomenon === 'SEV ICE') {
            return ice
        } else if (f.properties.phenomenon === 'SEV MTW') {
            return mountainWavesSevere
        } else if (f.properties.phenomenon === 'SEV EMBD TS') {
            return thunderstorm
        } else if (f.properties.phenomenon === 'EMBD TS') {
            return thunderstorm
        } else if (f.properties.phenomenon === 'EMBD TSGR') {
            return thunderstorm
        } else {
            return sigmetIcon
        }
    }
}

function aawIcon(f) {
    return iconAAW
}

function subtitle(f) {
    if (f.layer === 'aerodromes') {
        return f?.properties?.designator
    } else if (f.layer === 'aws') {
        return 'Aws'
    } else if (f.layer === 'webcams') {
        return 'Webcam'
    } else if (f.layer === 'notam') {
        return `${f?.properties?.series}${f?.properties?.number}/${f?.properties?.year}`
    } else if (f.layer === 'flight-advisory') {
        return f?.properties?.activity
    } else if (f.layer === 'rpas') {
        return 'from Airshare'
    } else if (f.layer === 'obstacles') {
        return `${f?.properties?.type}, ${f?.properties?.elevation} ft`
    } else if (f.layer === 'reportedobstacles') {
        return `${f?.properties?.type}, ${f?.properties?.elevation} ft`
    } else if (f.layer === 'sigwx' || f.layer === 'sigwx-symbol-static') {
        if (f?.properties?.type === 'volcano') {
            return f?.properties?.volcano
        } else if (f?.properties?.type === 'radiation') {
            return ''
        } else {
            return f?.properties?.lowerlabel && f?.properties?.lowerlabel
                ? `${
                      f.properties.lowerlabel === 'XXX'
                          ? 'XXX'
                          : f.properties.lowerlabel.substring(0, 2) === 'FL' ||
                            f.properties.lowerlabel === 'SFC'
                          ? f.properties.lowerlabel
                          : f.properties.lowerlabel + ' ft'
                  } - ${
                      f.properties.upperlabel === 'XXX'
                          ? 'XXX'
                          : f.properties.upperlabel.substring(0, 2) === 'FL'
                          ? f.properties.upperlabel
                          : f.properties.upperlabel + ' ft'
                  }`
                : null
        }
    } else if (f.layer === 'sigmet') {
        return f?.properties?.level && f.properties.level !== 'null'
            ? f.properties.level
            : f?.properties?.lowerlabel
            ? `${
                  f.properties.lowerlabel === 'XXX'
                      ? 'XXX'
                      : f.properties.lowerlabel.substring(0, 2) === 'FL' ||
                        f.properties.lowerlabel === 'SFC'
                      ? f.properties.lowerlabel
                      : f.properties.lowerlabel + ' ft'
              } - ${
                  f.properties.upperlabel === 'XXX'
                      ? 'XXX'
                      : f.properties.upperlabel.substring(0, 2) === 'FL'
                      ? f.properties.upperlabel
                      : f.properties.upperlabel + ' ft'
              }`
            : null
    } else {
        return 'Unkown'
    }
}

function id(f) {
    if (f.layer === 'sigmet') {
        return f?.properties?.id !== 'null' ? f.properties.id : ''
    } else {
        return ''
    }
}

const multiModalConfig = {
    aerodromes: {
        color: 'blue',
        icon: (item) => aerodromeIcon(item),
        watermark: (item) => aerodrome,
        title: (item) => `${item?.properties?.name}`,
        subtitle: (item) => subtitle(item),
        id: null,
    },
    aws: {
        color: 'grey',
        icon: (item) => aws,
        watermark: (item) => weatherStation,
        title: (item) => `${item?.properties?.name}`,
        subtitle: (item) => `${item?.properties?.designator}`,
        id: null,
    },
    webcams: {
        color: 'grey',
        icon: (item) => webcam,
        watermark: (item) => camera,
        title: (item) => `${item?.properties?.name}`,
        subtitle: (item) => subtitle(item),
        id: null,
    },
    notam: {
        color: 'orange',
        icon: (item) => notamIcon(item),
        watermark: (item) => notam,
        title: (item) => `${code23Decode(item?.properties?.code23)}`,
        subtitle: (item) => subtitle(item),
        id: null,
    },
    // any changes made to sigwx - please duplicate in sigwx-symbol-static
    sigwx: {
        color: 'orange',
        icon: (item) => metIcon(item),
        watermark: (item) => metWatermark(item),
        title: (item) =>
            (item?.properties?.frequency
                ? `${sigWxFrequencyFormatter(item.properties.frequency)} `
                : '') +
            (item?.properties?.type === 'volcano'
                ? `VOLCANIC ALERT ${
                      item?.properties?.alertlevel
                          ? 'LEVEL ' + item.properties.alertlevel
                          : ''
                  }`
                : item?.properties?.type === 'clearAirTurbulence'
                ? 'MOD CLEAR AIR TURBULENCE'
                : item?.properties?.type === 'mountainWaves'
                ? 'MOUNTAIN WAVES'
                : item?.properties?.type === 'radiation'
                ? 'RADIATION'
                : 'MOD ' + item?.properties?.type.toUpperCase()),
        subtitle: (item) => subtitle(item),
        id: null,
    },
    // any changes made to sigwx-symbol-static - please duplicate in sigwx
    'sigwx-symbol-static': {
        color: 'orange',
        icon: (item) => metIcon(item),
        watermark: (item) => metWatermark(item),
        title: (item) =>
            (item?.properties?.frequency
                ? `${sigWxFrequencyFormatter(item.properties.frequency)} `
                : '') +
            (item?.properties?.type === 'volcano'
                ? `VOLCANIC ALERT ${
                      item?.properties?.alertlevel
                          ? 'LEVEL ' + item.properties.alertlevel
                          : ''
                  }`
                : item?.properties?.type === 'clearAirTurbulence'
                ? 'MOD CLEAR AIR TURBULENCE'
                : item?.properties?.type === 'mountainWaves'
                ? 'MOUNTAIN WAVES'
                : item?.properties?.type === 'radiation'
                ? 'RADIATION'
                : 'MOD ' + item?.properties?.type.toUpperCase()),
        subtitle: (item) => subtitle(item),
        id: null,
    },
    sigmet: {
        color: 'orange',
        icon: (item) => metIcon(item),
        watermark: (item) => metWatermark(item),
        title: (item) => `${item?.properties?.phenomenon.toUpperCase()}`,
        subtitle: (item) => subtitle(item),
        id: (item) => id(item),
    },
    rpas: {
        color: 'orange',
        icon: () => droneSymbol,
        watermark: (item) => drone,
        title: () => 'RPAS Flight',
        subtitle: (item) => ` #${item?.properties?.id}`,
        id: null,
    },
    grafor: {
        color: 'orange',
        watermark: (item) => grafor,
        icon: (item) => graforIcon,
        title: (item) => 'GRAFOR',
        subtitle: (item) => `Area ${item?.properties?.number}`,
        id: null,
    },
    aaw: {
        color: 'orange',
        watermark: (item) => wind,
        icon: (item) => aawIcon(item),
        title: (item) => 'Aviation Area Winds',
        subtitle: (item) => `${item?.properties?.name}`,
        id: null,
    },
}

export default function ClickedMultiple() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        // console.log("clickedFeatures length:", clickedFeatures.length)
        clickedFeatures.length > 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    function onCloseHandler() {
        clickedFeatures.length > 1 && setClickedFeatures([])
        onClose()
    }

    const feat = clickedFeatures[0]
    const sigwxAlerts =
        feat?.properties?.alerts &&
        JSON.parse(feat.properties.alerts).length &&
        JSON.parse(feat.properties.alerts)

    const hasVAEruptionSigmet =
        feat.properties.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.some((value) => value.startsWith('VA SIGMET in force'))

    // console.log('clickedFeatures', clickedFeatures)

    return (
        <LightMode>
            <Modal
                isCentered
                variant="primary"
                size="md"
                onClose={onCloseHandler}
                isOpen={isOpen}
                motionPreset="slideInBottom"
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton color="white" />
                    <ModalHeader
                        backgroundColor="gray.600"
                        backgroundImage="linear-gradient(345deg, #445777 10%, #2b3c5a 90%)"
                        color="white"
                        fontSize="1rem"
                        paddingStart="6"
                    >
                        SELECT AN ITEM
                    </ModalHeader>
                    <ModalBody p={0}>
                        <Table variant="simple">
                            <Tbody>
                                {clickedFeatures.length &&
                                    clickedFeatures.length > 1 &&
                                    clickedFeatures.map((feat) => {
                                        return (
                                            <Tr
                                                overflow="hidden"
                                                clipPath="inset(0px 0px 0px 0px)"
                                                key={
                                                    feat.layer +
                                                    feat.properties.id
                                                }
                                                w="100%"
                                                _hover={{
                                                    cursor: 'pointer',
                                                    backgroundColor: 'gray.100',
                                                }}
                                                onClick={() =>
                                                    setClickedFeatures(
                                                        clickedFeatures.filter(
                                                            (cf) =>
                                                                cf.layer ===
                                                                    feat.layer &&
                                                                cf.properties
                                                                    .id ===
                                                                    feat
                                                                        .properties
                                                                        .id
                                                        )
                                                    )
                                                }
                                                // backgroundImage="linear-gradient(345deg, #f5f7f9 10%, #fff 90%)"
                                            >
                                                <Td
                                                    paddingTop={4}
                                                    paddingBottom={4}
                                                    paddingStart={6}
                                                    paddingEnd={3}
                                                    width="70px"
                                                >
                                                    <Image
                                                        boxSize="32px"
                                                        src={multiModalConfig[
                                                            feat.layer
                                                        ].icon(feat)}
                                                        alt="icon"
                                                        color="#666666"
                                                    />
                                                    {feat.properties.type ===
                                                        'volcano' &&
                                                        feat?.properties
                                                            ?.alertlevel && ( //  *** ALERT LEVEL TEXT FOR VAA ***
                                                            <Flex
                                                                position="absolute"
                                                                width="32px"
                                                                marginTop="-25px"
                                                                justifyContent="center"
                                                                color="white"
                                                                pt={
                                                                    hasVAEruptionSigmet
                                                                        ? '3px'
                                                                        : '0px'
                                                                }
                                                            >
                                                                <Text
                                                                    fontSize="0.65rem"
                                                                    fontWeight="600"
                                                                    letterSpacing="-1px"
                                                                >
                                                                    {
                                                                        feat
                                                                            .properties
                                                                            .alertlevel
                                                                    }
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                </Td>
                                                <Td
                                                    fontFamily="Open Sans"
                                                    fontWeight="bold"
                                                    paddingTop={4}
                                                    paddingBottom={4}
                                                    paddingStart={2}
                                                    paddingEnd={2}
                                                    textTransform={
                                                        feat.layer === 'aws' &&
                                                        'uppercase'
                                                    }
                                                >
                                                    <Text>
                                                        {multiModalConfig[
                                                            feat.layer
                                                        ].title(feat)}
                                                    </Text>
                                                    {feat.layer ===
                                                        'sigmet' && (
                                                        <Text
                                                            fontWeight="400"
                                                            fontSize="0.7rem"
                                                        >
                                                            {multiModalConfig[
                                                                feat.layer
                                                            ].id(feat)}
                                                        </Text>
                                                    )}
                                                </Td>
                                                <Td
                                                    fontFamily="Open Sans"
                                                    textAlign="end"
                                                    paddingTop={4}
                                                    paddingBottom={4}
                                                    paddingStart={2}
                                                    paddingEnd={7}
                                                >
                                                    <Text
                                                        whiteSpace="pre"
                                                        zIndex="1"
                                                        position="relative"
                                                    >
                                                        {multiModalConfig[
                                                            feat.layer
                                                        ].subtitle(feat)}
                                                    </Text>
                                                    <Flex
                                                        position="absolute"
                                                        right="18px"
                                                        justifyContent="flex-end"
                                                        marginTop="-22px"
                                                    >
                                                        <Icon
                                                            as={multiModalConfig[
                                                                feat.layer
                                                            ].watermark(feat)}
                                                            alt=""
                                                            pointerEvents="none"
                                                            transform="scale(2.4) translateY(0px)"
                                                            opacity="0.03"
                                                            boxSize="40px"
                                                            color="#033c77"
                                                        />
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                            </Tbody>
                        </Table>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </LightMode>
    )
}
