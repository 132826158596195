import React, {
    useEffect,
    useContext,
    useState,
    useRef,
    useCallback,
} from 'react'
import { useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { MapContext } from 'react-mapbox-gl'
import * as turf from '@turf/turf'
import { Text, Flex, Icon, useBreakpointValue } from '@chakra-ui/react'
import { isFirefox, isDesktop } from 'react-device-detect'
import { ReactComponent as turbulence } from '../../../../../icons/assets/turbulence-symbol-severe.svg'
import { ReactComponent as mountainWaves } from '../../../../../icons/assets/mountain-waves-severe.svg'
import { ReactComponent as mountainWavesSymbol } from '../../../../../icons/assets/mountain-waves-symbol-severe.svg'
import { ReactComponent as icing } from '../../../../../icons/assets/icing-severe.svg'
import { ReactComponent as dust } from '../../../../../icons/assets/dust.svg'
import { ReactComponent as radioactive } from '../../../../../icons/assets/atomic-cloud.svg'
import { ReactComponent as unknown } from '../../../../../icons/assets/unknown.svg'
import { ReactComponent as tropicalcyclone } from '../../../../../icons/assets/tropical-cyclone.svg'
import { ReactComponent as tropicalcycloneSymbol } from '../../../../../icons/assets/tropical-cyclone-symbol.svg'
import { ReactComponent as DirectionArrow } from '../../../../../icons/assets/direction-arrow.svg'
import { ReactComponent as wind } from '../../../../../icons/assets/wind.svg'
import { ReactComponent as ice } from '../../../../../icons/assets/ice.svg'
import { ReactComponent as vac } from '../../../../../icons/assets/vac-symbol.svg'
import { ReactComponent as volcano } from '../../../../../icons/assets/vaa.svg'
import { ReactComponent as snow } from '../../../../../icons/assets/snow.svg'
import { ReactComponent as thunderstorm } from '../../../../../icons/assets/thunderstorm.svg'
import { ReactComponent as thunderstormSymbol } from '../../../../../icons/assets/thunderstorm-symbol.svg'

import {
    statusData,
    selectedTimeState,
    dataModeState,
} from '../../../../../globalState'
import { formatISO, parseISO } from 'date-fns'

export default function SigmetList(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        expanded,
        dataLoading,
        setDataLoading,
        dataLoadingError,
        setDataLoadingError,
        lowerAltitude,
        upperAltitude,
    } = props
    const status = useRecoilValue(statusData)
    const version = useRef()
    const [data, setData] = useState(null)
    const mapInstance = useContext(MapContext)
    const [flyTo, setFlyTo] = useState(null)
    const [mapCenter, setMapCenter] = useState(null)
    const selectedTime = useRecoilValue(selectedTimeState)
    const dataMode = useRecoilValue(dataModeState)

    const fetchSigmets = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/sigmet`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (status.sigmet.version !== version.current) {
            version.current = status.sigmet.version
            fetchSigmets()
                .then((sigmetData) => {
                    setDataLoading(false)
                    setData(sigmetData)
                    console.log('Updating SIGMET data')
                    //  console.log(sigmetData, 'sigmetData')
                })
                .catch((error) => {
                    console.log(error)
                    setDataLoadingError(true)
                    setDataLoading(false)
                })
        }
    }, [status, setDataLoadingError, setDataLoading, fetchSigmets])

    // GET VIEWPORT COORDINATES
    const getMapViewBounds = (mapInstance) => {
        const bounds = mapInstance.getBounds()
        const mapSW = bounds._sw
        const mapNE = bounds._ne
        const mapNW = bounds.getNorthWest()
        const mapSE = bounds.getSouthEast()
        const mapNWend = bounds.getNorthWest()

        return [
            [mapNW.lng, mapNW.lat],
            [mapNE.lng, mapNE.lat],
            [mapSE.lng, mapSE.lat],
            [mapSW.lng, mapSW.lat],
            [mapNWend.lng, mapNWend.lat],
        ]
    }
    var viewportDimensions = getMapViewBounds(mapInstance)
    const viewport = turf.polygon([viewportDimensions])

    // CENTER FLYTO
    const mapAlign = useBreakpointValue({
        base: [0, 75],
        md: [75, 0],
    })

    const featPadding = useBreakpointValue({
        base: 25,
        md: 200,
    })

    const [formattedData, setFormattedData] = useState()

    useEffect(() => {
        if (data?.features) {
            const currentTime = Date.now() / 1000
            setFormattedData({
                type: 'FeatureCollection',
                features: data.features
                    .filter((feat) =>
                        dataMode === 'met'
                            ? feat?.properties?.end_epoch > currentTime
                            : true
                    )
                    .map((feat) => {
                        var newFeat = feat
                        newFeat.properties.center =
                            turf.centerOfMass(feat).geometry.coordinates
                        return newFeat
                    }),
            })
        }
    }, [data, dataMode])

    // FLY TO
    useEffect(() => {
        if (flyTo) {
            if (flyTo?.geometry?.type === 'Point') {
                mapInstance.flyTo({
                    center: flyTo?.geometry?.coordinates,
                    zoom: 13,
                })
            } else {
                mapInstance.fitBounds(turf.bbox(flyTo), {
                    offset: mapAlign,
                    padding: featPadding,
                })
            }
        }
        setFlyTo(null)
    }, [flyTo, mapInstance, mapAlign, featPadding])

    useEffect(() => {
        if (!mapCenter) {
            const { lng, lat } = mapInstance.getCenter()
            setMapCenter([lng, lat])
        }
        const handleMove = (e) => {
            const { lng, lat } = e.target.getCenter()
            setMapCenter([lng, lat])
        }
        mapInstance.on('moveend', handleMove)
        return () => {
            mapInstance.off('moveend', handleMove)
        }
    }, [mapInstance, mapCenter, setMapCenter])

    const SIGMETConfig = {
        'ISOL TS': {
            title: 'ISOLATED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'OBSC TS': {
            title: 'OBSCURE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'EMBD TS': {
            title: 'EMBEDDED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'FRQ TS': {
            title: 'FREQUENT THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SQL TS': {
            title: 'SQUALL LINE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'OBSC TSGR': {
            title: 'OBSCURE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'EMBD TSGR': {
            title: 'EMBEDDED THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'FRQ TSGR': {
            title: 'FREQUENT THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SQL TSGR': {
            title: 'SQUALL LINE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SEV TURB': {
            title: 'SEVERE TURBULENCE',
            icon: turbulence,
            watermark: wind,
            colour: '#e76f6f',
        },
        'SEV ICE': {
            title: 'SEVERE ICING',
            icon: icing,
            watermark: ice,
            colour: '#58ccf1',
        },
        'SEV ICE (FZRA)': {
            title: 'SEVERE ICING WITH FREEZING RAIN',
            icon: ice,
            watermark: ice,
            colour: '#58ccf1',
        },
        'SEV MTW': {
            title: 'MOUNTAIN WAVES',
            icon: mountainWavesSymbol,
            watermark: mountainWaves,
            colour: '#152238',
        },
        'HVY DS': {
            title: 'HEAVY DUST STORM',
            icon: dust,
            watermark: wind,
            colour: '#989898',
        },
        'HVY SS': {
            title: 'HEAVY SNOW STORM',
            icon: snow,
            watermark: snow,
            colour: '#152238',
        },
        'RDOACT CLD': {
            title: 'RADIOACTIVE CLOUD',
            icon: radioactive,
            watermark: radioactive,
            colour: '#9fd923',
        },
        TC: {
            title: 'TROPICAL CYCLONE',
            icon: tropicalcycloneSymbol,
            watermark: tropicalcyclone,
            colour: '#1261A0',
        },
        'VA ERUPTION': {
            title: 'VOLCANIC ASH ERUPTION',
            icon: vac,
            watermark: volcano,
            colour: '#eb6aeb',
        },
        'VA CLD': {
            title: 'VOLCANIC ASH CLOUD',
            icon: vac,
            watermark: volcano,
            colour: '#eb6aeb',
        },
        '': {
            title: 'UNDEFINED',
            icon: unknown,
            colour: '#85a0b5',
        },
        null: {
            title: 'UNDEFINED',
            icon: unknown,
            colour: '#85a0b5',
        },
    }

    const SIGMETObsfcst = {
        FCS: {
            title: 'FORECAST',
        },
        OBS: {
            title: 'OBSERVED',
        },

        '': {
            title: '',
        },
    }

    // useEffect(() => {
    //     console.log('SIGMET Data:', data)
    //     console.log('formattedData:', formattedData)
    // }, [data, formattedData])

    if (
        !dataLoading &&
        !dataLoadingError &&
        mapCenter &&
        formattedData &&
        formattedData.features &&
        formattedData.features.length
    ) {
        return (
            <>
                {!dataLoading &&
                    !dataLoadingError &&
                    mapCenter &&
                    formattedData &&
                    formattedData.features &&
                    formattedData.features.length &&
                    formattedData.features
                        .sort((a, b) => {
                            const sigmetCenterA = a.properties.center[1]
                            const sigmetCenterB = b.properties.center[1]
                            if (sigmetCenterA < sigmetCenterB) {
                                return 1
                            }
                            if (sigmetCenterA > sigmetCenterB) {
                                return -1
                            } else {
                                return 0
                            }
                        })
                        .map((feat, i) => {
                            const config =
                                SIGMETConfig[feat.properties.phenomenon]

                            // measure distance from center of screen to center of sigmet
                            var viewportCenter = turf.point(mapCenter)
                            var sigmetCenter = turf.point(
                                feat.properties.center
                            )
                            var bearing =
                                (Math.round(
                                    turf.bearing(viewportCenter, sigmetCenter)
                                ) +
                                    360) %
                                360

                            // display distance
                            var options = { units: 'miles' }
                            var distance = Math.round(
                                turf.distance(
                                    viewportCenter,
                                    sigmetCenter,
                                    options
                                ) * 0.86898 // conversion number to convert miles to NM
                            )

                            // see if sigmet overlaps the viewport edges (partially in view)
                            var sigmetPolygon = turf.polygon([
                                feat.geometry.coordinates[0],
                            ])
                            var overlap = turf.booleanOverlap(
                                viewport,
                                sigmetPolygon
                            )

                            // see if sigmet center sits within the viewport edges (fully in view)
                            var withinViewport = turf.booleanPointInPolygon(
                                sigmetCenter,
                                viewport
                            )

                            const configObsfcst =
                                SIGMETObsfcst[
                                    feat.properties.obsfcst.slice(0, 3)
                                ]

                            const outsideSelectedTime =
                                dataMode === 'advs' &&
                                (formatISO(selectedTime) <
                                    formatISO(
                                        parseISO(feat.properties.validfrom)
                                    ) ||
                                    formatISO(selectedTime) >
                                        formatISO(
                                            parseISO(feat.properties.validto)
                                        ))

                            const outsideSelectedAltitude =
                                lowerAltitude > feat.properties.upperfilter ||
                                upperAltitude < feat.properties.lowerfilter

                            return (
                                <Flex
                                    key={`${feat.properties.id}-${i}`}
                                    bg={
                                        isFirefox
                                            ? 'rgba(255,255,255,0.9)'
                                            : 'rgba(255,255,255,0.75)'
                                    }
                                    backdropFilter="blur(10px)"
                                    _hover={{
                                        opacity: '1',
                                        cursor: 'pointer',
                                        background: isFirefox
                                            ? 'rgba(255,255,255,1)'
                                            : 'rgba(255,255,255,0.9)',
                                    }}
                                    onClick={() => {
                                        if (!outsideSelectedAltitude) {
                                            setFlyTo(feat)
                                        }
                                    }}
                                    opacity={
                                        outsideSelectedAltitude ||
                                        outsideSelectedTime
                                            ? '0.5' //  if selected altitude range is outside sigmet levels
                                            : !overlap && !withinViewport
                                            ? '0.85' // if sigmet is not within viewport
                                            : '1'
                                    }
                                    borderRadius="20px"
                                    pl={expanded ? '5px' : '0'}
                                    pr={expanded ? '9px' : '0'}
                                    transition="all ease 200ms"
                                    mt={{ base: '0px', md: '0px' }}
                                    mb={{
                                        base: '10px',
                                        md:
                                            isDesktop &&
                                            i + 1 ===
                                                formattedData.features.length
                                                ? '3px'
                                                : '10px',
                                    }}
                                    flexDirection="column"
                                >
                                    <Flex
                                        my={expanded ? '3px' : '0'}
                                        width="100%"
                                        flexDirection="row"
                                        minHeight="32px"
                                    >
                                        <Flex alignItems="center">
                                            <Icon
                                                bg={
                                                    (overlap ||
                                                        withinViewport) &&
                                                    !outsideSelectedAltitude
                                                        ? config?.colour
                                                            ? config.colour
                                                            : 'transparent'
                                                        : 'transparent'
                                                }
                                                color={
                                                    (overlap ||
                                                        withinViewport) &&
                                                    !outsideSelectedAltitude
                                                        ? 'white'
                                                        : 'light.100'
                                                }
                                                borderRadius="100%"
                                                px="2px"
                                                boxSize="28px"
                                                alignItems="center"
                                                justifyContent="center"
                                                as={config && config.icon}
                                                // border="1px solid"
                                                // borderColor={
                                                //     (overlap || withinViewport) &&
                                                //     upperAltitude >=
                                                //         feat.properties.lowerfilter &&
                                                //     lowerAltitude <=
                                                //         feat.properties.upperfilter
                                                //         ? 'transparent'
                                                //         : 'rgba(60, 74, 90, 0.15)'
                                                // }
                                            />
                                        </Flex>
                                        <Flex
                                            pl="7px"
                                            lineHeight="1.5"
                                            width="100%"
                                            py={expanded ? '1px' : '0'}
                                            flexDirection="column"
                                            color="light.100"
                                            alignItems="center"
                                        >
                                            <Flex
                                                width="100%"
                                                height="100%"
                                                flexDirection="row"
                                                alignItems="flex-start"
                                                justifyContent="space-between"
                                                // borderBottom="1px solid rgba(0,0,0,0.05)"
                                            >
                                                <Flex
                                                    width="100%"
                                                    height="100%"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                >
                                                    <Text
                                                        whiteSpace="pre-line"
                                                        fontSize="10px"
                                                        fontWeight="700"
                                                        lineHeight="1.3"
                                                        pt="1px"
                                                        textTransform="uppercase"
                                                    >
                                                        {config && config.title}
                                                    </Text>
                                                    {/* {feat?.properties
                                                        ?.phenomenon ===
                                                        'VA ERUPTION' && (
                                                        <Text
                                                            fontSize="10px"
                                                            fontWeight="600"
                                                            whiteSpace="pre-line"
                                                            color={'red'}
                                                        >
                                                            EXERCISE ONLY
                                                        </Text>
                                                    )} */}
                                                </Flex>
                                                {outsideSelectedTime ? (
                                                    <Text
                                                        fontSize="10px"
                                                        fontWeight="600"
                                                        color="#c44d4d"
                                                    >
                                                        Outside selected time
                                                    </Text>
                                                ) : (
                                                    <Flex
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        color={'light.100'}
                                                    >
                                                        {overlap ||
                                                        withinViewport ? (
                                                            <Text
                                                                fontSize="10px"
                                                                fontWeight="500"
                                                                whiteSpace="pre-line"
                                                            >
                                                                {
                                                                    configObsfcst.title
                                                                }
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text
                                                                    fontSize="10px"
                                                                    fontWeight="700"
                                                                    marginRight="5px"
                                                                    whiteSpace="pre"
                                                                >
                                                                    {distance}{' '}
                                                                    NM
                                                                </Text>
                                                                <Icon
                                                                    boxSize="14px"
                                                                    transform={`rotate(${bearing}deg)`}
                                                                    as={
                                                                        DirectionArrow
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Flex>
                                                )}
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                height="100%"
                                                fontSize="10px"
                                                fontWeight="400"
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Flex flexDirection="row">
                                                    {feat.properties.level ? (
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .level
                                                            }
                                                        </Text>
                                                    ) : (
                                                        feat.properties
                                                            .lowerlabel &&
                                                        feat.properties
                                                            .upperlabel && (
                                                            <Flex
                                                                flexDirection="row"
                                                                fontWeight={
                                                                    outsideSelectedAltitude
                                                                        ? '600'
                                                                        : '400'
                                                                }
                                                                color={
                                                                    outsideSelectedAltitude &&
                                                                    (overlap ||
                                                                        withinViewport)
                                                                        ? '#c44d4d'
                                                                        : 'light.100'
                                                                }
                                                            >
                                                                {outsideSelectedAltitude ? (
                                                                    <Text>
                                                                        Outside
                                                                        altitude
                                                                        filter
                                                                        range
                                                                    </Text>
                                                                ) : (
                                                                    <>
                                                                        <Text whiteSpace="pre-line">
                                                                            {feat
                                                                                .properties
                                                                                .lowerlabel &&
                                                                                `${
                                                                                    feat.properties.lowerlabel.substring(
                                                                                        0,
                                                                                        2
                                                                                    ) ===
                                                                                        'FL' ||
                                                                                    feat
                                                                                        .properties
                                                                                        .lowerlabel ===
                                                                                        'SFC'
                                                                                        ? feat
                                                                                              .properties
                                                                                              .lowerlabel
                                                                                        : feat
                                                                                              .properties
                                                                                              .lowerlabel +
                                                                                          ' ft'
                                                                                }`}
                                                                        </Text>
                                                                        <Text px="3px">
                                                                            –
                                                                        </Text>
                                                                        <Text whiteSpace="pre-line">
                                                                            {feat
                                                                                .properties
                                                                                .upperlabel &&
                                                                                `${
                                                                                    feat.properties.upperlabel.substring(
                                                                                        0,
                                                                                        2
                                                                                    ) ===
                                                                                    'FL'
                                                                                        ? feat
                                                                                              .properties
                                                                                              .upperlabel
                                                                                        : feat
                                                                                              .properties
                                                                                              .upperlabel +
                                                                                          ' ft'
                                                                                }`}
                                                                        </Text>
                                                                    </>
                                                                )}
                                                            </Flex>
                                                        )
                                                    )}
                                                </Flex>

                                                {/* {feat.properties.intensity && (
                                                    <Flex flexDirection="row">
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .intensity
                                                            }
                                                        </Text>
                                                    </Flex>
                                                )} */}

                                                {feat.properties.movement && (
                                                    <Flex flexDirection="row">
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .movement
                                                            }
                                                        </Text>
                                                    </Flex>
                                                )}

                                                {/* <Flex flexDirection="row">
                                                    <Text whiteSpace="pre-line">
                                                        {feat.properties.id}
                                                    </Text>
                                                </Flex> */}
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            height="auto"
                                            width="36px"
                                            minWidth="36px"
                                            overflow="hidden"
                                            mr="-3px"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                        >
                                            <Icon
                                                sx={{ transform: 'scale(1.2)' }}
                                                color="rgba(20,25,30, 0.07)"
                                                py="2px"
                                                boxSize="32px"
                                                alignItems="center"
                                                justifyContent="center"
                                                as={config && config.watermark}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        })}
            </>
        )
    } else {
        return (
            <Flex // NO DATA
                width="100%"
                justifyContent="center"
                alignItems="center"
                px="5px"
                h={{ base: '38px', md: '38px' }}
                borderRadius="20px"
                backdropFilter={expanded ? 'blur(10px)' : 'none'}
                background="rgba(255,255,255,0.1)"
                textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                border="1px solid"
                borderColor="rgba(255,255,255,0.7)"
                color="white"
                zIndex="1"
                fontWeight="500"
                fontSize={{ base: '0.7rem', xs: '0.75rem', md: '0.8rem' }}
                whiteSpace="pre-line"
                textAlign="center"
                lineHeight="1.2"
            >
                {formattedData &&
                ((formattedData.features &&
                    formattedData.features.length === 0) ||
                    formattedData.metadata) ? (
                    <Text>No current SIGMET advisories</Text>
                ) : (
                    <Text>NO SIGMET information received</Text>
                )}
            </Flex>
        )
    }
}
