import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { isFirefox } from 'react-device-detect'
import { useAdminRole } from '../../../../auth/hooks'
import {
    Box,
    Button,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    Text,
    Flex,
    Fade,
    Icon,
    useDisclosure,
    useColorModeValue,
    CircularProgress,
    CircularProgressLabel,
    HStack,
    useBreakpointValue,
} from '@chakra-ui/react'
import Image from '../../../components/Image'
// import { useSwipeable } from 'react-swipeable'
import { useQuery } from 'react-query'
import { parseISO, formatDistanceToNow } from 'date-fns'
import PinchDrag, { useScale } from '../../components/PinchDrag'
import ZoomSlider from '../../components/ZoomSlider'
import RainForecastLegend from './components/RainForecastLegend'
import ErrorToggle from '../../components/ErrorToggle'
import FullWidthError from '../../components/FullWidthError'
import ErrorText from '../../components/ErrorText'

import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
} from '../../../../globalState'

import { isMobileOnly, isIOS, isMobileSafari } from 'react-device-detect'
import formatDateTime from '../../../../util/dateFormatter'
import TimeSlider from './components/TimeSlider'
import { ReactComponent as RainIcon } from '../../../../icons/assets/rain.svg'
import { ReactComponent as SnowIcon } from '../../../../icons/assets/snow.svg'
import { ReactComponent as WindIcon } from '../../../../icons/assets/wind.svg'
import { ReactComponent as rainForecastIcon } from '../../../../icons/assets/rain-forecast.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-nocircle.svg'

export default function RainForecastImageryLooper() {
    const { getAccessTokenSilently } = useAuth0()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const isAdmin = useAdminRole()
    const [selectedTime, setSelectedTime] = useState(null)
    const [previousSelectedTime, setPreviousSelectedTime] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [looping, setLooping] = useState(false)
    const [speed, setSpeed] = useState('1x')
    const [selectedSetTimes, setSelectedSetTimes] = useState([])
    const [imageSet, setImageSet] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(0)
    const [percentLoaded, setPercentLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const timeZone = useRecoilValue(timeZoneState)
    const [legendShow, setLegendShow] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    // PinchZoom
    const { scale, setScale, xy, setXy } = useScale()
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.rainforecast) {
            setImagesLoaded(0)
            setPercentLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, layerSelection, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    const fetchRainForecastImagery = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/rainforecast`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const { data, status } = useQuery(
        `rainForecast`,
        fetchRainForecastImagery,
        { refetchOnWindowFocus: false }
    )

    // This acts as a reset for selected type change
    useEffect(() => {
        if (data) {
            setImagesLoaded(0)
            setPercentLoaded(0)
            setSelectedSetTimes(data.map((i) => i.validat))
            setImageSet(data)
        }
    }, [data])

    useEffect(() => {
        data &&
            selectedSetTimes &&
            setLoaded(imagesLoaded === selectedSetTimes.length)
        const percent = 100 / selectedSetTimes.length
        percent && setPercentLoaded(imagesLoaded * percent)
    }, [data, imagesLoaded, selectedSetTimes])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded(0)
        }
    }, [showNetworkErrors])

    function onMainImageLoad() {
        setImagesLoaded((i) => i + 1)
    }

    // // LEGEND ON MOBILE SHOW BY SWIPE DOWN/UP
    // const legendSwipe = useSwipeable({
    //     onSwipedDown: () => {
    //         setLegendShow(true)
    //     },
    //     onSwipedUp: () => {
    //         setLegendShow(false)
    //     },
    // })

    function isOutOfDate() {
        const itemData =
            data &&
            selectedTime &&
            imageSet.filter((i) => selectedTime === i.validat)[0]
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow = showOutdatedErrors || isOutOfDate().outofdate

    const errorHeight = useBreakpointValue({
        base: 330 + (isAdmin && showErrorToggle && 30) + (errorShow && 70),
        md: 290 + (errorShow && 70),
        lg: 290 + (errorShow && 70),
        xl: 290 + (errorShow && 40),
        '2xl': 290 + (errorShow && 40),
        '3xl': 290 + (errorShow && 40),
    })

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }} // added flex here as header height not sticking causing spacing issues elsewhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        // {...legendSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            // {...legendSwipe}
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                md: '200px',
                                            }}
                                            paddingLeft={{
                                                base: '30px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={rainForecastIcon}
                                                ml={{
                                                    base: minimiseActive
                                                        ? '0px'
                                                        : '40px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    Rain Forecast
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: minimiseActive
                                                    ? '90px'
                                                    : '40px',
                                                md: '40px',
                                                ml: '500px',
                                                lg: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Flex
                                                color="light.100"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                pr={{
                                                    base: '0px',
                                                    md: '10px',
                                                    ml: '10px',
                                                }}
                                                pt={{
                                                    base: '1px',
                                                    lg: '0px',
                                                }}
                                                width={{
                                                    base: '40px',
                                                    ml: '100%',
                                                }}
                                                opacity={{
                                                    base: minimiseActive
                                                        ? '1'
                                                        : '0',
                                                    ml: '1',
                                                }}
                                                visibility={{
                                                    base: minimiseActive
                                                        ? 'visible'
                                                        : 'hidden',
                                                    ml: 'visible',
                                                }}
                                                transition="all ease 300ms"
                                            >
                                                <ZoomSlider
                                                    scale={scale}
                                                    xy={xy}
                                                    setScale={setScale}
                                                    setXy={setXy}
                                                    maxScale={8}
                                                />
                                            </Flex>
                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    md: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                // opacity={detailsShow ? '1' : '0.8'}
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setLegendShow(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            md: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            md: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        as={InfoIcon}
                                                        boxSize={5}
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={
                                            isAdmin && showErrorToggle
                                                ? '100%'
                                                : '0'
                                        }
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS && isMobileSafari
                                        ? 'calc(100% - 145px)'
                                        : 'calc(100% - 130px)',
                                md: '100%',
                            }}
                            background="#edf1f7"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Flex
                                    // {...legendSwipe}
                                    flexDirection={{
                                        base: 'column-reverse',
                                        md: 'row',
                                    }}
                                    w="100%"
                                    h="100%"
                                    bg={{
                                        base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 80%)',
                                        md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 80%)',
                                    }}
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'column-reverse',
                                            md: 'row',
                                        }}
                                        position={{
                                            base: 'absolute',
                                            md: 'relative',
                                        }}
                                        top={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? '100px'
                                                    : '70px',
                                            md: '0px',
                                        }}
                                        w="100%"
                                        h={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? 'calc(100% - 100px)'
                                                    : 'calc(100% - 70px)',
                                            md: '100%',
                                        }}
                                    >
                                        <Flex // IMAGE DISPLAY AND TIME SLIDER
                                            width="100%"
                                            h="100%"
                                            position={'relative'}
                                            flexDirection="column"
                                            alignItems="center"
                                            whiteSpace="pre-line"
                                            overflowX="hidden"
                                            overflowY="hidden"
                                        >
                                            {data && errorShow && (
                                                <Flex
                                                    px="15px"
                                                    pt="5px"
                                                    mb="-10px"
                                                    width="100%"
                                                    justifyContent="center"
                                                    zIndex="1"
                                                    backdropFilter="blur(4px)"
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                        md: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                    }}
                                                >
                                                    <ErrorText>
                                                        There has been an issue
                                                        with the connection
                                                        between PreFlight and
                                                        MetService. This imagery
                                                        was last validated with
                                                        MetService{' '}
                                                        <strong>
                                                            {formatDistanceToNow(
                                                                parseISO(
                                                                    isOutOfDate()
                                                                        .updated
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </strong>
                                                        .<br />
                                                        There is a possibility
                                                        that we are unable to
                                                        display imagery that is
                                                        up to date. Please check
                                                        back again soon.
                                                    </ErrorText>{' '}
                                                </Flex>
                                            )}
                                            <Flex
                                                w="100%"
                                                maxWidth={`calc(100vh - ${errorHeight}px)`}
                                                h="100%"
                                                maxHeight="100%"
                                                minHeight={{
                                                    base: '100%',
                                                    md: '100%',
                                                }}
                                                pos={'relative'}
                                                alignItems="baseline"
                                                justifyContent="center"
                                                padding="20px"
                                                paddingBottom={{
                                                    base: errorShow
                                                        ? '270px'
                                                        : '100px',
                                                    md: errorShow
                                                        ? '180px'
                                                        : '100px',
                                                    lg: errorShow
                                                        ? '180px'
                                                        : '100px',
                                                    xl: errorShow
                                                        ? '135px'
                                                        : '100px',
                                                }}
                                                transition="all ease-in-out 300ms"
                                            >
                                                <Box
                                                    w="100%"
                                                    h="100%"
                                                    pos="relative"
                                                >
                                                    {status !== 'loading' &&
                                                        status === 'error' && (
                                                            <Text
                                                                pb="10px"
                                                                mt="-10px"
                                                                textAlign="center"
                                                                fontWeight="500"
                                                            >
                                                                Error loading
                                                                data
                                                            </Text>
                                                        )}

                                                    {status === 'loading' && (
                                                        <Flex
                                                            zIndex="1"
                                                            position="absolute"
                                                            top="0px"
                                                            left="0px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            width={'100%'}
                                                            pt="34vh"
                                                            px={{
                                                                base: '10px',
                                                                md: '30px',
                                                            }}
                                                        >
                                                            <Spinner
                                                                color={
                                                                    'light.200'
                                                                }
                                                                thickness="3px"
                                                                speed="0.45s"
                                                                boxSize="60px"
                                                            />
                                                        </Flex>
                                                    )}
                                                    {status !== 'loading' &&
                                                        !loaded &&
                                                        !!percentLoaded && (
                                                            <>
                                                                <Flex
                                                                    w="100%"
                                                                    h="100%"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    position="absolute"
                                                                >
                                                                    <CircularProgress
                                                                        value={
                                                                            percentLoaded
                                                                        }
                                                                        size="80px"
                                                                        thickness="4px"
                                                                        color="light.200"
                                                                    >
                                                                        <CircularProgressLabel>
                                                                            {`${Math.round(
                                                                                percentLoaded
                                                                            )}%`}
                                                                        </CircularProgressLabel>
                                                                    </CircularProgress>
                                                                </Flex>
                                                            </>
                                                        )}

                                                    <PinchDrag
                                                        scale={scale}
                                                        setScale={setScale}
                                                        xy={xy}
                                                        setXy={setXy}
                                                        style={{
                                                            zIndex: '101',
                                                        }}
                                                        maxZoom={8}
                                                        maxZoomNewScale={7.999}
                                                    >
                                                        <Box
                                                            w="100%"
                                                            h="0"
                                                            borderRadius="10px"
                                                            pt={
                                                                loaded
                                                                    ? '100%'
                                                                    : 0
                                                            }
                                                            pos="relative"
                                                            zIndex={101}
                                                        >
                                                            {imageSet.map(
                                                                (i, idx) => (
                                                                    <Fade
                                                                        key={`rainForecastImg${idx}`}
                                                                        in={
                                                                            loaded &&
                                                                            (selectedTime ===
                                                                                i.validat ||
                                                                                previousSelectedTime ===
                                                                                    i.validat)
                                                                        }
                                                                    >
                                                                        <Box
                                                                            pos="absolute"
                                                                            zIndex={
                                                                                selectedTime ===
                                                                                i.validat
                                                                                    ? 5
                                                                                    : 4
                                                                            }
                                                                            top="0px"
                                                                            left="0px"
                                                                            transition="all ease 200ms"
                                                                            w="100%"
                                                                            h="0"
                                                                            borderRadius="10px"
                                                                            overflow="hidden"
                                                                            pt="calc(100% * (130 / 100))"
                                                                            opacity={
                                                                                loaded
                                                                                    ? 1
                                                                                    : 0.7
                                                                            }
                                                                        >
                                                                            {i.url && (
                                                                                <Flex
                                                                                    overflow="hidden"
                                                                                    border="1px solid #e2e8f0"
                                                                                    pos="absolute"
                                                                                    top="0px"
                                                                                    w={{
                                                                                        base: '100%',
                                                                                        md: '100%',
                                                                                    }}
                                                                                    h={{
                                                                                        base: '100%',
                                                                                        md: '100%',
                                                                                    }}
                                                                                    transition="all ease 200ms"
                                                                                    fit="cover"
                                                                                    objectPosition={
                                                                                        'left top'
                                                                                    }
                                                                                    borderRadius="10px"
                                                                                >
                                                                                    <Image
                                                                                        src={`${window.location.origin}/metproxy?url=${i.url}`}
                                                                                        key={`rainForecastImg-${idx}`}
                                                                                        sx={{
                                                                                            transform:
                                                                                                'scale(1.38)',
                                                                                        }}
                                                                                        margin="-4% 0 0% 0%"
                                                                                        w="100%"
                                                                                        h="100%"
                                                                                        fit="cover"
                                                                                        objectPosition="51% 0%"
                                                                                        onLoad={
                                                                                            onMainImageLoad
                                                                                        }
                                                                                    />
                                                                                </Flex>
                                                                            )}
                                                                        </Box>
                                                                    </Fade>
                                                                )
                                                            )}
                                                        </Box>
                                                    </PinchDrag>
                                                </Box>
                                            </Flex>
                                            <Flex
                                                w="100%"
                                                pb="20px"
                                                height={{
                                                    base: '85px',
                                                    md: '85px',
                                                }}
                                                bottom={{
                                                    base: '5px',
                                                    md: '0px',
                                                }}
                                                alignItems="center"
                                                flexDirection="column"
                                                justifyContent={{
                                                    base: 'flex-start',
                                                    md: 'flex-start',
                                                }}
                                                opacity={!loaded ? '0.15' : '1'}
                                                transition="all ease 300ms"
                                                position="absolute"
                                                zIndex="101"
                                            >
                                                <Flex // IMAGE TIME
                                                    h="30px"
                                                    mb="5px"
                                                    pt="3px"
                                                    pb="3px"
                                                    px="15px"
                                                    justify={'center'}
                                                    alignItems="center"
                                                    fontWeight="bold"
                                                    fontSize="0.8rem"
                                                    textAlign="center"
                                                    color="light.100"
                                                    backdropFilter="blur(4px)"
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(223, 232, 241, 0.8)'
                                                            : 'rgba(223, 232, 241, 0.3)',
                                                        md: isFirefox
                                                            ? 'rgba(240, 248, 253, 0.8)'
                                                            : 'rgba(240, 248, 253, 0.3)',
                                                    }}
                                                    borderRadius="20px"
                                                >
                                                    {selectedTime &&
                                                        formatDateTime(
                                                            selectedTime,
                                                            timeZone
                                                        )}
                                                </Flex>
                                                {!loaded && (
                                                    <Box
                                                        position="absolute"
                                                        height="80px"
                                                        width="100%"
                                                        zIndex="1"
                                                        marginTop="0px"
                                                    />
                                                )}
                                                {selectedSetTimes && (
                                                    <Flex // TIME SLIDER
                                                        w="100%"
                                                        px="20px"
                                                        maxWidth={{
                                                            base: '700px',
                                                            md: '800px',
                                                            lg: '900px',
                                                        }}
                                                        justify={'center'}
                                                    >
                                                        <TimeSlider
                                                            style={{
                                                                width: 'calc(100% - 10px)',
                                                            }}
                                                            times={
                                                                selectedSetTimes
                                                            }
                                                            selectedTime={
                                                                selectedTime
                                                            }
                                                            setSelectedTime={
                                                                setSelectedTime
                                                            }
                                                            setPreviousSelectedTime={
                                                                setPreviousSelectedTime
                                                            }
                                                            speed={speed}
                                                            setSpeed={setSpeed}
                                                            playing={playing}
                                                            setPlaying={
                                                                setPlaying
                                                            }
                                                            looping={looping}
                                                            setLooping={
                                                                setLooping
                                                            }
                                                            mostRecent={false}
                                                        />
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                        <Flex // RIGHT MENU (CONTROLS AND LEGEND)
                                            pos="relative"
                                            flexDirection={{
                                                base: 'row',
                                                md: 'column',
                                            }}
                                            h={{
                                                base: '0px',
                                                md: '100%',
                                            }}
                                            minHeight={{
                                                base: '0px',
                                                md: '100%',
                                            }}
                                            width={{
                                                base: '100%',
                                                md: '250px',
                                            }}
                                            minWidth={{
                                                base: '100%',
                                                md: '250px',
                                            }}
                                            // marginBottom={{
                                            //     base: legendShow
                                            //         ? '130px'
                                            //         : '0px',
                                            //     md: '0',
                                            // }}
                                            transition="all ease-in-out 300ms"
                                            mt={{
                                                base: '-1px',
                                                md: '0',
                                            }}
                                            background={{
                                                base: 'light.55',
                                                md: 'light.10',
                                            }}
                                            borderLeft={{
                                                base: 'none',
                                                md: '1px solid #E2E8F0',
                                            }}
                                            boxShadow={{
                                                base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                md: 'none',
                                            }}
                                            zIndex="101"
                                        >
                                            <Flex
                                                // {...legendSwipe}
                                                className="legend"
                                                position={{
                                                    base: 'absolute',
                                                    md: 'relative',
                                                }}
                                                flexDirection={{
                                                    base: 'row',
                                                    md: 'column',
                                                }}
                                                justifyContent={{
                                                    base: 'center',
                                                    md: 'flex-start',
                                                }}
                                                pt={{
                                                    base: '15px',
                                                    md: 'unset',
                                                }}
                                                pb={{
                                                    base: '15px',
                                                    md: 'unset',
                                                }}
                                                w="100%"
                                                height={{
                                                    base: legendShow
                                                        ? '160px'
                                                        : '0px',
                                                    md: '100%',
                                                }}
                                                marginTop={{
                                                    base: legendShow
                                                        ? '0'
                                                        : '-60px',
                                                    md: '20px',
                                                }}
                                                transition="all ease-in-out 300ms"
                                                marginBottom={{
                                                    base: legendShow
                                                        ? '0'
                                                        : '0px',
                                                    md: '20px',
                                                }}
                                                backdropFilter={{
                                                    base: 'blur(10px)',
                                                    md: 'none',
                                                }}
                                                textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                background={{
                                                    base: isFirefox
                                                        ? 'rgba(247, 249, 251, 0.95)'
                                                        : 'rgba(247, 249, 251, 0.85)',
                                                    md: 'none',
                                                }}
                                                boxShadow={{
                                                    base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                    md: 'none',
                                                }}
                                            >
                                                <Flex
                                                    flexDirection={{
                                                        base: 'column',
                                                        md: 'column',
                                                    }}
                                                    justifyContent={{
                                                        base: 'space-evenly',
                                                        md: 'space-evenly',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'center',
                                                    }}
                                                    width="100%"
                                                >
                                                    <Flex
                                                        flexDirection={{
                                                            base: 'row',
                                                            md: 'column',
                                                        }}
                                                        alignItems={{
                                                            base: 'center',
                                                            md: 'flex-start',
                                                        }}
                                                        px={{
                                                            base: '20px',
                                                            md: '25px',
                                                        }}
                                                        minHeight={{
                                                            base: '0px',
                                                            md: '60px',
                                                        }}
                                                        pb={{
                                                            base: legendShow
                                                                ? '5px'
                                                                : '0px',
                                                            md: '0px',
                                                        }}
                                                        transition="all ease-in-out 300ms"
                                                        width="100%"
                                                        color="light.100"
                                                        borderBottom={{
                                                            base: 'none',
                                                            md: '1px solid',
                                                        }}
                                                        borderBottomColor={{
                                                            base: 'none',
                                                            md: '#E2E8F0',
                                                        }}
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="5"
                                                            pb={{
                                                                base: '0',
                                                                md: '5px',
                                                            }}
                                                            width="auto"
                                                            fontSize="0.8rem"
                                                            lineHeight="1.3"
                                                            alignSelf="left"
                                                            color={{
                                                                base: 'gray.600',
                                                                ml: 'gray.500',
                                                            }}
                                                            fontWeight={{
                                                                base: '500',
                                                                md: '600',
                                                            }}
                                                        >
                                                            Analysis time
                                                        </Text>
                                                        {imageSet.map(
                                                            (validTime, i) => (
                                                                <Text
                                                                    key={`GraforTime${validTime.validat}`}
                                                                    display={
                                                                        selectedTime ===
                                                                        validTime.validat
                                                                            ? 'block'
                                                                            : 'none'
                                                                    }
                                                                    paddingStart="0"
                                                                    paddingEnd="0"
                                                                    opacity="0.7"
                                                                    fontWeight="400"
                                                                    py="0px"
                                                                    fontSize="0.8rem"
                                                                    lineHeight="1.3"
                                                                    borderColor="transparent"
                                                                >
                                                                    {validTime?.analysistime &&
                                                                        formatDateTime(
                                                                            validTime.analysistime,
                                                                            timeZone
                                                                        )}
                                                                </Text>
                                                            )
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        transition="all ease 300ms"
                                                        width={{
                                                            base: '100px',
                                                            md: '100%',
                                                        }}
                                                        position={{
                                                            base: 'absolute',
                                                            md: 'relative',
                                                        }}
                                                        height={{
                                                            base: '100%',
                                                            md: 'unset',
                                                        }}
                                                        maxHeight={{
                                                            base: '120px',
                                                            md: '60px',
                                                        }}
                                                        left={{
                                                            base: '20px',
                                                            md: 'unset',
                                                        }}
                                                        minWidth={{
                                                            base: '100px',
                                                            md: '100%',
                                                        }}
                                                        pt={{
                                                            base: '0px',
                                                            md: '20px',
                                                        }}
                                                        pb={{
                                                            base: '0px',
                                                            md: '20px',
                                                        }}
                                                        pl={{
                                                            base: '0px',
                                                            md: '10px',
                                                        }}
                                                        pr={{
                                                            base: '0px',
                                                            md: '10px',
                                                        }}
                                                        ml={{
                                                            base: '-2px',
                                                            md: '0px',
                                                        }}
                                                        flexGrow="1"
                                                        flexDirection={{
                                                            base: 'column-reverse',
                                                            md: 'row',
                                                        }}
                                                        justifyContent={{
                                                            base: 'space-evenly',
                                                            md: 'space-evenly',
                                                        }}
                                                        alignItems={{
                                                            base: 'flex-start',
                                                            md: 'center',
                                                        }}
                                                        gap={{
                                                            base: '0px',
                                                            md: '5px',
                                                        }}
                                                    >
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                md: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                md: 'center',
                                                            }}
                                                            marginTop={{
                                                                base: '-20px',
                                                                md: '0px',
                                                            }}
                                                        >
                                                            <RainIcon color="#718096" />
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    md: '1',
                                                                }}
                                                                marginRight={{
                                                                    base: '3px',
                                                                    md: '3px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                ml="5px"
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.8rem',
                                                                    md: '0.8rem',
                                                                }}
                                                                color={{
                                                                    base: 'gray.600',
                                                                    ml: 'gray.500',
                                                                }}
                                                                fontWeight="500"
                                                                lineHeight="1.2"
                                                            >
                                                                Rain (mm)
                                                            </Text>
                                                        </Flex>
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                md: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                md: 'center',
                                                            }}
                                                        >
                                                            <WindIcon color="#718096" />
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    md: '1',
                                                                }}
                                                                ml="5px"
                                                                marginRight={{
                                                                    base: '3px',
                                                                    md: '3px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.8rem',
                                                                    md: '0.8rem',
                                                                }}
                                                                color={{
                                                                    base: 'gray.600',
                                                                    ml: 'gray.500',
                                                                }}
                                                                fontWeight="500"
                                                                lineHeight="1.2"
                                                            >
                                                                Wind (kts)
                                                            </Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        pl={{
                                                            base: '120px',
                                                            md: '25px',
                                                        }}
                                                        pr={{
                                                            base: '20px',
                                                            md: '25px',
                                                        }}
                                                        flexDirection={{
                                                            base: 'row',
                                                            md: 'column',
                                                        }}
                                                        justifyContent="center"
                                                        w="100%"
                                                        height="100%"
                                                        maxHeight="400px"
                                                        transition="all ease 300ms"
                                                    >
                                                        <RainForecastLegend />
                                                    </Flex>

                                                    <Flex
                                                        transition="all ease 300ms"
                                                        width={{
                                                            base: '100%',
                                                            md: '100%',
                                                        }}
                                                        maxHeight={{
                                                            base: '24px',
                                                            md: '60px',
                                                        }}
                                                        height="100%"
                                                        pt={{
                                                            base: '0px',
                                                            md: '40px',
                                                        }}
                                                        pb={{
                                                            base: '0px',
                                                            md: '0px',
                                                        }}
                                                        pl={{
                                                            base: '18px',
                                                            md: '25px',
                                                        }}
                                                        pr={{
                                                            base: '0px',
                                                            md: '25px',
                                                        }}
                                                        ml={{
                                                            base: '-1px',
                                                            md: '0px',
                                                        }}
                                                        mt={{
                                                            base: '-5px',
                                                            md: '0px',
                                                        }}
                                                        flexGrow="1"
                                                        flexDirection={{
                                                            base: 'row',
                                                            md: 'row',
                                                        }}
                                                        justifyContent={{
                                                            base: 'flex-start',
                                                            md: 'center',
                                                        }}
                                                        alignItems={{
                                                            base: 'flex-start',
                                                            md: 'center',
                                                        }}
                                                    >
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                md: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                md: 'center',
                                                            }}
                                                            marginTop={{
                                                                base: '0px',
                                                                md: '0px',
                                                            }}
                                                            height={{
                                                                base: '24px',
                                                                md: '50px',
                                                            }}
                                                            minHeight={{
                                                                base: '24px',
                                                                md: '50px',
                                                            }}
                                                        >
                                                            <SnowIcon color="#718096" />
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    md: '1',
                                                                }}
                                                                marginRight={{
                                                                    base: '40px',
                                                                    md: '20px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                ml="5px"
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.8rem',
                                                                    md: '0.8rem',
                                                                }}
                                                                color={{
                                                                    base: 'gray.600',
                                                                    ml: 'gray.500',
                                                                }}
                                                                fontWeight="500"
                                                                lineHeight="1.2"
                                                            >
                                                                Snow
                                                            </Text>
                                                        </Flex>
                                                        <Flex
                                                            height={{
                                                                base: '100%',
                                                                md: '50px',
                                                            }}
                                                            minHeight={{
                                                                base: '7px',
                                                                md: '50px',
                                                            }}
                                                            pt={{
                                                                base: '2px',
                                                                md: '0px',
                                                            }}
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            flexDirection="row"
                                                        >
                                                            {/* *** COLOURED BOX *** */}
                                                            <Flex
                                                                className="snow"
                                                                transition="all ease 300ms"
                                                                width={{
                                                                    base: '30px',
                                                                    md: '40px',
                                                                }}
                                                                height={{
                                                                    base: '5px',
                                                                    md: '5px',
                                                                }}
                                                                mt={{
                                                                    base: '0px',
                                                                    md: '3px',
                                                                }}
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                overflow="hidden"
                                                                borderRadius="5px"
                                                                background="rgb(127, 0, 127)"
                                                                // border="1px solid #acb7c4"
                                                            ></Flex>
                                                        </Flex>
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                md: 'row',
                                                            }}
                                                            position={{
                                                                base: 'relative',
                                                                md: 'absolute',
                                                            }}
                                                            pl={{
                                                                base: '12px',
                                                                md: '20px',
                                                            }}
                                                            pr={{
                                                                base: '25px',
                                                                md: '20px',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                md: 'center',
                                                            }}
                                                            marginTop={{
                                                                base: '0px',
                                                                md: '80px',
                                                            }}
                                                            height={{
                                                                base: '24px',
                                                                md: '50px',
                                                            }}
                                                            minHeight={{
                                                                base: '24px',
                                                                md: '50px',
                                                            }}
                                                        >
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    md: '1',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                textAlign={{
                                                                    base: 'left',
                                                                    md: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.65rem',
                                                                    md: '0.7rem',
                                                                }}
                                                                color="light.101"
                                                                fontWeight={{
                                                                    base: '300',
                                                                    md: '300',
                                                                }}
                                                                lineHeight="1.2"
                                                            >
                                                                When thick
                                                                purple contour
                                                                lines are
                                                                plotted, any
                                                                precipitation
                                                                within the area
                                                                is likely to
                                                                fall as snow
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading Rain Forecast.<br />Please try again later.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
