import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Text, Box, Flex, SlideFade } from '@chakra-ui/react'
import { isFirefox } from 'react-device-detect'
import CautionIcon from '../../../icons/menu-icons/CautionIcon'
import {
    dataModeState,
    modalVisibilityState,
    displayDisclaimerState,
    displayDisclaimerShowState,
} from '../../../globalState'

export default function AeroDisclaimer() {
    const dataMode = useRecoilValue(dataModeState)
    const aeroMode = dataMode === 'aero'
    const startTimer = useRef(null)
    const endTimer = useRef(null)
    const endShowTimer = useRef(null)
    const [show, setShow] = useState(false)
    const [displayDisclaimer, setDisplayDisclaimer] = useRecoilState(
        displayDisclaimerState
    )
    const [displayDisclaimerShow, setDisplayDisclaimerShow] = useRecoilState(
        displayDisclaimerShowState
    )
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const loginMessageModal = modalVisibility.viewLoginMessage

    const [disclaimerRead, setDisclaimerRead] = useState(false)

    useEffect(() => {
        if (aeroMode && !loginMessageModal && !disclaimerRead) {
            startTimer.current = setTimeout(() => {
                setDisplayDisclaimer(true)
                // setShow(true)
            }, 2000)
            endTimer.current = setTimeout(() => {
                setDisplayDisclaimerShow(false)
                setShow(false)
                setDisclaimerRead(true)
            }, 13000)
        } else {
            clearTimeout(startTimer.current)
            clearTimeout(endTimer.current)
        }
    }, [
        loginMessageModal,
        setDisplayDisclaimer,
        setDisplayDisclaimerShow,
        aeroMode,
        disclaimerRead,
    ])

    useEffect(() => {
        if (show) {
            setDisplayDisclaimer(true)
            setDisplayDisclaimerShow(true)
            endShowTimer.current = setTimeout(() => {
                setShow(false)
                setDisplayDisclaimerShow(false)
                setDisclaimerRead(true)
            }, 13000)
        } else {
            clearTimeout(endShowTimer.current)
        }
    }, [show, setDisplayDisclaimer, setDisplayDisclaimerShow])

    return (
        aeroMode && (
            <Box
                direction={'row'}
                pos={'absolute'}
                bottom={{
                    base: displayDisclaimerShow ? '35px' : '27px',
                    md: displayDisclaimerShow ? '35px' : '20px',
                }}
                right={{
                    base: displayDisclaimerShow ? '15px' : '3px',
                    md: displayDisclaimerShow ? '15px' : '0px',
                }}
                opacity={displayDisclaimer ? '1' : '0'}
                visibility={displayDisclaimer ? 'visible' : 'hidden'}
                onClick={() => {
                    if (displayDisclaimerShow && disclaimerRead) {
                        setShow(false)
                        setDisplayDisclaimerShow(false)
                        setDisclaimerRead(true)
                    } else {
                        setShow(true)
                    }
                }}
                ml={{ base: '80px', md: '50vw' }}
                mr={{ base: 'auto', md: '0' }}
                transition="all ease-out 600ms"
                _hover={{
                    cursor:
                        displayDisclaimerShow && !disclaimerRead
                            ? 'default'
                            : 'pointer',
                }}
                zIndex="1400"
            >
                <Flex
                    alignItems={'flex-start'}
                    bg={
                        isFirefox && displayDisclaimerShow
                            ? 'rgba(149, 180, 195, 0.9)'
                            : displayDisclaimerShow
                            ? 'rgba(120, 155, 170, 0.6)'
                            : 'none'
                        // : 'rgba(149, 180, 195, 0.6)' // light background
                    }
                    backdropFilter={
                        displayDisclaimerShow ? 'blur(10px)' : 'none'
                    }
                    px={{ base: '10px', md: '15px' }}
                    py={{ base: '7px', md: '10px' }}
                    borderRadius="20px"
                    transition="all ease-out 600ms"
                    boxShadow={
                        displayDisclaimerShow
                            ? '0px 3px 20px -7px rgba(0, 0, 0, 0.2)'
                            : 'none'
                    }
                    style={{
                        overflow: displayDisclaimerShow ? 'initial' : 'hidden',
                        transition: 'overflow ease-out 500ms',
                    }}
                    // height={{
                    //     base: '65px',
                    //     sm: '54px',
                    //     md: '54px',
                    // }}
                    height={displayDisclaimerShow ? 'unset' : '54px'}
                >
                    <Box
                        opacity={displayDisclaimerShow ? '0.8' : '0.5'}
                        _hover={{ opacity: '1' }}
                        marginTop="2px"
                    >
                        <CautionIcon
                            boxSize={8}
                            transition="all ease-out 600ms"
                            color="rgba(255, 255, 255, 1)"
                            // color="rgba(7, 158, 217, 0.8)" // Blue Icon
                        />
                    </Box>
                    <SlideFade
                        zIndex={!displayDisclaimerShow ? '1' : '2'}
                        offsetY="0px"
                        animateOpacity
                        in={displayDisclaimerShow}
                        style={{ overflow: 'initial' }}
                    >
                        <Text
                            fontSize={{
                                base: displayDisclaimerShow
                                    ? '0.65rem'
                                    : '0.65rem',
                                md: displayDisclaimerShow ? '0.7rem' : '0.7rem',
                            }}
                            lineHeight="1.6"
                            fontWeight="500"
                            // transition="all ease-in-out 500ms"
                            width={{
                                base: displayDisclaimerShow ? '100%' : '0px',
                                md: displayDisclaimerShow ? '342px' : '0',
                            }}
                            maxWidth={{
                                base: 'calc(100vw - 155px)',
                                md: '342px',
                            }}
                            opacity={displayDisclaimerShow ? '1' : '0'}
                            visibility={
                                displayDisclaimerShow ? 'visible' : 'hidden'
                            }
                            marginLeft={{
                                base: displayDisclaimerShow ? '8px' : '0',
                                md: displayDisclaimerShow ? '12px' : '0',
                            }}
                            pb="1px"
                            color="rgba(255, 255, 255, 0.9)"
                            // color="rgba(7, 158, 217, 0.8)" // Blue Text
                        >
                            Information on the map within{' '}
                            <span style={{ fontWeight: '700' }}>AERO</span> mode
                            is solely intended to enhance situational awareness
                            and should not be considered a suitable alternative
                            for the aeronautical charts within AIPNZ
                        </Text>
                    </SlideFade>
                </Flex>
            </Box>
        )
    )
}
