import React, { useState, useEffect, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isFirefox } from 'react-device-detect'
import {
    Flex,
    Text,
    Center,
    Spinner,
    useColorModeValue,
    useBreakpoint,
    Tooltip,
} from '@chakra-ui/react'

import {
    dataModeState,
    layerSelectionState,
    layerStatusState,
    layerSelectionHandler,
    wipMenuItemsState,
    simulateFreeUserState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../../globalState'

import MenuIcon from '../../../../icons/menu-icons/MenuIcon'

import DataCountBadge from './DataCountBadge'
import BuggyBadge from './Badges'
import { UnavailableBadge } from './Badges'

import { ReactComponent as MapIcon } from '../../../../icons/assets/map.svg'
import { ReactComponent as NewTabIcon } from '../../../../icons/assets/new-tab.svg'
import PremiumBadge from '../../PremiumBadge'

export default function SidebarSubfolderItem({
    item,
    closeFolder,
    userTier,
    isPremium,
}) {
    const {
        label,
        id,
        type,
        externalURL,
        isDisabled,
        isBuggy,
        wip,
        // tier,
        canUse,
        unavailable,
    } = item

    const [active, setActive] = useState(false)
    const layerState = useRecoilValue(layerStatusState)
    const dataMode = useRecoilValue(dataModeState)
    const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const layerHandler = useSetRecoilState(layerSelectionHandler)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const breakpoint = useBreakpoint()

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const isHidden = !wipMenuItems && wip
    const mfaExpired = userTier > 1 && !mfaVerified

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )
    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        label && setActive(layerSelection[dataMode][id])
    }, [layerSelection, dataMode, id, label])

    function textColorHandler() {
        if (label && layerState[`${id}Error`]) {
            return 'red.300'
        } else if (active) {
            return subfolderItemActive
        } else {
            return subfolderItemColor
        }
    }

    const mapShow = type === 'raster' || type === 'vector'

    const textHover = useColorModeValue('light.80', 'dark.80')
    const subfolderItemColor = useColorModeValue('brand.strong', 'dark.100')
    const subfolderItemActive = useColorModeValue('light.200', 'dark.200')
    const subfolderItemHover = useColorModeValue('light.60', 'dark.60')
    const subfolderItemHoverFF = useColorModeValue('light.61', 'dark.61')

    return (
        <Flex
            display={isHidden ? 'none' : null}
            w="100%"
            color={active ? subfolderItemActive : textColorHandler()}
            alignItems="center"
            h={{ base: '40px', md: '38px' }}
            py={'20px'}
            pl={'14px'}
            pr={'15px'}
            _hover={
                isDisabled || unavailable
                    ? {
                          cursor: 'not-allowed',
                      }
                    : !isDisabled &&
                      !unavailable && {
                          backgroundColor: isFirefox
                              ? subfolderItemHoverFF
                              : subfolderItemHover,
                          color: active ? subfolderItemActive : textHover,
                          cursor: 'pointer',
                          borderRadius: '3px',
                      }
            }
            onClick={() => {
                if (!isDisabled && !unavailable) {
                    breakpoint !== 'base' && closeFolder() // if not mobile, close the folder
                    if (type === 'external') {
                        window.open(externalURL, '_blank')
                    } else if (label && !active && mfaExpired && !canUse) {
                        setShowResetMfaModal(true)
                    } else if (
                        label &&
                        !active &&
                        ((simulateFreeUser && !canUse) ||
                            (!canUse && userTier < 2))
                    ) {
                        setShowPricingTiersModal(true)
                    } else if (label && !active) {
                        return layerHandler({
                            mode: dataMode,
                            layer: id,
                        })
                    }
                }
            }}
        >
            <Flex
                opacity={
                    ((mfaExpired || simulateFreeUser) && !canUse) ||
                    (!canUse && userTier < 2) ||
                    unavailable
                        ? '0.3'
                        : isDisabled
                        ? '30%'
                        : '100%'
                }
            >
                <Center
                    w={'34px'}
                    h={'32px'}
                    bgGradient={
                        active &&
                        'linear-gradient(to bottom right, #00aa8a, #00f1ab)'
                    }
                    boxShadow={active && '0px 3px 15px -5px rgba(0, 0, 0, 0.5)'}
                    borderRadius="10px"
                    mr="14px"
                >
                    {label && layerState[`${id}Loading`] ? (
                        <Spinner color={active && '#ffffff'} boxSize="20px" />
                    ) : (
                        <MenuIcon
                            id={id}
                            activeColor={'white'}
                            active={active}
                            color={active && '#ffffff'}
                        />
                    )}
                </Center>
            </Flex>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Flex
                    alignItems="center"
                    opacity={
                        ((mfaExpired || simulateFreeUser) && !canUse) ||
                        (userTier < 2 && !canUse) ||
                        unavailable
                            ? '0.3'
                            : isDisabled
                            ? '30%'
                            : '100%'
                    }
                >
                    <Text
                        fontSize={{ base: '0.8rem', md: '12px' }}
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        cursor={!(isDisabled || unavailable) && 'pointer'}
                        fontWeight={active ? '600' : '400'}
                        lineHeight={isBuggy && label.length > 14 ? '1' : '1.1'}
                        // color={
                        //     layer && layerState[`${id}Error`]
                        //         ? 'red.300'
                        //         : active
                        //         ? subfolderItemActive
                        //         : subfolderItemColor
                        // }
                        textDecoration={
                            label && layerState[`${id}Error`] && 'line-through'
                        }
                        _hover={
                            !(isDisabled || unavailable) &&
                            !active && {
                                color: textHover,
                                cursor: 'pointer',
                            }
                        }
                    >
                        {label}
                    </Text>
                    {!unavailable && <DataCountBadge id={id} />}
                </Flex>

                <Flex alignContent="flex-end" marginTop="1px">
                    <Flex
                        ml={(isBuggy || mapShow || externalURL) && 2}
                        gap="3px"
                    >
                        {isBuggy && <BuggyBadge />}
                        {mapShow && (
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                opacity={
                                    ((mfaExpired || simulateFreeUser) &&
                                        !canUse) ||
                                    (userTier < 2 && !canUse) ||
                                    unavailable
                                        ? '0.3'
                                        : isDisabled
                                        ? '30%'
                                        : '100%'
                                }
                            >
                                <MapIcon
                                    opacity="0.9"
                                    width="22px"
                                    height="22px"
                                    color={
                                        active
                                            ? subfolderItemActive
                                            : textColorHandler()
                                    }
                                />
                            </Flex>
                        )}
                        {externalURL && (
                            <NewTabIcon
                                opacity="0.9"
                                width="22px"
                                height="22px"
                                color={
                                    active
                                        ? subfolderItemActive
                                        : textColorHandler()
                                }
                            />
                        )}
                        {(((mfaExpired || simulateFreeUser) && !canUse) ||
                            (userTier < 2 && !canUse)) && (
                            <Flex justifyContent="center" alignItems="center">
                                <Tooltip
                                    label="Premium Only"
                                    hasArrow
                                    borderRadius="7px"
                                    height="26px"
                                    fontSize="0.8rem"
                                    alignItems="center"
                                    display="flex"
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    placement="auto"
                                    ml="5px"
                                    color="light.10"
                                    bg="brand.premium"
                                >
                                    <PremiumBadge />
                                </Tooltip>
                            </Flex>
                        )}
                        {unavailable &&
                            !(
                                ((mfaExpired || simulateFreeUser) && !canUse) ||
                                (userTier < 2 && !canUse)
                            ) && <UnavailableBadge label={label} />}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
