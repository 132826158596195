import React, { useState, useEffect, useContext } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import { useAuth0 } from '@auth0/auth0-react'
import { useAdminRole } from '../../../../auth/hooks'
import {
    Flex,
    Box,
    useDisclosure,
    LightMode,
    Drawer,
    Center,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    Text,
    Spinner,
    useBreakpointValue,
    Icon,
} from '@chakra-ui/react'

import {
    parseISO,
    formatDistanceToNow,
    intervalToDuration,
    formatDistanceToNowStrict,
} from 'date-fns'
import { useQuery } from 'react-query'
import { useSwipeable } from 'react-swipeable'

import FullWidthError from '../FullWidthError'
import ErrorText from '../ErrorText'
import ErrorToggle from '../ErrorToggle'

import {
    clickedFeaturesState,
    zoomToActiveState,
    mapZoomLevelState,
    fullscreenModalVisibleState,
    showErrorTogglesState,
    globalTimeState,
} from '../../../../globalState'
import { ReactComponent as awsIcon } from '../../../../icons/assets/aws.svg'

import InfoContent from './Info'

export default function AWSModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const isAdmin = useAdminRole()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showErrors, setShowErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const zoomToActive = useRecoilValue(zoomToActiveState)
    const feat = clickedFeatures[0]

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const { getAccessTokenSilently } = useAuth0()
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const handleFullscreenModal = useRecoilValue(fullscreenModalVisibleState)
    const mapInstance = useContext(MapContext)
    const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelState)

    function onCloseHandler() {
        onClose()
        setClickedFeatures([])
        setZoomLevel(null)
    }

    // Fetch AWS Data
    const fetchAwsData = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/aws/${feat.properties.designator}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, refetch, status, remove } = useQuery(
        'awsData',
        fetchAwsData,
        {
            refetchInterval: 60000, // refetch data every minute
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    // Fetch Aerodrome Data on load
    useEffect(() => {
        remove()
        refetch()
    }, [feat.properties.designator, remove, refetch])

    // Close if Fullscreen modal opens
    useEffect(() => {
        if (handleFullscreenModal) {
            onClose()
            setClickedFeatures([])
            setZoomLevel(null)
        }
    }, [handleFullscreenModal, onClose, setClickedFeatures, setZoomLevel])

    // AWS Data
    const aws = data && data.length > 0 && data[0] ? data[0] : false

    // AWS Data
    const awsData =
        data && data.length > 0 && data[0] && data[0].data
            ? data[0].data
            : false

    const currentTime = useRecoilValue(globalTimeState)
    // AWS Out of Date Error Message Times
    const awsIssuedTime =
        awsData && awsData && awsData.obs_timestamp
            ? parseISO(awsData.obs_timestamp)
            : null
    const awsTimeDifference =
        awsIssuedTime &&
        intervalToDuration({
            start: awsIssuedTime,
            end: currentTime,
        })
    const awsOutOfDateIssueTime =
        (awsTimeDifference && awsTimeDifference.days >= 1) ||
        (awsTimeDifference &&
            awsTimeDifference.days < 1 &&
            awsTimeDifference.hours >= 1) // = 1 hour or greater
            ? 'WARNING'
            : awsTimeDifference && awsTimeDifference.minutes >= 45 // = 45 mins or greater
            ? 'CAUTION'
            : 'NONE'

    const closeButtonPositionSmallScreen = useBreakpointValue({
        base: { top: '0px', right: '0px' },
        md: { display: 'none' },
    })
    const closeButtonPositionLargeScreen = useBreakpointValue({
        base: { display: 'none' },
        md: {
            marginRight: '0',
            left: '10px',
            right: 'auto',
        },
    })
    const headerAlign = useBreakpointValue({
        base: { paddingRight: '0' },
        md: { paddingLeft: '40px', paddingRight: '0' },
    })
    const overlayBG = useBreakpointValue({
        base: { background: 'blackAlpha.700' },
        md: { background: 'none', width: '0vw' },
    })
    const DrawerSize = useBreakpointValue({
        base: { size: 'full' },
        md: { size: 'xs' },
    })
    const DrawerLayout = useBreakpointValue({
        base: 'bottom',
        md: 'right',
    })

    // TODO - add change so background and colouring changes for dark mode if night time
    // Converting Now Time to a 4 digit number (simple steps)
    // const time = Date.now()
    // const timeFormatted = formatISO(time, { format: 'basic' })
    // const now = lightFormat(parseISO(timeFormatted), 'HHmm')
    // const nowAsNumber = Number(now)

    // // SUNRISE - plus/minus 30 minutes either side of MCT
    // const MCT = data && data.twilighttimes && parseISO(data.twilighttimes.mct)
    // const sunriseStart = // as a 4 digit number
    //     MCT &&
    //     Number(
    //         lightFormat(parseISO(formatISO(MCT, { format: 'basic' })), 'HHmm')
    //     )
    // const sunriseEnd = // as a 4 digit number
    //     MCT &&
    //     Number(
    //         lightFormat(
    //             parseISO(formatISO(addMinutes(MCT, 30), { format: 'basic' })),
    //             'HHmm'
    //         )
    //     )

    // // SUNSET - plus/minus 30 minutes either side of ECT
    // const ECT = data && data.twilighttimes && parseISO(data.twilighttimes.ect)
    // const sunsetStart = // as a 4 digit number
    //     ECT &&
    //     Number(
    //         lightFormat(
    //             parseISO(formatISO(addMinutes(ECT, -30), { format: 'basic' })),
    //             'HHmm'
    //         )
    //     )
    // const sunsetEnd = // as a 4 digit number
    //     ECT &&
    //     Number(
    //         lightFormat(parseISO(formatISO(ECT, { format: 'basic' })), 'HHmm')
    //     )

    // // TIMES OF DAY
    // const night =
    //     (sunriseStart && nowAsNumber < sunriseStart) ||
    //     (sunsetEnd && nowAsNumber > sunsetEnd)
    // const sunrise =
    //     sunriseStart &&
    //     sunriseEnd &&
    //     nowAsNumber >= sunriseStart &&
    //     nowAsNumber <= sunriseEnd
    // const day = nowAsNumber > sunriseEnd && nowAsNumber < sunsetStart
    // const sunset =
    //     sunriseStart &&
    //     sunriseEnd &&
    //     nowAsNumber >= sunsetStart &&
    //     nowAsNumber <= sunsetEnd
    // const timePeriod = night
    //     ? 'Night'
    //     : sunrise
    //     ? 'Sunrise'
    //     : day
    //     ? 'Day'
    //     : sunset
    //     ? 'Sunset'
    //     : 'Day'

    // Zoom-out function when closing Aerodrome Drawer on button only (clicking overlay does not)
    function zoomOutOnClose() {
        if (
            zoomToActive &&
            clickedFeatures.length === 1 &&
            clickedFeatures[0].layer === 'aws'
        ) {
            mapInstance.flyTo({
                center: clickedFeatures[0].geometry.coordinates,
                zoom: zoomLevel,
                curve: 1.2,
                offset: [0, 0],
            })
            setZoomLevel(null)
        }
    }

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            onCloseHandler()
            zoomOutOnClose()
        },
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // useEffect(() => {
    //     console.log(data, 'data')
    //     console.log(awsData, 'awsData')
    // }, [data, awsData])

    return (
        <LightMode>
            <Drawer
                closeOnOverlayClick={true}
                variant="aerodrome"
                onClose={() => {
                    onCloseHandler()
                    zoomOutOnClose()
                }}
                isOpen={isOpen}
                isCentered
                placement={DrawerLayout}
                onChange={DrawerLayout}
                {...DrawerSize}
                mt="60px"
                containerStyle={{ width: '0vw' }}
                trapFocus={false}
                background="white"
            >
                <DrawerCloseButton
                    {...closeButtonPositionSmallScreen}
                    color={{ base: 'white', md: '#444444' }}
                    zIndex="1301"
                    opacity="1"
                    fontSize="1.1rem"
                    boxShadow="none !important"
                    {...noFocus}
                    width="48px"
                    height="48px"
                />
                <DrawerOverlay
                    {...overlayBG}
                    onClick={() => {
                        onCloseHandler()
                    }}
                />
                {status === 'loading' && (
                    <div className="pageload">
                        <Center w="100%" h="100%" mt="-65px">
                            <Spinner
                                color={'light.200'}
                                emptyColor="rgba(255,255,255,0.3)"
                                thickness="3px"
                                speed="0.45s"
                                boxSize="60px"
                            />
                        </Center>
                    </div>
                )}
                {status !== 'loading' && status === 'success' && (
                    <DrawerContent
                        height={{ base: 'calc(100% - 47px)', md: '100%' }}
                        maxHeight={{ base: 'calc(100% - 47px)', md: '100%' }}
                        borderTopRadius={{ base: '27px', md: '0px' }}
                        boxShadow={{
                            base: '0px -5px 20px -7px rgba(0,0,0,0.4)',
                            md: '-5px 0px 15px -5px rgba(0,0,0,0.2)',
                        }}
                        width={{
                            base: '100%',
                            md: '',
                        }}
                        maxWidth={{
                            base: '100%',
                            md: '23rem',
                        }}
                        //TODO
                        // EXPAND/COLLAPSE DRAWER WIDTH TRANSITION IS BUGGY - its trying to compete with the Drawer opening transition when set to 'all', or bounces from left when set to 'width'.
                        // transition="width ease-in 100ms"
                    >
                        <DrawerHeader
                            zIndex="1"
                            // {...tabSwipe}
                            {...headerSwipe}
                            backgroundColor={'#576B84'}
                            bgImage={
                                'linear-gradient(160deg, rgb(35, 55, 80) 0%, rgb(96, 131, 179) 90%)'
                            }
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            // backgroundBlendMode="overlay"
                            paddingInlineStart={{ base: 4, md: 5 }}
                            paddingInlineEnd={{ base: 4, md: 5 }}
                            minHeight="73px"
                            paddingTop="3"
                            borderTopRadius={{ base: '25px', md: '0px' }}
                            clipPath="inset(0px 0px 0px 0px)"
                        >
                            <DrawerCloseButton
                                {...closeButtonPositionLargeScreen}
                                color="white"
                                opacity="0.6"
                                boxShadow="none !important"
                                _hover={{ opacity: '1' }}
                                {...noFocus}
                                fontSize="1rem"
                                width="40px"
                                height="40px"
                                mt="-4px"
                            />
                            <Flex w="100%" {...headerAlign}>
                                <Icon
                                    position="absolute"
                                    pointerEvents="none"
                                    transform={{
                                        base: 'scale(2) translateX(3px) translateY(4px)',
                                        md: 'scale(2.2) translateX(-18px) translateY(2px)',
                                    }}
                                    opacity="0.04"
                                    boxSize="44px"
                                    as={awsIcon}
                                    alt="!"
                                    color="#ffffff"
                                />
                                <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Text
                                        color="white"
                                        fontSize="md"
                                        fontWeight="500"
                                        paddingTop={'1px'}
                                    >
                                        {feat.properties.designator}
                                    </Text>

                                    <Box position="absolute" right="15px">
                                        <Text
                                            textAlign="right"
                                            color="white"
                                            paddingTop={{
                                                base:
                                                    feat.properties.name
                                                        .length > 22
                                                        ? '3px'
                                                        : feat.properties.name
                                                              .length > 16
                                                        ? '2px'
                                                        : '0',
                                                md:
                                                    feat.properties.name
                                                        .length > 18
                                                        ? '3px'
                                                        : feat.properties.name
                                                              .length > 14
                                                        ? '2px'
                                                        : '0',
                                            }}
                                            fontSize={{
                                                base:
                                                    feat.properties.name
                                                        .length > 22
                                                        ? '0.9rem'
                                                        : feat.properties.name
                                                              .length > 16
                                                        ? '1rem'
                                                        : '1.2rem',
                                                md:
                                                    feat.properties.name
                                                        .length > 18
                                                        ? '0.9rem'
                                                        : feat.properties.name
                                                              .length > 14
                                                        ? '1rem'
                                                        : '1.2rem',
                                            }}
                                            fontWeight="bold"
                                            lineHeight={{
                                                base:
                                                    feat.properties.name
                                                        .length > 22
                                                        ? '1.8'
                                                        : feat.properties.name
                                                              .length > 16
                                                        ? '1.6'
                                                        : '1.3',
                                                md: '1.3',
                                            }}
                                        >
                                            {feat.properties.name}
                                        </Text>
                                        <Text
                                            textAlign="right"
                                            color="white"
                                            fontSize="0.8rem"
                                            pt="2px"
                                            fontWeight={500}
                                        >
                                            Automatic Weather Station
                                        </Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </DrawerHeader>
                        <DrawerBody p={0} bg="light.30">
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    width="100%"
                                    py="15px"
                                    px="15px"
                                    zIndex="100"
                                    position="absolute"
                                    background="rgba(245, 248, 251, 0.6)"
                                    gap="15px"
                                    justifyContent="space-between"
                                    backdropFilter="blur(15px)"
                                >
                                    <Flex
                                        background="white"
                                        borderRadius="20px"
                                    >
                                        <ErrorToggle
                                            showErrors={showNetworkErrors}
                                            setShowErrors={setShowNetworkErrors}
                                            label="Simulate Network Error"
                                        />
                                    </Flex>
                                    <Flex
                                        background="white"
                                        borderRadius="20px"
                                    >
                                        <ErrorToggle
                                            showErrors={showErrors}
                                            setShowErrors={setShowErrors}
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            <Flex
                                flexDirection="column"
                                width="100%"
                                pt={isAdmin && showErrorToggle && '60px'}
                            >
                                {/* ERROR MESSAGES */}
                                {(showErrors || aws.outofdate) && (
                                    <Flex
                                        mt="15px"
                                        justifyContent="center"
                                        w="100%"
                                        px="15px"
                                    >
                                        <ErrorText>
                                            There has been an issue with the
                                            connection between PreFlight and
                                            MetService. This AWS was last
                                            validated with the MetService{' '}
                                            <strong>
                                                {formatDistanceToNow(
                                                    parseISO(aws.updated),
                                                    {
                                                        addSuffix: true,
                                                    }
                                                )}
                                            </strong>
                                            .<br />
                                            There is a high likelihood that the
                                            latest AWS is not available in
                                            PreFlight. Please check back again
                                            soon.
                                        </ErrorText>
                                    </Flex>
                                )}

                                {!showErrors &&
                                    !aws.outofdate &&
                                    ((data && !awsData) ||
                                        awsOutOfDateIssueTime === 'CAUTION' ||
                                        awsOutOfDateIssueTime ===
                                            'WARNING') && (
                                        <Flex
                                            mb={{
                                                base: '15px',
                                                xl: '10px',
                                            }}
                                            mt={{
                                                base: '25px',
                                                xl: '25px',
                                            }}
                                            w="100%"
                                            px="15px"
                                            justifyContent="center"
                                            align="center"
                                            flexDirection="column"
                                            textAlign="center"
                                            color={
                                                awsOutOfDateIssueTime &&
                                                awsOutOfDateIssueTime ===
                                                    'CAUTION'
                                                    ? '#ff7f00'
                                                    : 'red'
                                            }
                                            fontSize="12px"
                                        >
                                            {aws?.updated && (
                                                <>
                                                    <Text>
                                                        The data from this AWS
                                                        was last validated{' '}
                                                    </Text>
                                                    <Text>
                                                        <strong>
                                                            {formatDistanceToNowStrict(
                                                                parseISO(
                                                                    aws.updated
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </strong>
                                                        .
                                                    </Text>
                                                </>
                                            )}
                                            {(awsOutOfDateIssueTime ===
                                                'WARNING' ||
                                                (data && !awsData)) && (
                                                <Text>
                                                    There is a possibility there
                                                    has been an issue receiving
                                                    the latest data and that the
                                                    latest weather observation
                                                    for {aws.designator} is not
                                                    available. Please check back
                                                    again soon.
                                                </Text>
                                            )}
                                        </Flex>
                                    )}

                                {status === 'error' || showNetworkErrors ? (
                                    <Center
                                        h="100%"
                                        w="100%"
                                        px="15px"
                                        flexDirection="column"
                                    >
                                        <FullWidthError
                                            message={`Unable to download data for ${feat.properties.designator}. \nPlease close this pane and try clicking the AWS again.`}
                                        />
                                    </Center>
                                ) : (
                                    <Box w="100%" h="100%">
                                        <InfoContent
                                            awsData={awsData}
                                            awsIssuedTime={awsIssuedTime}
                                            awsOutOfDateIssueTime={
                                                awsOutOfDateIssueTime
                                            }
                                        />
                                    </Box>
                                )}
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                )}
            </Drawer>
        </LightMode>
    )
}
