import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useRef,
} from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Source, Layer, MapContext } from 'react-mapbox-gl'

import {
    selectedBriefingAreasState,
    briefingAreaSourceState,
    dataModeState,
    briefingCreatedState,
} from '../../../globalState'
import BriefingSelection from './BriefingSelection'

export default function BriefingAreasLayer() {
    const dataMode = useRecoilValue(dataModeState)
    const [hoveredArea, setHoveredArea] = useState(null)
    const [selectedAreas, setSelectedAreas] = useRecoilState(
        selectedBriefingAreasState
    )
    const [briefingCreated, setBriefingCreated] =
        useRecoilState(briefingCreatedState)
    const mapInstance = useContext(MapContext)

    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )

    useCallback(() => {
        if (dataMode === 'brief' && briefingCreated) {
            setBriefingCreated(false)
        }
    }, [dataMode, briefingCreated, setBriefingCreated])

    const offHover = useCallback(() => {
        mapInstance.getCanvas().style.cursor = ''
        setHoveredArea(null)
    }, [mapInstance])

    const tapTime = useRef(null)

    const handleClick = (e) => {
        const clickedArea =
            briefingAreaSource === 'aaw'
                ? {
                      area: e.features[0].properties.area,
                      name: e.features[0].properties.name,
                  }
                : {
                      designator: e.features[0].properties.designator,
                  }
        setSelectedAreas((prevState) =>
            prevState
                .map((area) =>
                    briefingAreaSource === 'aaw' ? area.area : area.designator
                )
                .includes(
                    briefingAreaSource === 'aaw'
                        ? clickedArea.area
                        : clickedArea.designator
                )
                ? prevState.filter((area) =>
                      briefingAreaSource === 'aaw'
                          ? area.area !== clickedArea.area
                          : area.designator !== clickedArea.designator
                  )
                : [...prevState, clickedArea]
        )
    }

    useEffect(() => {
        setBriefingAreaSource('briefingarea')
    }, [setBriefingAreaSource])

    return (
        <>
            <Source
                id="briefingareas"
                geoJsonSource={{
                    type: 'geojson',
                    data:
                        briefingAreaSource === 'aaw'
                            ? `${window.location.origin}/data/aaw/areas`
                            : `${window.location.origin}/data/briefingareas`,
                }}
            />
            <Layer
                id="briefingareas-symbol"
                sourceId="briefingareas"
                type="symbol"
                textSize=""
                layout={{
                    'text-field':
                        briefingAreaSource === 'aaw'
                            ? ['get', 'area']
                            : ['get', 'designator'],
                }}
                filter={
                    briefingAreaSource === 'aaw'
                        ? ['!=', ['get', 'area'], 'LINES']
                        : null
                }
            />
            <Layer
                id="briefingareas-line"
                sourceId="briefingareas"
                type="line"
                paint={{
                    'line-color': '#ffffff',
                    'line-width': 3,
                }}
            />

            <Layer
                id="briefingareas-fill"
                onMouseEnter={(e) => {
                    mapInstance.getCanvas().style.cursor = 'pointer'
                    setHoveredArea(
                        briefingAreaSource === 'aaw'
                            ? e.features[0].properties.area
                            : e.features[0].properties.designator
                    )
                }}
                onMouseMove={(e) => {
                    mapInstance.getCanvas().style.cursor = 'pointer'
                    setHoveredArea(
                        briefingAreaSource === 'aaw'
                            ? e.features[0].properties.area
                            : e.features[0].properties.designator
                    )
                }}
                onMouseLeave={offHover}
                onTouchStart={() => {
                    tapTime.current = new Date()
                }}
                onTouchEnd={(e) => {
                    const now = new Date()
                    if (now - tapTime.current < 300) {
                        handleClick(e)
                    }
                }}
                onClick={handleClick}
                sourceId="briefingareas"
                type="fill"
                filter={
                    briefingAreaSource === 'aaw'
                        ? ['!=', ['get', 'area'], 'LINES']
                        : null
                }
                paint={{
                    'fill-opacity': 0,
                }}
            />
            <Layer
                id="briefingareas-fill-highlighted"
                sourceId="briefingareas"
                type="fill"
                filter={
                    briefingAreaSource === 'aaw'
                        ? ['==', ['get', 'area'], hoveredArea]
                        : ['==', ['get', 'designator'], hoveredArea]
                }
                paint={{
                    'fill-color': '#1FD27E',
                    'fill-opacity': 0.25,
                }}
            />
            <Layer
                id="briefingareas-fill-selected"
                sourceId="briefingareas"
                type="fill"
                filter={
                    briefingAreaSource === 'aaw'
                        ? [
                              'in',
                              ['get', 'area'],
                              [
                                  'literal',
                                  selectedAreas.map((area) => area.area),
                              ],
                          ]
                        : [
                              'in',
                              ['get', 'designator'],
                              [
                                  'literal',
                                  selectedAreas.map((area) => area.designator),
                              ],
                          ]
                }
                paint={{
                    'fill-color': '#1F5BD2',
                    'fill-opacity': 0.25,
                }}
            />
            <BriefingSelection />
        </>
    )
}
