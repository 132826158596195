import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Icon,
    Link,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { setModalVisibilityState, modalVisibilityState } from '../globalState'

import { ReactComponent as ChromeIcon } from '../map/modals/assets/chromeIcon.svg'
import { ReactComponent as FirefoxIcon } from '../map/modals/assets/firefoxIcon.svg'
import { ReactComponent as SafariIcon } from '../map/modals/assets/safariIcon.svg'

export default function DeviceRequirementsModal(props) {
    const { deviceWarning, setDeviceWarning } = props
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceRequirements', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.deviceRequirements ? onOpen() : onClose()
    }, [modalVisibility.deviceRequirements, onOpen, onClose])

    function onCloseHandler() {
        if (deviceWarning) {
            setShow(false)
            setDeviceWarning(false)
        } else setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={deviceWarning && 'deviceWarning'}
                    size="3xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    {!deviceWarning && <ModalOverlay />}
                    <ModalContent mx="15px">
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            Device Requirements
                        </ModalHeader>
                        <ModalBody
                            background="light.20"
                            style={{ padding: 0 }}
                            fontSize="0.8rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={5}
                                pb={8}
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                <Text pt="10px">
                                    This web application has been designed to
                                    make it easier to access and interpret
                                    pre-flight information. The key to this is
                                    making it more visual and therefore the
                                    dynamic map is a core part of the
                                    application.
                                </Text>
                                <Text pt="10px">
                                    However, because of the amount of
                                    information processed and presented, this
                                    does come at a small cost in that it
                                    requires newer devices and computers to run
                                    smoothly.
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Recommended Browsers:</strong>
                                </Text>
                                <Flex
                                    pt="10px"
                                    width={{ base: '100%', sm: 'auto' }}
                                    flexDirection="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    px="9px"
                                >
                                    <Flex
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={ChromeIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                            mt="-2px"
                                        >
                                            <Icon
                                                as={FirefoxIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={SafariIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        fontSize="0.7rem"
                                        borderBottom="1px solid #e2e5e9"
                                        pt="5px"
                                        pb="5px"
                                        color="gray.400"
                                        fontWeight="300"
                                    >
                                        <Text width="80px" textAlign="center">
                                            Chrome
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Firefox
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Safari
                                        </Text>
                                    </Flex>
                                    <Flex
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        pt="5px"
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            104+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            91+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            15+
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Text pl="10px" pt="10px">
                                    We recommend the current version of Chrome,
                                    Firefox or Safari.{' '}
                                </Text>
                                <Text pt="25px" fontSize="0.9rem">
                                    <strong>Recommended Devices:</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Preflight will run on Apple, Android and
                                    Windows devices, however we recommend that
                                    you use smart phones, tablets or computers
                                    that are approximately{' '}
                                    <strong>less than 4 years old</strong>{' '}
                                    <em>
                                        (this is subject to the graphics
                                        hardware of your particular make and
                                        model)
                                    </em>
                                    .
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If you are using a computer with a dedicated
                                    graphics processor (GPU), you will get
                                    better performance if you ensure this is
                                    used for the web browser running this web
                                    application.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If applicable, your web browser may support
                                    Hardware Acceleration. This may also improve
                                    performance if this is turned ON in your
                                    browser settings.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Older devices may still run this web
                                    application, but you are more likely to
                                    experience longer loading times with older
                                    devices and those with limited graphics
                                    performance.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    If using an older device we recommend you
                                    access the briefing function or Met
                                    functions instead of doing a lot of
                                    navigating around the map as these functions
                                    won't require as much performance from your
                                    device.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Alternatively, you can use{' '}
                                    <Link
                                        fontWeight="600"
                                        color="light.200"
                                        href="https://ifis.airways.co.nz/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        IFIS
                                    </Link>{' '}
                                    to access the traditional briefing
                                    information.
                                </Text>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    {deviceWarning
                                        ? 'Accept and Continue'
                                        : 'Close'}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
