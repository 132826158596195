import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
    Flex,
    Box,
    Modal,
    Heading,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    useDisclosure,
    LightMode,
    Icon,
    Text,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { ReactComponent as ChartsIcon } from '../../../icons/assets/charts.svg'
import { ReactComponent as PDFIcon } from '../../../icons/assets/pdf.svg'
import { isIOS, isMobileOnly } from 'react-device-detect'
import PDFViewer from './PDFViewer'
import {
    dataModeState,
    layerSelectionState,
    pdfViewerContentState,
    setModalVisibilityState,
    modalVisibilityState,
    fullscreenModalVisibleState,
} from '../../../globalState'

const options = {
    aic: {
        // CHECK IF NEEDS UPDATING
        id: 'AIC',
        label: 'Aeronautical Information Circulars',
        url: 'https://www.aip.net.nz/assets/AIP-Additional-Files/aic_8aug24.pdf', // NEXT ONE IS 8 AUGUST 2024!
        proxy: true,
        icon: ChartsIcon,
    },
    sup: {
        // CHECK IF NEEDS UPDATING
        id: 'SUP',
        label: 'AIP Supplements',
        url: 'https://www.aip.net.nz/assets/AIP-Additional-Files/aip_supp_8aug24.pdf', // THIS IS TO BE UPDATED ON MONTHLY BASIS (AIRAC CYCLE)
        proxy: true,
        icon: ChartsIcon,
    },
    // sup_dawn: {  // *** SAVED FOR FUTURE USE FOR ANOTHER TEMP SUP ETC. ***
    //     // CHECK IF STILL VALID - Will need to remove link in Settings menu as well.
    //     id: 'sup_dawn',
    //     label: 'AIP Supplements',
    //     sublabel: 'Dawn Aerospace RPAS Flight Testing',
    //     url: 'https://www.aip.net.nz/assets/AIP-Additional-Files/aip_supp_dawnaero_1apr24.pdf',
    //     proxy: true,
    //     icon: ChartsIcon,
    // },
    daylighttables: {
        id: 'daylighttables',
        label: 'Daylight Tables',
        url: 'https://www.aip.net.nz/assets/AIP/General-GEN/2-TABLES-AND-CODES/GEN_2.7.pdf',
        proxy: true,
        icon: ChartsIcon,
    },
    abreviationsanddefinitions: {
        id: 'abreviationsanddefinitions',
        label: 'Abreviations and Definitions',
        url: 'https://www.aip.net.nz/assets/AIP/General-GEN/2-TABLES-AND-CODES/GEN_2.2.pdf',
        proxy: true,
        icon: ChartsIcon,
    },
    airaccycle2024: {
        id: 'airaccycle2024',
        label: 'AIRAC Cycle Dates 2024',
        url: 'https://www.aviation.govt.nz/assets/airspace-and-aerodromes/aip/AIPNZ-Update-Cycle-for-2024.pdf',
        proxy: true,
        icon: PDFIcon,
    },
    weathercard: {
        id: 'weathercard',
        label: 'Weather Card',
        url: 'https://www.aviation.govt.nz/assets/publications/products/weather-card.pdf',
        proxy: true,
        icon: PDFIcon,
    },
    vfrmet: {
        id: 'vfrmet',
        label: 'VFR Meteorology GAP Booklet',
        url: 'https://www.aviation.govt.nz/assets/publications/gaps/vfr-met-web.pdf',
        proxy: true,
        icon: PDFIcon,
    },
    grafor: {
        id: 'grafor',
        label: 'GRAFOR User Guide',
        url: 'https://about.metservice.com/assets/Aviation/GRAFOR_documentation_130618_V08.pdf',
        proxy: true,
        icon: PDFIcon,
    },
    gnzsigwx: {
        id: 'gnzsigwx',
        label: 'Graphical NZ SIGWX User Guide',
        // url: `${window.location.origin}/pdfs/GRAPHICAL_NZ_SIGWX_User_Guide_2023.pdf`, //TODO - not loading on Dev/Prod but is on localhost
        url: 'https://about.metservice.com/assets/Aviation/GRAPHICAL-NZ-SIGWX-docV01-User-Guide-2023-updated.pdf',
        proxy: true,
        icon: PDFIcon,
    },
}

export default function PDFModal() {
    // const setModalState = useSetRecoilState(setModalVisibilityState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setFullscreen = useSetRecoilState(fullscreenModalVisibleState)
    const mode = useRecoilValue(dataModeState)
    const layers = useRecoilValue(layerSelectionState)
    const [content, setContent] = useRecoilState(pdfViewerContentState)
    const [details, setDetails] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const mountedRef = useRef(null)

    useEffect(() => {
        setFullscreen(isOpen)
    }, [isOpen, setFullscreen])

    const setShowPdfModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pdf', value })
        },
        [setModalVisibility]
    )

    const onCloseHandler = useCallback(() => {
        setShowPdfModal(false)
        setContent(null)
        onClose()
        setFullscreen(false)
    }, [onClose, setContent, setFullscreen, setShowPdfModal])

    useEffect(() => {
        if (mountedRef.current) {
            onCloseHandler()
        }
    }, [layers, mode, onCloseHandler])

    useEffect(() => {
        if (content && modalVisibility.pdf) {
            setDetails(options[content])
            onOpen()
            mountedRef.current = true
        }
    }, [content, modalVisibility.pdf, onOpen, setDetails])

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    onClose={onCloseHandler}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        {details && (
                            <>
                                <Flex
                                    direction="column"
                                    h={{
                                        base: '70px',
                                        md: '70px',
                                    }}
                                    minHeight={{
                                        base: '70px',
                                        md: '70px',
                                    }}
                                    // added flex here as header height not sticking causing spacing issues elswhere
                                >
                                    <ModalHeader
                                        backgroundColor={'light.10'}
                                        borderBottom="1px solid"
                                        borderBottomColor="#E2E8F0"
                                        backgroundSize="100% auto"
                                        backgroundRepeat="no-repeat"
                                        backgroundBlendMode="overlay"
                                        h={{
                                            base: '70px',
                                            md: '70px',
                                        }}
                                        w="100%"
                                        paddingInlineStart={4}
                                        paddingInlineEnd={4}
                                        flex="1"
                                        boxShadow={{
                                            // base: '0px 5px 10px -1px rgba(0,0,0,0.05)',
                                            base: 'none',
                                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                        }}
                                        zIndex="1000"
                                    >
                                        <ModalCloseButton
                                            color="black"
                                            mt="6px"
                                            mr="5px"
                                        />
                                        <Flex
                                            flexDirection="row"
                                            width="100%"
                                            height="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Flex
                                                height={'100%'}
                                                ml={{
                                                    base: '50px',
                                                    md: '10px',
                                                }}
                                                color="black"
                                                pr={8}
                                                alignItems="center"
                                            >
                                                <Icon
                                                    as={details.icon}
                                                    boxSize={'30px'}
                                                />
                                                <Box pl={4}>
                                                    <Flex
                                                        flexDirection={{
                                                            base: 'column',
                                                            lg: 'row',
                                                        }}
                                                        width="100%"
                                                        height="100%"
                                                        justifyContent={{
                                                            base: 'center',
                                                            lg: 'flex-start',
                                                        }}
                                                        alignItems={{
                                                            base: 'flex-start',
                                                            lg: 'baseline',
                                                        }}
                                                    >
                                                        <Heading
                                                            variant="mainHeading"
                                                            color="black"
                                                            lineHeight={
                                                                details.label
                                                                    .length > 12
                                                                    ? '1.2'
                                                                    : '1.3'
                                                            }
                                                        >
                                                            {details.label}
                                                        </Heading>
                                                        {details?.sublabel && (
                                                            <>
                                                                <Text
                                                                    fontSize="0.95rem"
                                                                    mx="5px"
                                                                    color="gray.500"
                                                                    display={{
                                                                        base: 'none',
                                                                        lg: 'block',
                                                                    }}
                                                                >
                                                                    –
                                                                </Text>
                                                                <Text
                                                                    fontSize={{
                                                                        base: '0.75rem',
                                                                        md: '0.8rem',
                                                                        lg: '0.95rem',
                                                                    }}
                                                                    color="gray.500"
                                                                    lineHeight={
                                                                        details
                                                                            .label
                                                                            .length >
                                                                        12
                                                                            ? '1.2'
                                                                            : '1.3'
                                                                    }
                                                                >
                                                                    {
                                                                        details?.sublabel
                                                                    }
                                                                </Text>
                                                            </>
                                                        )}
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    </ModalHeader>
                                </Flex>
                                <ModalBody
                                    py={{ base: '0px', md: '0rem' }}
                                    px={{ base: '0px', md: '0rem' }}
                                >
                                    <Box
                                        className="pdf-viewer"
                                        w="100%"
                                        height={{
                                            base:
                                                isIOS && isMobileOnly
                                                    ? 'calc(100vh - 155px)'
                                                    : 'calc(100vh - 140px)',
                                            md: 'calc(100vh - 70px)',
                                        }}
                                    >
                                        <PDFViewer
                                            fileUrl={
                                                details.proxy
                                                    ? `${
                                                          window.location.origin
                                                      }/data/chart/${encodeURIComponent(
                                                          details.url
                                                      )}`
                                                    : details.url
                                            }
                                        />
                                    </Box>
                                </ModalBody>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
