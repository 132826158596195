import React, { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { TbTrash, TbTrashX } from 'react-icons/tb'
import { ReactComponent as FavouriteIcon } from '../../../../../icons/assets/favourite-outline.svg'
import { ReactComponent as FavouritesNoList } from '../../../../../icons/assets/favourites-no-list.svg'
import { ReactComponent as FavouritesPartList } from '../../../../../icons/assets/favourites-part-list.svg'
import { ReactComponent as FavouritesFullList } from '../../../../../icons/assets/favourites-full-list.svg'
import { FiEdit } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import { BsQuestionCircle } from 'react-icons/bs'
import { isMobile, isDesktop } from 'react-device-detect'
import { ExcludedAerodromes, ExcludedAws } from '../briefing-menu/ExcludedItems'
import {
    Flex,
    Text,
    Box,
    Checkbox,
    Link,
    Icon,
    Tooltip,
    Input,
    Button,
    IconButton,
    Textarea,
    FormControl,
    FormErrorMessage,
    Spinner,
    Collapse,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from '@chakra-ui/react'
import '../../briefing.css'
import {
    SourceComponent,
    SelectedComponent,
    ContentsComponent,
    PeriodToComponent,
    MaxaltComponent,
    AerodromesComponent,
    AwsComponent,
} from './ExistingFavouriteComponents'
import {
    updatedParamsState,
    showFavouritesState,
    showManageFavouritesState,
    newFavouriteTitleState,
    newFavouriteNoteState,
    loadFavouriteState,
    updateExistingFavouriteState,
    sortedFavouritesListState,
    deleteExistingFavouriteState,
    deletedFavouritesState,
    currentUrlState,
    excludedAerodromesState,
    excludedAwsState,
    selectAerodromesState,
    selectAwsState,
    isBriefingOnlyState,
    updatingExcludedItemsState,
    updateMainDetailsState,
    briefingStandardState,
} from '../../../../../globalState'

export default function LoadBriefingFavourite(props) {
    const {
        duplicateUrl,
        duplicateTitle,
        tab0Text,
        maxLimitFavourites,
        nearingFavouritesLimit,
        reachedFavouritesLimit,
        menuContents,
        formattedKeys,
        filteredExcludedAerodromes,
        filteredExcludedAws,
        includedAerodromes,
        includedAws,
        briefingData,
        isPremium,
        updatingFavourites,
        emptyTitle,
    } = props

    const updatedParams = useRecoilValue(updatedParamsState)
    const showFavourites = useRecoilValue(showFavouritesState) // this is provided from the 3 Brief mode selections only
    const showManageFavourites = useRecoilValue(showManageFavouritesState)
    const updateExistingFav = useRecoilValue(updateExistingFavouriteState)
    const [newFavouriteTitle, setNewFavouriteTitle] = useRecoilState(
        newFavouriteTitleState
    )
    const [newFavouriteNote, setNewFavouriteNote] = useRecoilState(
        newFavouriteNoteState
    )
    const [loadFavourite, setLoadFavourite] = useRecoilState(loadFavouriteState)
    const sortedFavouritesList = useRecoilValue(sortedFavouritesListState)
    const briefingStandard = useRecoilValue(briefingStandardState)
    const [deleteExistingFav, setDeleteExistingFav] = useRecoilState(
        deleteExistingFavouriteState
    )
    const [deletedFavourites, setDeletedFavourites] = useRecoilState(
        deletedFavouritesState
    ) // selected favourite to be deleted
    const [updateMainDetails, setUpdateMainDetails] = useRecoilState(
        updateMainDetailsState
    )
    const [editingDetails, setEditingDetails] = useState(false)
    const setCurrentUrl = useSetRecoilState(currentUrlState)
    const setExcludedAerodromes = useSetRecoilState(excludedAerodromesState)
    const setExcludedAws = useSetRecoilState(excludedAwsState)
    const [selectAerodromes, setSelectAerodromes] = useRecoilState(
        selectAerodromesState
    )
    const [selectAws, setSelectAws] = useRecoilState(selectAwsState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const [updatingExcludedItems, setUpdatingExcludedItems] = useRecoilState(
        updatingExcludedItemsState
    )

    const [showNotes, setShowNotes] = useState(null)

    const favouriteSelected = (title) => {
        if (deleteExistingFav) {
            return deletedFavourites
                .map((deletedFav) => deletedFav.title)
                .includes(title)
        } else {
            return loadFavourite && loadFavourite.title === title
        }
    }

    const handleItemClick = (fav) => {
        const isChecked = Boolean(favouriteSelected(fav.title)) || false
        if (
            !deleteExistingFav &&
            !updateExistingFav &&
            loadFavourite?.title === fav.title
        ) {
            setLoadFavourite(null) // Deselect if already selected
            if (updateExistingFav) {
                setNewFavouriteTitle('')
                setNewFavouriteNote('')
            }
        } else if (!deleteExistingFav) {
            setLoadFavourite(fav) // Select favourite if not selected
            if (updateExistingFav && !isChecked) {
                setNewFavouriteTitle('')
                setNewFavouriteNote('')
            }
        } else if (deleteExistingFav) {
            if (!showFavourites && deleteExistingFav) {
                // Check if the favourite is already in the deletedFavourites array
                if (
                    deletedFavourites.find(
                        (deletedFav) => deletedFav.title === fav.title
                    ) !== undefined
                ) {
                    // Remove the favourite from deletedFavourites array
                    setDeletedFavourites((prevState) =>
                        prevState.filter(
                            (deletedFav) => deletedFav.title !== fav.title
                        )
                    )
                } else {
                    // Add the favourite to deletedFavourites array
                    setDeletedFavourites((prevState) => [...prevState, fav])
                }
            }
        }
    }

    useEffect(() => {
        if (updateMainDetails && updateExistingFav) {
            if (loadFavourite?.title && !editingDetails) {
                setNewFavouriteTitle(loadFavourite.title)
                setEditingDetails(true)
            }
            if (loadFavourite?.note && !editingDetails) {
                setNewFavouriteNote(loadFavourite.note)
                setEditingDetails(true)
            }
        }
    }, [
        updateExistingFav,
        updateMainDetails,
        loadFavourite,
        editingDetails,
        newFavouriteTitle,
        setNewFavouriteTitle,
        newFavouriteNote,
        setNewFavouriteNote,
    ])

    useEffect(() => {
        if (
            updateExistingFav &&
            !updateMainDetails &&
            (duplicateTitle || emptyTitle)
        ) {
            setNewFavouriteTitle('')
        }
        if (updateExistingFav && !updateMainDetails) {
            setEditingDetails(false)
        }
    }, [
        updateExistingFav,
        duplicateTitle,
        emptyTitle,
        updateMainDetails,
        setNewFavouriteTitle,
        setEditingDetails,
    ])

    const noFocus = {
        _focus: {
            boxShadow: 'none',
        },
    }

    return (
        <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexWrap="wrap"
            width="100%"
            minHeight={{
                base: '3rem',
                md: '10rem',
            }}
            pb={showManageFavourites ? '0px' : '40px'}
            transition="all ease 300ms"
            px={
                !showManageFavourites && {
                    base: '15px',
                    md: '45px',
                }
            }
        >
            <Flex
                alignItems="flex-end"
                justifyContent="center"
                flexDirection="column"
                gap={{
                    base: '10px',
                    md: '10px',
                }}
                width="100%"
                maxWidth={
                    showNotes === 'allNotes' && sortedFavouritesList?.length > 0
                        ? '100%'
                        : {
                              base: '100%',
                              md: '600px',
                              '2xl': '678px',
                          }
                }
                fontSize="0.8rem"
                transition="all ease 300ms"
                px={{
                    base: '0px',
                    md: showManageFavourites ? '17px' : '25px',
                }}
            >
                <Flex
                    alignItems="center"
                    justifyContent={{ base: 'flex-start', md: 'space-between' }}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap="15px"
                    width="100%"
                    fontSize="0.8rem"
                    pt={{ base: '6', md: '7' }}
                    pb="5px"
                >
                    {sortedFavouritesList?.length > 0 && (
                        <>
                            <Text
                                width="100%"
                                pb={{ base: '3', md: '1' }}
                                textAlign={{
                                    base: 'center',
                                    md:
                                        sortedFavouritesList?.length === 0
                                            ? 'center'
                                            : 'start',
                                }}
                                fontSize="0.85rem"
                                color="#868b8e"
                            >
                                {updateExistingFav
                                    ? 'Update an existing saved favourite Briefing'
                                    : 'Select from your list of saved favourite Briefings'}
                            </Text>
                            <Flex
                                alignItems="flex-end"
                                justifyContent={{
                                    base: 'center',
                                    smd: 'flex-end',
                                }}
                                flexDirection={{ base: 'row', md: 'row' }}
                                width={{ base: '100%', md: 'auto' }}
                                gap="15px"
                                fontSize="0.8rem"
                                pb="5px"
                                pl="2px"
                            >
                                <Button
                                    background="#F7FAFC"
                                    color="#a8b2c0"
                                    variant="outline"
                                    fontSize="1rem"
                                    fontWeight="500"
                                    height={{ base: '26px', md: '24px' }}
                                    width={'136px'}
                                    style={{
                                        minWidth: '136px',
                                    }}
                                    justifyContent="initial"
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    borderRadius="20px"
                                    {...noFocus}
                                    _active={{
                                        background: '#F2F7FA',
                                        boxShadow:
                                            'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                    }}
                                    _hover={{
                                        background: '#F2F7FA',
                                    }}
                                >
                                    <Box
                                        mt="0px"
                                        ml="-1px"
                                        px="17px"
                                        height={{ base: '22px', md: '20px' }}
                                        width="44px"
                                        minWidth="44px"
                                        background="white"
                                        borderRadius="20px"
                                        bg="#ffffff"
                                        boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                                        transform={
                                            showNotes === 'allNotes'
                                                ? 'translateX(200%)'
                                                : showNotes === 'refNote'
                                                ? 'translateX(100%)'
                                                : 'translateX(0%)'
                                        }
                                        transition="all ease 300ms"
                                    ></Box>
                                    <Flex
                                        zIndex="1"
                                        justifyContent="space-evenly"
                                        marginLeft="-44px"
                                        width="132px"
                                    >
                                        <Tooltip
                                            label={'Hide all details'}
                                            hasArrow
                                            borderRadius="7px"
                                            height="26px"
                                            fontSize="0.7rem"
                                            alignItems="center"
                                            display={
                                                isMobile || !isDesktop
                                                    ? 'none'
                                                    : 'flex'
                                            }
                                            isDisabled={isMobile || !isDesktop}
                                            placement="top"
                                            m="5px"
                                            mr="2px"
                                            color="light.10"
                                            bg="dark.10"
                                        >
                                            <Flex
                                                zIndex="1"
                                                height={{
                                                    base: '26px',
                                                    md: '24px',
                                                }}
                                                paddingLeft="2px"
                                                justifyContent="center"
                                                alignItems="center"
                                                width="44px"
                                                opacity={
                                                    !showNotes ? '1' : '0.5'
                                                }
                                                color={
                                                    !showNotes
                                                        ? 'light.200'
                                                        : 'light.100'
                                                }
                                                transition="all ease 300ms"
                                                onClick={() => {
                                                    setShowNotes(null)
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                }}
                                            >
                                                <Icon
                                                    as={FavouritesNoList}
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </Flex>
                                        </Tooltip>

                                        <Tooltip
                                            label={'Show reference note'}
                                            hasArrow
                                            borderRadius="7px"
                                            height="26px"
                                            fontSize="0.7rem"
                                            alignItems="center"
                                            display={
                                                isMobile || !isDesktop
                                                    ? 'none'
                                                    : 'flex'
                                            }
                                            isDisabled={isMobile || !isDesktop}
                                            placement="top"
                                            m="5px"
                                            mr="2px"
                                            color="light.10"
                                            bg="dark.10"
                                        >
                                            <Flex
                                                zIndex="1"
                                                height={{
                                                    base: '26px',
                                                    md: '24px',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                width="44px"
                                                opacity={
                                                    showNotes === 'refNote'
                                                        ? '1'
                                                        : '0.5'
                                                }
                                                color={
                                                    showNotes === 'refNote'
                                                        ? 'light.200'
                                                        : 'light.100'
                                                }
                                                transition="all ease 300ms"
                                                onClick={() => {
                                                    setShowNotes('refNote')
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                }}
                                            >
                                                <Icon
                                                    as={FavouritesPartList}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </Tooltip>

                                        <Tooltip
                                            label={'Show all details'}
                                            hasArrow
                                            borderRadius="7px"
                                            height="26px"
                                            fontSize="0.7rem"
                                            alignItems="center"
                                            display={
                                                isMobile || !isDesktop
                                                    ? 'none'
                                                    : 'flex'
                                            }
                                            isDisabled={isMobile || !isDesktop}
                                            placement="top"
                                            m="5px"
                                            mr="2px"
                                            color="light.10"
                                            bg="dark.10"
                                        >
                                            <Flex
                                                zIndex="1"
                                                height={{
                                                    base: '26px',
                                                    md: '24px',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                width="44px"
                                                opacity={
                                                    showNotes === 'allNotes'
                                                        ? '1'
                                                        : '0.5'
                                                }
                                                color={
                                                    showNotes === 'allNotes'
                                                        ? 'light.200'
                                                        : 'light.100'
                                                }
                                                transition="all ease 300ms"
                                                onClick={() => {
                                                    setShowNotes('allNotes')
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                }}
                                            >
                                                <Icon
                                                    as={FavouritesFullList}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </Tooltip>
                                    </Flex>
                                </Button>

                                {briefingStandard && (
                                    <Tooltip
                                        label={
                                            deleteExistingFav
                                                ? 'Cancel delete'
                                                : 'Select favourites to delete'
                                        }
                                        hasArrow
                                        borderRadius="7px"
                                        height="26px"
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        display={
                                            isMobile || !isDesktop
                                                ? 'none'
                                                : 'flex'
                                        }
                                        isDisabled={isMobile || !isDesktop}
                                        placement="top"
                                        m="5px"
                                        mr="2px"
                                        color="light.10"
                                        bg="dark.10"
                                    >
                                        <Button
                                            variant="outline"
                                            fontWeight="500"
                                            fontSize="1.1rem"
                                            height={{
                                                base: '26px',
                                                md: '24px',
                                            }}
                                            width={'44px'}
                                            style={{
                                                minWidth: '44px',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                            p="3px"
                                            borderRadius="20px"
                                            boxShadow={
                                                deleteExistingFav &&
                                                'inset 0px 0px 5px -2px rgba(0,0,0,0.2)'
                                            }
                                            color={
                                                deleteExistingFav
                                                    ? 'white'
                                                    : '#8b949b'
                                            }
                                            borderColor={
                                                deleteExistingFav
                                                    ? 'red.500'
                                                    : 'gray.200'
                                            }
                                            background={
                                                deleteExistingFav
                                                    ? 'red.500'
                                                    : 'white'
                                            }
                                            {...noFocus}
                                            onClick={() => {
                                                setLoadFavourite(null)
                                                setDeletedFavourites([])
                                                setCurrentUrl(false)
                                                setDeleteExistingFav(
                                                    (prevState) => !prevState
                                                )
                                            }}
                                            _active={{
                                                background: deleteExistingFav
                                                    ? 'red.600'
                                                    : '#F2F7FA',
                                                boxShadow:
                                                    'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                            }}
                                            _hover={{
                                                color: deleteExistingFav
                                                    ? 'white'
                                                    : 'light.100',
                                                background: deleteExistingFav
                                                    ? 'red.600'
                                                    : '#F2F7FA',
                                                borderColor: deleteExistingFav
                                                    ? 'red.600'
                                                    : 'gray.300',
                                            }}
                                        >
                                            <Icon
                                                as={
                                                    deleteExistingFav
                                                        ? TbTrashX
                                                        : TbTrash
                                                }
                                            />
                                        </Button>
                                    </Tooltip>
                                )}
                            </Flex>
                        </>
                    )}
                </Flex>
                <Flex
                    width="100%"
                    border="1px solid"
                    borderColor="gray.200"
                    bg="gray.50"
                    px={{ base: '15px', md: '20px' }}
                    pt={{
                        base: '15px',
                        md: '25px',
                    }}
                    pb="27px"
                    gap={{
                        base: '20px',
                        md: '18px',
                    }}
                    mb={
                        showManageFavourites &&
                        !sortedFavouritesList?.length &&
                        '25px'
                    }
                    alignItems="flex-start"
                    justifyContent="center"
                    flexDirection="column"
                >
                    {!updatingFavourites &&
                        !showFavourites &&
                        sortedFavouritesList?.length > 0 &&
                        (nearingFavouritesLimit ||
                            reachedFavouritesLimit ||
                            (duplicateUrl && updateExistingFav)) && (
                            <Flex
                                width="100%"
                                gap={{
                                    base: '15px',
                                    md: '10px',
                                }}
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                pb={{
                                    base: '0px',
                                    md: '5px',
                                }}
                                mt="-5px"
                            >
                                {(nearingFavouritesLimit ||
                                    reachedFavouritesLimit) && (
                                    <Flex
                                        px="5px"
                                        gap={{
                                            base: '0px',
                                            smd: '2px',
                                        }}
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                        }}
                                    >
                                        <Text
                                            pl={{
                                                base: '0px',
                                                smd: '20px',
                                            }}
                                            color="gray.400"
                                            fontWeight="400"
                                            fontSize="0.8rem"
                                            textAlign="center"
                                        >
                                            {`You ${
                                                reachedFavouritesLimit
                                                    ? 'have reached'
                                                    : 'are reaching'
                                            } a maximum limit of ${maxLimitFavourites} saved favourite Briefings`}
                                        </Text>
                                        <Popover placement="top">
                                            <PopoverTrigger>
                                                <Flex
                                                    width="30px"
                                                    height="28px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    borderRadius="10px"
                                                    color="gray.400"
                                                    opacity="0.8"
                                                    cursor="pointer"
                                                >
                                                    <BsQuestionCircle fontSize="0.9rem" />
                                                </Flex>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                color="white"
                                                bg="gray.600"
                                                px="5px"
                                                borderColor="gray.600"
                                                borderRadius="20px"
                                                fontSize="0.75rem"
                                                alignItems="center"
                                                textAlign="center"
                                                lineHeight="1.4"
                                                fontFamily="Open Sans"
                                            >
                                                <PopoverArrow bg="gray.600" />
                                                <PopoverBody>
                                                    <Text>
                                                        Currently there is a
                                                        limit of up to{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '700',
                                                            }}
                                                        >
                                                            5
                                                        </span>{' '}
                                                        Briefings that can be
                                                        added to your list of
                                                        saved Favourites.
                                                    </Text>
                                                    <Text pt="5px">
                                                        This limit may increase
                                                        in future.
                                                    </Text>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Flex>
                                )}
                                {duplicateUrl && updateExistingFav && (
                                    <Text
                                        color="warning.100"
                                        fontWeight="500"
                                        fontSize="0.8rem"
                                        width="100%"
                                        textAlign="center"
                                    >
                                        You already have a saved favourite
                                        Briefing with this criteria
                                    </Text>
                                )}
                            </Flex>
                        )}

                    {sortedFavouritesList?.length > 0 ? (
                        sortedFavouritesList.map((fav, i) => {
                            const isChecked =
                                Boolean(favouriteSelected(fav.title)) || false
                            const briefingComponents = {
                                ...fav.params,
                                aaw: fav.aaw,
                            }
                            const existingUrl =
                                duplicateUrl && updatedParams?.url === fav.url

                            const expandSelected =
                                updateExistingFav &&
                                isChecked &&
                                !deleteExistingFav

                            const invalidTitle = Boolean(
                                sortedFavouritesList
                                    .filter((i) => i.title !== fav.title)
                                    .map((i) => i.title)
                                    .includes(newFavouriteTitle) ||
                                    (emptyTitle && updateMainDetails) ||
                                    duplicateTitle
                            )

                            const updatedAerodromes =
                                isChecked &&
                                updateExistingFav &&
                                updatingExcludedItems &&
                                (updatedParams?.excluded ||
                                    briefingComponents?.excluded ||
                                    filteredExcludedAerodromes) &&
                                !(
                                    (updatedParams?.excluded ||
                                        briefingComponents.excluded) ===
                                    filteredExcludedAerodromes.join(',')
                                )

                            const updatedAws =
                                isChecked &&
                                updateExistingFav &&
                                updatingExcludedItems &&
                                (updatedParams?.excludedaws ||
                                    briefingComponents?.excludedaws ||
                                    filteredExcludedAws) &&
                                !(
                                    (updatedParams?.excludedaws ||
                                        briefingComponents.excludedaws) ===
                                    filteredExcludedAws.join(',')
                                )

                            return (
                                <Flex
                                    key={`${i}-${fav.title}`}
                                    overflow="hidden"
                                    zIndex="2"
                                    minHeight={
                                        showNotes === 'allNotes' ||
                                        expandSelected
                                            ? '160px'
                                            : showNotes === 'refNote'
                                            ? '60px'
                                            : '40px'
                                    }
                                    height={'100%'}
                                    w="100%"
                                    pl="7px"
                                    pr="7px"
                                    pt="6px"
                                    pb="6px"
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                    borderRadius="20px"
                                    flexDirection="row"
                                    transition="all ease 250ms"
                                    border="1px solid"
                                    borderColor={
                                        isChecked && deleteExistingFav
                                            ? 'red.500'
                                            : isChecked && updateExistingFav
                                            ? 'cyan.400'
                                            : isChecked && existingUrl
                                            ? 'gray.300'
                                            : isChecked
                                            ? 'light.200'
                                            : 'white'
                                    }
                                    bg={
                                        existingUrl && !deleteExistingFav
                                            ? 'rgba(255,255,255,0.35)'
                                            : 'light.10'
                                    }
                                    boxShadow={{
                                        base: '0px 7px 25px -7px rgba(0, 15, 35, 0.3)',
                                        md: '0px 7px 25px -7px rgba(0, 15, 35, 0.25)',
                                    }}
                                    gap="10px"
                                    onClick={() => {
                                        if (!(updateMainDetails && isChecked)) {
                                            if (!isChecked) {
                                                setUpdateMainDetails(false)
                                            }
                                            handleItemClick(fav)
                                            if (
                                                !showFavourites &&
                                                !isChecked &&
                                                existingUrl &&
                                                !deleteExistingFav
                                            ) {
                                                setCurrentUrl(true)
                                            } else if (
                                                !showFavourites &&
                                                isChecked &&
                                                existingUrl
                                            ) {
                                                setCurrentUrl(false)
                                            } else if (!showFavourites) {
                                                setCurrentUrl(false)
                                            }
                                        }
                                    }}
                                    _hover={{
                                        cursor: 'pointer',
                                        bg:
                                            (!existingUrl ||
                                                deleteExistingFav ||
                                                updateExistingFav) &&
                                            'light.10',
                                        opacity: '1',
                                        transform: {
                                            base: 'none',
                                            lg:
                                                (!existingUrl ||
                                                    deleteExistingFav ||
                                                    updateExistingFav) &&
                                                `translateY(-1px) translateX(0px) scale(1.01)`,
                                        },
                                        boxShadow:
                                            (!existingUrl ||
                                                deleteExistingFav) &&
                                            '0px 12px 30px -12px rgba(0, 15, 35, 0.5)',
                                    }}
                                >
                                    <Flex
                                        w="100%"
                                        minHeight="26px"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        flexDirection="row"
                                        transition="all ease 300ms"
                                        gap="5px"
                                        fontSize={{
                                            base: '0.85rem',
                                            md: '0.9rem',
                                        }}
                                    >
                                        <Flex
                                            width="31px"
                                            minWidth="31px"
                                            height="31px"
                                            mt="-2px"
                                            mb="-3px"
                                            minHeight="26px"
                                            justifyContent="center"
                                            alignItems="center"
                                            flexDirection="row"
                                            transition="all ease 300ms"
                                            fontWeight="600"
                                            borderRadius="20px"
                                        >
                                            <Icon
                                                mt="-2px"
                                                width="31px"
                                                minWidth="31px"
                                                height="30px"
                                                color="gray.200"
                                                as={FavouriteIcon}
                                            />
                                            <Text
                                                color="gray.500"
                                                mt="2px"
                                                ml="-31px"
                                                width="31px"
                                                textAlign="center"
                                                fontSize={{
                                                    base: '0.7rem',
                                                    md: '0.7rem',
                                                }}
                                            >
                                                {i + 1}
                                            </Text>
                                        </Flex>
                                        <Flex
                                            w="100%"
                                            minHeight="26px"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            flexDirection="column"
                                            transition="all ease 300ms"
                                            pl="5px"
                                            fontSize={{
                                                base: '0.85rem',
                                                md: '0.9rem',
                                            }}
                                        >
                                            <Flex
                                                w="100%"
                                                minHeight="22px"
                                                pt="2px"
                                                justifyContent="space-between"
                                                alignItems={{
                                                    base: 'flex-start',
                                                    md: 'flex-start',
                                                }}
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'row',
                                                }}
                                                gap={{
                                                    base: '0',
                                                    md: '5px',
                                                }}
                                            >
                                                <Flex
                                                    w="100%"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    gap="10px"
                                                    flex="1"
                                                >
                                                    {expandSelected && (
                                                        <Flex
                                                            alignItems="flex-start"
                                                            gap="10px"
                                                            flex="1"
                                                        >
                                                            <IconButton
                                                                variant="outline"
                                                                borderRadius="10px"
                                                                mt="5px"
                                                                size="sm"
                                                                pl={
                                                                    !updateMainDetails &&
                                                                    '2px'
                                                                }
                                                                pb={
                                                                    !updateMainDetails &&
                                                                    '1px'
                                                                }
                                                                color={
                                                                    updateMainDetails
                                                                        ? 'light.200'
                                                                        : 'gray.600'
                                                                }
                                                                _hover={{
                                                                    color: updateMainDetails
                                                                        ? 'light.200'
                                                                        : 'gray.800',
                                                                }}
                                                                icon={
                                                                    updateMainDetails ? (
                                                                        <IoClose fontSize="1.5rem" />
                                                                    ) : (
                                                                        <FiEdit fontSize="1.1rem" />
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    setUpdateMainDetails(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                            />
                                                            {updateMainDetails &&
                                                                !editingDetails && (
                                                                    <Flex
                                                                        width="100%"
                                                                        overflow="hidden"
                                                                        transition="height ease 250ms"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        height="42px"
                                                                    >
                                                                        <Spinner
                                                                            color="light.200"
                                                                            emptyColor="rgba(255,255,255,0.25)"
                                                                            thickness="2px"
                                                                            speed="0.45s"
                                                                            boxSize="24px"
                                                                            ml="2px"
                                                                        />
                                                                    </Flex>
                                                                )}
                                                        </Flex>
                                                    )}

                                                    <Flex
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        flexDirection="column"
                                                        gap="0px"
                                                        width="100%"
                                                        display={
                                                            updateMainDetails &&
                                                            isChecked
                                                                ? 'none'
                                                                : 'flex'
                                                        }
                                                    >
                                                        <Text
                                                            color={
                                                                deleteExistingFav &&
                                                                isChecked
                                                                    ? 'red.500'
                                                                    : updateExistingFav &&
                                                                      isChecked
                                                                    ? 'cyan.400'
                                                                    : existingUrl &&
                                                                      isChecked
                                                                    ? 'warning.100'
                                                                    : isChecked
                                                                    ? 'light.200'
                                                                    : 'gray.600'
                                                            }
                                                            transition="all ease 250ms"
                                                            fontWeight="600"
                                                            pr="15px"
                                                            pt="1px"
                                                        >
                                                            {updateExistingFav &&
                                                            isChecked &&
                                                            newFavouriteTitle &&
                                                            newFavouriteTitle.length
                                                                ? newFavouriteTitle
                                                                : fav.title}
                                                        </Text>
                                                        <Text
                                                            minHeight={
                                                                showNotes ||
                                                                expandSelected
                                                                    ? '20px'
                                                                    : '0px'
                                                            }
                                                            height={
                                                                showNotes ||
                                                                expandSelected
                                                                    ? 'auto'
                                                                    : '0px'
                                                            }
                                                            opacity={
                                                                showNotes ||
                                                                expandSelected
                                                                    ? fav.note
                                                                        ? '1'
                                                                        : '0.7'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                showNotes ||
                                                                expandSelected
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            fontStyle={
                                                                fav.note
                                                                    ? 'initial'
                                                                    : 'italic'
                                                            }
                                                            transition="all ease 300ms"
                                                            color={'gray.400'}
                                                            fontWeight={
                                                                fav.note
                                                                    ? '400'
                                                                    : '300'
                                                            }
                                                            fontSize={{
                                                                base: '0.8rem',
                                                                md: '0.8rem',
                                                            }}
                                                        >
                                                            {updateExistingFav &&
                                                            isChecked &&
                                                            newFavouriteNote &&
                                                            newFavouriteNote.length
                                                                ? newFavouriteNote
                                                                : fav.note
                                                                ? fav.note
                                                                : 'No reference note'}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                {existingUrl && (
                                                    <Text
                                                        mr={{
                                                            base:
                                                                updateExistingFav ||
                                                                deleteExistingFav
                                                                    ? '0px'
                                                                    : '-28px',
                                                            md:
                                                                updateExistingFav ||
                                                                deleteExistingFav
                                                                    ? '0px'
                                                                    : '-25px',
                                                        }}
                                                        minWidth={
                                                            isChecked &&
                                                            updateMainDetails
                                                                ? '0px'
                                                                : '100px'
                                                        }
                                                        maxWidth={
                                                            isChecked &&
                                                            updateMainDetails
                                                                ? '0px'
                                                                : '100px'
                                                        }
                                                        opacity={
                                                            isChecked &&
                                                            updateMainDetails
                                                                ? '0'
                                                                : '1'
                                                        }
                                                        overflow="hidden"
                                                        minHeight="20px"
                                                        height="20px"
                                                        color={
                                                            isChecked &&
                                                            !deleteExistingFav &&
                                                            !updateExistingFav
                                                                ? 'warning.100'
                                                                : 'gray.400'
                                                        }
                                                        fontWeight={
                                                            isChecked &&
                                                            !deleteExistingFav &&
                                                            !updateExistingFav
                                                                ? '500'
                                                                : '300'
                                                        }
                                                        transition="all ease 250ms"
                                                        pt="2px"
                                                        textAlign={{
                                                            base: 'left',
                                                            md: 'right',
                                                        }}
                                                        fontStyle="italic"
                                                        fontSize={{
                                                            base: '0.75rem',
                                                            md: '0.75rem',
                                                        }}
                                                    >
                                                        Current Briefing
                                                    </Text>
                                                )}
                                            </Flex>

                                            {updateMainDetails &&
                                                expandSelected && (
                                                    <Flex
                                                        width={{
                                                            base: 'calc(100% + 55px)',
                                                            md: 'calc(100% - 45px)',
                                                        }}
                                                        ml={{
                                                            base: '-30px',
                                                            md: '43px',
                                                        }}
                                                        mt={{
                                                            base: existingUrl
                                                                ? '-10px'
                                                                : '10px',
                                                            md: '-36px',
                                                        }}
                                                        height="100%"
                                                        minHeight={{
                                                            base: '185px',
                                                            md: '153px',
                                                        }}
                                                        overflow="hidden"
                                                        transition="height ease 250ms"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            gap={{
                                                                base: '15px',
                                                                md: '10px',
                                                            }}
                                                            pt="2px"
                                                            pb="10px"
                                                            alignItems="center"
                                                            justifyContent="flex-start"
                                                            flexDirection="column"
                                                            fontSize="0.75rem"
                                                            transition="all ease 350ms"
                                                            opacity={
                                                                updateMainDetails &&
                                                                !editingDetails
                                                                    ? '0'
                                                                    : '1'
                                                            }
                                                        >
                                                            <Flex
                                                                width="100%"
                                                                gap="5px"
                                                                alignItems={{
                                                                    base: 'flex-start',
                                                                    md: 'flex-start',
                                                                }}
                                                                justifyContent="flex-start"
                                                                flexDirection="column"
                                                            >
                                                                <Text
                                                                    color={{
                                                                        base: 'gray.500',
                                                                        md: 'gray.600',
                                                                    }}
                                                                    fontWeight="600"
                                                                    minWidth="160px"
                                                                >
                                                                    Update
                                                                    Title:
                                                                </Text>
                                                                <FormControl
                                                                    isInvalid={
                                                                        invalidTitle
                                                                    }
                                                                >
                                                                    <Input
                                                                        isRequired
                                                                        maxWidth={{
                                                                            base: '100%',
                                                                            md: '100%',
                                                                        }}
                                                                        cursor={
                                                                            'pointer'
                                                                        }
                                                                        color={
                                                                            'light.100'
                                                                        }
                                                                        _placeholder={{
                                                                            color: 'gray.300',
                                                                        }}
                                                                        variant="primary"
                                                                        _focus={{
                                                                            borderColor:
                                                                                invalidTitle
                                                                                    ? 'red.500'
                                                                                    : 'brand.200',
                                                                        }}
                                                                        fontSize={{
                                                                            base: '0.85rem',
                                                                            md: '0.8rem',
                                                                        }}
                                                                        height="8"
                                                                        size="sm"
                                                                        id="last-name"
                                                                        placeholder="Title of your new saved Favourite Briefing"
                                                                        border="1px solid #cbd5e0"
                                                                        borderColor={
                                                                            invalidTitle
                                                                                ? 'red.500'
                                                                                : '#cbd5e0'
                                                                        }
                                                                        borderRadius={{
                                                                            base: '10px',
                                                                            md: '10px',
                                                                        }}
                                                                        value={
                                                                            newFavouriteTitle
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setNewFavouriteTitle(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                    {!updatingFavourites &&
                                                                        duplicateTitle && (
                                                                            <FormErrorMessage fontSize="0.8rem">
                                                                                The
                                                                                title
                                                                                cannot
                                                                                be
                                                                                the
                                                                                same
                                                                                as
                                                                                another
                                                                                saved
                                                                                favourite.
                                                                            </FormErrorMessage>
                                                                        )}
                                                                    {!updatingFavourites &&
                                                                        emptyTitle && (
                                                                            <FormErrorMessage fontSize="0.8rem">
                                                                                The
                                                                                title
                                                                                cannot
                                                                                be
                                                                                empty.
                                                                            </FormErrorMessage>
                                                                        )}
                                                                </FormControl>
                                                            </Flex>
                                                            <Flex
                                                                pt={{
                                                                    base: '0px',
                                                                    md: '5px',
                                                                }}
                                                                width="100%"
                                                                gap={{
                                                                    base: '10px',
                                                                    md: '5px',
                                                                }}
                                                                alignItems={{
                                                                    base: 'flex-start',
                                                                    md: 'flex-start',
                                                                }}
                                                                justifyContent="flex-start"
                                                                flexDirection="column"
                                                            >
                                                                <Flex
                                                                    gap="2px"
                                                                    pr="1px"
                                                                    width="100%"
                                                                    justifyContent="space-between"
                                                                    alignItems="flex-end"
                                                                    flexDirection="row"
                                                                >
                                                                    <Text
                                                                        fontWeight="600"
                                                                        color={{
                                                                            base: 'gray.500',
                                                                            md: 'gray.600',
                                                                        }}
                                                                    >
                                                                        Update
                                                                        Reference
                                                                        Note:
                                                                    </Text>
                                                                    <Text
                                                                        textAlign="right"
                                                                        fontSize={{
                                                                            base: '0.7rem',
                                                                            md: '0.7rem',
                                                                        }}
                                                                        fontWeight="300"
                                                                        color="gray.400"
                                                                        fontStyle="italic"
                                                                    >
                                                                        Optional
                                                                    </Text>
                                                                </Flex>
                                                                <Textarea
                                                                    variant="primary"
                                                                    minHeight={{
                                                                        base: '5rem',
                                                                        md: '3.3rem',
                                                                    }}
                                                                    width="100%"
                                                                    cursor={
                                                                        'pointer'
                                                                    }
                                                                    color={
                                                                        'light.100'
                                                                    }
                                                                    _placeholder={{
                                                                        color: 'gray.300',
                                                                    }}
                                                                    fontSize={{
                                                                        base: '0.85rem',
                                                                        md: '0.8rem',
                                                                    }}
                                                                    height="8"
                                                                    size="sm"
                                                                    id="last-name"
                                                                    placeholder={`i.e. "Cross-country flight NZPP - NZWB - NZCH"`}
                                                                    backgroundColor="light.10"
                                                                    backgroundClip="padding-box"
                                                                    border="1px solid"
                                                                    transition="border-color .15s ease-in-out,box-shadow .15s ease-in-out"
                                                                    borderColor="#cbd5e0"
                                                                    borderRadius={{
                                                                        base: '10px',
                                                                        md: '10px',
                                                                    }}
                                                                    background="light.10"
                                                                    _focus={{
                                                                        borderColor:
                                                                            'brand.200',
                                                                    }}
                                                                    value={
                                                                        newFavouriteNote
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setNewFavouriteNote(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                )}

                                            <Flex
                                                width={{
                                                    base: 'calc(100% + 55px)',
                                                    md: 'calc(100% + 25px)',
                                                }}
                                                borderTop="1px solid #e9edf3"
                                                minHeight={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? '60px'
                                                        : '0px'
                                                }
                                                flexDirection="column"
                                                height={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? 'auto'
                                                        : '0px'
                                                }
                                                opacity={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? '1'
                                                        : '0'
                                                }
                                                visibility={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                transition="all ease 300ms"
                                                color="gray.500"
                                                fontWeight="300"
                                                mt={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? '10px'
                                                        : '0px'
                                                }
                                                pt={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? '13px'
                                                        : '0px'
                                                }
                                                pb={
                                                    showNotes === 'allNotes' ||
                                                    expandSelected
                                                        ? '9px'
                                                        : '0px'
                                                }
                                                gap={{
                                                    base: '13px',
                                                    md: '9px',
                                                }}
                                                lineHeight="1.4"
                                                ml={{
                                                    base: '-30px',
                                                    md: '0px',
                                                }}
                                                fontSize={{
                                                    base: '0.8rem',
                                                    md: '0.75rem',
                                                }}
                                            >
                                                {/* Selected Briefing Areas */}
                                                {briefingComponents?.source && (
                                                    <SourceComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                )}

                                                {/*  Selected AAW Areas */}
                                                {(briefingComponents?.selected ||
                                                    briefingComponents?.coords) && (
                                                    <SelectedComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                )}

                                                {/*  Custom Coords - UNUSED */}
                                                {/* {briefingComponents?.coords && (
                                                    <CoordsComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                )} */}

                                                {/* Selected Areas */}
                                                <ContentsComponent
                                                    updateExistingFav={
                                                        updateExistingFav
                                                    }
                                                    isChecked={isChecked}
                                                    updatedParams={
                                                        updatedParams
                                                    }
                                                    briefingComponents={
                                                        briefingComponents
                                                    }
                                                    menuContents={menuContents}
                                                    formattedKeys={
                                                        formattedKeys
                                                    }
                                                />

                                                {/*  Period to */}
                                                {briefingComponents?.time && (
                                                    <PeriodToComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                )}

                                                {/*  Maximum Altitude */}
                                                {briefingComponents?.maxalt && (
                                                    <MaxaltComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                )}

                                                {/*  Excluded Aerodromes */}
                                                <Flex
                                                    gap="10px"
                                                    width="100%"
                                                    alignItems="flex-start"
                                                    justifyContent="space-between"
                                                    flexDirection="row"
                                                >
                                                    <AerodromesComponent
                                                        updateExistingFav={
                                                            updateExistingFav
                                                        }
                                                        isChecked={isChecked}
                                                        updatedParams={
                                                            updatedParams
                                                        }
                                                        briefingComponents={
                                                            briefingComponents
                                                        }
                                                    />
                                                    {updateExistingFav &&
                                                        isChecked && (
                                                            <ExcludedItemsSelectButton
                                                                type={
                                                                    'aerodromes'
                                                                }
                                                                selectItem={
                                                                    selectAerodromes
                                                                }
                                                                otherItem={
                                                                    selectAws
                                                                }
                                                                setSelectItem={
                                                                    setSelectAerodromes
                                                                }
                                                                setOtherItem={
                                                                    setSelectAws
                                                                }
                                                                updatedItem={
                                                                    updatedAerodromes
                                                                }
                                                                setUpdatingExcludedItems={
                                                                    setUpdatingExcludedItems
                                                                }
                                                            />
                                                        )}
                                                </Flex>
                                                {updateExistingFav &&
                                                    isChecked && (
                                                        <Collapse
                                                            animateOpacity
                                                            unmountOnExit
                                                            in={
                                                                selectAerodromes
                                                            }
                                                            position={
                                                                'relative'
                                                            }
                                                            style={{
                                                                overflow:
                                                                    'hidden',
                                                                opacity:
                                                                    selectAerodromes
                                                                        ? '1'
                                                                        : '0',
                                                                width: '100%',
                                                                minWidth:
                                                                    '100%',
                                                            }}
                                                        >
                                                            <Flex
                                                                width="100%"
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                background="light.30"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                gap="20px"
                                                                mb="15px"
                                                                px="15px"
                                                                py="10px"
                                                            >
                                                                <Text
                                                                    textAlign="center"
                                                                    fontSize={{
                                                                        base: '0.75rem',
                                                                        smd: '0.8rem',
                                                                    }}
                                                                    color="gray.500"
                                                                    fontWeight="300"
                                                                    lineHeight="1.2"
                                                                    whiteSpace="pre-line"
                                                                >
                                                                    Select{' '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '500',
                                                                        }}
                                                                    >
                                                                        Aerodromes
                                                                    </span>{' '}
                                                                    below to
                                                                    reinstate or
                                                                    exclude in
                                                                    your
                                                                    briefing
                                                                </Text>
                                                                <ExcludedAerodromes
                                                                    briefingData={
                                                                        briefingData
                                                                    }
                                                                    filteredExcludedAerodromes={
                                                                        filteredExcludedAerodromes
                                                                    }
                                                                    setExcludedAerodromes={
                                                                        setExcludedAerodromes
                                                                    }
                                                                    includedAerodromes={
                                                                        includedAerodromes
                                                                    }
                                                                    selectAerodromes={
                                                                        selectAerodromes
                                                                    }
                                                                    isPremium={
                                                                        isPremium
                                                                    }
                                                                    selectAws={
                                                                        selectAws
                                                                    }
                                                                    setSelectAerodromes={
                                                                        setSelectAerodromes
                                                                    }
                                                                    setSelectAws={
                                                                        setSelectAws
                                                                    }
                                                                    isBriefingOnly={
                                                                        isBriefingOnly
                                                                    }
                                                                    showManageFavourites={
                                                                        showManageFavourites
                                                                    }
                                                                    updateExistingFav={
                                                                        updateExistingFav
                                                                    }
                                                                    setUpdatingExcludedItems={
                                                                        setUpdatingExcludedItems
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Collapse>
                                                    )}

                                                {/* Excluded AWS */}
                                                {!isPremium && (
                                                    <>
                                                        <Flex
                                                            gap="10px"
                                                            width="100%"
                                                            alignItems="flex-start"
                                                            justifyContent="space-between"
                                                            flexDirection="row"
                                                        >
                                                            <AwsComponent
                                                                updateExistingFav={
                                                                    updateExistingFav
                                                                }
                                                                isChecked={
                                                                    isChecked
                                                                }
                                                                updatedParams={
                                                                    updatedParams
                                                                }
                                                                briefingComponents={
                                                                    briefingComponents
                                                                }
                                                            />
                                                            {updateExistingFav &&
                                                                isChecked && (
                                                                    <ExcludedItemsSelectButton
                                                                        type={
                                                                            'aws'
                                                                        }
                                                                        selectItem={
                                                                            selectAws
                                                                        }
                                                                        otherItem={
                                                                            selectAerodromes
                                                                        }
                                                                        setSelectItem={
                                                                            setSelectAws
                                                                        }
                                                                        setOtherItem={
                                                                            setSelectAerodromes
                                                                        }
                                                                        updatedItem={
                                                                            updatedAws
                                                                        }
                                                                        setUpdatingExcludedItems={
                                                                            setUpdatingExcludedItems
                                                                        }
                                                                    />
                                                                )}
                                                        </Flex>

                                                        {updateExistingFav &&
                                                            isChecked && (
                                                                <Collapse
                                                                    animateOpacity
                                                                    unmountOnExit
                                                                    in={
                                                                        selectAws
                                                                    }
                                                                    position={
                                                                        'relative'
                                                                    }
                                                                    style={{
                                                                        overflow:
                                                                            'hidden',
                                                                        opacity:
                                                                            selectAws
                                                                                ? '1'
                                                                                : '0',
                                                                        width: '100%',
                                                                        minWidth:
                                                                            '100%',
                                                                    }}
                                                                >
                                                                    <Flex
                                                                        width="100%"
                                                                        border="1px solid"
                                                                        borderColor="gray.200"
                                                                        background="light.30"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        gap="20px"
                                                                        mb="15px"
                                                                        px="15px"
                                                                        py="10px"
                                                                    >
                                                                        <Text
                                                                            textAlign="center"
                                                                            fontSize={{
                                                                                base: '0.75rem',
                                                                                smd: '0.8rem',
                                                                            }}
                                                                            color="gray.500"
                                                                            fontWeight="300"
                                                                            lineHeight="1.2"
                                                                            whiteSpace="pre-line"
                                                                        >
                                                                            Select{' '}
                                                                            <span
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '500',
                                                                                }}
                                                                            >
                                                                                Automatic
                                                                                Weather
                                                                                Stations
                                                                            </span>{' '}
                                                                            below
                                                                            to
                                                                            reinstate
                                                                            or
                                                                            exclude
                                                                            in
                                                                            your
                                                                            briefing
                                                                        </Text>
                                                                        <ExcludedAws
                                                                            isBriefingOnly={
                                                                                isBriefingOnly
                                                                            }
                                                                            setExcludedAws={
                                                                                setExcludedAws
                                                                            }
                                                                            briefingData={
                                                                                briefingData
                                                                            }
                                                                            filteredExcludedAerodromes={
                                                                                filteredExcludedAerodromes
                                                                            }
                                                                            filteredExcludedAws={
                                                                                filteredExcludedAws
                                                                            }
                                                                            includedAws={
                                                                                includedAws
                                                                            }
                                                                            selectAerodromes={
                                                                                selectAerodromes
                                                                            }
                                                                            setSelectAerodromes={
                                                                                setSelectAerodromes
                                                                            }
                                                                            selectAws={
                                                                                selectAws
                                                                            }
                                                                            setSelectAws={
                                                                                setSelectAws
                                                                            }
                                                                            isPremium={
                                                                                isPremium
                                                                            }
                                                                            showManageFavourites={
                                                                                showManageFavourites
                                                                            }
                                                                            updateExistingFav={
                                                                                updateExistingFav
                                                                            }
                                                                            setUpdatingExcludedItems={
                                                                                setUpdatingExcludedItems
                                                                            }
                                                                        />
                                                                    </Flex>
                                                                </Collapse>
                                                            )}
                                                    </>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Checkbox
                                        variant={
                                            deleteExistingFav
                                                ? 'delete'
                                                : updateExistingFav
                                                ? 'update'
                                                : 'favourites'
                                        }
                                        _control={{
                                            background:
                                                updateExistingFav &&
                                                !deleteExistingFav
                                                    ? 'cyan.400 !important'
                                                    : 'red.500 !important',
                                        }}
                                        opacity={
                                            existingUrl &&
                                            !deleteExistingFav &&
                                            !updateExistingFav
                                                ? '0'
                                                : '1'
                                        }
                                        visibility={
                                            existingUrl &&
                                            !deleteExistingFav &&
                                            !updateExistingFav
                                                ? 'hidden'
                                                : 'visible'
                                        }
                                        height="26px"
                                        width="26px"
                                        minWidth="26px"
                                        transition="opacity 300ms"
                                        colorScheme={
                                            deleteExistingFav
                                                ? 'red'
                                                : updateExistingFav
                                                ? 'cyan'
                                                : 'teal'
                                        }
                                        isChecked={isChecked}
                                        isReadOnly={true}
                                        pointerEvents="none"
                                    ></Checkbox>
                                </Flex>
                            )
                        })
                    ) : (
                        <Flex
                            w="100%"
                            minHeight="40px"
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="column"
                            py="30px"
                            gap="15px"
                            cursor="default"
                        >
                            <Text
                                minHeight="20px"
                                color="gray.500"
                                fontWeight="400"
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.95rem',
                                }}
                                width="100%"
                                textAlign="center"
                            >
                                Your saved favourites list is empty
                            </Text>
                            <Text
                                pb="5px"
                                minHeight="20px"
                                color="gray.500"
                                fontWeight="300"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.8rem',
                                }}
                                width="100%"
                                textAlign="center"
                            >
                                {!showManageFavourites && !tab0Text ? (
                                    'Create a Saved Favourite from within a Standard or Custom Briefing'
                                ) : (
                                    <Text>
                                        Select{' '}
                                        <Link
                                            px="1px"
                                            fontWeight="500"
                                            color="gray.600"
                                            _hover={{
                                                textDecoration: 'none',
                                                cursor: 'default',
                                                // color: 'light.200',
                                                // cursor: 'pointer',
                                            }}
                                            // onClick={() => { // TODO - sort this out later if needed. Its buggy
                                            //     setTabIndex(0)
                                            // }}
                                        >
                                            {updateExistingFav
                                                ? 'Save As New'
                                                : tab0Text}
                                        </Link>{' '}
                                        to add your first favourite Briefing
                                    </Text>
                                )}
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    )
}

export const ExcludedItemsSelectButton = (props) => {
    const {
        selectItem,
        otherItem,
        setSelectItem,
        setOtherItem,
        updatedItem,
        setUpdatingExcludedItems,
        type,
    } = props

    return (
        <Tooltip
            label={
                selectItem && type
                    ? 'Close'
                    : `Reinstate or exclude ${
                          type === 'aws'
                              ? 'Automatic Weather Stations'
                              : 'Aerodromes'
                      }`
            }
            hasArrow
            borderRadius="7px"
            minHeight="26px"
            fontSize="0.7rem"
            textAlign="center"
            alignItems="center"
            display={isMobile || !isDesktop ? 'none' : 'flex'}
            isDisabled={isMobile || !isDesktop}
            placement="left"
            m="5px"
            mr="2px"
            color="light.10"
            bg="dark.10"
        >
            <Button
                variant="outline"
                borderRadius="25px"
                mt={{ base: '0px', md: '-5px' }}
                mb="8px"
                fontSize="0.75rem"
                height={{
                    base: '30px',
                    md: '26px',
                }}
                width="74px"
                minWidth="74px"
                background={updatedItem && selectItem ? 'light.200' : 'white'}
                borderColor={
                    updatedItem && selectItem ? 'light.200' : 'gray.200'
                }
                color={
                    updatedItem && selectItem
                        ? 'white'
                        : selectItem
                        ? 'light.200'
                        : 'gray.600'
                }
                fontWeight="500"
                transition="all ease 200ms"
                _hover={{
                    color:
                        updatedItem && selectItem
                            ? 'white'
                            : selectItem
                            ? 'light.200'
                            : 'gray.800',

                    opacity: '1',
                    background:
                        updatedItem && selectItem ? 'light.201' : 'light.30',
                    borderColor:
                        updatedItem && selectItem ? 'light.201' : 'gray.200',
                }}
                _active={{
                    background:
                        updatedItem && selectItem ? 'light.201' : 'light.30',
                    boxShadow: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                onClick={() => {
                    if (otherItem) {
                        setOtherItem(false)
                        setUpdatingExcludedItems(false)
                    }
                    setSelectItem((prevState) => !prevState)
                }}
            >
                <Icon
                    as={FiEdit}
                    fontSize={selectItem ? '0rem' : '1rem'}
                    variant="outline"
                    pb="1px"
                    transition="all ease 200ms"
                />
                <Text pr="4px">
                    {updatedItem && selectItem
                        ? 'UPDATE'
                        : selectItem
                        ? 'Close'
                        : 'Edit'}
                </Text>
            </Button>
        </Tooltip>
    )
}
